import { styled, keyframes } from 'styled-components';
// import { GRADIENT_COLOR } from '../constents/BaseStyles';
import loading from '../../assets/images/Loading.gif';

const ScaleOut = keyframes`
0% {
  transform: scale(0);
}
100% {
  transform: scale(1);
}
`;

const LoaderContainer = styled.span`
  width: 140px;
  height: 140px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  background: url(${loading}) center no-repeat;

  &::before,
  &::after {
    content: '';
    box-sizing: border-box;
    width: 140px;
    height: 140px;
    // border: 2px solid red;
    position: absolute;
    // left: 0;
    // top: 0;
    animation: ${ScaleOut} 2s ease-in-out infinite;
  }

  &::after {
    // border-color: green;
    animation-delay: 1s;
  }
`;
const LoaderMainConatiner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;
export { LoaderMainConatiner, LoaderContainer };
