import { Row } from '../CalendarModal/CalendarModal.Styles';
import { CircleStackContainer, KababIcons, UserIconText } from './Cards.Styles';
import BlueKabab from '../../assets/icons/BlueKabab.svg';
import DynamicSVGIcon from '../DynamicSVGIcon';
import {
  ProfileDiv,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import {
  FIND_COLOR_RANGE,
  FORMAT_TIMESTAMP,
  GET_DUMMY_PROJECT_COLOR,
  STANDARDIZE_PROJECT_TYPE,
} from '../../constants/functions';
import { COLORS } from '../../constants/BaseStyles';
import {
  CLIENT_TYPE_USER_LIST,
  GET_INITIALS_FROM_NAME,
  PROJECT_OPTION,
} from '../../constants/constant';
import {
  ProjectCardMenuContainer,
  ProjectCardTitleContainer,
  ProjectCardTopPartCantainer,
  ProjectName,
  ProjectTypeText,
} from './ProjectCards.Styles';
import Tag from '../../assets/icons/tag.svg';
import { useState } from 'react';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import OptionCard from '../options/OptionCard';
import { useSelector } from 'react-redux';

const ProjectCards = ({ data, onCardClick, onClickOption = () => {} }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const statusColor = GET_DUMMY_PROJECT_COLOR(data);
  const type = STANDARDIZE_PROJECT_TYPE(data);
  const format = 'Do MMMM, YYYY';
  const { userData } = useSelector((state) => state.loggedinUser);

  return (
    <>
      <ProjectCardTopPartCantainer
        height={'120px'}
        bg={statusColor}
        onClick={() => {
          onCardClick(data);
        }}
      >
        {!CLIENT_TYPE_USER_LIST.includes(userData?.type) && (
          <ProjectCardMenuContainer>
            <KababIcons
              onClick={(e) => {
                e.stopPropagation();
                setIsOptionsOpen((prev) => !prev);
              }}
            >
              {isOptionsOpen && (
                <UseClickOutside
                  onClickOutside={() => {
                    setIsOptionsOpen(false);
                  }}
                >
                  <OptionCard
                    right={'0px'}
                    top={'20px'}
                    onClickOption={onClickOption}
                    additionalData={data}
                    option={PROJECT_OPTION}
                  />
                </UseClickOutside>
              )}
              <DynamicSVGIcon iconUrl={BlueKabab} width="0.18rem" />
            </KababIcons>
          </ProjectCardMenuContainer>
        )}
        <ProjectTypeText bg={statusColor}>
          <div>
            <DynamicSVGIcon iconUrl={Tag} width="1.5rem" />
          </div>
          <div>{type}</div>
        </ProjectTypeText>
        <ProjectCardTitleContainer justify={'space-between'}>
          <ProjectName>{data?.project_name}</ProjectName>
        </ProjectCardTitleContainer>
      </ProjectCardTopPartCantainer>
      <Row spaceBetween bgColor={'#2A2A2A'} height={'55px'} padding={'20px'}>
        <CircleStackContainer>
          {data?.users?.slice(0, 4)?.map((data) => {
            return (
              <ProfileDiv
                stackedProfiles
                img={data?.profile_pic}
                bg={FIND_COLOR_RANGE(data?.name, data?.profile_pic)}
              >
                {!data?.profile_pic ? (
                  <UserIconText>
                    {GET_INITIALS_FROM_NAME(data?.name)}
                  </UserIconText>
                ) : null}
                {/* {!data?.profile_pic ? GET_INITIALS_FROM_NAME(data?.name) : ''} */}
              </ProfileDiv>
            );
          })}
          {data?.users_count > 4 && data?.users_count - 4}
        </CircleStackContainer>
        <RoleWrapper color={COLORS.TEXT_MEDIUM_GREY}>
          {FORMAT_TIMESTAMP(data?.updated_at, format)}
        </RoleWrapper>
      </Row>
    </>
  );
};
export default ProjectCards;
