import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { Col, Label } from '../CalendarModal/CalendarModal.Styles';
import { STRINGS } from '../../constants/labels';
import { ASSET_STATUS } from '../../constants/constant';
import FormatStatusLabel from '../Dropdown/FormatStatusLabel.View';
import { FormContainer, SelectDiv } from './FormComponent.Styles';
import generateStatusStyles from '../Dropdown/StatusDropdownStyle';

const AddLinkForm = ({
  fontSize,
  setAddLinkData,
  addLinkData,
  edit = false,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  const [selectedStatus, setSelectedStatus] = useState(null);
  //STYLE SELECT DROPDOWN
  const dynamicStyles = generateStatusStyles();

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
    setAddLinkData({
      ...addLinkData,
      status: {
        value: option?.value,
      },
    });
  };

  useEffect(() => {
    if (edit) {
      const response = ASSET_STATUS.filter(
        (data) => data?.value === addLinkData?.status?.value
      );
      setSelectedStatus(response[0]);
    }
  }, [edit]);
  return (
    <FormContainer>
      <Col width={'560px'}>
        <NameWrapper fontSize={'17px'}>
          {edit ? strings.UPDATE_ENTRY : strings.ADD_NEW_ENTRY}
        </NameWrapper>
        <RoleWrapper>{strings.ENTER_DETAILS}</RoleWrapper>
      </Col>
      <Col justify={'flex-end'} width={'270px'}>
        <Label fontSize={fontSize}>{strings.TITLE}</Label>
      </Col>
      <Input
        width={'450px'}
        type="text"
        placeholder={strings.ENTER_TITLE}
        value={addLinkData?.title}
        maxLength={100}
        onChange={(e) => {
          setAddLinkData({
            ...addLinkData,
            title: e.target.value,
          });
        }}
      />
      <Col justify={'flex-end'} width={'270px'}>
        <Label fontSize={fontSize}>{strings.LINK}</Label>
      </Col>
      <Input
        width={'450px'}
        type="text"
        placeholder={strings.ENTER_LINK}
        value={addLinkData?.link}
        onChange={(e) => {
          const inputValue = e.target.value;
          let modifiedLink = inputValue;

          if (
            !inputValue.startsWith('http') &&
            !inputValue.startsWith('https')
          ) {
            // If the link doesn't start with "http" or "https", add "https://"
            modifiedLink = `https://${inputValue}`;
          }

          setAddLinkData({
            ...addLinkData,
            link: modifiedLink,
          });
        }}
      />

      <Col justify={'flex-end'} width={'270px'}>
        <Label fontSize={fontSize}>{strings.STATUS}</Label>
      </Col>
      <SelectDiv marginLeft={'55px'}>
        <Select
          options={ASSET_STATUS}
          value={selectedStatus}
          onChange={handleStatusChange}
          searchable={true}
          styles={dynamicStyles}
          formatOptionLabel={FormatStatusLabel}
          menuPortalTarget={document.querySelector('body')}
          placeholder={strings.SELECT_STATUS}
        />
      </SelectDiv>
    </FormContainer>
  );
};

export default AddLinkForm;
