/**
 * apiEndpoints js
 *
 * All apis' urls are defined here
 *
 * Project: MarkoApp
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { IS_DEV } from '../../constants/constant';

export const rootConfig = {
	apiRoot: IS_DEV ? 'https://api.marko.panorbitprojects.com/api' : 'https://pano-api.panorbitprojects.com/api',
};

export const serverRootDomain = 'panorbitprojects.com';

export const apiEndPoints = {
  createUser: `${rootConfig.apiRoot}/register/`,
  forgetPassword: `${rootConfig.apiRoot}/forgot-password/`,
  login: `${rootConfig.apiRoot}/login/`,
  validate: `${rootConfig.apiRoot}/validate_otp/`,
  logout: `${rootConfig.apiRoot}/logout/`,
  getMyAgency: `${rootConfig.apiRoot}/get_user_agency/`,
  editMyAgency: `${rootConfig.apiRoot}/update_company/`,
  addClient: `${rootConfig.apiRoot}/register_company/`,
  editClient: `${rootConfig.apiRoot}/update_company/`,
  viewClients: `${rootConfig.apiRoot}/list_companies/`,
  viewClient: `${rootConfig.apiRoot}/company_details/`,
  viewProjects: `${rootConfig.apiRoot}/list_projects/`,
  listLinks: `${rootConfig.apiRoot}/list_links/`,
  addLink: `${rootConfig.apiRoot}/add_link/`,
  deleteLink: `${rootConfig.apiRoot}/delete_link/`,
  editLink: `${rootConfig.apiRoot}/update_link/`,
  deleteProject: `${rootConfig.apiRoot}/delete_project/`,
  deleteClients: `${rootConfig.apiRoot}/delete_company/`,
  users: `${rootConfig.apiRoot}/list_users/`,
  posts: `${rootConfig.apiRoot}/list_users/`,
  deleteUser: `${rootConfig.apiRoot}/delete/`,
  selectCompany: `${rootConfig.apiRoot}/filter_companies/`,
  selectUserCompany: `${rootConfig.apiRoot}/filter_user_companies/`,
  addUser: `${rootConfig.apiRoot}/register/`,
  editUSer: `${rootConfig.apiRoot}/update_user/`,
  userDetails: `${rootConfig.apiRoot}/get_user/`,
  createProject: `${rootConfig.apiRoot}/create_project/`,
  userList: `${rootConfig.apiRoot}/filter_users/`,
  updateProject: `${rootConfig.apiRoot}/update_project/`,
  projectDetails: `${rootConfig.apiRoot}/project_details/`,
  addPost: `${rootConfig.apiRoot}/add_event/`,
  viewPosts: `${rootConfig.apiRoot}/list_event/`,
  getEvent: `${rootConfig.apiRoot}/get_event/`,
  updateEvent: `${rootConfig.apiRoot}/update_event/`,
  addAsset: `${rootConfig.apiRoot}/add_update_asset/`,
  getAssets: `${rootConfig.apiRoot}/get_asset/`,
  getAssetVersions: `${rootConfig.apiRoot}/get_asset_versions/`,
  getSingleAssetVersion: `${rootConfig.apiRoot}/get_single_asset_version/`,
  addComment: `${rootConfig.apiRoot}/add_comment/`,
  addWebsiteComment: `${rootConfig.apiRoot}/add_comment_webpage/`,
  delete_comment: `${rootConfig.apiRoot}/delete_comment/`,
  update_comment: `${rootConfig.apiRoot}/update_comment/`,
  getComments: `${rootConfig.apiRoot}/get_comments_by_asset_id/`,
  getComment: `${rootConfig.apiRoot}/get_comment/`,
  updateAssetVersion: `${rootConfig.apiRoot}/update_asset_version/`,
  scrapeWebpage: `${rootConfig.apiRoot}/scrape_webpage/`,
  htmlWebpage: `${rootConfig.apiRoot}/get_s3_webpage_data/`,
  getWebpage: `${rootConfig.apiRoot}/get_webpage_version/`,
  getWebpageVersions: `${rootConfig.apiRoot}/get_webpage_all_versions/`,
  getAllWebpages: `${rootConfig.apiRoot}/get_all_webpages/`,
  deleteWebpage: `${rootConfig.apiRoot}/delete_webpage_by_id/`,
  getCommentsByWebpage: `${rootConfig.apiRoot}/get_comments_by_webpage_id/`,
  updateWebpageVersion: `${rootConfig.apiRoot}/update_webpage_version/`,
  fileList: `${rootConfig.apiRoot}/list_files/`,
  createFolder: `${rootConfig.apiRoot}/create_folder/`,
  uploadFile: `${rootConfig.apiRoot}/upload_multiple_files/`,
  uploadFolder: `${rootConfig.apiRoot}/upload_folder/`,
  deleteFolder: `${rootConfig.apiRoot}/delete_folder/`,
  // notification
  notificationList: `${rootConfig.apiRoot}/list_notification/`,
  notificationCount: `${rootConfig.apiRoot}/get_notification_count/`,
  readNotification: `${rootConfig.apiRoot}/read_notification/`,
  //
  getProjectByCompanyId: `${rootConfig.apiRoot}/get_projects_by_company_id/`,
  // assets->
  assetsComment: `${rootConfig.apiRoot}/get_comments_by_asset_version/`,
  usersAgency: `${rootConfig.apiRoot}/get_users_by_company_id/`,
};
