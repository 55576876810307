/**
 * Button View js
 *
 * This component will make button component
 *
 * Project: MarkoApp
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 *
 */
import DynamicSVGIcon from '../DynamicSVGIcon';
import { ButtonContainer, ButtonText, RightIcon } from './Button.Styles';
import DropDownIcon from '../../assets/icons/DropDownIcon.svg';
const Button = ({
  text,
  width,
  height,
  bgColor,
  id,
  opacity,
  fontSize,
  rightIconrotation = -90,
  paddingleft,
  rightIcon,
  iconSize,
  btnClick = () => {},
}) => {
  return (
    <ButtonContainer
      width={width}
      height={height}
      bgColor={bgColor}
      id={id}
      opacity={opacity}
      onClick={() => {
        btnClick();
      }}
    >
      <ButtonText id={id} opacity={opacity} fontSize={fontSize}>
        {text}
      </ButtonText>
      {rightIcon && (
        <RightIcon paddingleft={paddingleft}>
          <DynamicSVGIcon
            iconUrl={rightIcon}
            rotate={rightIconrotation}
            width={iconSize}
          />
        </RightIcon>
      )}
    </ButtonContainer>
  );
};
export default Button;
