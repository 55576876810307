import { createReducer } from 'reduxsauce';
import { BaseTypes } from './action';

const INITIAL_STATE = {
  notificationLoading: false,
  notificationList: [],
  notificationListCount: 0,
};

// <-----------------------reducer for viewing list of brands-------------------->

const notificationListStart = (state = INITIAL_STATE, action) => {
  return {
    notificationLoading: true,
    ...state,
  };
};
const notificationListSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    notificationLoading: false,
    notificationList: data?.data,
  };
};
const notificationListError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    notificationLoading: false,
    errorMessage,
  };
};
// <-----------------------reducer for count list of notification-------------------->

const notificationCountStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const notificationCountSuccess = (state = INITIAL_STATE, action) => {
  const { unread_notification_count } = action.payload?.data;
  return {
    ...state,
    notificationListCount: unread_notification_count,
  };
};
const notificationCountError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    errorMessage,
  };
};
//
// <-----------------------reducer for read notification-------------------->

const readNotificationStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const readNotificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    // notificationListCount: unread_notification_count,
  };
};
const readNotificationError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    errorMessage,
  };
};

const HANDLERS = {
  [BaseTypes.NOTIFICATION_LIST_START]: notificationListStart,
  [BaseTypes.NOTIFICATION_LIST_SUCCESS]: notificationListSuccess,
  [BaseTypes.NOTIFICATION_LIST_ERROR]: notificationListError,
  //
  [BaseTypes.NOTIFICATION_COUNT_START]: notificationCountStart,
  [BaseTypes.NOTIFICATION_COUNT_SUCCESS]: notificationCountSuccess,
  [BaseTypes.NOTIFICATION_COUNT_ERROR]: notificationCountError,
  //
  [BaseTypes.READ_NOTIFICATION_START]: readNotificationStart,
  [BaseTypes.READ_NOTIFICATION_SUCCESS]: readNotificationSuccess,
  [BaseTypes.READ_NOTIFICATION_ERROR]: readNotificationError,
};
export const baseReducer = createReducer(INITIAL_STATE, HANDLERS);
