/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-template */
/* eslint-disable function-paren-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useSelector } from 'react-redux';
// import { kpvData } from '../../../constants/sampleData';
import styled from 'styled-components';
import moment from 'moment';
// import {
//   AddToCart,
//   CancelBlack,
//   RemoveFromCart,
//   searchBlack,
//   rightArrow,
// } from '../../../assets/icons';
import {
  Container,
  ContentContainer,
  HeaderContainer,
  MachineHeaderText,
  Row,
  TableContainer,
  Cell,
  CancelIcon,
  SearchIcon,
  SearchInput,
  ActionIcon,
  SearchBar,
} from './TabsTable.Style.jsx';
import Loader from '../loader/Loader.View';
import DynamicSVGIcon from '../DynamicSVGIcon.js';
// import Pagination from './TableDependency/Pagination/Pagination.view';
import {
  Table,
  Tr,
  Td,
  Th,
  // FlexContainer,
  // ArrowIC,
} from './TabsTable.Style.jsx';
import {
  Colors,
  FlexContainer,
  ArrowIC,
  fontSize,
} from '../../constants/BaseStyles';
// import Toast from './TableDependency/Toast/index.jsx';
// import DropDownModal from './TableDependency/DropDownModal/DropDownModal_V1';
// import PopUpModal from './TableDependency/PopUpModal/Modal.view';
// import ButtonComp from './TableDependency/Button.view';
import { isEmpty } from '../../utils/utility.js';
import Groupby from './GroupBy.jsx';
import TableContent from './TableContent';
import { curryIsOptionsVisible, OPTIONS_TYPES } from '../../utils/utility.js';
import {
  CustomCheckBox,
  CustomCheckBoxOuter,
  CheckBoxInput,
} from '../../constants/GlobalStyles.Styles';
import PageInfiniteScroll from './PageInfiniteScroll.view.jsx';
// import { compare } from './utility';
// import SortBy from './TableDependency/OptionsModal/OptionsModal';
import SortArrowDown from '../../assets/icons/SortArrowDown.svg';
import SortArrowUp from '../../assets/icons/SortArrowUp.svg';
import { COLORS } from '../../constants/BaseStyles';
import Loading from '../loader/Loader.View.jsx';
// import EmptyFile from '../EmptyFileComponent/EmptyFile.View';

const TabTable = styled(Table)`
  background-color: ${Colors.light.core.secondary};
  border-radius: 10px;
  padding: 1rem;
  padding-bottom: 0;
  border-spacing: 0;
  position: relative;
`;

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.backgroundColor};
  z-index: 1;
`;

const TabsTable = ({
  // activeTab = 'a',
  // setActiveTab,
  // tabs = [
  //   { id: 1, name: 'a' },
  //   { id: 2, name: 'b' },
  //   { id: 3, name: 'c' }
  // ],
  // removeTab,
  // handleTabSelect,
  metaDetails,
  dataDetails,
  extraDetails,
  pageSize,
  checkList,
  setCheckList,
  scrollInfinite,
  handlePageChange,
  assetShortlist,
  shortListData,
  handleShortList,
  assetListLoading,
  onItemSelect = () => {},
  showPagiation = true,
  saveSearchQueries,
  savedSearchQueries,
  showShortlistSelection = true,
  resetSearchQueries = () => {},
  error,
  rowOptions,
  handleRowOptionsItemClick: handleRowOptionsItemClickHandler,
  id,
  tableHeaderDisable,
  ObjectComponent = () => {},
  handleSortBy = () => {},
  scrollDetails = {},
  checkBoxDisable = false,
  CustomTableRow = false,
  customTableStyle = {},
  customCellStyle = {},
  customHeaderStyle = {},
  scrollHeight,
  radio = false,
  rowOptionsDependentKeys = [],
  loading = false,
  isMultiple,
  children = <></>,
}) => {
  const [allFilesChecked, setAllFilesChecked] = useState(false);
  const [showSearchInp, setShowSearchInp] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const [searchQuery, setSearchQuery] = useState(savedSearchQueries || {});
  const [filteredResultData, setFilteredResultData] = useState(null);
  const [groupByList, setGroupByList] = useState([]);
  const [groupBy, setGroupBy] = useState();
  const searchRef = useRef();
  // const metaColumns = metaDetails?.columns || metaDetails?.fields;
  const [isInc, setIsInc] = useState('');
  const [activeColumn, setActiveColumn] = useState({ key: 'updated_by' });

  const handleSelectAll = () => {
    setAllFilesChecked(!allFilesChecked);
    setCheckList(dataDetails.map((data) => data.id));
    handleShortList(dataDetails);
    if (allFilesChecked) {
      setCheckList([]);
      handleShortList([]);
    }
  };

  const handleSelectOne = (e, entry) => {
    const { id, checked } = e.target;
    const intId = Number(id);
    if (radio) {
      setCheckList([intId]);
      handleShortList([entry]);
      if (!checked) {
        setCheckList(checkList.filter((item) => item !== intId));
        handleShortList(shortListData.filter((data) => data.id !== entry.id));
      }
    } else {
      setCheckList([...checkList, intId]);
      handleShortList([...shortListData, entry]);
      if (!checked) {
        setCheckList(checkList.filter((item) => item !== intId));
        handleShortList(shortListData.filter((data) => data.id !== entry.id));
      }
    }
  };

  const isLocalSearch = !savedSearchQueries && true;
  const getDataDetailsByGroup = (dataDetails, groupBy) => {
    try {
      if (!groupBy) {
        return {
          noGrouping: dataDetails,
        };
      }
      if (!dataDetails) {
        return {
          [groupBy]: [],
        };
      }
      return dataDetails.reduce((result, dataItem) => {
        const groupValue = dataItem[groupBy];
        if (!result[groupValue]) {
          return { ...result, [groupValue]: [dataItem] };
        }
        result[groupValue].push(dataItem);
        return result;
      }, {});
    } catch (e) {}
    return {};
  };
  useEffect(() => {
    // set groupBylist & groupBy
    if (!isEmpty(metaDetails?.grouping)) {
      const grouping = metaDetails.grouping?.map((groupName) => ({
        key: groupName,
        title: metaDetails.columns?.find((col) => col.key === groupName)?.title,
      }));
      setGroupByList(grouping);
      const defaultGroup = grouping[0].key;
      setGroupBy(defaultGroup);
    }
    // compute inputs
    if (!isLocalSearch) {
      const showFeildInputs = Object.keys(savedSearchQueries).reduce(
        (acc, key) => ({ ...acc, [key]: savedSearchQueries[key] && true }),
        {}
      );
      setShowSearchInp(showFeildInputs);
    }

    return () => {
      resetSearchQueries();
    };
  }, []);

  // format the normal/fitered data based on groups
  const [rowsDetails, setRowsDetails] = useState({});
  useEffect(() => {
    const rowsDetailsArrRes =
      Object.keys(showSearchInp).some((e) => showSearchInp[e]) &&
      filteredResultData
        ? filteredResultData
        : dataDetails;
    const rowsDetailsRes = rowsDetailsArrRes?.length
      ? getDataDetailsByGroup(rowsDetailsArrRes, groupBy)
      : {};
    setRowsDetails(rowsDetailsRes);
  }, [filteredResultData, dataDetails, groupBy]);

  // useEffect(() => {
  //   // searchable feilds computation
  //   const metaColumns = metaDetails?.columns || metaDetails?.fields;
  //   const searchableFields = metaColumns?.filter((field) => field.searchable);
  //   const tempShowSearchInp = {};
  //   searchableFields?.forEach((e) => {
  //     tempShowSearchInp[e.key] = savedSearchQueries[e.key]?.length > 0;
  //   });
  //   setShowSearchInp({ ...tempShowSearchInp });
  // }, []);

  const fetchData = (searchQueryParam) => {
    saveSearchQueries(searchQueryParam || searchQuery);
    handlePageChange(1, { searchParams: searchQueryParam || searchQuery });
  };

  const [showToast, setShowToast] = useState(false);
  const handleSearchQuery = (field, e, isDirectValue = false) => {
    let locSearchQuery = e?.target?.value || '';
    if (isDirectValue) {
      locSearchQuery = e;
    }
    if (!isLocalSearch && !isDirectValue) {
      // toast handling
      if (!showToast && locSearchQuery.length > 0) {
        setShowToast(true);
      } else if (locSearchQuery.length < 1) {
        setShowToast(false);
      }
    }

    const updatedSearchQueries = { ...searchQuery, [field]: locSearchQuery };
    setSearchQuery(updatedSearchQueries);
    if (isLocalSearch) {
      let tempFilteredKpvGroupDet = { ...dataDetails };

      // eslint-disable-next-line arrow-body-style
      tempFilteredKpvGroupDet = dataDetails.filter((item) => {
        return Object.keys(searchQuery).every((query) => {
          if (query === field) {
            return String(item[query])
              .toLowerCase()
              .includes(locSearchQuery.toLowerCase());
          }
          // all other cumulative search queries are checked
          return String(item[query])
            .toLowerCase()
            .includes(searchQuery[query].toLowerCase());
        });
      });

      setFilteredResultData(tempFilteredKpvGroupDet);
    } else {
      const savedSearchQuery = savedSearchQueries[field] || '';
      // fetch data if complete data is present(isDirectValue===true)
      if (isDirectValue && savedSearchQuery !== locSearchQuery) {
        fetchData({ ...searchQuery, [field]: locSearchQuery });
      }
    }
  };

  const _handleKeyPress = (e) => {
    e.preventDefault();
    if (e.key === 'Enter' && !isLocalSearch) {
      setShowToast(false);
      fetchData();
    }
  };

  const handleUICompVisiblity = ({ field, index }, uiComp = 'input') => {
    if (uiComp === 'dropdown') {
      setShowDropdown({ ...showDropdown, [field]: !showDropdown[field] });
    } else {
      // closing the searchinput(click cancel icon)
      if (showSearchInp[field]) {
        handleSearchQuery(field, '', true);
      }
      setShowSearchInp({ ...showSearchInp, [field]: !showSearchInp[field] });
    }
  };

  const handleIconSort = (col) => {
    setIsInc((prev) => {
      return !prev;
    });
    handleSortBy(col, isInc);
  };

  const DateRangeModal = (col) => <></>;

  const handleGroupBy = (item) => {
    setGroupBy(item);
  };

  const visibleColumnsCount = metaDetails?.columns?.reduce((count, obj) => {
    if (obj.visible) {
      return count + 1;
    }
    return count;
  }, 0);

  return (
    <Container className="table" left={'100px'}>
      {loading && !metaDetails?.pageNumber ? (
        <Loading height={'70Vh'} />
      ) : dataDetails.length > 0 ? (
        <ContentContainer>
          <PageInfiniteScroll
            dataLength={dataDetails.length}
            extraDetails={extraDetails}
            scrollInfinite={scrollInfinite}
            scrollDetails={scrollDetails}
            scrollHeight={scrollHeight}
          >
            <TabTable style={{ ...customTableStyle }}>
              <TableHead backgroundColor={COLORS.PRIMARY_BLACK}>
                <Tr
                  className="header"
                  style={{ display: tableHeaderDisable ? 'none' : '' }}
                >
                  {!checkBoxDisable && (
                    <Th>
                      <CustomCheckBoxOuter>
                        <CheckBoxInput
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            dataDetails.length === shortListData.length
                              ? true
                              : false
                          }
                        ></CheckBoxInput>
                        <CustomCheckBox></CustomCheckBox>
                      </CustomCheckBoxOuter>
                    </Th>
                  )}
                  {metaDetails?.columns?.map((col, index) => {
                    const { searchable, sortable, options, type } = col;
                    const columnOptionsConfig = {
                      searchable,
                      sortable,
                      options,
                      type,
                    };
                    const isOptionVisible =
                      curryIsOptionsVisible(columnOptionsConfig);
                    return (
                      col?.visible && (
                        <Th
                          border
                          key={col.key}
                          width={col.width}
                          className={'colHeading ' + col.key}
                          style={{ padding: '0 1rem' }}
                        >
                          <FlexContainer
                            className="jc_center"
                            style={{
                              textAlign: 'center',
                              position: 'relative',
                            }}
                          >
                            {showSearchInp[col.key] &&
                            isOptionVisible(OPTIONS_TYPES.SEARCHABLE) ? (
                              <SearchInput
                                className="show"
                                ref={searchRef}
                                onKeyUp={(e) => _handleKeyPress(e)}
                                type="text"
                                placeholder={'Search ' + col.title}
                                onChange={(e) => handleSearchQuery(col.key, e)}
                                value={searchQuery[col.key]}
                                // onKeyUp={(e) => _handleKeyPress(e)}
                              />
                            ) : (
                              <>
                                <FlexContainer
                                  className="col ai_center title_hover"
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                  }}
                                  onClick={() => {
                                    columnOptionsConfig.sortable &&
                                      handleIconSort(col);
                                    columnOptionsConfig.sortable &&
                                      setActiveColumn(col);
                                  }}
                                >
                                  {/* show title for all and dropdown value if present */}
                                  <p
                                    style={{
                                      fontSize: '0.8rem',
                                      fontWeight: '600',
                                      ...customHeaderStyle,
                                    }}
                                    className="title"
                                  >
                                    {col.title +
                                      (col.options?.length &&
                                      searchQuery[col.key]
                                        ? '=' + searchQuery[col.key]
                                        : '')}
                                  </p>
                                </FlexContainer>
                                {columnOptionsConfig.sortable && (
                                  <ArrowIC
                                    className="arrow"
                                    onClick={() => {
                                      columnOptionsConfig.sortable &&
                                        handleIconSort(col);
                                      columnOptionsConfig.sortable &&
                                        setActiveColumn(col);
                                    }}
                                  >
                                    <DynamicSVGIcon
                                      width="16px"
                                      iconUrl={
                                        isInc ? SortArrowUp : SortArrowDown
                                      }
                                    />
                                  </ArrowIC>
                                )}
                              </>
                            )}
                            {/* search/cancel icon only for input type */}
                            {isOptionVisible(OPTIONS_TYPES.SEARCHABLE) && (
                              <SearchIcon
                                onClick={() =>
                                  handleUICompVisiblity({
                                    field: col.key,
                                    index,
                                  })
                                }
                              ></SearchIcon>
                            )}

                            {/* dropdown icon for open/close state */}
                            {(isOptionVisible(OPTIONS_TYPES.HAS_OPTIONS) ||
                              isOptionVisible(OPTIONS_TYPES.IS_DATE_TIME)) && (
                              // dropdown/cancel icon based on value
                              <SearchIcon>
                                {!searchQuery[col.key] ? (
                                  <div
                                    style={{
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      transform: 'rotate(90deg)',
                                    }}
                                    onClick={() =>
                                      handleUICompVisiblity(
                                        { field: col.key, index },
                                        'dropdown'
                                      )
                                    }
                                  ></div>
                                ) : (
                                  <FlexContainer
                                    style={{ width: '100%' }}
                                    className="jc_center"
                                  >
                                    <div
                                      style={{
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        transform: 'rotate(270deg)',
                                      }}
                                      onClick={() =>
                                        handleSearchQuery(col.key, '', true)
                                      }
                                    ></div>
                                  </FlexContainer>
                                )}
                              </SearchIcon>
                            )}

                            {/* show group by for first column & if groupList exists */}
                            {index === 0 && !isEmpty(groupByList) && (
                              <div style={{ marginLeft: 'auto' }}>
                                <Groupby
                                  selected={groupBy}
                                  onClick={handleGroupBy}
                                  list={groupByList}
                                />
                              </div>
                            )}
                          </FlexContainer>
                          {/* dropdown ui */}
                          <div style={{ width: '100%', position: 'relative' }}>
                            {col.type !== 'DateTime' &&
                              showDropdown[col.key] && <></>}
                          </div>
                          {/* date dropdown ui */}
                          <div
                            style={{
                              width: '200%',
                              position: 'relative',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {col.type === 'DateTime' &&
                              showDropdown[col.key] && (
                                <>{DateRangeModal(col)}</>
                              )}
                          </div>
                        </Th>
                      )
                    );
                  })}
                </Tr>
              </TableHead>
              <tbody>
                {assetListLoading ? (
                  <Loader />
                ) : (
                  Object.keys(rowsDetails).map((type, index) => (
                    <TableContent
                      checkList={checkList}
                      handleSelectOne={handleSelectOne}
                      key={index}
                      type={type}
                      rowsDetails={rowsDetails}
                      metaDetails={metaDetails}
                      assetShortlist={assetShortlist}
                      visibleColumnsCount={visibleColumnsCount}
                      onItemSelect={onItemSelect}
                      showShortlistSelection={showShortlistSelection}
                      handleShortList={handleShortList}
                      rowOptions={rowOptions}
                      id={id}
                      handleRowOptionsItemClickHandler={
                        handleRowOptionsItemClickHandler
                      }
                      ObjectComponent={ObjectComponent}
                      checkBoxDisable={checkBoxDisable}
                      rowOptionsDependentKeys={rowOptionsDependentKeys}
                      customCellStyle={customCellStyle}
                    />
                  ))
                )}
              </tbody>
            </TabTable>
          </PageInfiniteScroll>
          {!assetListLoading &&
            !error &&
            metaDetails?.columns &&
            !Object.values(rowsDetails).some((values) => values.length > 0) && (
              <div style={{ fontSize: fontSize.text, textAlign: 'center' }}>
                No items available
              </div>
            )}
        </ContentContainer>
      ) : (
        children
      )}
    </Container>
  );
};

export default TabsTable;
