import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Col } from '../../components/CalendarModal/CalendarModal.Styles';
import { CardsContainer } from '../dashboards/adminDashboard.Styles';
import ProjectCards from '../../components/Cards/ProjectCards';
import { useEffect, useState } from 'react';
import {
  BRAND_ERROR,
  CLIENT_CARD_NAMES,
  CLIENT_TYPE_USER_LIST,
  DUMMY_PROJECT_NAMES,
  INTERNAL_TEAM_MEMBER_LIST,
} from '../../constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { BrandCreators } from './store/action';
import { Creators as ProjectCreators } from '../projects/store/action';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import AddProjectform from '../../components/form/AddProjectForm.View';
import { STRINGS } from '../../constants/labels';
import UniversalModal from '../../components/modals/UniversalModal';
import ProjectTypeForm from '../../components/form/ProjectTypeForm';
import { AddProjectsCreators } from '../addProjects/store/action';
import { ErrorToast } from '../../services/toast/toast';
import { TOAST_POSITION } from '../../constants/BaseStyles';
import { ModalHeading, ModalSubHeading } from './Brand.styles';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';

const BrandProjects = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  const params = useParams();
  const { company_id } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectTypes, setProjectTypes] = useState([]);
  const [formValues, setFormValues] = useState({
    project_name: '',
    project_type: '',
    website_url: '',
    base_company_spoc_username: '',
    base_company_project_lead_username: '',
    agency_users: [],
    client_spoc_username: '',
    client_users: [],
    started_at: '',
    description: '',
    base_company_id: company_id,
    socialMediaLinks: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [cardList, setCardList] = useState([]);
  const [isOpenModal, setIsopenModal] = useState(false);
  const [isSubHeaderButtonClicked, setIsSubHeaderButtonClicked] =
    useState(false);
  const [projectSelected, setProjectSelected] = useState('');
  const [searchParams] = useSearchParams();
  // console.log();
  // const {projectListData}  =

  const { userData } = useSelector((state) => state.loggedinUser);
  const { projectListData } = useSelector((state) => state.brand);

  useEffect(() => {
    if (company_id) {
      dispatch(BrandCreators.getProjectsByCompanyId({ companyId: company_id }));
    }
  }, [company_id, dispatch]);
  useEffect(() => {
    if (userData?.is_admin) {
      setCardList(DUMMY_PROJECT_NAMES);
    } else {
      setCardList(CLIENT_CARD_NAMES);
    }
  }, [userData?.is_admin]);
  const onCardClick = (data) => {
    const { project_id, project_name } = data;
    navigate(`/brands/${project_id}/table?project_name=${project_name}`);
  };

  const onClickOption = (data, additionalData) => {
    const { key } = data;
    const { project_id } = additionalData;
    switch (key) {
      case 'delete':
        setIsModalOpen({ ...isModalOpen, deleteModal: true, id: project_id });
        break;
      case 'edit':
        // setEditModal(true);
        // setIsSubHeaderButtonClicked(true);
        // setBrandValues({
        //   company_id: company_id,
        //   company_name: company_name,
        //   start_date: moment(created_at).format('YYYY-MM-DD HH:mm:ss'),
        //   account_manager_username: account_manager?.username,
        //   description: description,
        //   toggle: false,
        //   disabled: false,
        // });
        // if (company_logo) {
        //   setImageData(company_logo);
        // }
        break;
      default:
        break;
    }
  };

  const onDelete = () => {
    dispatch(
      ProjectCreators.deleteProject({
        pageNumber: 1,
        input: '',
        project_id: isModalOpen.id,
        company_id,
      })
    );
    setIsModalOpen({ ...isModalOpen, deleteModal: false, id: null });
  };

  const closeModal = () => {
    setIsopenModal(false);
    setIsSubHeaderButtonClicked(false);
    setFormValues({
      project_name: '',
      project_type: '',
      website_url: '',
      base_company_spoc_username: '',
      base_company_project_lead_username: '',
      agency_users: [],
      client_spoc_username: '',
      client_users: [],
      started_at: '',
      description: '',
      base_company_id: company_id,
    });
  };
  //
  const addProjectType = () => {
    setIsSubHeaderButtonClicked(false);
    setIsopenModal(true);
  };
  const createProject = () => {
    let fromerror = false; // Initialize to false
    Object.keys(formValues).forEach((field) => {
      if (
        formValues[field]?.length === 0 &&
        field !== 'client_users' &&
        field !== 'agency_users'
      ) {
        if (
          formValues?.project_type !== 'SOCIAL_MEDIA' &&
          field === 'socialMediaLinks'
        ) {
          fromerror = false;
        } else {
          // Check if length is 0
          ErrorToast(
            BRAND_ERROR[field],
            TOAST_POSITION.TOP_CENTER,
            `error_${field}`
          );
          fromerror = true;
        } // Set to true if any field is empty
      }
    });

    // If no errors, dispatch action
    if (!fromerror) {
      dispatch(
        AddProjectsCreators.addProject({
          addProjectData: formValues,
          companyId: company_id,
        })
      );
      fromerror = false;
      closeModal();
    }
  };

  useEffect(() => {
    if (userData?.type) {
      if (CLIENT_TYPE_USER_LIST.includes(userData?.type)) {
        navigate('/projects');
      }
    } else if (userData?.user_organisation_role) {
      if (
        CLIENT_TYPE_USER_LIST.includes(userData?.user_organisation_role) ||
        INTERNAL_TEAM_MEMBER_LIST.includes(userData?.user_organisation_role)
      ) {
        navigate('/projects');
      }
    }
  }, [navigate, userData]);

  return (
    <>
      <SubHeader
        title={searchParams.get('company_name')}
        isSubHeaderButtonClicked={isSubHeaderButtonClicked}
        setIsSubHeaderButtonClicked={setIsSubHeaderButtonClicked}
        customBackFunction={() => {
          navigate('/brands');
        }}
      />
      <Col
        width={'100%'}
        marginLeft={'0px'}
        justify={'flex-start'}
        align={'flex-start'}
      >
        <CardsContainer>
          {projectListData[company_id]?.map((data, index) => {
            return (
              <div key={index}>
                <ProjectCards
                  data={data || DUMMY_PROJECT_NAMES}
                  onCardClick={onCardClick}
                  onClickOption={onClickOption}
                />
              </div>
            );
          })}
          {projectListData[company_id]?.length === 0 ? (
            <>
              <Empty
                heading={strings.NOT_FOUND_PROJECT}
                hubheading1={strings.WANT_TO_ADD_PROJECT}
                height={'85vh'}
              />
            </>
          ) : null}
        </CardsContainer>
      </Col>
      {/* <----------------create brand project------------------> */}
      <UniversalModal
        // height={'400px'}
        isOpenModal={isSubHeaderButtonClicked}
        btn={true}
        leftText={strings.CANCEL}
        rightText={projectSelected ? strings.ADD : strings.NEXT}
        // rightBtnopacity={projectSelected.length}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={addProjectType}
        onleftbtnClick={closeModal}
      >
        <ProjectTypeForm
          projectSelected={projectSelected}
          setProjectSelected={setProjectSelected}
          projectTypes={projectTypes}
          setProjectTypes={setProjectTypes}
        />
      </UniversalModal>
      {/* <------------------------------------------------------------> */}
      <UniversalModal
        isOpenModal={isOpenModal}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={createProject}
        onleftbtnClick={closeModal}
      >
        {/* branding */}

        {/* {projectSelected === BRANDING && ( */}
        <AddProjectform
          formValues={formValues}
          setFormValues={setFormValues}
          projectTypes={projectSelected}
        />
        {/* )} */}
      </UniversalModal>
      {/* <-----------------------------------------------------------> */}
      <UniversalModal
        isOpenModal={isModalOpen.deleteModal}
        leftText={'Cancel'}
        rightText={'Delete'}
        borderRadius={'14px'}
        btn={true}
        onleftbtnClick={() =>
          setIsModalOpen({ ...isModalOpen, deleteModal: false, id: null })
        }
        onrightbtnClick={onDelete}
      >
        <div
          style={{
            width: '360px',
            height: '160px',
          }}
        >
          <ModalHeading>Delete Project?</ModalHeading>
          <ModalSubHeading>
            You are about to delete this project
          </ModalSubHeading>
        </div>
      </UniversalModal>
    </>
  );
};
export default BrandProjects;
