import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  brandList: ['payload'],
  brandListStart: ['payload'],
  brandListSuccess: ['payload'],
  brandListError: ['payload'],
  //
  deleteBrandList: ['payload'],
  deleteBrandListStart: ['payload'],
  deleteBrandListSuccess: ['payload'],
  deleteBrandListError: ['payload'],
  //
  addBrand: ['payload'],
  addBrandStart: ['payload'],
  addBrandSuccess: ['payload'],
  addBrandError: ['payload'],
  //
  editBrand: ['payload'],
  editBrandStart: ['payload'],
  editBrandSuccess: ['payload'],
  editBrandError: ['payload'],
  //
  getProjectsByCompanyId: ['payload'],
  getProjectsByCompanyIdStart: ['payload'],
  getProjectsByCompanyIdSuccess: ['payload'],
  getProjectsByCompanyIdError: ['payload'],
});
export { Types as BrandTypes, Creators as BrandCreators };
