import React from 'react';
import { Row } from '../CalendarModal/CalendarModal.Styles';
import { NameWrapper } from '../../pages/BaseLayout/BaseLayout.Styles';

const FormatLabelV1 = (option) => {
  return (
    <Row flexStart>
      <NameWrapper>{option?.label}</NameWrapper>
    </Row>
  );
};

export default FormatLabelV1;
