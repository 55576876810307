import { call, put } from 'redux-saga/effects';
import { apiEndPoints } from '../../../services/axios/endPoint';
import {
  ApiService,
  ApiServiceMultipart,
} from '../../../services/axios/apiService';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../../constants/constant';
import { ERROR_HANDLING } from '../../../constants/functions';
import { BrandCreators } from './action';
import { SuccessToast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';

// <---------------saga for list of brands----------------->
export function* brandListSaga(action) {
  const { sortParams, pageNumber, input } = action.payload;
  try {
    yield put(BrandCreators.brandListStart());
    const response = yield call(ApiService.post, apiEndPoints.viewClients, {
      query_item: input,
      ...sortParams,
      page_size: DEFAULT_CLIENT_PAGE_SIZE,
      page: pageNumber,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(BrandCreators.brandListSuccess({ data: data?.meta }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BrandCreators.brandListError(payload));
  }
}
export function* addBrandSaga(action) {
  try {
    yield put(BrandCreators.addBrandStart());

    const { formData } = action.payload;
    const response = yield call(
      ApiServiceMultipart.post,
      apiEndPoints.addClient,
      formData
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(BrandCreators.addBrandSuccess({ data }));
      SuccessToast(
        STRINGS['ENGLISH'].BRAND_ADDED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(
        BrandCreators.brandList({
          pageNumber: 1,
          sortParams: {},
          input: '',
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BrandCreators.addBrandError(payload));
  }
}
//
export function* editBrandSaga(action) {
  try {
    yield put(BrandCreators.editBrandStart());

    const { formData } = action.payload;

    const response = yield call(
      ApiServiceMultipart.put,
      apiEndPoints.editClient,
      formData
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(BrandCreators.editBrandSuccess({ data }));
      let validationMessage = 'Brand updated successfully';
      SuccessToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'success1');
      yield put(
        BrandCreators.brandList({
          pageNumber: 1,
          sortParams: {},
          input: '',
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BrandCreators.editBrandError(payload));
  }
}
// <---------------saga for delete client's from list----------------->
export function* deleteBrnadsSaga(action) {
  try {
    const { companyId } = action.payload;
    yield put(BrandCreators.deleteBrandListStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteClients, {
      params: {
        company_id: companyId,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].SUCCESSFULL_DELETION,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(BrandCreators.deleteBrandListSuccess({}));
      yield put(
        BrandCreators.brandList({
          pageNumber: 1,
          sortParams: {},
          input: '',
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BrandCreators.deleteBrandListError(payload));
  }
}
// <---------------saga for list of brands----------------->
export function* getProjectListByCompanyIdsaga(action) {
  const { companyId, pageNumber = 1 } = action.payload;
  try {
    yield put(BrandCreators.getProjectsByCompanyIdStart());
    const response = yield call(
      ApiService.get,
      apiEndPoints.getProjectByCompanyId,
      {
        params: {
          company_id: companyId,
          page_size: 100,
          page: pageNumber,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        BrandCreators.getProjectsByCompanyIdSuccess({
          data: data?.meta,
          companyId,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BrandCreators.getProjectsByCompanyIdError(payload));
  }
}
