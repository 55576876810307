import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const UserMoalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const UserProfile = styled.div`
  color: ${COLORS.WHITE};
  font-size: 1.4rem;
  font-family: PoppinsRegular;
  padding-bottom: 10px;
`;
export const UserDetils = styled.div`
  color: ${COLORS.NOTIFICATION_INACTIVE_COLOR};
  font-family: PoppinsRegular;
  font-size: 1rem;
`;

export const ChoosePic = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export const UserAvatar = styled.div`
  width: 130px;
  background-color: ${COLORS.WHITE};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 130px;
  border: 1px solid #272626;
  border-radius: 50%;
  margin-top: 35px;
  cursor: pointer;
  position: relative;
  /* Hide ChoosePic by default */
  ${ChoosePic} {
    display: none;
  }

  /* Show ChoosePic when hovering over UserAvatar */
  &:hover ${ChoosePic} {
    display: flex;
  }
`;

export const UserForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
`;

export const InputLabel = styled.div`
  font-family: PoppinsRegular;
  color: ${({ disable }) =>
    disable
      ? COLORS.NOTIFICATION_TIME_COLOR
      : COLORS.NOTIFICATION_ACTIVE_COLOR};
  font-size: 0.8rem;
  padding-bottom: 5px;
  padding-top: 5px;
`;
export const UserInput = styled.input`
  background: ${COLORS.NOTIFICATION_BG};
  color: ${({ disable }) =>
    disable ? COLORS.NOTIFICATION_TIME_COLOR : COLORS.WHITE};
  border: ${({ border }) =>
    border ? 'none' : `1px solid ${COLORS.BACKGROUND_COLOR}`};
  border-radius: 8px;
  height: ${({ border }) => (border ? '33px' : '35px')};
  width: 100%;
  outline: none;
  padding-left: 10px;
  font-family: PoppinsRegular;
`;
export const UpdateBtnContainer = styled.div`
  display: flex;
  column-gap: 10px;
`;
export const PhoneNumberContainer = styled.div`
  display: flex;
  height: 35px;
  width: 100%;
  background: ${COLORS.NOTIFICATION_BG};
  border: 1px solid ${COLORS.BACKGROUND_COLOR};
  border-radius: 8px;
  align-items: center;
`;
export const CountrySelectContainer = styled.div`
  width: 85px;
  cursor: pointer;
  border-right: 1px solid ${COLORS.BACKGROUND_COLOR};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  position: relative;
  font-family: PoppinsRegular;
`;
export const CountryCode = styled.div`
  font-family: PoppinsRegular;
  font-size: 0.9rem;
`;
export const DropDownIcon = styled.div``;
export const CountryList = styled.div`
  max-height: 150px;
  position: absolute;
  top: 38px;
  left: 0px;
  background-color: ${COLORS.NOTIFICATION_BG};
  width: 72px;
  overflow-y: auto;
  border: 1px solid ${COLORS.BACKGROUND_COLOR};
  border-radius: 8px;
  font-family: PoppinsRegular;
`;
export const CallingCodes = styled.div`
  color: ${COLORS.WHITE};
  font-family: PoppinsRegular;
  font-size: 0.9rem;
`;
