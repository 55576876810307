import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const FormContainer = styled.div`
  font-family: PoppinsRegular, sans-serif;
  margin-top: ${({ center }) => (center ? '5vh' : '0px')};
`;
export const FirstHalfContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  row-gap: 10px;
`;
export const SecondHalfContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  row-gap: 10px;
  margin-top: 15px;
`;
export const FlexContainer = styled.div`
  flex: 1;
  display: flex;
`;
export const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 30px;
  margin-right: 30px;
  row-gap: 10px;
`;
export const InputBox = styled.input`
  font-family: PoppinsRegular, sans-serif;
  height: 34px;
  width: ${({ width }) => (width ? width : 'auto')};
  border: 0.5px solid ${COLORS.BORDER};
  background-color: ${COLORS.PRIMARY_BLACK};
  color: ${COLORS.TEXT_MEDIUM_GREY};
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  line-height: 2.4em;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  font-size: 12.5px;
`;

export const Label = styled.label`
  color: ${COLORS.TEXT};
  width: ${({ width }) => (width ? width : 'auto')};
  font-size: 15px;
  &:after {
    content: ${({ isMandatory }) => (isMandatory ? '" *"' : '""')};
    color: red;
  }
`;
export const Asterisk = styled.span`
  color: red;
`;
export const LabelContainer = styled.div`
  margin-left: 30px;
  margin-top: 10px;
`;
export const TestAreaBox = styled.textarea`
  font-family: PoppinsRegular, sans-serif;
  border: 0.5px solid ${COLORS.BORDER};
  background-color: ${({ showBg, disabled }) =>
    showBg && disabled ? COLORS.PRIMARY_BLACK : COLORS.PRIMARY_GREY};
  color: ${COLORS.TEXT_GREY};
  height: ${({ height }) => (height ? height : 'auto')};
  width: ${({ width }) => (width ? width : 'auto')};
  margin: ${({ margin }) => (margin ? margin : '0 auto')};
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  margin-bottom: 25px;
  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '30px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;
export const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const AddMoreDiv = styled.div`
  height: 35px;
  width: 140px;
  border: 0.5px solid ${COLORS.BORDER};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${COLORS.TERTIARY_TEXT_COLOR};
  cursor: pointer;
  display: ${({ btnVisible }) => (btnVisible ? 'none' : 'flex')};
  margin-bottom: 10px;
`;

export const TimeContent = styled.div`
  font-size: 14px;
`;

export const LinkContainer = styled.div`
  font-size: 14px;
  height: 34px;
  text-decoration: none;
  color: inherit;
  font-family: PoppinsRegular, sans-serif;
  &:hover {
    text-decoration: underline;
  }
`;
export const StyledLink = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Add any additional styles here */
`;

export const DeleteEditDiv = styled.div`
  padding: 10px;
`;

export const SocialMediaDiv = styled.div`
  padding: 5px;
  width: 100%;
`;

export const DateDiv = styled.div`
  margin-left: 20px;
  width: 80%;
`;

export const SelectDiv = styled.div`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '20px')};
  width: 100%;
  font-size: 13px;
`;
