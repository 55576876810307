import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const AssetsContainer = styled.div`
  margin-top: 70px;
  margin-left: 95px;
  background-color: ${COLORS.PRIMARY_BLACK};
`;
export const AssetReviewAndComment = styled.div`
  display: flex;
  padding-right: 15px;
  column-gap: 20px;
`;
export const ImageAsset = styled.img`
  object-fit: contain;
  width: 100%;
  // height: 100%;
  scale: ${({ scale }) => scale};
`;
export const ZoomContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  display: flex;
  background-color: ${COLORS.BACKGROUND_COLOR};
  border-radius: 12px;
  height: 35px;
  align-items: center;
  width: 150px;
  justify-content: space-evenly;
  padding-left: 15px;
  padding-right: 15px;
`;
export const ZoomInContainer = styled.div``;
export const ZoomValue = styled.div`
  color: ${COLORS.WHITE};
  font-size: 12px;
  font-family: PoppinsRegular;
`;
export const ZoomOutContainer = styled.div``;
export const FullScreen = styled.div``;
export const AssetStatusContainer = styled.div`
  position: relative;
`;
export const StatusWrpper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;
`;
export const StatusLogo = styled.div``;
export const StatusList = styled.div`
  position: absolute;
  background-color: ${COLORS.STATUS_BG_COLOR};
  z-index: 1;
  width: 130px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  border-radius: 8px;
  top: 35px;
  right: 0;
`;
export const StatusLabel = styled.div`
  color: ${COLORS.WHITE};
  font-size: 12px;
  font-family: PoppinsRegular;
`;
export const SelectedStatus = styled.div``;
export const AssetStatus = styled.div`
  color: ${COLORS.WHITE};
  font-size: 12px;
  font-family: PoppinsRegular;
  background-color: ${({ bg }) => COLORS[bg]};
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  // position: relative;
  cursor: pointer;
`;
export const VersionList = styled.div`
  position: absolute;
  background-color: ${COLORS.STATUS_BG_COLOR};
  z-index: 1;
  width: 110px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  border-radius: 8px;
  top: 45px;
  right: 0;
`;
export const VersionName = styled.div`
  color: ${COLORS.NOTIFICATION_ACTIVE_COLOR};
  font-size: 14px;
  font-family: PoppinsRegular;
  cursor: pointer;
`;
export const AddVersionWrapper = styled.div`
  padding-left: 100px;
  padding-right: 100px;
`;
export const AddVersionHeading = styled.div`
  color: ${COLORS.WHITE};
  font-family: PoppinsRegular;
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 8px;
`;
export const AddVersionSubHeading = styled.div`
  color: ${COLORS.NOTIFICATION_INACTIVE_COLOR};
  font-family: PoppinsRegular;
  font-size: 14px;
`;
export const VersionCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${({ marginTop }) => marginTop || '50px'};
`;
export const VersionCaptionLabel = styled.div`
  color: ${COLORS.NOTIFICATION_INACTIVE_COLOR};
  font-family: PoppinsRegular;
  font-size: 12px;
  padding-bottom: 7px;
`;
export const VersionCaptionInput = styled.input`
  background: ${COLORS.NOTIFICATION_BG};
  border: 1px solid ${COLORS.FOLDER_BG_COLOR};
  border-radius: 8px;
  height: 40px;
  padding-left: 10px;
  width: 100%;
  color: ${COLORS.PLACEHOLDER_COLOR};
  outline: none;
`;
export const UploadVersionFiles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
`;
export const UploadVersionFilesLabel = styled.div`
  color: ${COLORS.NOTIFICATION_INACTIVE_COLOR};
  font-family: PoppinsRegular;
  font-size: 12px;
  padding-bottom: 7px;
`;
export const UploadVersionFolderContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;
export const CaptionContainer = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-height: 10vh;
`;
export const CaptionLabel = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
export const CaptionText = styled.div`
  color: ${COLORS.WHITE};
  font-size: 14px;
  font-family: PoppinsRegular;
  letter-spacing: -0.08px;
`;
export const AssetCaption = styled.div`
  color: ${COLORS.WHITE};
  font-size: 14px;
  font-family: PoppinsRegular;
  letter-spacing: -0.06px;
`;
export const AssetReviewContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  height: 80vh;
  overflow-y: auto;
`;
export const BlankAsset = styled.div`
  width: 100%;
  border-radius: 12px;
  min-height: 80vh;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.WHITE};
  font-family: PoppinsSemiBold;
  font-size: 40px;
`;
export const ImageAssetContainer = styled.div`
  background-color: gray;
  width: 100%;
  height: ${({ fullScreen }) => (fullScreen ? '100vh' : '70vh')};
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`;
export const VideoAssetContainer = styled.div`
  background-color: gray;
  width: 100%;
  height: 70vh;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`;
export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const WebsiteAssetContainer = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  height: 80vh;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`;
export const PdfContainer = styled.div`
  border-radius: 12px;
  height: 70vh;
  position: relative;
`;
export const DownloadAsset = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.BTN_PRIMARY};
  border-radius: 8px;
  width: 35px;
  height: 35px;
  cursor: pointer;
`;
export const AssetCommentContainer = styled.div`
  background-color: ${COLORS.NOTIFICATION_BG};
  width: 30%;
  height: 80vh;
  border-radius: 12px;
`;
export const EditCaption = styled.div``;
export const CaptionCloseModal = styled.div`
  position: absolute;
  right: 0;
`;
export const ListOfCaption = styled.div``;
export const CaptionHistoryHeading = styled.div`
  color: ${COLORS.TERTIARY_TEXT_COLOR};
`;
