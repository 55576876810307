import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;
export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ bg }) => COLORS[bg]};
  color: ${COLORS.WHITE};
  font-family: PoppinsRegular;
  height: 30px;
  width: 120px;
`;
