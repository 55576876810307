/**
 * Auth.View.js
 *
 * it will give background for signup, login, forgetpassword.
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { STRINGS } from '../../constants/labels';
import { Outlet } from 'react-router-dom';
import {
  AuthLeftContainer,
  AuthRightContainer,
  AuthFormContainer,
  CompanyInfo,
  AuthImageContainer,
  AuthBGImageContainer,
} from './Auth.Styles';
import { LandingPage } from '../../constants/GlobalStyles.Styles';
import LoginLeftText from './login/LoginLeftText.View';

const Auth = () => {
  //Text
  const strings = STRINGS['ENGLISH'];

  return (
    <LandingPage direction={'row'}>
      <AuthBGImageContainer>
        <AuthLeftContainer>
          <AuthFormContainer noBg>
            <LoginLeftText />
          </AuthFormContainer>
        </AuthLeftContainer>
        <AuthRightContainer>
          <AuthFormContainer>
            <Outlet />
          </AuthFormContainer>
        </AuthRightContainer>
      </AuthBGImageContainer>
    </LandingPage>
  );
};

export default Auth;
