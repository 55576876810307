import React, { useEffect, useState } from 'react';
import { MyAgencyCreators } from './store/action';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { STRINGS } from '../../constants/labels';
import {
  BTN_TEXT,
  EMAIL_REGEX,
  ORGANISATION_TABS,
  PANORBIT,
  USER_VALIDATIONS_MAPPING,
} from '../../constants/constant';

import { BtnContainer, Header, TabButton } from './MyAgencyBase.Styles';
import { Row } from '../../components/CalendarModal/CalendarModal.Styles';
import { Button } from '../../constants/GlobalStyles.Styles';
import UniversalModal from '../../components/modals/UniversalModal';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorToast } from '../../services/toast/toast';
import { COLORS, TOAST_POSITION } from '../../constants/BaseStyles';
import { SubHeaderAction } from '../../constants/functions';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import AddUsersAgency from '../../components/form/AddUsersAgency.View';
import { AddUserCreators } from '../addUser/store/action';
import AddUsersClient from '../../components/form/AddUsersClient.View';
import EditOrgDetails from '../../components/form/EditOrgDetails.View';

const MyAgencyBase = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  //navigation
  const navigate = useNavigate();

  //redux
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.loggedinUser);
  const myAgencyData = useSelector((state) => state.myAgency.myAgencyData);
  const editLoading = useSelector((state) => state.myAgency.editLoading);
  const errorUpdate = useSelector((state) => state.myAgency.error);

  //location
  const location = useLocation();

  const [isOpenModal, setIsOpenModal] = useState({
    addMyAgencyUsers: false,
    addBrandAgencyUsers: false,
    editOrgDetails: false,
  });
  const [formValues, setFormValues] = useState({
    first_name: '',
    base_company_id: '',
    base_company_name: PANORBIT,
    is_admin: '',
    phone_number: '',
    is_active: true,
    type: 'AGENCY',
    user_organisation_role: '',
    country_code: '+91',
    role: '',
    email: '',
  });

  const [activeTab, setActiveTab] = useState();
  const pathname = location.pathname;
  const { savemode } = useSelector((state) => state.addUser);

  const [orgDetails, setOrgDetails] = useState({
    company_id: '',
    company_name: '',
    email: '',
    type: '',
    website: '',
    company_logo: '',
    description: '',
    primary_contact_name: '',
    primary_contact_phone_number: '',
    country_code: '+91',
    company_logo_file: null,
  });
  //nav
  const navigateTo = (url) => {
    navigate(url);
  };
  const onclickTableAction = () => {
    // return;
    switch (activeTab) {
      case 'team_members':
        setIsOpenModal((prevState) => ({
          ...prevState,
          addMyAgencyUsers: true,
        }));
        setFormValues({
          first_name: '',
          base_company_id: '',
          base_company_name: PANORBIT,
          is_admin: false,
          phone_number: '',
          is_active: true,
          type: 'AGENCY',
          user_organisation_role: '',
          country_code: '+91',
          role: '',
          email: '',
        });
        break;
      case 'brand_members':
        setIsOpenModal((prevState) => ({
          ...prevState,
          addBrandAgencyUsers: true,
        }));
        setFormValues({
          first_name: '',
          base_company_id: '',
          base_company_name: '',
          is_admin: false,
          phone_number: '',
          is_active: true,
          type: 'CLIENT',
          role: 'CLIENT_TEAM_MEMBER',
          country_code: '+91',
          user_organisation_role: 'CLIENT_TEAM_MEMBER',
          email: '',
        });
        break;
      case 'details':
        setIsOpenModal((prevState) => ({ ...prevState, editOrgDetails: true }));
        let country_code = '+91';
        let primary_contact_phone_number = '';
        if (myAgencyData?.primary_contact_phone_number?.includes('-')) {
          const numberBreakDown =
            myAgencyData?.primary_contact_phone_number.split('-');
          country_code = numberBreakDown[0];
          primary_contact_phone_number = numberBreakDown[1];
        } else if (myAgencyData?.primary_contact_phone_number) {
          primary_contact_phone_number =
            myAgencyData?.primary_contact_phone_number;
        }
        setOrgDetails((prevState) => ({
          ...prevState,
          company_id: myAgencyData?.company_id,
          company_name: myAgencyData?.company_name,
          email: myAgencyData?.email,
          type: myAgencyData?.type,
          website: myAgencyData?.website,
          company_logo: myAgencyData?.company_logo,
          description: myAgencyData?.description,
          primary_contact_name: myAgencyData?.primary_contact_name,
          primary_contact_phone_number: primary_contact_phone_number,
          country_code: country_code,
          company_logo_file: null,
        }));
        break;
      default:
        // Handle other cases or do nothing
        break;
    }
  };
  useEffect(() => {
    SubHeaderAction(pathname, setActiveTab);
  }, [pathname]);

  // CALENDAR CLICK FUNCUTIONS
  const handleTabClick = (data) => {
    if (data?.tab === 'dashboard') return;
    navigateTo(`/organisation/${data}`);
  };

  const closeModal = () => {
    setIsOpenModal((prevState) => ({
      ...prevState,
      addMyAgencyUsers: false,
      addBrandAgencyUsers: false,
      editOrgDetails: false,
    }));
    setFormValues({
      first_name: '',
      base_company_id: '',
      base_company_name: PANORBIT,
      is_admin: false,
      phone_number: '',
      is_active: true,
      type: 'AGENCY',
      user_organisation_role: '',
      country_code: '+91',
      role: '',
      email: '',
    });
  };

  const handleSubmit = (e) => {
    let valid = true;
    let validationMessage = 'Input fields are required';
    if (formValues.type === 'AGENCY') {
      delete formValues.base_company_id;
    }
    for (const [key, value] of Object.entries(formValues)) {
      if (value === '' || value === undefined) {
        valid = false;
        validationMessage = `${USER_VALIDATIONS_MAPPING[key]} is required`;
        break; // No need to continue checking other fields if one is empty
      } else if (key === 'email' && !EMAIL_REGEX.test(value)) {
        valid = false;
        validationMessage = 'Email is invalid';
        break; // No need to continue checking other fields if email is invalid
      } else if (key === 'phone_number' && value?.toString().length !== 10) {
        valid = false;
        validationMessage = 'Phone number should be 10 digits';
        break; // No need to continue checking other fields if phone number length is invalid
      }
    }

    if (valid) {
      dispatch(
        AddUserCreators.addUser({
          data: formValues,
        })
      );
    } else {
      ErrorToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'error1');
    }
  };

  const handleSaveOrgDetails = () => {
    // return false;
    let valid = true;
    let isUpdated = false;
    let validationMessage = 'Input fields are required';
    let formDataObj = new FormData();
    //
    while (valid) {
      if (orgDetails?.company_name) {
        if (myAgencyData?.company_name !== orgDetails?.company_name) {
          //
          formDataObj.append('company_name', orgDetails?.company_name);
          isUpdated = true;
        }
      } else if (!orgDetails?.company_name) {
        valid = false;
        validationMessage = 'Company name is required';
        break;
      }
      if (orgDetails?.primary_contact_name) {
        if (
          myAgencyData?.primary_contact_name !==
          orgDetails?.primary_contact_name
        ) {
          //
          formDataObj.append(
            'primary_contact_name',
            orgDetails?.primary_contact_name
          );
          isUpdated = true;
        }
      } else if (!orgDetails?.primary_contact_name) {
        valid = false;
        validationMessage = 'Primary contact name is required';
        break;
      }
      if (orgDetails?.primary_contact_phone_number) {
        console.log('HEREEE  ____ 1');
        if (
          myAgencyData?.primary_contact_phone_number !==
          `${orgDetails?.country_code}-${orgDetails?.primary_contact_phone_number}`
        ) {
          //
          formDataObj.append(
            'primary_contact_phone_number',
            `${orgDetails?.country_code}-${orgDetails?.primary_contact_phone_number}`
          );
          isUpdated = true;
        }
      } else if (!orgDetails?.primary_contact_phone_number) {
        valid = false;
        validationMessage = 'Primary contact number is required';
        break;
      }
      if (orgDetails?.email) {
        if (myAgencyData?.email !== orgDetails?.email) {
          //
          if (!EMAIL_REGEX.test(orgDetails?.email)) {
            valid = false;
            validationMessage = 'Valid primary contact email is required';
            break;
          }
          formDataObj.append('email', orgDetails?.email);
          isUpdated = true;
        }
      } else if (!orgDetails?.email) {
        valid = false;
        validationMessage = 'Primary contact email is required';
        break;
      }
      if (orgDetails?.website) {
        if (myAgencyData?.website !== orgDetails?.website) {
          //
          formDataObj.append('website', orgDetails?.website);
          isUpdated = true;
        }
      }
      if (orgDetails?.company_logo_file) {
        formDataObj.append('company_logo', orgDetails?.company_logo_file);
        isUpdated = true;
      }
      break;
    }
    if (valid && isUpdated) {
      formDataObj.append('company_id', myAgencyData?.company_id);
      dispatch(
        MyAgencyCreators.editMyAgency({
          data: formDataObj,
        })
      );
    } else if (!valid) {
      ErrorToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'error1');
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    dispatch(MyAgencyCreators.viewMyAgency());
  }, [dispatch]);

  useEffect(() => {
    if (userData.hasOwnProperty('is_admin')) {
      if (!userData.is_admin) {
        navigate('/');
      }
    }
  }, [navigate, userData]);

  useEffect(() => {
    if (savemode) {
      closeModal();
    }
  }, [savemode]);

  useEffect(() => {
    if (!editLoading && !errorUpdate) {
      closeModal();
    }
  }, [editLoading, errorUpdate]);

  return (
    <>
      <SubHeader
        title={strings.ORGANISATION}
        isBack={false}
        titleColor={COLORS.WHITE}
        btnFalse={false}
      />
      <Header top={'140px'} left={'100px'}>
        <Row spaceBetween style={{ marginBottom: '-3px' }}>
          <Row flexStart>
            {ORGANISATION_TABS.map((data) => (
              <TabButton
                active={pathname.includes(data?.url)}
                onClick={() => {
                  handleTabClick(data.url);
                }}
              >
                {data.tab}
              </TabButton>
            ))}
          </Row>

          <BtnContainer>
            {BTN_TEXT[activeTab] && (
              <Button
                width={'130px'}
                height={'35px'}
                noBoxShadow={true}
                marginTop={'-8px'}
                onClick={() => {
                  onclickTableAction();
                }}
              >
                {BTN_TEXT[activeTab]}
              </Button>
            )}
          </BtnContainer>
        </Row>
      </Header>
      {/* <-------------------Modals for add internal users-------------------> */}
      <UniversalModal
        width={'500px'}
        height={'550px'}
        isOpenModal={isOpenModal?.addMyAgencyUsers}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={handleSubmit}
        onleftbtnClick={closeModal}
      >
        <AddUsersAgency
          edit={false}
          formType={'AGENCY'}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </UniversalModal>
      {/*<-------------------Modal for add clients users-------------------->  */}
      <UniversalModal
        width={'500px'}
        height={'550px'}
        isOpenModal={isOpenModal?.addBrandAgencyUsers}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={handleSubmit}
        onleftbtnClick={closeModal}
      >
        <AddUsersClient
          edit={false}
          formType={'CLIENT'}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </UniversalModal>
      {/*<-------------------Modal for add clients users-------------------->  */}
      <UniversalModal
        width={'500px'}
        height={'750px'}
        isOpenModal={isOpenModal?.editOrgDetails}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPDATE}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={handleSaveOrgDetails}
        onleftbtnClick={closeModal}
      >
        <EditOrgDetails
          edit={false}
          formType={'CLIENT'}
          formValues={orgDetails}
          setFormValues={setOrgDetails}
        />
      </UniversalModal>
      <Outlet />
    </>
  );
};

export default MyAgencyBase;
