/**
 * sagas js
 *
 * it will have all the sagas combined
 *
 * For more function follow axios documentation
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { takeLatest } from 'redux-saga/effects';

import { Types } from './actions';

import { loginSaga, logoutSaga, checkMailSaga } from '../pages/auth/store/saga';
import {
  viewMyAgencySaga,
  editMyAgencySaga,
} from '../pages/myAgency/store/saga';

import {
  getUsersSaga,
  updateUserDetailsSaga,
} from '../pages/homepage/store/saga';

import {
  viewClientsSaga,
  addClientSaga,
  editClientSaga,
  deleteClientsSaga,
  viewClientSaga,
} from '../pages/clients/store/saga';

import {
  deleteUserSaga,
  featchUserListSaga,
  getUserListAgencySaga,
  selectClientSaga,
} from '../pages/users/store/saga';
import { addUserSaga, editUserSaga } from '../pages/addUser/store/saga';

import {
  viewProjectTableListSaga,
  deleteProjectSaga,
  getFilesSaga,
  createFolderSaga,
  uploadFileSaga,
  uploadFolderSaga,
  deleteFolderSaga,
  projectDetailsSaga,
  projectsListSaga,
  addLinkSaga,
  deleteLinkSaga,
  editLinkSaga,
  updateProjectSaga,
  addWebpagesSaga,
  websiteListSaga,
  deleteWebPageSaga,
} from '../pages/projects/store/saga';
import {
  addProjectSaga,
  editProjectSaga,
  getUSerLsitSaga,
  getfilteredUserListSaga,
} from '../pages/addProjects/store/saga';

import {
  getPostsSaga,
  addPostSaga,
  updateEventSaga,
} from '../pages/calendar/store/saga';

import {
  getEventSaga,
  editEventSaga,
  getAssetsSaga,
} from '../pages/viewPosts/store/saga';

import { addAssetSaga } from '../pages/uploadAssets/store/saga';

import {
  getAssetVersionsSaga,
  getSingleAssetVersionSaga,
  getCommentsSaga,
  addCommentSaga,
  deleteCommentSaga,
  updateAssetVersionSaga,
} from '../pages/assetVersions/store/saga';

import {
  scrapeWebpageSaga,
  getWebpageSaga,
  getAllWebpagesSaga,
  getCommentsByWebsiteSaga,
  addWebsiteCommentSaga,
  deleteWebsiteSaga,
  getHTMLPageSaga,
  getWebpageVersionsSaga,
  updateWebStatusSaga,
} from '../pages/WebsiteFeedback/store/saga';
import {
  addBrandSaga,
  brandListSaga,
  deleteBrnadsSaga,
  editBrandSaga,
  getProjectListByCompanyIdsaga,
} from '../pages/brands/store/saga';
import {
  countUnreadNotificationSaga,
  notificationListSaga,
  readNotificationSaga,
} from '../pages/BaseLayout/store/saga';
import {
  addNewAssetSaga,
  addssetCommentSaga,
  assetCommentSaga,
  deleteAssetsCommentSaga,
  getVersionListSaga,
  updateAssetsCommentSaga,
  updateAssetsStatusSaga,
  viewAssetsSaga,
} from '../pages/assetsView/store/saga';

export function* watchAuth() {
  yield takeLatest(Types.CHECK_MAIL, checkMailSaga);
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.LOGOUT, logoutSaga);
  yield takeLatest(Types.VIEW_MY_AGENCY, viewMyAgencySaga);
  yield takeLatest(Types.EDIT_MY_AGENCY, editMyAgencySaga);
  yield takeLatest(Types.ADD_CLIENT, addClientSaga);
  yield takeLatest(Types.EDIT_CLIENT, editClientSaga);
  yield takeLatest(Types.VIEW_CLIENTS, viewClientsSaga);
  yield takeLatest(Types.VIEW_CLIENT, viewClientSaga); //view single client by id
  yield takeLatest(Types.DELETE_CLIENTS, deleteClientsSaga);
  yield takeLatest(Types.GET_USER_LIST, featchUserListSaga);
  yield takeLatest(Types.DELETE_USER, deleteUserSaga);
  yield takeLatest(Types.SELECT_CLIENT, selectClientSaga);
  yield takeLatest(Types.ADD_USER, addUserSaga);
  yield takeLatest(Types.EDIT_USER, editUserSaga);
  yield takeLatest(Types.GET_USER_DETAILS, getUsersSaga);
  yield takeLatest(Types.VIEW_PROJECT_TABLE_LIST, viewProjectTableListSaga);
  yield takeLatest(Types.ADD_PROJECT, addProjectSaga);
  yield takeLatest(Types.USER_LIST, getUSerLsitSaga);
  yield takeLatest(Types.FILTERED_USER_LIST, getfilteredUserListSaga);
  yield takeLatest(Types.EDIT_PROJECT, editProjectSaga);
  yield takeLatest(Types.DELETE_PROJECT, deleteProjectSaga);
  yield takeLatest(Types.GET_POSTS, getPostsSaga);
  yield takeLatest(Types.ADD_POST, addPostSaga);
  yield takeLatest(Types.GET_EVENT, getEventSaga);
  yield takeLatest(Types.EDIT_EVENT, editEventSaga);
  yield takeLatest(Types.ADD_ASSET, addAssetSaga);
  yield takeLatest(Types.GET_ASSETS, getAssetsSaga);
  yield takeLatest(Types.GET_ASSET_VERSIONS, getAssetVersionsSaga);
  yield takeLatest(Types.GET_SINGLE_ASSET_VERSION, getSingleAssetVersionSaga);
  yield takeLatest(Types.UPDATE_ASSET_VERSION, updateAssetVersionSaga);

  yield takeLatest(Types.GET_COMMENTS, getCommentsSaga);
  yield takeLatest(Types.ADD_COMMENT, addCommentSaga);
  yield takeLatest(Types.DELETE_COMMENT, deleteCommentSaga);
  //website feedback
  yield takeLatest(Types.SCRAPE_WEBPAGE, scrapeWebpageSaga);
  yield takeLatest(Types.GET_HTML_PAGE, getHTMLPageSaga);
  yield takeLatest(Types.GET_WEBPAGE, getWebpageSaga);
  yield takeLatest(Types.GET_WEBPAGE_VERSIONS, getWebpageVersionsSaga);
  yield takeLatest(Types.GET_ALL_WEBPAGES, getAllWebpagesSaga);
  yield takeLatest(Types.DELETE_WEBSITE, deleteWebsiteSaga);
  yield takeLatest(Types.GET_COMMENTS_BY_WEBSITE, getCommentsByWebsiteSaga);
  yield takeLatest(Types.ADD_WEBSITE_COMMENT, addWebsiteCommentSaga);
  // file and folder in project
  yield takeLatest(Types.FILE_LIST, getFilesSaga);
  yield takeLatest(Types.CREATE_FOLDER, createFolderSaga);
  yield takeLatest(Types.UPLOAD_FILE, uploadFileSaga);
  yield takeLatest(Types.UPLOAD_FOLDER, uploadFolderSaga);
  yield takeLatest(Types.ADD_BRAND, addBrandSaga);
  yield takeLatest(Types.EDIT_BRAND, editBrandSaga);
  yield takeLatest(Types.DELETE_FOLDER, deleteFolderSaga);
  yield takeLatest(Types.BRAND_LIST, brandListSaga);
  yield takeLatest(Types.DELETE_BRAND_LIST, deleteBrnadsSaga);
  yield takeLatest(
    Types.GET_PROJECTS_BY_COMPANY_ID,
    getProjectListByCompanyIdsaga
  );

  yield takeLatest(Types.NOTIFICATION_LIST, notificationListSaga);
  yield takeLatest(Types.NOTIFICATION_COUNT, countUnreadNotificationSaga);
  yield takeLatest(Types.READ_NOTIFICATION, readNotificationSaga);
  //
  yield takeLatest(Types.UPDATE_USER_DETAILS, updateUserDetailsSaga);
  yield takeLatest(Types.PROJECT_DETAILS, projectDetailsSaga);
  yield takeLatest(Types.PROJECTS_LIST, projectsListSaga);
  //
  yield takeLatest(Types.ADD_LINK, addLinkSaga);
  yield takeLatest(Types.DELETE_LINK, deleteLinkSaga);
  yield takeLatest(Types.EDIT_LINK, editLinkSaga);
  //
  yield takeLatest(Types.UPDATE_PROJECT, updateProjectSaga);
  // editLinkSaga
  // assets
  yield takeLatest(Types.VIEW_ASSET_COMMENT, assetCommentSaga);
  yield takeLatest(Types.ADD_ASSET_COMMENT, addssetCommentSaga);
  yield takeLatest(Types.VIEW_ASSETS, viewAssetsSaga);
  yield takeLatest(Types.DELETE_ASSET_COMMENT, deleteAssetsCommentSaga);
  yield takeLatest(Types.UPDATE_ASSET_COMMENT, updateAssetsCommentSaga);
  yield takeLatest(Types.UPDATE_ASSET_STATUS, updateAssetsStatusSaga);
  yield takeLatest(Types.GET_ASSET_VERSION_LIST, getVersionListSaga);
  yield takeLatest(Types.UPLOAD_NEW_VERSION_FILE, addNewAssetSaga);
  yield takeLatest(Types.ADD_WEBPAGES, addWebpagesSaga);
  yield takeLatest(Types.GET_WEB_PAGE_LIST, websiteListSaga);
  yield takeLatest(Types.DELETE_WEB_PAGE, deleteWebPageSaga);
  yield takeLatest(Types.UPDATE_WEB_STATUS, updateWebStatusSaga);

  yield takeLatest(Types.GET_USER_LIST_AGENCY, getUserListAgencySaga);
  yield takeLatest(Types.UPDATE_EVENT_DETAILS, updateEventSaga);
}
