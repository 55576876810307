import React from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { cookie } from './services/cookies/cookieServices';
import { useDispatch } from 'react-redux';
import { Creators } from './pages/auth/store/action';
import { setAccessTokenToHeader } from './services/axios/helper';
import { ToastMessageContainer } from './components/NotificationStyles/Notify.Styles';
import Navigation from './routes/Navigation';
import { TOAST_STYLE } from './constants/BaseStyles';
import { GlobalScrollBar } from './constants/GlobalStyles.Styles';

function App() {
  const dispatch = useDispatch();
  const userDetails = cookie.get('userDetails');

  if (userDetails) {
    const { accessToken } = userDetails;
    if (accessToken) {
      const payload = {
        accessToken,
      };
      setAccessTokenToHeader(accessToken);
      dispatch(Creators.loginSuccess(payload));
    }
  }

  /*----------------------------Firebase message handling-----------------------*/
  return (
    <>
      <Navigation />
      <GlobalScrollBar />
      <ToastMessageContainer autoClose={3000} style={TOAST_STYLE} />
    </>
  );
}

export default App;
