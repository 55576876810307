/**
 * saga js
 *
 * it will have all the  calendar related sagas
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { PostsCreators } from './action';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { EventCreators } from '../../viewPosts/store/action';

//TEXT
const strings = STRINGS['ENGLISH'];
// <---------------saga for add post ----------------->
export function* addPostSaga(action) {
  const { addPostData, project_id } = action.payload;

  try {
    yield put(PostsCreators.addPostStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.addPost,
      addPostData
    );
    if (response.status >= 200 && response.status < 300) {
      yield put(PostsCreators.addPostSuccess());
      yield put(PostsCreators.getPosts({ project_id }));
      SuccessToast(strings.EVENT_ADD_SUCCESS, TOAST_POSITION.TOP_CENTER);
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(PostsCreators.addPostError(payload));
  }
}

// <---------------saga for fetch posts list ----------------->
export function* getPostsSaga(action) {
  const { project_id } = action.payload;

  try {
    //pass project id
    yield put(PostsCreators.getPostsStart());
    const response = yield call(ApiService.post, apiEndPoints.viewPosts, {
      project_id: project_id,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(PostsCreators.getPostsSuccess({ data: data?.data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(PostsCreators.getPostsError(payload));
  }
}
// <----------------update event data------------------------->
// <---------------saga for fetch posts list ----------------->
export function* updateEventSaga(action) {
  const { project_id, data: apiPayloadData } = action.payload;

  try {
    yield put(PostsCreators.updateEventDetailsStart());
    const response = yield call(ApiService.post, apiEndPoints.updateEvent, {
      ...apiPayloadData,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(PostsCreators.updateEventDetailsSuccess({ data: data?.data }));
      yield put(
        EventCreators.getAssets({
          ref: 'event',
          ref_id: apiPayloadData.event_id,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(PostsCreators.updateEventDetailsError(payload));
  }
}
