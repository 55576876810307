/**
 * Login Page function component
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { InputField, Container } from './Login.Styles';
import {
  HeaderText,
  InputBox,
  Button,
} from '../../../constants/GlobalStyles.Styles';
import { STRINGS } from '../../../constants/labels';
import { CHECK_VALIDITY } from '../../../constants/validation';
import { ErrorToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { Creators } from '../store/action';
import { GET_WINDOW_SIZE } from '../../../constants/functions';
import { sizeValue } from '../../../constants/device';
import { setDataToLocal } from '../../../services/cookies/helper';
import { RoleWrapper } from '../../../pages/BaseLayout/BaseLayout.Styles';
import {
  Col,
  Label,
  Row,
} from '../../../components/CalendarModal/CalendarModal.Styles';

const Login = () => {
  //state management
  const [searchParams, setSeachParams] = useSearchParams();
  const dType = searchParams.get('type');

  const [email, setEmail] = useState('');
  const [validatedInfo, setValidatedInfo] = useState(false);
  const [otp, setOtp] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [otpValid, setOtpValid] = useState(false);
  const [isEmailBtnClicked, setIsEmailBtnClicked] = useState(false);
  const [isOTPBtnClicked, setIsOTPBtnClicked] = useState(false);
  const [backendValidation, setBackendValidation] = useState(false);

  //Text
  const strings = STRINGS['ENGLISH'];

  //redux
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { checked, mailExist, validationSuccess } = useSelector(
    (state) => state.auth
  );

  const emailRules = {
    required: true,
    isEmail: true,
  };

  const otpRules = {
    required: true,
    isStrictlyNumeric: true,
    minLength: 6,
    maxLength: 6,
  };

  //will verify if entered mail is valid or not
  const verifyEmail = async (event) => {
    setIsEmailBtnClicked(true);
    event.preventDefault();
    let is_valid = true;
    let validationMessage = '';
    if (dType) {
      setDataToLocal('type', dType);
    }

    if (!CHECK_VALIDITY(email, emailRules)) {
      setEmailValid(false);
      if (email.trim() === '') {
        validationMessage = strings.MAIL + strings.REQUIRED;
      } else {
        validationMessage = strings.IN_CORRECT_EMAIL;
      }
      is_valid = false;
    }
    if (is_valid) {
      setEmailValid(true);
      setBackendValidation(true);
    } else {
      ErrorToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'error1');
    }
  };

  const preventIllegal = (e) => {
    const result = e.target.value.replace(/\D/g, '');
    setOtp(result);
  };

  useEffect(() => {
    if (backendValidation) {
      dispatch(Creators.checkMail({ mail: email }));
    }
  }, [backendValidation]);

  useEffect(() => {
    if (backendValidation) {
      if (mailExist === 'FAILURE') {
        setEmailValid(false);
        setBackendValidation(false);
      } else if (mailExist === 'SUCCESS') {
        setValidatedInfo(true);
        setBackendValidation(false);
      }
    }
  }, [checked, mailExist]);

  //for otp validition ui
  useEffect(() => {
    if (validationSuccess) {
      setOtpValid(true);
    }
  }, [validationSuccess]);

  //will trigger while clicking login button, will login using mail and otp
  const loggingIn = () => {
    if (CHECK_VALIDITY(otp, otpRules)) {
      const payload = {
        mail: email,
        otp: otp,
      };
      dispatch(Creators.login(payload));
      setIsOTPBtnClicked(true);
    } else {
      let validationMessage = '';
      if (otp.trim() === '') {
        validationMessage = strings.OTP + strings.REQUIRED;
      } else {
        validationMessage = strings.IN_CORRECT_OTP;
      }
      setOtpValid(false);
      ErrorToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'error1');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnLogin = useCallback(debounce(loggingIn, 400), [otp]);
  return (
    <Container>
      <Col marginBottom={'30px'}>
        <HeaderText>{strings.LOGIN}</HeaderText>
        <RoleWrapper>{strings.LOGIN_TO_ACCOUNT}</RoleWrapper>
      </Col>
      <Col
        marginLeft={'10px'}
        align={'flex-start'}
        justify={'space-between'}
        width={'80%'}
        height={'65%'}
      >
        <InputField>
          <Col width={'100%'} align={'flex-start'}>
            <Label>{strings.EMAIL_ADDRESS}</Label>
            <Row spaceBetween>
              <InputBox
                className={
                  isEmailBtnClicked && emailValid
                    ? 'valid'
                    : isEmailBtnClicked
                    ? 'invalid'
                    : ''
                }
                width={'400px'}
                type="email"
                value={email}
                disabled={validatedInfo}
                onChange={(email) => {
                  setEmail(email.target.value);
                  if (isEmailBtnClicked) {
                    setIsEmailBtnClicked(false);
                    setEmailValid(true);
                  }
                }}
                placeholder={strings.MAIL_PLACEHOLDER}
              />
            </Row>
          </Col>
        </InputField>
        {validatedInfo && (
          <InputField
            margin={
              GET_WINDOW_SIZE().innerWidth > sizeValue.tablet
                ? '0px'
                : '40px 0px 0px 0px'
            }
          >
            <Col width={'100%'} align={'flex-start'}>
              <Label width={'auto'}>{strings.OTP_FULL_FORM}</Label>
              <InputBox
                className={
                  isOTPBtnClicked && otpValid
                    ? 'valid'
                    : isOTPBtnClicked
                    ? 'invalid'
                    : ''
                }
                width={'400px'}
                maxLength={6}
                type="text"
                value={otp}
                onChange={(e) => {
                  preventIllegal(e);
                }}
                placeholder={strings.OTP}
              />
            </Col>
          </InputField>
        )}
        <Button
          width={'400px'}
          type="submit"
          onClick={(event) => {
            if (validatedInfo) {
              event.preventDefault();
              debounceOnLogin();
            } else {
              verifyEmail(event);
            }
          }}
        >
          {validatedInfo ? strings.SIGN_IN : strings.PROCEED}
        </Button>
      </Col>
    </Container>
  );
};

export default Login;
