/**
 * reducer js
 *
 * it will have all the asset related reducers
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createReducer } from 'reduxsauce';
import { AssetsTypes, PostsTypes } from './action';

const INITIAL_STATE = {
  error: false,
  loading: true,
  addAssetSuccessful: false,
  uploading: false,
  newlyAddedVerId: null,
};
// <---------------reducer for add posts to calendar----------------->
const addAssetStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    addAssetSuccessful: false,
    uploading: true,
  };
};
const addAssetSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    addAssetSuccessful: true,
    uploading: false,
    newlyAddedVerId: data?.data?.version_id,
  };
};
const addAssetError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    addAssetSuccessful: false,
    uploading: false,
  };
};

const resetState = (state = INITIAL_STATE, action) => {
  return {
    error: false,
    loading: true,
    addAssetSuccessful: false,
    uploading: false,
  };
};
const HANDLERS = {
  [AssetsTypes.ADD_ASSET_START]: addAssetStart,
  [AssetsTypes.ADD_ASSET_SUCCESS]: addAssetSuccess,
  [AssetsTypes.ADD_ASSET_ERROR]: addAssetError,
  //
  [AssetsTypes.RESET_STATE]: resetState,
};
export const AssetsReducer = createReducer(INITIAL_STATE, HANDLERS);
