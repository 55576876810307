import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  NameWrapper,
  RoleWrapper,
  Rolesd,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import {
  Col,
  Label,
  ProjectTypeContainer,
  Row,
} from '../CalendarModal/CalendarModal.Styles';
import { TextArea } from '../../constants/GlobalStyles.Styles';
import { FormContainer } from './AddBrandForm.Styles';
import { STRINGS } from '../../constants/labels';
import { CardCantainer, IconAndText } from '../Cards/Cards.Styles';
import { PROJECT_TYPES } from '../../constants/constant';
import {
  CardsContainer,
  ProjectTypeCardsContainer,
} from '../../pages/dashboards/adminDashboard.Styles';
import UniversalModal from '../modals/UniversalModal';
import AddProjectform from './AddProjectForm.View';
import { STANDARDIZE_PROJECT_TYPE } from '../../constants/functions';

const ProjectTypeForm = ({
  projectSelected,
  setProjectSelected,
  projectTypes,
  setProjectTypes,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  const [isOpenModal, setIsopenModal] = useState(false);
  // const [projectSelected, setProjectSelected] = useState([]);

  useEffect(() => {
    setProjectTypes(PROJECT_TYPES);
  }, []);

  const closeModal = () => {
    setIsopenModal(false);
  };

  const setProjectType = (projectType) => {
    setProjectSelected(projectType);
    setIsopenModal(true);
  };

  const openProjectForm = () => {
    //does not work as we are calling modal from a modal
    setIsopenModal(true);
  };

  return (
    <FormContainer width={'660px'} marginBottom={'15px'}>
      <Col width={'100%'} align={'center'}>
        <NameWrapper fontSize={'17px'}>
          {strings.CREATE_NEW_PROJECT}
        </NameWrapper>
        {/* <RoleWrapper>{strings.CHOOSE_PROJECT_TYPE}</RoleWrapper> */}
        <Rolesd>{strings.CHOOSE_PROJECT_TYPE}</Rolesd>
      </Col>
      <Row marginBottom={'25px'}>
        <ProjectTypeCardsContainer
          noMarginLeft
          marginTop={'10px'}
          justify={'space-evenly'}
        >
          {projectTypes.map((project) => (
            <ProjectTypeContainer
              width={'140px'}
              height={'215px'}
              img={'true'}
              Selected={projectSelected === project?.type}
              onClick={() => setProjectType(project?.type)}
            >
              <CardCantainer
                borderRadiusAll
                width={'90%'}
                height={'165px'}
                margin={'8px'}
                img={project?.img}
              />
              <IconAndText height={'30px'} padding={'5px'} justify={'flex-end'}>
                {STANDARDIZE_PROJECT_TYPE(project)}
              </IconAndText>
            </ProjectTypeContainer>
          ))}
        </ProjectTypeCardsContainer>
      </Row>
      {/* <UniversalModal
        width={'500px'}
        isOpenModal={isOpenModal}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        rightBtnDisabled={false}
        onrightbtnClick={openProjectForm}
        onleftbtnClick={closeModal}
      >
        <AddProjectform projectType={projectSelected} fontSize={'13px'} />
      </UniversalModal> */}
    </FormContainer>
  );
};

export default ProjectTypeForm;
