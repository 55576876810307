import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

const IFrameContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export { IFrameContainer };
