import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
import {
  DEFAULT_AUTH_BUTTON_WIDTH,
  DEFAULT_AUTH_BUTTON_HEIGHT,
} from '../../constants/constant';
<style>
  @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
</style>;
export const P = styled.div`
  color: #595959;
  font-size: 14px;
  line-height: 0.1;
  &:after {
    content: ${({ isMandatory }) => (isMandatory ? '" *"' : '""')};
    color: red;
  }
`;

export const Form = styled.div`
  font-family: 'PoppinsRegular', sans-serif;
  margin-left: 65px;
  margin-top: 75px;
  height: 95vh;
  width: 94vw;
  background-color: ${COLORS.PRIMARY_BACKGROUND_COLOR};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ cards }) => (cards ? 'flex-start' : 'center')};
  margin-top: ${({ cards }) => (cards ? '75px' : '0px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  width: ${({ width }) => (width ? width : 'auto')};
  margin-bottom: 30px;
  // margin-left: ${({ cards }) => (cards ? '75px' : '0px')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 5%;
`;

export const Card = styled(Col)`
    height: 105px;
    width: 120px;
    padding: 15px;
    border-radius: 5px;
    margin: 5px 20px 5px 5px;
    text-align: center;
    font-size: 0.9em;
    color: ${COLORS.TERTIARY_TEXT_COLOR};
    background-color: ${COLORS.PRIMARY_BACKGROUND_COLOR};
    border-top: ${COLORS.GREEN} 2.5px solid;
    box-shadow: -webkit-box-shadow: 0 6px 12px ${COLORS.BOX_SHADOW_COLOR};
    box-shadow: 0 6px 12px ${COLORS.BOX_SHADOW_COLOR};
    @media (hover: hover) {
        &:hover {
            font-weight: 600;
            cursor: pointer;
        }
`;

export const ToggleMode = styled.div`
  margin-top: 15px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '95%')};
  cursor: pointer;
`;

export const Center = styled.div`
  height: ${({ height }) => (height ? height : DEFAULT_AUTH_BUTTON_HEIGHT)};
  width: ${({ width }) => (width ? width : DEFAULT_AUTH_BUTTON_WIDTH)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  // align-items: center;
  gap: 15px;
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};
  margin-left: ${({ noMarginLeft }) => (noMarginLeft ? '0px' : '95px')};
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  max-width: calc(100vw - 100px);
`;
export const ProjectTypeCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};
  margin-left: ${({ noMarginLeft }) => (noMarginLeft ? '0px' : '95px')};
  overflow-y: auto;
`;
export const BrandCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
`;
