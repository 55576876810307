/**
 * action js
 *
 * it will have all the asset versions related actions
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getAssetVersions: ['payload'],
  getAssetVersionsStart: ['payload'],
  getAssetVersionsSuccess: ['payload'],
  getAssetVersionsError: ['payload'],
  //
  latestVersion: ['payload'],
  //
  getSingleAssetVersion: ['payload'],
  getSingleAssetVersionStart: ['payload'],
  getSingleAssetVersionSuccess: ['payload'],
  getSingleAssetVersionError: ['payload'],
  //
  getComments: ['payload'],
  getCommentsStart: ['payload'],
  getCommentsSuccess: ['payload'],
  getCommentsError: ['payload'],
  //
  addComment: ['payload'],
  addCommentStart: ['payload'],
  addCommentSuccess: ['payload'],
  addCommentError: ['payload'],
  //
  getComment: ['payload'],
  getCommentStart: ['payload'],
  getCommentSuccess: ['payload'],
  getCommentError: ['payload'],
  //
  deleteComment: ['payload'],
  deleteCommentStart: ['payload'],
  deleteCommentSuccess: ['payload'],
  deleteCommentError: ['payload'],
  //
  updateAssetVersion: ['payload'],
  updateAssetVersionStart: ['payload'],
  updateAssetVersionSuccess: ['payload'],
  updateAssetVersionError: ['payload'],
  //
  downloadAsset: ['payload'],
  downloadAssetStart: ['payload'],
  downloadAssetSuccess: ['payload'],
  downloadAssetError: ['payload'],
  //
  setVideoTimestamp: ['payload'],
  resetVideoTimestamp: [],
  resetState: ['payload'],
});

export { Types as AssetVersionsTypes, Creators as AssetVersionsCreators };
