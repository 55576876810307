/**
 * action js
 *
 * it will have all the website feedback actions
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  scrapeWebpage: ['payload'],
  scrapeWebpageStart: ['payload'],
  scrapeWebpageSuccess: ['payload'],
  scrapeWebpageError: ['payload'],
  //
  resetScrapeSuccess: ['payload'],
  //
  getHTMLPage: ['payload'],
  getHTMLPageStart: ['payload'],
  getHTMLPageSuccess: ['payload'],
  getHTMLPageError: ['payload'],
  //
  getWebpage: ['payload'],
  getWebpageStart: ['payload'],
  getWebpageSuccess: ['payload'],
  getWebpageError: ['payload'],
  //
  //
  getWebpageVersions: ['payload'],
  getWebpageVersionsStart: ['payload'],
  getWebpageVersionsSuccess: ['payload'],
  getWebpageVersionsError: ['payload'],
  //
  getAllWebpages: ['payload'],
  getAllWebpagesStart: ['payload'],
  getAllWebpagesSuccess: ['payload'],
  getAllWebpagesError: ['payload'],
  //
  addWebsiteComment: ['payload'],
  addWebsiteCommentStart: ['payload'],
  addWebsiteCommentSuccess: ['payload'],
  addWebsiteCommentError: ['payload'],
  //
  updateWebStatus: ['payload'],
  updateWebStatusStart: ['payload'],
  updateWebStatusSuccess: ['payload'],
  updateWebStatusError: ['payload'],

  //
  getCommentsByWebsite: ['payload'],
  getCommentsByWebsiteStart: ['payload'],
  getCommentsByWebsiteSuccess: ['payload'],
  getCommentsByWebsiteError: ['payload'],
  //
  deleteWebsite: ['payload'],
  deleteWebsiteStart: ['payload'],
  deleteWebsiteSuccess: ['payload'],
  deleteWebsiteFailure: ['payload'],
  //
  highlightComment: ['comment'],
});

export { Types as WebpageTypes, Creators as WebpageCreators };
