import { createReducer } from 'reduxsauce';
import { loggedUserTypes } from './action';

const INITIAL_STATE = {
  error: false,
  loading: true,
  clientData: {},
  userData: {},
  goBackPath: -1,
};

// <---------------reducer for get user details----------------->
const getUserDetailsStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const getUserDetailsSuccessReducer = (state = INITIAL_STATE, action) => {
  const { userData } = action.payload;
  return {
    ...state,
    userData: userData?.data,
  };
};
const getUserDetailsErrorReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
//
// <---------------reducer for get user details----------------->
const updateUserDetailsStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const updateUserDetailsSuccessReducer = (state = INITIAL_STATE, action) => {
  const { userData } = action.payload;
  return {
    ...state,
    userData: userData?.data,
  };
};
const updateUserDetailsErrorReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
// on back click action
const onBackClick = (state = INITIAL_STATE, action) => {
  const { goBackPath } = action.payload;
  return {
    ...state,
    goBackPath,
  };
};

const HANDLERS = {
  [loggedUserTypes.GET_USER_DETAILS_START]: getUserDetailsStartReducer,
  [loggedUserTypes.GET_USER_DETAILS_SUCCESS]: getUserDetailsSuccessReducer,
  [loggedUserTypes.GET_USER_DETAILS_FAILURE]: getUserDetailsErrorReducer,
  //
  [loggedUserTypes.UPDATE_USER_DETAILS_START]: updateUserDetailsStartReducer,
  [loggedUserTypes.UPDATE_USER_DETAILS_SUCCESS]:
    updateUserDetailsSuccessReducer,
  [loggedUserTypes.UPDATE_USER_DETAILS_FAILURE]: updateUserDetailsErrorReducer,
  //
  [loggedUserTypes.BACK_ACTION]: onBackClick,
};

export const loggedUserReducer = createReducer(INITIAL_STATE, HANDLERS);
