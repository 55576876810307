/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import moment from 'moment';
// import { AddToCart, RemoveFromCart } from '../../../assets/icons';
// import CollapseIcon from '../../SvgIcons/KPVList/CollapseIcon';
import DynamicSVGIcon from '../DynamicSVGIcon';
import {
  // Td,
  // ToogleGroupICon,
  // Tr,
  // ActionIcon,
  FlexContainer,
} from '../../constants/BaseStyles';
import {
  Td,
  Tr,
  ToogleGroupICon,
  RowOptionsKabab,
  LinkUrl,
} from './TabsTable.Style';
// import kababIcon from '../../../assets/icons/Filter/kabab.svg';
// import DropDownModal from './TableDependency/DropDownModal/DropDownModal';
import { validate, VALIDATION_TYPES, format } from '../../utils/utility';
import Kabab from '../../assets/icons/Kabab.svg';
// import Duplicate from '../../assets/images/Duplicate.png';
// import { COLORS } from '../../constants/BaseStyles';
import {
  CustomCheckBox,
  CustomCheckBoxOuter,
  CheckBoxInput,
} from '../../constants/GlobalStyles.Styles';
import OptionDropDown from './OptionDropDown';
import { TABLE_COLOR_COMBINATION } from '../../constants/constant';

const TableContent = ({
  type, // groupby value
  rowsDetails, // object: data is formed from rowsDetails[type]
  metaDetails, // object containing columns
  assetShortlist,
  visibleColumnsCount,
  onItemSelect,
  showShortlistSelection,
  handleShortList,
  rowOptions,
  handleRowOptionsItemClickHandler,
  id = 'id',
  checkList,
  handleSelectOne,
  ObjectComponent,
  checkBoxDisable,
  rowOptionsDependentKeys = [],
  customCellStyle,
}) => {
  const [showDetails, setShowDetails] = useState(true);
  const handleToggle = () => {
    setShowDetails((prev) => !prev);
  };
  // show rowoptions
  const [activeRowItem, setActiveRowItem] = useState(null);
  const handleOptionsClick = (item) => {
    setActiveRowItem(item?.id);
  };
  // close handler
  const closeDropdownOptions = () => {
    setActiveRowItem(null);
  };
  const handleRowOptionsItemClick = (...spred) => {
    handleRowOptionsItemClickHandler(...spred);
    closeDropdownOptions();
  };

  const getAddidtionalKeys = (data) => {
    const additionalKeys = {};
    rowOptionsDependentKeys.map((item) => {
      additionalKeys[item] = data[item];
    });
    return additionalKeys;
  };
  return (
    <>
      {/* hide row if there is grouping specified */}
      {type !== 'noGrouping' && (
        <Tr>
          <Td style={{ cursor: 'pointer' }} colSpan={visibleColumnsCount}>
            <FlexContainer className="ai_center">
              <ToogleGroupICon onClick={handleToggle}>
                {/* <CollapseIcon /> */}
              </ToogleGroupICon>
              <p>{type}</p>
            </FlexContainer>
          </Td>
        </Tr>
      )}
      {showDetails &&
        rowsDetails?.[type]?.map((entry, index) => (
          <Tr key={index}>
            {!checkBoxDisable && (
              <Td>
                <CustomCheckBoxOuter>
                  <CheckBoxInput
                    id={entry.id}
                    type="checkbox"
                    onChange={(e) => handleSelectOne(e, entry)}
                    checked={checkList.includes(entry.id)}
                  ></CheckBoxInput>

                  <CustomCheckBox></CustomCheckBox>
                </CustomCheckBoxOuter>
              </Td>
            )}

            {metaDetails?.columns?.map((col, key) => {
              let isImg = false;
              let isHtmlValue = false;
              let value = entry[col.key] || '-';
              if (typeof entry[col.key] === 'string') {
                isImg = entry[col.key]?.startsWith('http');
                isHtmlValue = entry[col.key]?.startsWith('<');
                if (
                  typeof entry[col.key] === 'string' &&
                  entry[col.key].trim() === 'to'
                ) {
                  value = '--:--:-- to --:--:--';
                }
              }
              if (validate(entry[col.key], VALIDATION_TYPES.ISO_DATE_STRING)) {
                value = moment(entry[col.key]).format(format);
              }
              let content = isImg ? (
                <LinkUrl href={value} target="blank">
                  {value}
                </LinkUrl>
              ) : (
                // <img height="40px" src={entry[col.key]} alt="spare" />
                <>{value}</>
              );
              if (isHtmlValue) {
                content = (
                  <p
                    style={{ fontSize: '1.6rem' }}
                    dangerouslySetInnerHTML={{ __html: entry[col.key] || '-' }}
                  />
                );
              }

              if (typeof ObjectComponent[col.key] === 'function') {
                let Component = ObjectComponent[col.key];
                content = <Component value={entry[col.key]} />;
              }

              return (
                col.visible &&
                (col?.key === 'row_options' ? (
                  <Td key={key}>
                    <RowOptionsKabab
                      className="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOptionsClick(entry);
                      }}
                    >
                      <DynamicSVGIcon iconUrl={Kabab} width="0.2rem" />

                      {activeRowItem === entry?.id && (
                        <OptionDropDown
                          entry={entry}
                          rowOptions={rowOptions}
                          closeDropdownOptions={closeDropdownOptions}
                          // setActiveRowItem={setActiveRowItem}
                        />
                      )}
                    </RowOptionsKabab>
                  </Td>
                ) : (
                  <Td
                    border
                    key={key}
                    color={TABLE_COLOR_COMBINATION[col?.key]}
                    style={{
                      background: metaDetails?.fieldsToColor?.includes(col.key)
                        ? entry?.[metaDetails?.colorField?.[0]]
                        : '',
                      cursor: 'pointer',
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      alignItems: 'center',
                      ...customCellStyle,
                    }}
                    // width={col.width}
                    // order={col.order}
                    // align={col.textAlign}
                    className={
                      assetShortlist?.some(
                        (ele) =>
                          ele.mainPartNumber === entry.mainPartNumber &&
                          ele.inventPartnumber === entry.inventPartnumber
                      )
                        ? 'active pointer'
                        : 'pointer'
                    }
                    removeVerticalPadding={isImg}
                    onClick={() => onItemSelect(entry)}
                  >
                    {content}
                  </Td>
                ))
              );
            })}
          </Tr>
        ))}
    </>
  );
};

export default TableContent;
