import { COLORS } from '../../constants/BaseStyles';

export const generateDropdownStyles = (state) => {
  const isVersionDropdown = state?.version;
  const isProjectTypeDropdown = state?.projectType;
  const disable = state?.disabled;

  return {
    control: (provided) => ({
      ...provided,
      background: disable
        ? `${COLORS.PRIMARY_BLACK}`
        : `${COLORS.PRIMARY_GREY}`,
      border: disable ? 'none' : `1px solid ${COLORS.OVERLAY_GREY}`,
      fontSize: '13px',
      textAlign: 'left',
      width: isVersionDropdown ? 'auto' : '420px',
      width: isProjectTypeDropdown ? '220px' : 'auto',
      margin: '5px',
    }),

    singleValue: (provided) => {
      return {
        ...provided,
        textAlign: 'center',
        color: `${COLORS.TEXT_MEDIUM_GREY}`,
        textAlign: 'left',
      };
    },

    dropdownIndicator: (provided) => {
      return {
        ...provided,
        color: disable ? `${COLORS.PRIMARY_BLACK}` : `${COLORS.OVERLAY_GREY}`,
      };
    },

    menu: (provided) => {
      return {
        ...provided,
        color: 'white',
        backgroundColor: `${COLORS.OVERLAY_GREY}`,
        width: isVersionDropdown ? '130px' : '420px',
        width: isProjectTypeDropdown ? '220px' : '420px',

        textAlign: 'left',
        fontSize: '13px',
      };
    },

    input: (provided) => {
      return {
        ...provided,
        color: `${COLORS.TEXT_MEDIUM_GREY}`,
        textAlign: 'left',
        fontSize: '13px',
      };
    },

    option: (provided) => {
      return {
        ...provided,
        color: 'white',
        backgroundColor: `${COLORS.OVERLAY_GREY}`,
        width: isVersionDropdown ? '130px' : '420px',
        width: isProjectTypeDropdown ? '220px' : 'auto',
        textAlign: 'left',
        fontSize: '13px',

        '&:hover': {
          background: `${COLORS.PRIMARY_BLACK}`,
        },
      };
    },

    indicatorSeparator: (provided) => {
      return {
        ...provided,
        background: disable
          ? `${COLORS.PRIMARY_BLACK}`
          : `${COLORS.OVERLAY_GREY}`,
      };
    },
  };
};
