import { useEffect, useRef, useState } from 'react';
import { COLORS, TOAST_POSITION } from '../../constants/BaseStyles';
import { Button } from '../../constants/GlobalStyles.Styles';
import { STRINGS } from '../../constants/labels';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import Camera from '../../assets/icons/Camera.svg';
import UniversalModal from './UniversalModal';
import {
  CallingCodes,
  ChoosePic,
  CountryCode,
  CountryList,
  CountrySelectContainer,
  DropDownIcon,
  InputLabel,
  PhoneNumberContainer,
  UpdateBtnContainer,
  UserAvatar,
  UserDetils,
  UserForm,
  UserInput,
  UserMoalContainer,
  UserProfile,
} from './UserModals.Styles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import { ErrorToast } from '../../services/toast/toast';
import { loggedUserCreators } from '../../pages/homepage/store/action';
import { useDispatch } from 'react-redux';
import DropDown from '../../assets/icons/DropDownIcon.svg';

const UserModals = ({ isProfileOpen, setIsProfileOpen, userData }) => {
  const strings = STRINGS['ENGLISH'];
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  //
  const [userDetails, setUserDetails] = useState({});
  const [countries, setCountries] = useState([]);
  const [imageData, setImageData] = useState('');
  const [showDropDown, setShowDorpDown] = useState(false);

  // useEffect hook to fetch data from the 'https://restcountries.com/v2/all' endpoint
  useEffect(() => {
    // Define an asynchronous function to fetch country data
    const fetchData = async () => {
      try {
        const response = await fetch('https://restcountries.com/v2/all');
        const data = await response.json();
        // Update the state with the fetched country data
        setCountries(data);
      } catch (error) {
        // Handle errors if the fetch operation fails
        console.error('Error fetching country data:', error);
      }
    };

    // Invoke the fetchData function when the component mounts (empty dependency array)
    fetchData();
  }, []);
  //
  useEffect(() => {
    if (userData) {
      setUserDetails(userData);
    }
  }, [userData]);

  const onUpdateProfile = (event) => {
    if (event.target.files && event.target.files[0]) {
      // setIsCropper(true);
      setImageData(event.target.files[0]);
      // setImage(URL.createObjectURL(event.target.files[0]));
      setUserDetails({
        ...userDetails,
        profile_pic_url: URL.createObjectURL(event.target.files[0]),
      });
    }
  };
  //
  const onUpdateSubmittProfile = () => {
    const formData = new FormData();
    formData.append('username', userDetails?.username);
    formData.append('country_code', userDetails?.country_code);
    if (imageData) {
      formData.append('profile_pic', imageData);
    }
    if (userDetails?.first_name) {
      formData.append('first_name', userDetails?.first_name);
    } else {
      ErrorToast(
        'User name can not be empty',
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    if (userDetails?.phone_number?.toString().length === 10) {
      formData.append('phone_number', userDetails?.phone_number);
    } else {
      ErrorToast(
        'Enter valid phone number',
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    dispatch(loggedUserCreators.updateUserDetails({ formData }));
    setIsProfileOpen(false);
  };

  const cancelButtonClick = () => {
    if (userData) {
      setUserDetails(userData);
    }
    setIsProfileOpen(false);
  };

  return (
    <UniversalModal
      height={'550px'}
      isOpenModal={isProfileOpen}
      width={'600px'}
      btn={true}
      // leftText={strings.NO}
      // rightText={strings.YES}
      // onrightbtnClick={addNewVersion}
      // onleftbtnClick={cancel}
    >
      <UserMoalContainer>
        <UserProfile>Profile</UserProfile>
        {/* <UserDetils>user details</UserDetils> */}
        <UserAvatar
          onClick={(e) => {
            e.stopPropagation();
            fileInput.current && fileInput.current.click();
          }}
          img={userDetails?.profile_pic_url}
        >
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={onUpdateProfile}
            ref={fileInput}
            style={{
              display: 'none',
            }}
          ></input>
          <ChoosePic>
            <DynamicSVGIcon iconUrl={Camera} />
          </ChoosePic>
        </UserAvatar>
        <UserForm>
          <InputLabel>Name</InputLabel>
          <UserInput
            value={userDetails?.first_name}
            onChange={(e) => {
              setUserDetails({ ...userDetails, first_name: e.target.value });
            }}
            maxLength={60}
          />
          <InputLabel>Contact Number</InputLabel>
          <PhoneNumberContainer>
            <CountrySelectContainer
              onClick={() => {
                setShowDorpDown(true);
              }}
            >
              <CountryCode>{userDetails?.country_code}</CountryCode>
              <DropDownIcon>
                <DynamicSVGIcon iconUrl={DropDown} rotate={'-90'} />
              </DropDownIcon>
              {showDropDown && (
                <CountryList>
                  {countries?.map((data) => {
                    return (
                      <>
                        <CallingCodes
                          onClick={(event) => {
                            event.stopPropagation();
                            setUserDetails({
                              ...userDetails,
                              country_code: `+${data?.callingCodes[0]}`,
                            });

                            setShowDorpDown(false);
                          }}
                        >{`+${data?.callingCodes[0]}`}</CallingCodes>
                      </>
                    );
                  })}
                </CountryList>
              )}
            </CountrySelectContainer>
            <UserInput
              border={true}
              value={userDetails?.phone_number}
              maxLength={10}
              onChange={(event) => {
                if (!isNaN(event.target.value)) {
                  setUserDetails({
                    ...userDetails,
                    phone_number: event.target.value,
                  });
                }
              }}
            />
          </PhoneNumberContainer>

          <InputLabel disable={true}>Email Address</InputLabel>
          <UserInput value={userDetails?.email} disabled disable={true} />
        </UserForm>
        {/* <------------------btn container-----------------> */}
        <UpdateBtnContainer>
          <Button
            // marginTop={buttonMarginTopStyle}
            // visibility={visibilityStyle}
            width={'100px'}
            height={'35px'}
            noBoxShadow={true}
            noTopMargin
            bgColor={COLORS.BTN_PRIMARY}
            onClick={cancelButtonClick}
          >
            {strings.CANCEL}
          </Button>
          <Button
            // marginTop={buttonMarginTopStyle}
            // visibility={visibilityStyle}
            width={'100px'}
            height={'35px'}
            noBoxShadow={true}
            noTopMargin
            onClick={onUpdateSubmittProfile}
          >
            {strings.UPDATE}
          </Button>
        </UpdateBtnContainer>
      </UserMoalContainer>
    </UniversalModal>
  );
};
export default UserModals;
