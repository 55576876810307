import React, { useEffect, useRef, useState } from 'react';

//react - pdf
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import {
  ArrowButton,
  HoverWrapper,
  NavigationContainer,
  PageInfo,
  PdfOverflowDiv,
  PdfWrapper,
} from './PdfViewer.Styles';

import { useCallback } from 'react';
import { PdfIntersectionObserver } from './PdfIntersectionObserver.View';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({ pdfProps }) => {
  const [numPages, setNumPages] = useState(null);
  const [visiblePages, setVisiblePages] = useState({});

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  //get list of all pages visible
  const setPageVisibility = useCallback((pageNumber, isIntersecting) => {
    setVisiblePages((prevVisiblePages) => ({
      ...prevVisiblePages,
      [pageNumber]: isIntersecting,
    }));
  }, []);

  //return only first page that is visible, even if 2 pages are visbie ie: at the end of page n and beginning of page n+1
  const getFirstVisiblePage = () => {
    const visiblePageNumbers = Object.entries(visiblePages)
      .filter(([key, value]) => value)
      .map(([key]) => parseInt(key));

    if (visiblePageNumbers.length > 0) {
      return visiblePageNumbers[0];
    }
    return 0;
  };

  return (
    <>
      <HoverWrapper>
        <PdfOverflowDiv>
          <PdfWrapper>
            <Document
              file={pdfProps?.[0]}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {/* display multiple pages */}
              {Array.from({ length: numPages || 0 }, (_, index) => (
                <PdfIntersectionObserver
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  setPageVisibility={setPageVisibility}
                />
              ))}
            </Document>
          </PdfWrapper>
        </PdfOverflowDiv>
        <NavigationContainer>
          <PageInfo>{`${getFirstVisiblePage()} of ${numPages}`}</PageInfo>
        </NavigationContainer>
      </HoverWrapper>
    </>
  );
};
export default PdfViewer;
