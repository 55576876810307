import React from 'react';
import { Row } from '../CalendarModal/CalendarModal.Styles';
import {
  NameWrapper,
  ProfileDiv,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { CardUsername } from '../Cards/Cards.Styles';
import { STRINGS } from '../../constants/labels';

const FormatSocialMediaLabel = (option, { context }) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  return context === 'value' ? (
    <Row flexStart>
      <ProfileDiv img={option?.img} width="20px" height={'20px'}></ProfileDiv>
    </Row>
  ) : (
    <Row flexStart>
      <ProfileDiv width="20px" height="20px" img={option?.img}></ProfileDiv>
      <NameWrapper>{option?.label}</NameWrapper>
    </Row>
  );
};

export default FormatSocialMediaLabel;
