import 'react-datepicker/dist/react-datepicker.css';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store/action';
import { capitalizeFirstLetter } from '../../constants/functions';
import {
  Col,
  Label,
  Row,
} from '../../components/CalendarModal/CalendarModal.Styles';

import { Title } from '../assetVersions/AssetVersion.Style';
import UserCard from '../../components/Cards/UserCard.View';
import { STRINGS } from '../../constants/labels';
import { ScrollableDiv } from '../calendar/Calendar.Styles';

import { P } from './Project.Styles';

import BlueKabab from '../../assets/icons/BlueKabab.svg';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import { PROJECT_DETAILS_MAPPING, STATUS } from '../../constants/constant';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import { Website } from './ProjectDetailBaseView.Styles';

const ProjectDetails = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get('project_name');
  //
  const { projectDetails } = useSelector((state) => state.project);
  const navigate = useNavigate();
  //redux
  const dispatch = useDispatch();

  const param = useParams();
  const { project_id } = param;
  //states
  const [projectType, setProjectType] = useState(null);
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState(null);

  //consolidated states for form
  const [formData, setFormData] = useState({
    project_name: '',
    started_at: '',
    ended_at: '',
    project_type: '',
    website_url: '',
    created_at: '',
    status: '',
    description: '',
  });
  const [firstColData, setFirstColData] = useState({
    project_name: '',
    started_at: '',
    ended_at: '',
    project_type: '',
  });

  const [secondColData, setSecondColData] = useState({
    website_url: '',
    created_at: '',
    status: '',
    description: '',
  });

  useEffect(() => {
    if (project_id) {
      dispatch(Creators.projectDetails({ projectId: project_id }));
    }
  }, [dispatch, project_id]);

  useEffect(() => {
    if (projectDetails[project_id]) {
      setDescription(projectDetails[project_id]?.description);
      setProjectType({
        value: projectDetails[project_id]?.project_type,
        label: capitalizeFirstLetter(projectDetails[project_id]?.project_type),
      });
      setStatus({
        value: projectDetails[project_id]?.status,
        label: projectDetails[project_id]?.status,
      });

      const {
        project_name,
        started_at,
        ended_at,
        project_type,
        website_url,
        created_at,
        status,
        description,
      } = projectDetails[project_id];

      // Set the extracted data in the state
      setFormData({
        project_name,
        started_at,
        ended_at,
        project_type,
        website_url,
        created_at,
        status,
        description,
      });

      setFirstColData({
        project_name,
        started_at,
        ended_at,
        project_type,
      });

      setSecondColData({
        website_url,
        created_at,
        status,
      });
    }
  }, [projectDetails, project_id]);

  return (
    <>
      <SubHeader
        buttonDisplay={'none'}
        title={projectDetails[project_id]?.project_name || projectName}
        customBackFunction={() => {
          const url = `/brands/${projectDetails[project_id]?.base_company_id}`;
          navigate(url);
        }}
      />
      <Row
        padding={'10px'}
        marginTop={'60px'}
        marginLeft={'100px'}
        spaceBetween
        alignItems={'flex-start'}
      >
        <Col
          width={'60%'}
          align={'flex-start'}
          justify={'flex-start'}
          height={'80vh'}
        >
          <Title>{strings.DETAILS}</Title>
          <Row
            spaceBetween
            marginBottom={'40px'}
            alignItems={'flex-start'}
            marginTop={'5px'}
            changeDirection
          >
            <Col width={'50%'} maximizeResizeWidth>
              {Object.entries(firstColData).map(([key, value]) => {
                return (
                  <Row flexStart key={key}>
                    <Label fontSize={'13px'} noMarginTop>
                      <Row spaceBetween width={'90%'}>
                        {PROJECT_DETAILS_MAPPING[key]}
                        <DynamicSVGIcon iconUrl={BlueKabab} width="0.13rem" />
                      </Row>
                    </Label>
                    <P>{value}</P>
                  </Row>
                );
              })}
            </Col>
            <Col width={'50%'} maximizeResizeWidth>
              {Object.entries(secondColData).map(([key, value]) => {
                return (
                  <Row flexStart key={key}>
                    <Label fontSize={'13px'} noMarginTop>
                      <Row spaceBetween width={'90%'}>
                        {PROJECT_DETAILS_MAPPING[key]}
                        <DynamicSVGIcon iconUrl={BlueKabab} width="0.13rem" />
                      </Row>
                    </Label>
                    {key === 'website_url' ? (
                      <Website href={value} target="blank">
                        {value}
                      </Website>
                    ) : (
                      <P>{STATUS[value] || value}</P>
                    )}
                  </Row>
                );
              })}
            </Col>
          </Row>
          <Title>{strings.DESCRIPTION}</Title>
          <P maxWidth={'700px'}>{formData?.description}</P>
        </Col>
        <Row width={'25vw'} marginRight={'120px'} flexStart borderLeft>
          <Col align={'flex-start'} padding={'10px'}>
            <Title fontSize={'16px'} height={'30px'}>
              {strings.USERS} ({projectDetails[project_id]?.users?.length})
            </Title>
            <ScrollableDiv>
              <Col width={'25vw'} justify={'flex-start'} align={'flex-start'}>
                {projectDetails[project_id]?.users?.map((data, index) => (
                  <Col
                    key={index}
                    width={'25vw'}
                    height={'80px'}
                    marginTop={'10px'}
                    justify={'space-between'}
                    lightBorderBottom
                  >
                    <UserCard
                      height={'50px'}
                      width={'50px'}
                      profilePic={data?.img || data?.profile_pic_url}
                      username={data?.name}
                      usernameFontSize={'12px'}
                      rightDiv={data?.time}
                      marginBottom={'8px'}
                      showRole={data?.role}
                      noPurpleText
                      showDropDown={false}
                    />
                  </Col>
                ))}
              </Col>
            </ScrollableDiv>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ProjectDetails;
