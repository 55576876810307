/**
 * reducer js
 *
 * it will have all the dashboard related reducers
 */

import { createReducer } from 'reduxsauce';
import { AddProjectsTypes } from './action';
const INITIAL_STATE = {
  error: false,
  loading: true,
  addedSuccessFully: false,
  projectData: {},
  userList: [],
  clientList: [],
  detailedUserList: [],
  viewProjectData: {},
  filteredUserData: [],
};
// <-----------------------reducer for add project-------------------->
const addProjectStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    addedSuccessFully: false,
  };
};
const addProjectSuccess = (state = INITIAL_STATE, action) => {
  // const { data } = action.payload?.data;
  return {
    ...state,
    error: false,
    loading: false,
    addedSuccessFully: true,
  };
};
const addProjectError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    addedSuccessFully: false,
  };
};
const resetStateReduer = (state = INITIAL_STATE, action) => {
  return {
    addedSuccessFully: false,
  };
};
// <--------------------------user list reducer---------------------->
const userListStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    UserLoading: true,
    // savemode: false,
  };
};
const userListSuccessReducer = (state = INITIAL_STATE, action) => {
  const { userList } = action.payload;
  return {
    ...state,
    userListLoading: false,
    // savemode: true,
    userList: userList?.data,
  };
};
const filteredUserListSuccessReducer = (state = INITIAL_STATE, action) => {
  const { userList } = action.payload;
  return {
    ...state,
    userListLoading: false,
    // savemode: true,
    filteredUserData: userList?.data,
  };
};

const userListFailureReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addUserLoading: false,
    // savemode: false,
  };
};

// <--------------------------Edit user  reducer---------------------->
const editProjectStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editUserLoading: true,
    editProject: false,
  };
};
const editProjectSuccessReducer = (state = INITIAL_STATE, action) => {
  const { projectViewData, update } = action.payload;
  return {
    ...state,
    userListLoading: false,
    viewProjectData: projectViewData.data,
    editProject: update ? true : false,
  };
};
const editProjectFailureReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addUserLoading: false,
    editProject: false,
  };
};
const editProjectResetREducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editProject: false,
  };
};

const HANDLERS = {
  [AddProjectsTypes.ADD_PROJECT_START]: addProjectStart,
  [AddProjectsTypes.ADD_PROJECT_SUCCESS]: addProjectSuccess,
  [AddProjectsTypes.ADD_PROJECT_ERROR]: addProjectError,
  [AddProjectsTypes.RESET_STATE]: resetStateReduer,
  //
  [AddProjectsTypes.USER_LIST_START]: userListStartReducer,
  [AddProjectsTypes.USER_LIST_SUCCESS]: userListSuccessReducer,
  [AddProjectsTypes.FILTERED_USER_LIST_SUCCESS]: filteredUserListSuccessReducer,

  [AddProjectsTypes.USER_LIST_FAILURE]: userListFailureReducer,

  //
  [AddProjectsTypes.EDIT_PROJECT_START]: editProjectStartReducer,
  [AddProjectsTypes.EDIT_PROJECT_SUCCESS]: editProjectSuccessReducer,
  [AddProjectsTypes.EDIT_PROJECT_FAILURE]: editProjectFailureReducer,
  [AddProjectsTypes.EDIT_PROJECT_RESET]: editProjectResetREducer,
  //
};

export const addProjectReducer = createReducer(INITIAL_STATE, HANDLERS);
