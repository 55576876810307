/**
 * reducer js
 *
 * it will have all the user list related reducers
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createReducer } from 'reduxsauce';
import { UserTypes } from './action';
// import { UserCreators } from './action';
const INITIAL_STATE = {
  userLsitLoading: false,
  userListData: [],
  userListAgencyData: [],
  userListHeader: {},
  filter: {},
  searchValue: '',
  deleteLoading: false,
  selectLoading: false,
  clientSelectData: [],
  selectedfilters: {},
};
// <---------------reducer for get users's from list----------------->
const getUserListStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    userLsitLoading: true,
  };
};
const getUserListSuccessReducer = (state = INITIAL_STATE, action) => {
  let userList = [];
  const { data } = action.payload;
  if (data?.page_number === 1) {
    userList = data?.data;
  } else {
    userList = [...state.userListData, ...data?.data];
  }
  return {
    ...state,
    userLsitLoading: false,
    userListHeader: data,
    userListData: userList,
  };
};
const getUserListFailureReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    userLsitLoading: false,
  };
};
//
const userFiltersReducer = (state = INITIAL_STATE, action) => {
  const { filter, searchValue, selectedOption } = action.payload;
  return {
    ...state,
    filter: filter,
    searchValue: searchValue,
    selectedfilters: selectedOption,
  };
};
// <---------------reducer for delete user--------------------->
const deleteUserStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLoading: true,
  };
};
const deleteUserSuccessReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLoading: false,
  };
};
const deleteUserFailureReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteLoading: false,
  };
};
// <---------------reducer for select clients--------------------->
const selectClientStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selectLoading: true,
  };
};
const selectClientSuccessReducer = (state = INITIAL_STATE, action) => {
  const { selectData } = action.payload;
  return {
    ...state,
    selectLoading: false,
    clientSelectData: selectData.data,
  };
};
const selectClientFailureReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selectLoading: false,
  };
};
// <------------------reset user filter------------------------->
const resetUserFiltersReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    filter: {},
    searchValue: '',
    selectedfilters: {},
  };
};

const getUserListAgencyStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    userLsitLoading: true,
  };
};
const getUserListAgencySuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    userLsitLoading: false,
    userListHeader: data,
    userListAgencyData: data?.data,
  };
};
const getUserListAgencyFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    userLsitLoading: false,
  };
};

const HANDLERS = {
  [UserTypes.GET_USER_LIST_START]: getUserListStartReducer,
  [UserTypes.GET_USER_LIST_SUCCESS]: getUserListSuccessReducer,
  [UserTypes.GET_USER_LIST_FAILURE]: getUserListFailureReducer,
  [UserTypes.USER_FILTERS]: userFiltersReducer,
  //
  [UserTypes.DELETE_USER_START]: deleteUserStartReducer,
  [UserTypes.DELETE_USER_SUCCESS]: deleteUserSuccessReducer,
  [UserTypes.DELETE_USER_FAILURE]: deleteUserFailureReducer,
  //
  [UserTypes.SELECT_CLIENT_START]: selectClientStartReducer,
  [UserTypes.SELECT_CLIENT_SUCCESS]: selectClientSuccessReducer,
  [UserTypes.SELECT_CLIENT_FAILURE]: selectClientFailureReducer,
  //
  [UserTypes.RESET_USER_FILTERS]: resetUserFiltersReducer,

  [UserTypes.GET_USER_LIST_AGENCY_START]: getUserListAgencyStart,
  [UserTypes.GET_USER_LIST_AGENCY_SUCCESS]: getUserListAgencySuccess,
  [UserTypes.GET_USER_LIST_AGENCY_FAILURE]: getUserListAgencyFailure,
};

export const userListReducer = createReducer(INITIAL_STATE, HANDLERS);
