import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const ResponsiveContainer = styled.div`
  position: absolute;
  bottom: 5px;
  //   right: 10px;
  display: flex;
  //   background-color: ${COLORS.BACKGROUND_COLOR};

  height: 35px;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  //   padding-left: 15px;
  //   padding-right: 15px;
`;
export const IconsContainers = styled.div`
  background-color: ${COLORS.BACKGROUND_COLOR};
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-between;
  border-radius: 12px;
  height: 30px;
`;
export const ResponsiveIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  column-gap: 10px;
`;
export const Icon = styled.div`
  background-color: ${({ active }) => active && COLORS.ACTIVE_BG};
  width: 25px;
  height: 25px;
  border-radius: 4px;
`;

export const WebsiteReviewContainer = styled.div`
  width: 70%;
  height: 80vh;
`;
