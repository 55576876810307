import { useDispatch } from 'react-redux';
import { STRINGS } from '../../constants/labels';
import {
  CommentBox,
  CommentBtn,
  CommentCount,
  CommentHeader,
  CommentInput,
  CommentOperation,
  Comments,
  Input,
  ReplyContainer,
} from './Comment.Styles';
import DynamicSVGIcon from '../DynamicSVGIcon';
// assets
import Kabab from '../../assets/icons/Kabab.svg';
import Button from '../Buttons/Button.View';
import { COLORS } from '../../constants/BaseStyles';
import { useState } from 'react';
import { AssetCreators } from '../../pages/assetsView/store/action';
import CommentIndividual from './CommentIndividual';
import OptionCard from '../options/OptionCard';
import {
  COMMENT_MODIFICATION,
  COMMENT_MODIFICATION_UNRESOLVED,
} from '../../constants/constant';
import { useSearchParams } from 'react-router-dom';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import { WebpageCreators } from '../../pages/WebsiteFeedback/store/action';

const CommentItem = ({
  comment,
  setComment,
  onComment,
  oncancelComment,
  comments,
  commentMetaData,
  website = false,
  view_type,
}) => {
  const dispatch = useDispatch();
  //
  const [searchParams] = useSearchParams();
  const assetID = searchParams.get('asset_id');
  const versionId = searchParams.get('version_id');
  const webpage_id = searchParams.get('webpage_id');
  const project_id = searchParams.get('project_id');
  //
  const [isCommentModify, setIsCommentModify] = useState(false);
  const [selectedIDforReply, setSelectedIDforReply] = useState('');

  //text
  const strings = STRINGS['ENGLISH'];

  const onClickOption = (data) => {
    for (let key in commentMetaData) {
      commentMetaData[key] = JSON.parse(commentMetaData[key]);
    }
    const { key } = data;
    switch (key) {
      case 'sort_by_latest':
        if (website) {
          dispatch(
            WebpageCreators.getCommentsByWebsite({
              webpage_id,
              version_id: versionId,
              project_id,
              latest: !commentMetaData?.sort_by_latest,
              resolved: commentMetaData?.filter_by_resolved,
              FilterByMe: commentMetaData?.filter_by_me,
              view_type,
            })
          );
        } else {
          dispatch(
            AssetCreators.viewAssetComment({
              assetID,
              versionId,
              latest: !commentMetaData?.sort_by_latest,
              resolved: commentMetaData?.filter_by_resolved,
              FilterByMe: commentMetaData?.filter_by_me,
            })
          );
        }

        break;
      case 'filter_by_me':
        if (website) {
          dispatch(
            WebpageCreators.getCommentsByWebsite({
              webpage_id,
              version_id: versionId,
              project_id,
              resolved: commentMetaData?.filter_by_resolved,
              filter_by_me: !commentMetaData?.filter_by_me,
              view_type,
            })
          );
        } else {
          dispatch(
            AssetCreators.viewAssetComment({
              assetID,
              versionId,
              resolved: commentMetaData?.filter_by_resolved,
              FilterByMe: !commentMetaData?.filter_by_me,
            })
          );
        }
        break;
      case 'filter_by_resolved':
        if (website) {
          dispatch(
            WebpageCreators.getCommentsByWebsite({
              webpage_id,
              version_id: versionId,
              project_id,
              filter_by_resolved: !commentMetaData?.filter_by_resolved,
              filter_by_me: commentMetaData?.filter_by_me,
              view_type,
            })
          );
        } else {
          dispatch(
            AssetCreators.viewAssetComment({
              assetID,
              versionId,
              latest: commentMetaData?.sort_by_latest,
              resolved: !commentMetaData?.filter_by_resolved,
              FilterByMe: commentMetaData?.filter_by_me,
            })
          );
        }
        break;
      default:
        if (website) {
          dispatch(
            WebpageCreators.getCommentsByWebsite({
              webpage_id,
              version_id: versionId,
              project_id,
              view_type,
            })
          );
        } else {
          dispatch(
            AssetCreators.viewAssetComment({
              assetID,
              versionId,
            })
          );
        }
        break;
    }

    setIsCommentModify(false);
  };

  return (
    <CommentBox>
      {/* <--------------------comment header---------------------> */}
      <CommentHeader>
        <CommentCount>Comments ({comments?.length})</CommentCount>
        <CommentOperation
          onClick={() => {
            setIsCommentModify(!isCommentModify);
          }}
        >
          <DynamicSVGIcon iconUrl={Kabab} width="0.15rem" />
          <>
            {isCommentModify && (
              <UseClickOutside
                onClickOutside={() => {
                  setIsCommentModify(false);
                }}
              >
                <OptionCard
                  option={
                    commentMetaData?.filter_by_resolved !== 'true' &&
                    commentMetaData?.filter_by_me !== 'true'
                      ? COMMENT_MODIFICATION
                      : COMMENT_MODIFICATION_UNRESOLVED
                  }
                  right={'-5px'}
                  onClickOption={onClickOption}
                />
              </UseClickOutside>
            )}
          </>
        </CommentOperation>
      </CommentHeader>
      {/* <---------------comments---------------> */}
      <Comments>
        {comments?.map((data) => {
          return (
            <>
              <CommentIndividual
                userData={{ data }}
                website={website}
                selectedIDforReply={selectedIDforReply}
                setSelectedIDforReply={setSelectedIDforReply}
                view_type={view_type}
              />

              {data?.comment_id === selectedIDforReply && (
                <ReplyContainer>
                  <Input
                    placeholder="Please reply"
                    value={comment}
                    type="text"
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    maxLength={256}
                  />
                  <CommentBtn>
                    <Button
                      text={strings.CANCEL}
                      width={100}
                      height={35}
                      bgColor={COLORS.BTN_PRIMARY}
                      opacity={1}
                      fontSize={'0.8rem'}
                      btnClick={() => {
                        setSelectedIDforReply('');
                      }}
                    />
                    <Button
                      text={strings.REPLY}
                      width={100}
                      height={35}
                      bgColor={COLORS.BTN_GRADIENT}
                      opacity={1}
                      fontSize={'0.8rem'}
                      btnClick={() => {
                        onComment(selectedIDforReply);
                        setSelectedIDforReply('');
                      }}
                    />
                  </CommentBtn>
                </ReplyContainer>
              )}
            </>
          );
        })}
      </Comments>
      {/* <---------------add comment-------------> */}
      {!selectedIDforReply && (
        <CommentInput>
          <Input
            value={comment}
            placeholder="Enter Comment"
            type="text"
            onChange={(e) => {
              setComment(e.target.value);
            }}
            maxLength={256}
          />
          <CommentBtn>
            {comment && (
              <Button
                text={strings.CANCEL}
                width={100}
                height={35}
                bgColor={COLORS.BTN_PRIMARY}
                opacity={1}
                fontSize={'0.8rem'}
                btnClick={oncancelComment}
              />
            )}
            <Button
              text={strings.COMMENT}
              width={100}
              height={35}
              bgColor={COLORS.BTN_GRADIENT}
              opacity={1}
              fontSize={'0.8rem'}
              btnClick={onComment}
            />
          </CommentBtn>
        </CommentInput>
      )}
    </CommentBox>
  );
};

export default CommentItem;
