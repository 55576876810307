import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const SideNavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.PRIMARY_GREY};
  z-index: 10;
`;
export const SideBarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 7px;
  width: 100%;
  color: ${COLORS.TEXT_GREY};
`;
export const LogoPosition = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const BotDiv = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  color: ${COLORS.TEXT_GREY};
  margin-bottom: 15px;
`;
export const Header = styled.div`
  position: fixed;
  top: 0;
  z-index: 3;
  right: 0;
  left: 80px;
  font-weight: 600;
  text-transform: capitalize;
  width: calc(100% - 55px);
  height: 70px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.PRIMARY_GREY};
  -webkit-box-shadow: 0 6px 12px ${COLORS.BOX_SHADOW_COLOR};
  box-shadow: 0 6px 12px ${COLORS.BOX_SHADOW_COLOR};
`;

// Styled components for search bar
export const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  width: 400px;
  height: 35px;
  padding: 7px 25px;
  color: ${COLORS.TEXT_LIGHT_GREY};
  background-color: ${COLORS.PRIMARY_BLACK};
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;

export const Profile = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;
`;

export const ExpandedProfile = styled.div`
  box-shadow: 0 6px 12px ${COLORS.BOX_SHADOW_COLOR};
  background-color: ${COLORS.PRIMARY_GREY};
  color: ${COLORS.TEXT_GREY};
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  right: -10px;
  top: 50px;
  width: 160px;
  border: 1px solid #7070703b;
`;
export const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${COLORS.TEXT_MEDIUM_GREY};
  background-color: ${COLORS.PRIMARY_GREY};
  padding: 10px;
`;

export const StyledLink = styled.div`
  font-size: 14px;
  padding: 10px;
  color: ${COLORS.COMMENTS_BACKGROUND_COLOR};
  display: flex;
  align-items: center;
  column-gap: 10px;
  text-decoration: none;
`;
