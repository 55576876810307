/**
 * saga js
 *
 * it will have all the  asset related sagas
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import {
  ApiService,
  ApiServiceMultipart,
} from '../../../services/axios/apiService';
import { AssetsCreators } from './action';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../../constants/constant';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast, Toast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { act } from 'react-dom/test-utils';
import { AssetVersionsCreators } from '../../assetVersions/store/action';

//TEXT
const strings = STRINGS['ENGLISH'];
// <---------------saga for add asset ----------------->
export function* addAssetSaga(action) {
  const { formData, asset_id, editable, debouncedNavigate } = action.payload;
  if (asset_id) {
    formData.append('asset_id', asset_id);
  }

  try {
    yield put(AssetsCreators.addAssetStart());
    Toast('uploading', TOAST_POSITION.TOP_CENTER);

    const response = yield call(
      ApiServiceMultipart.post,
      apiEndPoints.addAsset,
      formData
    );
    if (response.status >= 200 && response.status < 300) {
      yield put(AssetsCreators.addAssetSuccess(response));
      if (editable) {
        yield put(AssetVersionsCreators.getAssetVersions({ asset_id }));
      }
      if (asset_id) {
        SuccessToast(strings.ASSET_UPDATED, TOAST_POSITION.TOP_CENTER);
      } else {
        SuccessToast(strings.ASSET_ADDED, TOAST_POSITION.TOP_CENTER);
      }
      if (debouncedNavigate) {
        yield call(debouncedNavigate);
      }
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetsCreators.addAssetError(payload));
  }
}
