/**
 * action js
 *
 * it will have all the calendar related actions
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPosts: ['payload'],
  getPostsStart: ['payload'],
  getPostsSuccess: ['payload'],
  getPostsError: ['payload'],
  //
  addPost: ['payload'],
  addPostStart: ['payload'],
  addPostSuccess: ['payload'],
  addPostError: ['payload'],
  resetState: ['payload'],
  // edit event
  updateEventDetails: ['payload'],
  updateEventDetailsStart: ['payload'],
  updateEventDetailsSuccess: ['payload'],
  updateEventDetailsError: ['payload'],
});

export { Types as PostsTypes, Creators as PostsCreators };
