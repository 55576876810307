/**
 *  js
 *
 * it will have all the webpage related reducers
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createReducer } from 'reduxsauce';
import { WebpageTypes } from './action';

const INITIAL_STATE = {
  error: false,
  loading: false,
  scrapeSuccess: false,
  listLoading: false,
  deleteFileloading: false,
  scrapedWebsiteData: {},
  htmlWebsiteData: {},
  singleWebpageData: {},
  webpagesListData: [],
  webpagesListHeader: {},
  commentsData: [],
  webpageVersions: [],
  highlightComment: null,
  updateWebStatusloading: false,
  commentMetaData: {},
};

// <---------------reducer for scrape webpage----------------->
const scrapeWebpageStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    scrapeSuccess: false,
  };
};

const scrapeWebpageSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    scrapedWebsiteData: data,
    scrapeSuccess: true,
  };
};

const scrapeWebpageError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    scrapeSuccess: false,
  };
};

const resetScrapeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    scrapeSuccess: false,
  };
};

// <---------------reducer for get webpage HTML from s3----------------->
const getHTMLPageStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};

const getHTMLPageSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    htmlWebsiteData: data,
  };
};

const getHTMLPageError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------reducer for get webpage version----------------->
const getWebpageStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};

const getWebpageSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    singleWebpageData: { [data?.version_id]: data },
  };
};

const getWebpageError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------reducer for get all versions of webpage ----------------->
const getWebpageVersionsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};

const getWebpageVersionsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  let assetVersionsList = [];
  assetVersionsList = data;
  return {
    ...state,
    error: false,
    loading: false,
    webpageVersions: assetVersionsList,
  };
};

const getWebpageVersionsError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------  reducer for get websites from list----------------->
const getAllWebpagesStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listLoading: true,
  };
};

const getAllWebpagesSuccess = (state = INITIAL_STATE, action) => {
  let webpagesList = [];
  const { data } = action.payload;
  if (data?.page_number === 1) {
    webpagesList = data?.data;
  } else {
    webpagesList = [...state.webpagesListData, ...data?.data];
  }
  return {
    ...state,
    listLoading: false,
    webpagesListData: webpagesList,
    webpagesListHeader: data,
  };
};

const getAllWebpagesError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    listLoading: false,
  };
};

// <---------------reducer for getting list of comments by webpage id----------------->
const getCommentsByWebsiteStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};

const getCommentsByWebsiteSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  const meta = {
    filter_by_me: data?.filter_by_me,
    filter_by_resolved: data?.filter_by_resolved,
    sort_by_latest: data?.sort_by_latest,
  };
  return {
    ...state,
    error: false,
    loading: false,
    commentsData: data?.data,
    commentMetaData: meta,
  };
};

const getCommentsByWebsiteError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------reducer for adding comments to webpage id----------------->
const addWebsiteCommentStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    commentAddedSuccess: false,
  };
};
const addWebsiteCommentSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    addComment: data,
    commentAddedSuccess: data?.is_reply ? false : true,
  };
};
const addWebsiteCommentError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    commentAddedSuccess: false,
  };
};
//
// <---------------reducer for delete  webpage--------------------->
const deleteWebsiteStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteFileloading: true,
  };
};
const deleteWebsiteSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteFileloading: false,
  };
};
const deleteWebsiteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    deleteFileloading: false,
  };
};

// <---------------reducer to highlight comment from list to pin comment and vice versa--------------------->
const highlightCommentReducer = (state = INITIAL_STATE, action) => {
  const { comment } = action;
  return {
    ...state,
    highlightComment: comment,
  };
};
// <---------------reducer for delete  webpage--------------------->
const updateWebStatusStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateWebStatusloading: true,
  };
};
const updateWebStatusSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateWebStatusloading: false,
  };
};
const updateWebStatusFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    updateWebStatusloading: false,
  };
};
//
const HANDLERS = {
  [WebpageTypes.SCRAPE_WEBPAGE_START]: scrapeWebpageStart,
  [WebpageTypes.SCRAPE_WEBPAGE_SUCCESS]: scrapeWebpageSuccess,
  [WebpageTypes.SCRAPE_WEBPAGE_ERROR]: scrapeWebpageError,
  //
  [WebpageTypes.RESET_SCRAPE_SUCCESS]: resetScrapeSuccess,
  //
  [WebpageTypes.GET_HTML_PAGE_START]: getHTMLPageStart,
  [WebpageTypes.GET_HTML_PAGE_SUCCESS]: getHTMLPageSuccess,
  [WebpageTypes.GET_HTML_PAGE_ERROR]: getHTMLPageError,
  //
  [WebpageTypes.GET_WEBPAGE_START]: getWebpageStart,
  [WebpageTypes.GET_WEBPAGE_SUCCESS]: getWebpageSuccess,
  [WebpageTypes.GET_WEBPAGE_ERROR]: getWebpageError,
  //
  [WebpageTypes.GET_WEBPAGE_VERSIONS_START]: getWebpageVersionsStart,
  [WebpageTypes.GET_WEBPAGE_VERSIONS_SUCCESS]: getWebpageVersionsSuccess,
  [WebpageTypes.GET_WEBPAGE_VERSIONS_ERROR]: getWebpageVersionsError,
  //
  [WebpageTypes.GET_ALL_WEBPAGES_START]: getAllWebpagesStart,
  [WebpageTypes.GET_ALL_WEBPAGES_SUCCESS]: getAllWebpagesSuccess,
  [WebpageTypes.GET_ALL_WEBPAGES_ERROR]: getAllWebpagesError,
  //
  [WebpageTypes.GET_COMMENTS_BY_WEBSITE_START]: getCommentsByWebsiteStart,
  [WebpageTypes.GET_COMMENTS_BY_WEBSITE_SUCCESS]: getCommentsByWebsiteSuccess,
  [WebpageTypes.GET_COMMENTS_BY_WEBSITE_ERROR]: getCommentsByWebsiteError,
  //
  [WebpageTypes.ADD_WEBSITE_COMMENT_START]: addWebsiteCommentStart,
  [WebpageTypes.ADD_WEBSITE_COMMENT_SUCCESS]: addWebsiteCommentSuccess,
  [WebpageTypes.ADD_WEBSITE_COMMENT_ERROR]: addWebsiteCommentError,
  //
  [WebpageTypes.DELETE_WEBSITE_START]: deleteWebsiteStart,
  [WebpageTypes.DELETE_WEBSITE_SUCCESS]: deleteWebsiteSuccess,
  [WebpageTypes.DELETE_WEBSITE_FAILURE]: deleteWebsiteFailure,
  //
  [WebpageTypes.UPDATE_WEB_STATUS_START]: updateWebStatusStart,
  [WebpageTypes.UPDATE_WEB_STATUS_SUCCESS]: updateWebStatusSuccess,
  [WebpageTypes.UPDATE_WEB_STATUS_ERROR]: updateWebStatusFailure,
  //
  [WebpageTypes.HIGHLIGHT_COMMENT]: highlightCommentReducer,
};

export const WebpageReducer = createReducer(INITIAL_STATE, HANDLERS);
