import { ModalHeading, ModalSubHeading } from '../../pages/brands/Brand.styles';
import {
  CaptionInput,
  CreateFolderContainer,
  ModalHeadingContainer,
} from './CreateFolder.Styles';
import {
  InputContainer,
  InputLabel,
  ProjectInput,
} from './CreateFolder.Styles';

const CreateFolder = ({ folderName, setFolderame, caption }) => {
  return (
    <CreateFolderContainer>
      <ModalHeadingContainer>
        <ModalHeading>
          {caption ? 'Update Caption' : 'Create new folder'}
        </ModalHeading>
        <ModalSubHeading>
          {caption ? '' : 'Enter folder details'}
        </ModalSubHeading>
      </ModalHeadingContainer>
      <InputContainer>
        <InputLabel>{caption ? 'Caption' : 'Folder Name'}</InputLabel>
        {caption ? (
          <CaptionInput
            value={folderName}
            maxLength={256}
            onChange={(e) => {
              setFolderame(e.target.value);
            }}
          />
        ) : (
          <ProjectInput
            value={folderName}
            onChange={(e) => {
              setFolderame(e.target.value);
            }}
          />
        )}
      </InputContainer>
    </CreateFolderContainer>
  );
};
export default CreateFolder;
