import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
`;
export const FullScreenImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
export const CloseFullScreen = styled.div`
 position: absolute;
 right: 20px;
 top: 20px;
 z-index: 2
 padding: 2px;
`;
export const Heading = styled.div`
  font-size: 1rem;
`;
export const BtnContanier = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 5px;
  column-gap: 10px;
`;
export const SaveBtn = styled.div``;
export const CancelBtn = styled.div``;
