/**
 * reducer js
 *
 * it will have all the assets related reducers
 */

import { createReducer } from 'reduxsauce';
import { AssetType } from './action';
const INITIAL_STATE = {
  //   viewAssetsloading: false,
  comments: [],
  viewAssetCommentloading: false,
  loading: false,
  AssetVersionData: {},
  deleteCommentloading: true,
  getAssetVersionListLoading: false,
  assetVersionsListData: [],
  updateVersionloading: false,
  uploadNewVersionFileLoading: false,
};
//
const getViewAssetsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};

const getViewAssetsSuccess = (state = INITIAL_STATE, action) => {
  const { data, assetID } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    AssetVersionData: {
      ...state.AssetVersionData,
      [assetID]: data,
    },
  };
};

const getViewAssetsFailure = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------reducer for assets comment--------------------->
const viewAssetCommentStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    viewAssetCommentloading: true,
  };
};
const viewAssetCommentSuccess = (state = INITIAL_STATE, action) => {
  const { data, commentMeta } = action.payload;
  const updatedCommentMeta = {
    filter_by_me: commentMeta?.filter_by_me,
    filter_by_resolved: commentMeta?.filter_by_resolved,
    sort_by_latest: commentMeta?.sort_by_latest,
  };
  return {
    ...state,
    viewAssetCommentloading: false,
    comments: data,
    commentMetaData: updatedCommentMeta,
  };
};
const viewAssetCommentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    viewAssetCommentloading: false,
  };
};
// <---------------reducer for assets comment--------------------->
const addAssetCommentStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addAssetCommentloading: true,
  };
};
const addAssetCommentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addAssetCommentloading: false,
  };
};
const addAssetCommentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    viewAssetCommentloading: false,
  };
};
// <---------------reducer for deleting comment----------------->
const deleteAssetCommentStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    deleteCommentloading: true,
  };
};
const deleteAssetCommentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    deleteCommentloading: false,
  };
};
const deleteAssetCommentError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    deleteCommentloading: false,
  };
};
// <-----------------reducer for update asset status------------------->
const updateAssetStatusStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    updateAssetloading: true,
  };
};
const updateAssetStatusSucces = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    updateAssetloading: false,
  };
};
const updateAssetStatusFailure = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    updateAssetloading: false,
    error: true,
    errorMessage,
  };
};
// <---------------reducer for get all asset versions----------------->
const getAssetVersionListStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getAssetVersionListLoading: true,
  };
};

const getAssetVersionListSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;

  return {
    ...state,
    getAssetVersionListLoading: false,
    assetVersionsListData: data,
  };
};

const getAssetVersionListFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    assetVersionsLoading: false,
  };
};
// <---------------reducer for add new version of asset ----------------->
const uploadNewVersionFileStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadNewVersionFileLoading: true,
  };
};

const uploadNewVersionFileSuccess = (state = INITIAL_STATE, action) => {
  // const { data } = action.payload;

  return {
    ...state,
    uploadNewVersionFileLoading: false,
    // assetVersionsListData: data,
  };
};

const uploadNewVersionFileFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    uploadNewVersionFileLoading: false,
  };
};

const HANDLERS = {
  //
  [AssetType.VIEW_ASSET_COMMENT_START]: viewAssetCommentStart,
  [AssetType.VIEW_ASSET_COMMENT_SUCCESS]: viewAssetCommentSuccess,
  [AssetType.VIEW_ASSET_COMMENT_FAILURE]: viewAssetCommentFailure,
  //
  [AssetType.ADD_ASSET_COMMENT_START]: addAssetCommentStart,
  [AssetType.ADD_ASSET_COMMENT_SUCCESS]: addAssetCommentSuccess,
  [AssetType.ADD_ASSET_COMMENT_FAILURE]: addAssetCommentFailure,
  //
  [AssetType.VIEW_ASSETS_START]: getViewAssetsStart,
  [AssetType.VIEW_ASSETS_SUCCESS]: getViewAssetsSuccess,
  [AssetType.VIEW_ASSETS_FAILURE]: getViewAssetsFailure,
  //
  [AssetType.DELETE_ASSET_COMMENT_START]: deleteAssetCommentStart,
  [AssetType.DELETE_ASSET_COMMENT_SUCCESS]: deleteAssetCommentSuccess,
  [AssetType.DELETE_ASSET_COMMENT_FAILURE]: deleteAssetCommentError,
  //update status
  [AssetType.UPDATE_ASSET_STATUS_START]: updateAssetStatusStart,
  [AssetType.UPDATE_ASSET_STATUS_SUCCESS]: updateAssetStatusSucces,
  [AssetType.UPDATE_ASSET_STATUS_FAILURE]: updateAssetStatusFailure,
  //list of  version
  [AssetType.GET_ASSET_VERSION_LIST_START]: getAssetVersionListStart,
  [AssetType.GET_ASSET_VERSION_LIST_SUCCESS]: getAssetVersionListSuccess,
  [AssetType.GET_ASSET_VERSION_LIST_FAILURE]: getAssetVersionListFailure,
  //add new version of assets
  [AssetType.UPLOAD_NEW_VERSION_FILE_START]: uploadNewVersionFileStart,
  [AssetType.UPLOAD_NEW_VERSION_FILE_SUCCESS]: uploadNewVersionFileSuccess,
  [AssetType.UPLOAD_NEW_VERSION_FILE_FAILURE]: uploadNewVersionFileFailure,
};
export const assetsReducer = createReducer(INITIAL_STATE, HANDLERS);
