export function isEmpty(obj) {
  let isempty = false;
  const type = typeof obj;

  switch (type) {
    case 'object':
      isempty =
        obj === null ||
        (Array.isArray(obj) && obj.length === 0) ||
        (obj && Object.keys(obj).length === 0); // if object is empty
      break;
    case 'string':
      isempty = obj.trim() === '';
      break;
    default:
      isempty = isempty || !obj || type === 'undefined' || obj === null;
      break;
  }

  return isempty;
}
export const OPTIONS_TYPES = {
  IS_DATE_TIME: 'isDateTime',
  HAS_OPTIONS: 'hasOptions',
  SEARCHABLE: 'searchable',
  SORTABLE: 'sortable',
};

export const isOptionVisible = (optionType, optionsProvided) => {
  const { searchable, sortable, options, type } = optionsProvided;
  const hasOptions = options.length > 0;
  const isDateTime = type === 'DateTime';

  // based on priority the conditions are written in order
  if (isDateTime && searchable) {
    return optionType === 'isDateTime';
  }
  if (hasOptions) {
    return optionType === 'hasOptions';
  }
  if (searchable) {
    return optionType === 'searchable';
  }
  return optionsProvided[optionType];
};
export const curryIsOptionsVisible = (optionsVisible) => (optionType) =>
  isOptionVisible(optionType, optionsVisible);
//
export const VALIDATION_TYPES = {
  FLOAT: 'float',
  DATE_WITH_TIME: 'dateWithTime',
  DATE_WITH_TIME_WITH_SECONDS: 'dateWithTimeSeconds',
  ISO_DATE_STRING: 'isoDateString',
  TIME: 'time',
};
const regex = {
  [VALIDATION_TYPES.FLOAT]: /^[+-]?\d+(.\d+)?$/,
  [VALIDATION_TYPES.DATE_WITH_TIME]:
    /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d[" "]+[012]{0,1}[0-9]:[0-6][0-9]$/,
  [VALIDATION_TYPES.DATE_WITH_TIME_WITH_SECONDS]:
    /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d[" "]+[012]{0,1}[0-9]:[0-6][0-9]:[0-6][0-9]$/,
  [VALIDATION_TYPES.ISO_DATE_STRING]:
    /^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9]).*/,
  [VALIDATION_TYPES.TIME]: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
};
//
export const validate = (string, type) => {
  const regEx = regex[type];
  return regEx.test(string);
};
export const format = 'DD/MM/YYYY h:mm A';

export const findDevice = (width, height, devices) => {
  for (const device in devices) {
    if (devices[device].width === width && devices[device].height === height) {
      return device;
    }
  }
  return null;
};
