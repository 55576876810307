import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const BrandCardBackgroundContainer = styled.div`
  background: ${({ bg }) =>
    `transparent linear-gradient(254deg, ${bg} 0%, ${bg}A0 100%, #FFFFFF 100%) 0% 0% no-repeat padding-box`};
  width: 100%;
  height: 100px;
  border-radius: 14px;
  display: flex;
  align-items: flex-end;
  padding: 10px;
`;

export const BrandCardBackgroundBackContainer = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${COLORS.WHITE};
  border-radius: 16px;
`;

export const BrandCardNameRowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${COLORS.OVERLAY_GREY};
  padding: 10px 0px;
`;

export const BrandNameWrapper = styled.div`
  color: ${COLORS.WHITE};
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  font-family: PoppinsRegular;
`;
