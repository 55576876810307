import { FileTypeMap, FileTypeMapText } from '../../constants/BaseStyles';
import Delete from '../../assets/icons/Delete.svg';
import Web from '../../assets/icons/Web.svg';
import DynamicSVGIcon from '../DynamicSVGIcon';

import {
  Cards,
  CardsContainer,
  CardTitle,
  InfoWrapper,
  ThumbnilIConsContainer,
  CardImg,
  CardType,
  CommentCount,
  CardTypeContainer,
  DeleteAssetContainer,
  WebCardImg,
} from './AssetCards.Styles';

import 'react-tooltip/dist/react-tooltip.css';
import { CLIENT_TYPE_USER_LIST } from '../../constants/constant';
import { useSelector } from 'react-redux';

const AssetCards = ({ data, deltefile, onclick }) => {
  const { userData } = useSelector((state) => state.loggedinUser);
  return (
    <Cards
      onClick={() => {
        onclick(data);
      }}
    >
      <ThumbnilIConsContainer>
        <CardImg
          loading="lazy"
          src={
            data?.thumbnail_url ||
            data?.original_s3_image_link[0] ||
            FileTypeMap[data?.asset_type]
          }
        />
        {/* <----------------------asset type---------------> */}
        {FileTypeMapText[data?.asset_type] && (
          <CardTypeContainer>
            <CardType>{FileTypeMapText[data?.asset_type]}</CardType>
          </CardTypeContainer>
        )}
        {/* <----------------delete asset------------------> */}
        {!CLIENT_TYPE_USER_LIST.includes(userData?.type) && (
          <DeleteAssetContainer
            onClick={(e) => {
              e.stopPropagation();
              deltefile(data);
            }}
          >
            <DynamicSVGIcon iconUrl={Delete} />
          </DeleteAssetContainer>
        )}

        {/*  */}
      </ThumbnilIConsContainer>
      <InfoWrapper>
        <CardTitle>
          {typeof data?.name === 'string'
            ? data?.name
            : data?.file_name || data?.webpage_name}
        </CardTitle>
        <CommentCount>{data?.comments_count} comments</CommentCount>
      </InfoWrapper>
    </Cards>
  );
};
export default AssetCards;
