/**
 * action js
 *
 * it will have all the dashboard related actions
 *
 * */
import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  viewMyAgency: ['payload'],
  viewMyAgencyStart: ['payload'],
  viewMyAgencySuccess: ['payload'],
  viewMyAgencyError: ['payload'],
  editMyAgency: ['payload'],
  editMyAgencyStart: ['payload'],
  editMyAgencySuccess: ['payload'],
  editMyAgencyError: ['payload'],
});

export { Types as MyAgencyTypes, Creators as MyAgencyCreators };
