import styled from 'styled-components';
import { COLORS } from '../../../constants/BaseStyles';
import { device } from '../../../constants/device';

const InputFieldContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const EmailImageContainer = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 27.5px;
  right: 15px;
`;

const EyeImageContainer = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 27.5px;
  right: 15px;
`;

const SignupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    margin: 10px 0px;
  }
`;

const SignupText = styled.p`
  font-size: 0.7rem;
`;

const SignupLink = styled.p`
  font-size: 0.7rem;
  color: ${COLORS.PRIMARY_COLOR};
  padding-left: 5px;
  cursor: pointer;
`;

const OtherOptionsContainer = styled.div`
  margin-top: 10px;
  width: 315px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Title = styled.p`
  font-size: 0.7rem;
  display: flex;
  &:before,
  &:after {
    content: '';
    border-bottom: 1px solid;
    margin: 10px;
    width: 75px;
  }

  @media ${device.tablet} {
    opacity: 0.7;
  }
`;

const GoogleLogo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  cursor: pointer;
`;

const OutlookLogo = styled.img`
  width: 65px;
  height: 65px;
  object-fit: contain;
  cursor: pointer;
`;

const ForgetPassWordContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  @media ${device.tablet} {
    position: absolute;
    margin-top: -100px;
    justify-content: center;
  }
`;

const ForgetPasswordText = styled.span`
  font-size: 0.6rem;
  font-weight: 600;
`;

const ResetLink = styled.span`
  color: ${COLORS.PRIMARY_COLOR};
  text-decoration: underline;
  font-size: 0.6rem;
  font-weight: 600;
  cursor: pointer;
  margin-left: 2px;
`;

const BackLogin = styled.p`
  color: ${COLORS.PRIMARY_COLOR};
  font-size: 0.7rem;
  font-weight: 600;
  cursor: pointer;

  @media ${device.tablet} {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const InputField = styled.div`
  text-align: center;
  positon: relative;
  margin: ${({ margin }) => (margin ? margin : '0px')};
`;

const LoginOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    /* margin-top: 25px; */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

const LeftTitleText = styled.p`
  font-size: 40px;
  color: ${COLORS.COMMENTS_BACKGROUND_COLOR};
  display: flex;
  flex-direction: row;
  font-family: PoppinsMedium;
  align-items: center;
  letter-spacing: 0px;
  margin-top: 0px;
  & p {
    margin: 0;
    margin-right: 8px;
    letter-spacing: 0px;
  }
`;

export {
  EmailImageContainer,
  EyeImageContainer,
  SignupContainer,
  SignupText,
  SignupLink,
  OtherOptionsContainer,
  Title,
  GoogleLogo,
  OutlookLogo,
  ForgetPassWordContainer,
  ForgetPasswordText,
  ResetLink,
  BackLogin,
  InputField,
  LoginOptions,
  Container,
  InputFieldContainer,
  LeftTitleText,
};
