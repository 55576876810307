import {
  AddVersionHeading,
  AddVersionSubHeading,
  VersionCaptionContainer,
  VersionCaptionInput,
  VersionCaptionLabel,
} from '../../pages/assetsView/Assets.Styles';
import { AddUrlFormWrapper } from './AddUrlForm.Styles';

const AddUrlForm = ({ pageUrl, setPageUrl }) => {
  return (
    <AddUrlFormWrapper>
      <AddVersionHeading>Add new page</AddVersionHeading>
      <AddVersionSubHeading>Enter page details</AddVersionSubHeading>
      <VersionCaptionContainer marginTop={'30px'}>
        <VersionCaptionLabel>Page url</VersionCaptionLabel>
        <VersionCaptionInput
          placeholder="Enter Page url"
          value={pageUrl}
          onChange={(e) => {
            setPageUrl(e.target.value);
          }}
        />
      </VersionCaptionContainer>
    </AddUrlFormWrapper>
  );
};
export default AddUrlForm;
