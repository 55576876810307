/**
 * reducer js
 *
 * it will have all the dashboard related reducers
 */

import { createReducer } from 'reduxsauce';
import { MyAgencyTypes } from './action';

const INITIAL_STATE = {
  error: false,
  loading: true,
  myAgencyData: {},
  editLoading: false,
};

// <-----------------------reducer for view/edit myagency-------------------->
const viewMyAgencyStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const viewMyAgencySuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload?.data;
  return {
    ...state,
    error: false,
    loading: false,
    myAgencyData: data,
  };
};
const viewMyAgencyError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    loading: false,
    ...state,
    errorMessage,
  };
};
const editMyAgencyStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    editLoading: true,
  };
};
const editMyAgencySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    editLoading: false,
    myAgency: {
      ...state.myAgencyData,
    },
  };
};
const editMyAgencyError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    editLoading: false,
    errorMessage,
  };
};

const HANDLERS = {
  [MyAgencyTypes.VIEW_MY_AGENCY_START]: viewMyAgencyStart,
  [MyAgencyTypes.VIEW_MY_AGENCY_SUCCESS]: viewMyAgencySuccess,
  [MyAgencyTypes.VIEW_MY_AGENCY_ERROR]: viewMyAgencyError,

  [MyAgencyTypes.EDIT_MY_AGENCY_START]: editMyAgencyStart,
  [MyAgencyTypes.EDIT_MY_AGENCY_SUCCESS]: editMyAgencySuccess,
  [MyAgencyTypes.EDIT_MY_AGENCY_ERROR]: editMyAgencyError,
};

export const myAgencyReducer = createReducer(INITIAL_STATE, HANDLERS);
