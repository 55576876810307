import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import useClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import Radiobutton from '../Radiobutton/RadioButton';

const Button = styled.div`
  width: 28px;
  height: 28px;

  background: ${({ theme }) => theme.core.pureSecondary};
  border-radius: 4px;
  box-shadow: 1px 1px 5px #00000029;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > * {
    fill: ${({ theme }) => theme.contrast.primary} !important;
  }
`;

const Container = styled.div`
  position: relative;
`;

const DropContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 112;
  padding-bottom: 1rem;

  background: ${({ theme }) => theme.aux.secondary};
  border-radius: 6px 6px;
  box-shadow: 1px 1px 5px #00000029;

  & > .header {
    font-size: 16px;
    color: ${({ theme }) => theme.contrast.lightQuaternary};

    padding: 1.2rem 2rem;
    margin-bottom: 1rem;

    background: ${({ theme }) => theme.core.pureSecondary};
    border-radius: 6px 6px 0px 0px;
    box-shadow: 1px 1px 5px #00000029;
  }
`;

const ListItem = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.contrast.lightQuaternary};
  padding: 1rem 2rem;

  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Groupby = ({ list = ['None'], selected, onClick }) => {
  const [state, setState] = useState(false);
  const ref = useRef();

  useClickOutside(ref, () => setState(false));

  const handleSelection = (el) => {
    onClick(el);
    setState(false);
  };

  return (
    <Container>
      <Button onClick={() => setState(!state)}></Button>
      {state && (
        <DropContainer ref={ref}>
          <div className="header">Group By</div>
          {list.map((el, key) => (
            <ListItem key={key} onClick={() => handleSelection(el)}>
              <Radiobutton
                onClick={handleSelection}
                active={selected === el}
                margin="0 1rem 0 0"
              />
              {el}
            </ListItem>
          ))}
        </DropContainer>
      )}
    </Container>
  );
};

export default Groupby;
