import { COLORS } from '../../constants/BaseStyles';
import { APPROVED } from '../../constants/constant';

const generateStatusStyles = (stateProps) => {
  return {
    // ensure menu options dont get hidden behind asset div
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),

    control: (provided, state) => ({
      ...provided,
      background: 'transparent',
      border: ` 1px solid ${COLORS.DROPDOWN_BG}`,
      justifyContent: 'center',
      width: '450px',
      height: '35px',
      minHeight: '35px',
      textAlign: 'left',
    }),

    input: (provided, state) => ({
      ...provided,
      height: '15px',
    }),

    singleValue: (provided, state) => {
      return {
        ...provided,
        textAlign: 'left',
      };
    },

    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
      };
    },

    menu: (provided) => {
      return {
        ...provided,
        color: 'white',
        backgroundColor: `${COLORS.OVERLAY_GREY}`,
        width: '450px',
        textAlign: 'left',
        fontSize: '13px',
      };
    },

    option: (provided) => {
      return {
        ...provided,
        color: 'white',
        backgroundColor: `${COLORS.OVERLAY_GREY}`,
        width: '450px',
        textAlign: 'left',
        fontSize: '13px',

        '&:hover': {
          background: `${COLORS.PRIMARY_BLACK}`,
        },
      };
    },

    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        display: 'none',
      };
    },
  };
};

export default generateStatusStyles;
