import { COLORS } from '../../constants/BaseStyles';
import {
  Button,
  StyledModal,
  StyledModalWithCustomScrollbar,
} from '../../constants/GlobalStyles.Styles';
import {
  BtnContanier,
  CancelBtn,
  ModalContainer,
  SaveBtn,
} from './Modals.Styles';

const UniversalModal = ({
  isOpenModal,
  width,
  height,
  children,
  leftText = '',
  rightText = '',
  btn = false,
  rightBtnDisabled,
  rightBtnWidth = '90px',
  borderRadius,
  rightBtnopacity = 1,
  onleftbtnClick = () => {},
  onrightbtnClick = () => {},
}) => {
  return (
    <StyledModal
      isOpen={isOpenModal}
      width={width || 'auto'}
      height={height || 'auto'}
      borderRadius={borderRadius}
    >
      <ModalContainer>
        {children}
        {btn && (
          <BtnContanier>
            {leftText && (
              <CancelBtn>
                <Button
                  width={'90px'}
                  height={'35px'}
                  noBoxShadow={true}
                  noTopMargin
                  noBorder
                  type="submit"
                  bgColor={COLORS.BTN_SECONDARY}
                  onClick={() => onleftbtnClick()}
                >
                  {leftText}
                </Button>
              </CancelBtn>
            )}
            {rightText && (
              <SaveBtn>
                <Button
                  opacity={rightBtnopacity}
                  width={rightBtnWidth}
                  height={'35px'}
                  noTopMargin
                  type="submit"
                  noBoxShadow={true}
                  disabled={rightBtnDisabled}
                  onClick={() => onrightbtnClick()}
                >
                  {rightText}
                </Button>
              </SaveBtn>
            )}
          </BtnContanier>
        )}
      </ModalContainer>
    </StyledModal>
  );
};
export default UniversalModal;
