/**
 * reducer js
 *
 * it will have all the add user related reducers
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */
import { createReducer } from 'reduxsauce';
import { AddUserTypes } from './action';
const INITIAL_STATE = {
  addUserLoading: false,
  editUserLoading: false,
  userfetchedData: {},
  editmode: false,
  savemode: false,
};

// <---------------reducer for select clients--------------------->
const addUserStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addUserLoading: true,
    savemode: false,
  };
};
const addUserSuccessReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addUserLoading: false,
    savemode: true,
  };
};
const addUserFailureReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addUserLoading: false,
    savemode: false,
  };
};

// <---------------reducer for select clients--------------------->
const editUserStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editUserLoading: true,
    editmode: false,
  };
};
const editUserSuccessReducer = (state = INITIAL_STATE, action) => {
  const { userData, update } = action.payload;
  return {
    ...state,
    editUserLoading: false,
    userfetchedData: userData?.data,
    editmode: update ? true : false,
  };
};
const editUserFailureReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editUserLoading: false,
    editmode: false,
  };
};
// <---------------reset edit user----------->
const editmodeReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    editmode: false,
  };
};
// <---------------save mode user----------->
const saveModeREducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    savemode: false,
  };
};
const HANDLERS = {
  [AddUserTypes.ADD_USER_START]: addUserStartReducer,
  [AddUserTypes.ADD_USER_SUCCESS]: addUserSuccessReducer,
  [AddUserTypes.ADD_USER_FAILURE]: addUserFailureReducer,
  [AddUserTypes.SAVE_MODE]: saveModeREducer,

  //
  [AddUserTypes.EDIT_USER_START]: editUserStartReducer,
  [AddUserTypes.EDIT_USER_SUCCESS]: editUserSuccessReducer,
  [AddUserTypes.EDIT_USER_FAILURE]: editUserFailureReducer,
  [AddUserTypes.EDIT_MODE]: editmodeReducer,
};

export const addUserReducer = createReducer(INITIAL_STATE, HANDLERS);
