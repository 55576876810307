import { COLORS } from '../../constants/BaseStyles';

export const multiSelectDropwdownStyles = (state) => {
  const resize = state?.optionSelected;
  const isSocialMedia = state?.socialMedia;

  return {
    // ensure menu options dont get hidden behind  div
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),

    control: (provided) => ({
      ...provided,
      background: `${COLORS.PRIMARY_GREY}`,
      border: `1px solid ${COLORS.OVERLAY_GREY}`,
      textAlign: 'left',
      width: resize ? 'auto' : '35px',
      height: resize ? 'auto' : '35px',
      fontSize: resize ? '13px' : '22px',
      color: resize ? `${COLORS.PRIMARY_BLACK}` : `${COLORS.PRIMARY_GREY}`,
      margin: '5px',
    }),

    dropdownIndicator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },

    menu: (provided) => {
      return {
        ...provided,
        backgroundColor: `${COLORS.OVERLAY_GREY}`,
      };
    },

    option: (provided) => {
      return {
        ...provided,
        color: resize ? 'black' : 'white',
        backgroundColor: resize
          ? `${COLORS.PRIMARY_GREY}`
          : `${COLORS.OVERLAY_GREY}`,
        width: '420px',
        textAlign: 'left',
        fontSize: '13px',

        '&:hover': {
          background: `${COLORS.PRIMARY_BLACK}`,
        },
      };
    },

    indicatorSeparator: (provided) => {
      return {
        ...provided,
        background: `${COLORS.OVERLAY_GREY}`,
        display: 'none',
      };
    },

    multiValue: (provided) => {
      return {
        ...provided,
        background: `${COLORS.PRIMARY_BLACK}`,
        color: `${COLORS.TEXT_MEDIUM_GREY}`,
      };
    },
  };
};
