import styled from 'styled-components';

export const UploadEventFileContainer = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  height: 100%;
`;
export const CaptionInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;
export const UploadFileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
export const FileContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
