import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  addClient: ['payload'],
  addClientStart: ['payload'],
  addClientSuccess: ['payload'],
  addClientError: ['payload'],
  //
  editClient: ['payload'],
  editClientStart: ['payload'],
  editClientSuccess: ['payload'],
  editClientError: ['payload'],
  //
  viewClients: ['payload'],
  viewClientsStart: ['payload'],
  viewClientsSuccess: ['payload'],
  viewClientsError: ['payload'],
  //
  viewClient: ['payload'],
  viewClientStart: ['payload'],
  viewClientSuccess: ['payload'],
  viewClientError: ['payload'],
  //
  saveModeReducer: ['payload'],
  //
  deleteClients: ['payload'],
  deleteClientsStart: ['payload'],
  deleteClientsSuccess: ['payload'],
  deleteClientsError: ['payload'],
  clientFilters: ['payload'],
  resetClientData: ['payload'],
});

export { Types as ClientTypes, Creators as ClientCreators };
