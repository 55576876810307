import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const CommentBox = styled.div`
  height: 100%;
  padding: 10px;
  position: relative;
`;
export const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #353535;
  padding-bottom: 10px;
`;
export const CommentOperation = styled.div`
  position: relative;
`;
export const CommentCount = styled.div`
  color: ${COLORS.WHITE};
  font-size: 14px;
  font-family: PoppinsRegular;
  letter-spacing: -0.06px;
`;
export const CommentInput = styled.div`
  background: ${COLORS.PRIMARY_BLACK};
  border-radius: 6px;
  height: 120px;
  width: calc(100% - 20px);
  position: absolute;
  bottom: 10px;
`;
export const Input = styled.textarea`
  height: 70px;
  width: 100%;
  background: ${COLORS.PRIMARY_BLACK};
  border: none;
  outline: none;
  color: #e0e0e0;
  padding: 10px;
  font-family: PoppinsRegular;
  font-size: 12px;
  color: ${COLORS.WHITE};
  border-radius: 4px;
`;
export const CommentBtn = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: end;
  padding-right: 10px;
`;
export const Comments = styled.div`
  // padding-top: 10px;
  overflow-y: auto;
  height: calc(100% - 150px);
`;
export const CommentIndividualContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: 1px solid #121212;
  padding-bottom: 15px;
  padding-top: 15px;
`;
export const CommentProfileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UserProfileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;
export const ProfileNameAndTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;
export const ProfileName = styled.div`
  color: #e0e0e0;
  font-size: 14px;
  font-family: PoppinsMedium;
`;
export const CommentTime = styled.div`
  color: #a0a0a0;
  opacity: 0.8;
  font-size: 10px;
  font-family: PoppinsRegular;
`;

export const ViewComment = styled.div`
  color: ${COLORS.TERTIARY_TEXT_COLOR};
  font-size: 12px;
  font-family: PoppinsRegular;
  text-align: start;
`;
export const Reply = styled.div`
  color: #a0a0a0;
  opacity: 0.8;
  font-size: 12px;
  max-width: 35px;
  cursor: pointer;
  font-family: PoppinsRegular;
`;
export const ReplyContainer = styled.div``;
export const ReplyCommentContainer = styled.div`
  padding-left: 40px;
`;
