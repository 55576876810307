/**
 * action js
 *
 * it will have all the assets related actions
 *
 * */
import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  viewAssets: ['payload'],
  viewAssetsStart: ['payload'],
  viewAssetsSuccess: ['payload'],
  viewAssetsFailure: ['payload'],
  //
  viewAssetComment: ['payload'],
  viewAssetCommentStart: ['payload'],
  viewAssetCommentSuccess: ['payload'],
  viewAssetCommentFailure: ['payload'],
  //
  addAssetComment: ['payload'],
  addAssetCommentStart: ['payload'],
  addAssetCommentSuccess: ['payload'],
  addAssetCommentFailure: ['payload'],
  //
  deleteAssetComment: ['payload'],
  deleteAssetCommentStart: ['payload'],
  deleteAssetCommentSuccess: ['payload'],
  deleteAssetCommentFailure: ['payload'],
  // update comment
  updateAssetComment: ['payload'],
  updateAssetCommentStart: ['payload'],
  updateAssetCommentSuccess: ['payload'],
  updateAssetCommentFailure: ['payload'],
  //
  updateAssetStatus: ['payload'],
  updateAssetStatusStart: ['payload'],
  updateAssetStatusSuccess: ['payload'],
  updateAssetStatusFailure: ['payload'],
  //
  getAssetVersionList: ['payload'],
  getAssetVersionListStart: ['payload'],
  getAssetVersionListSuccess: ['payload'],
  getAssetVersionListFailure: ['payload'],
  //
  uploadNewVersionFile: ['payload'],
  uploadNewVersionFileStart: ['payload'],
  uploadNewVersionFileSuccess: ['payload'],
  uploadNewVersionFileFailure: ['payload'],
});
export { Types as AssetType, Creators as AssetCreators };
