import {
  EACH_COMMENT,
  GET_INITIALS_FROM_NAME,
  UNRESOLVED_EACH_COMMENT,
} from '../../constants/constant';
import { ProfileDiv } from '../../pages/BaseLayout/BaseLayout.Styles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import {
  CommentIndividualContainer,
  CommentOperation,
  CommentProfileHeader,
  CommentTime,
  ProfileName,
  ProfileNameAndTime,
  Reply,
  ReplyCommentContainer,
  UserProfileContainer,
  ViewComment,
} from './Comment.Styles';
// assets
import Kabab from '../../assets/icons/Kabab.svg';
import { CHECK_TIME_DIFFERENCE } from '../../constants/functions';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import OptionCard from '../options/OptionCard';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AssetCreators } from '../../pages/assetsView/store/action';
import { useSearchParams } from 'react-router-dom';
const CommentIndividual = ({
  userData,
  website,
  selectedIDforReply,
  setSelectedIDforReply,
  reply = false,
  caption = true,
  view_type,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const assetID = searchParams.get('asset_id');
  const versionId = searchParams.get('version_id');
  const webpage_id = searchParams.get('webpage_id');
  const version_id = searchParams.get('version_id');
  //
  const [selectedID, setSelectedID] = useState('');
  const onClickOption = (data) => {
    const { key } = data;
    switch (key) {
      case 'delete':
        dispatch(
          AssetCreators.deleteAssetComment({
            comment_id: selectedID,
            assetID,
            versionId,
            website,
            webpage_id,
            version_id,
            view_type,
          })
        );
        break;
      case 'resolve':
        const data = {
          is_resolved: !userData?.data?.is_resolved,
          comment_id: selectedID,
        };
        dispatch(
          AssetCreators.updateAssetComment({
            data,
            assetID,
            versionId,
            website,
            webpage_id,
            version_id,
            view_type,
          })
        );

        break;
      default:
        break;
    }
    setSelectedID('');
  };
  return (
    <>
      <CommentIndividualContainer>
        {/* <------------profile header---------------> */}
        <CommentProfileHeader>
          <UserProfileContainer>
            <ProfileDiv
              img={
                userData?.data?.created_by_user?.profile_pic ||
                userData?.data?.created_by?.profile_pic
              }
            >
              {!userData?.data?.created_by_user?.profile_pic ??
                GET_INITIALS_FROM_NAME(userData?.data?.created_by_user?.name)}
            </ProfileDiv>
            <ProfileNameAndTime>
              <ProfileName>
                {userData?.data?.created_by_user?.name ||
                  userData?.data?.created_by?.name}
              </ProfileName>
              <CommentTime>
                {CHECK_TIME_DIFFERENCE(userData?.data?.created_at)}
              </CommentTime>
            </ProfileNameAndTime>
            {userData?.data?.comment_no && (
              <ProfileDiv>{userData?.data?.comment_no}</ProfileDiv>
            )}
          </UserProfileContainer>
          {caption && (
            <CommentOperation
              onClick={() => {
                setSelectedID(userData?.data?.comment_id);
              }}
            >
              <DynamicSVGIcon iconUrl={Kabab} width="0.15rem" />
              <>
                {selectedID === userData?.data?.comment_id && (
                  <UseClickOutside
                    onClickOutside={() => {
                      setSelectedID('');
                    }}
                  >
                    <OptionCard
                      option={
                        userData?.data?.is_resolved
                          ? UNRESOLVED_EACH_COMMENT
                          : EACH_COMMENT
                      }
                      right={'0px'}
                      onClickOption={onClickOption}
                    />
                  </UseClickOutside>
                )}
              </>
            </CommentOperation>
          )}
        </CommentProfileHeader>
        {/* <---------------comment--------------------> */}
        <ViewComment>
          {userData?.data?.comments || userData?.data?.caption}
        </ViewComment>
        {/* <-------------------Reply------------------> */}
        {!reply && (
          <Reply
            onClick={() => {
              setSelectedIDforReply(userData?.data?.comment_id);
            }}
          >
            Reply
          </Reply>
        )}
      </CommentIndividualContainer>
      {userData?.data?.replies?.map((data) => {
        return (
          <ReplyCommentContainer>
            <CommentIndividual
              userData={{ data }}
              reply={true}
              website={website}
              view_type={view_type}
            />
          </ReplyCommentContainer>
        );
      })}
    </>
  );
};
export default CommentIndividual;
