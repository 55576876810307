/**
 * For Defining layout like page as it will be used thoughout the project
 */

import styled, { createGlobalStyle } from 'styled-components';
import { COLORS, INPUT_BORDER_RADIUS, BOX_SHADOW_STYLE } from './BaseStyles';
import Modal from 'styled-react-modal';
import {
  DEFAULT_AUTH_BUTTON_WIDTH,
  DEFAULT_AUTH_BUTTON_HEIGHT,
} from './constant';
import { css } from 'styled-components';

const LandingPage = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: ${COLORS.PRIMARY_BLACK};
  // background: transparent
  //   radial-gradient(
  //     closest-side at 50% 50%,
  //     var(--primary-color-853df9) 0%,
  //     #853df900 100%
  //   )
  //   0% 0% no-repeat padding-box;
  // background: transparent
  //   radial-gradient(closest-side at 50% 50%, #853df9 0%, #853df900 100%) 0% 0%
  //   no-repeat padding-box;
`;

const HeaderText = styled.p`
  font-size: 30px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0')};
  color: ${COLORS.COMMENTS_BACKGROUND_COLOR};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GradientText = styled.p`
  /* background: ${COLORS.WEBKIT_GRADIENT};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: ${COLORS.WHITE};
  font-weight: 700;
  margin-right: 10px;
`;

const InputBox = styled.input`
  margin-top: ${({ noTopMargin }) => (noTopMargin ? 0 : '15px')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  // font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  width: ${({ width }) => (width ? width : DEFAULT_AUTH_BUTTON_WIDTH)};
  height: ${({ height }) => (height ? height : DEFAULT_AUTH_BUTTON_HEIGHT)};
  outline: none;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${({ border }) =>
    border ? COLORS.BORDER : COLORS.OVERLAY_GREY};
  background-color: ${({ noBgColor }) =>
    noBgColor ? COLORS.TRANSPARENT : COLORS.TERTIARY_BACKGROUND_COLOR};
  background-color: ${({ bgColor }) =>
    bgColor ? bgColor : COLORS.PRIMARY_GREY};

  padding: ${({ datePicker }) =>
    datePicker ? '4px 10px 4px 10px' : '4px 30px 4px 10px'};
  padding: ${({ padding }) => (padding ? padding : '4px 30px 4px 10px')};
  color: ${({ textColor }) =>
    textColor ? textColor : COLORS.TEXT_MEDIUM_GREY};
  opacity: 1;
  // additional styles for cursor handling
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  // font-weight: ${({ readOnly }) => (readOnly ? 'bold' : 'normal')};
  border-radius: ${({ noBorderRadius }) =>
    noBorderRadius ? '0px' : INPUT_BORDER_RADIUS};
  ${(props) =>
    props?.phone && {
      padding: '4px 10px 4px 100px',
    }}
  ${(props) =>
    props?.project && {
      padding: '4px 50px 4px 10px',
    }}
    ${(props) =>
    props?.filterName && {
      padding: '4px 62px 4px 10px',
    }}

  /* Add border color for input box based on validation */
    &.valid {
    border: 1px solid ${COLORS.GREEN};
  }

  &.invalid {
    border: 1px solid ${COLORS.RED};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ placeholderColor }) =>
      placeholderColor ? placeholderColor : COLORS.PRIMARY_COLOR};
    opacity: 0.6;
    font-weight: 300;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ placeholderColor }) =>
      placeholderColor ? placeholderColor : COLORS.PRIMARY_COLOR};
    opacity: 0.6;
    font-weight: 300;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ placeholderColor }) =>
      placeholderColor ? placeholderColor : COLORS.PRIMARY_COLOR};
    opacity: 0.6;
    font-weight: 300;
  }

  &:focus {
    border-color: ${COLORS.PRIMARY_COLOR};
  }

  ::-ms-reveal,
  ::-ms-clear {
    display: none;
  }
`;

const TextArea = styled.textarea`
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : 'PoppinsRegular'};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 'auto')};
  // resize: none;
  margin-top: ${({ noTopMargin }) => (noTopMargin ? 0 : '15px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  width: ${({ width }) => (width ? width : DEFAULT_AUTH_BUTTON_WIDTH)};
  height: ${({ height }) => (height ? height : DEFAULT_AUTH_BUTTON_HEIGHT)};
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ border }) =>
    border ? COLORS.PRIMARY_COLOR : COLORS.TRANSPARENT};
  background-color: ${({ noBgColor }) =>
    noBgColor ? COLORS.TRANSPARENT : COLORS.TERTIARY_BACKGROUND_COLOR};
  background-color: ${({ bgColor }) =>
    bgColor ? bgColor : COLORS.PRIMARY_GREY};
  padding: ${({ datePicker }) => (datePicker ? '4px 10px 4px 10px' : '10px')};
  color: ${({ textColor }) => (textColor ? textColor : 'white')};
  opacity: 1;
  border-radius: ${({ noBorderRadius }) =>
    noBorderRadius ? '0px' : INPUT_BORDER_RADIUS};
  border: 1px solid ${COLORS.OVERLAY_GREY};
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ placeholderColor }) =>
      placeholderColor ? placeholderColor : COLORS.TEXT_MEDIUM_GREY};
    };
    opacity: 0.6;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 300)};
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ placeholderColor }) =>
      placeholderColor ? placeholderColor : COLORS.PRIMARY_COLOR};
    opacity: 0.6;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 300)};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ placeholderColor }) =>
      placeholderColor ? placeholderColor : COLORS.PRIMARY_COLOR};
    opacity: 0.6;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 300)};
  }
`;

const Button = styled.button`
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  width: ${({ width }) => (width ? width : DEFAULT_AUTH_BUTTON_WIDTH)};
  height: ${({ height }) => (height ? height : DEFAULT_AUTH_BUTTON_HEIGHT)};
  padding: ${({ padding }) => (padding ? padding : '4px;')};
  text-align: center;
  margin-top: ${({ noTopMargin }) => (noTopMargin ? 0 : '25px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '25px')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  disabled: ${({ disabled }) => (disabled ? 'disabled' : 'inherit')};
  display: ${({ visibility }) => (visibility ? visibility : 'hidden')};
  background: ${({ bgColor }) => (bgColor ? bgColor : COLORS.BTN_GRADIENT)};
  cursor: pointer;
  color: ${({ bgColor }) =>
    bgColor ? COLORS.TEXT_NEAR_WHITE : COLORS.PRIMARY_STROKE_COLOR};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '.9rem')};
  font-family: ${({ fontFam }) => (fontFam ? fontFam : 'PoppinsRegular')};
  font-weight: 400;
  border-width: 0.8px;
  border-style: solid;
  // border-color: ${({ bgColor }) => (bgColor ? '' : COLORS.TEXT_GREY)};
  border: none;
  outline: none;
  border-radius: ${({ noBorderRadius }) =>
    noBorderRadius ? 0 : INPUT_BORDER_RADIUS};
  box-shadow: ${({ noBoxShadow }) => (noBoxShadow ? 'none' : BOX_SHADOW_STYLE)};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '4px')};
  cursor: pointer;
  position: relative;
  @media print {
    display: none;
  }
`;

const CustomCheckBoxOuter = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
`;

const CheckBoxInput = styled.input`
  display: none;

  &:checked + span:after {
    display: block;
  }

  &:checked + span {
    background-color: ${COLORS.PRIMARY_COLOR};
  }
`;

const CustomCheckBox = styled.span`
  display: inline-block;
  height: ${({ height }) => height || '13px'};
  width: ${({ width }) => width || '13px'};
  border: 1px solid ${COLORS.PRIMARY_COLOR};
  border-radius: 2px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || `${COLORS.PRIMARY_STROKE_COLOR}`};
  position: relative;

  &::after {
    display: none;
    content: '';
    position: relative;
    left: ${({ left }) => left || '3px'};
    top: ${({ top }) => top || '0px'};
    width: 3px;
    height: 8px;
    border: solid ${COLORS.PRIMARY_STROKE_COLOR};
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
  }
`;

const StyledModal = Modal.styled`
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  min-height: 200px;
  min-width: 400px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  display: flex;
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  background: ${COLORS.PRIMARY_GREY};
  color: ${COLORS.COMMENTS_BACKGROUND_COLOR};
  padding:  ${({ padding }) => (padding ? padding : '20px')};
  overflow: ${({ scroll }) => (scroll ? scroll : 'hidden')};

  
`;

const GlobalScrollBar = createGlobalStyle`
// custom scrollbar
  ::-webkit-scrollbar {
    width: 2px; /* Set width of the scrollbar */
  }

  /* Set background color of the track */
  ::-webkit-scrollbar-track {
    // background-color: ${COLORS.PRIMARY_BLACK}; 
  }

  ::-webkit-scrollbar-thumb {
    /* Set color of the thumb */
    // background-color: ${COLORS.PURPLE}; 
    border-radius: 15px; 
    /* Set border around the thumb */
    // border: 5px solid ${COLORS.PRIMARY_BLACK}; 
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  border-bottom: 0.23px solid ${COLORS.SECONDARY_STROKE_COLOR};
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.p`
  color: ${COLORS.PRIMARY_ICON_TEXT_COLOR};
  font-weight: bold;
  font-family: PoppinsRegular;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
`;

const Steps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StepText = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  font-weight: bold;
  margin-right: 5px;
  font-family: PoppinsRegular;
  color: ${COLORS.PRIMARY_ICON_TEXT_COLOR};
`;

const StepNo = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  font-weight: bold;
  color: ${COLORS.PRIMARY_COLOR};
  font-family: PoppinsRegular;
`;

const BackContainer = styled.div`
  width: ${({ width }) => (width ? width : '20px')};
  height: ${({ height }) => (height ? height : '20px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  cursor: pointer;
`;

const Div = styled.div`
  ${(props) =>
    props.m &&
    css`
      margin: ${props.m}rem;
    `};
  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml}rem;
    `};
  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr}rem;
    `};
  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt}rem;
    `};
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb}rem;
    `};
  ${(props) =>
    props.mx &&
    css`
      margin: 0 ${props.mx}rem;
    `};
  ${(props) =>
    props.my &&
    css`
      margin: ${props.my}rem 0;
    `};
  ${(props) =>
    props.p &&
    css`
      pading: ${props.p}rem;
    `};
  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl}rem;
    `};
  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr}rem;
    `};
  ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt}rem;
    `};
  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb}rem;
    `};
  ${(props) =>
    props.px &&
    css`
      padding: 0 ${props.px}rem;
    `};
  ${(props) =>
    props.py &&
    css`
      padding: ${props.py}rem 0;
    `};
`;
const PopupModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${COLORS.PRIMARY_ICON_TEXT_COLOR};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.1rem')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  text-transform: capitalize;
  font-family: PoppinsRegular;
`;

export {
  LandingPage,
  HeaderText,
  InputBox,
  TextArea,
  Button,
  CustomCheckBoxOuter,
  CheckBoxInput,
  CustomCheckBox,
  StyledModal,
  ModalHeader,
  ModalTitle,
  Steps,
  StepText,
  StepNo,
  BackContainer,
  Div,
  PopupModalHeader,
  GlobalScrollBar,
  GradientText,
};
