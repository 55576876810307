import { createReducer } from 'reduxsauce';
import { ClientTypes } from './action';

const INITIAL_STATE = {
  error: false,
  clientLoading: false,
  ClientsListloading: false,
  loading: true,
  clientData: {},
  clientsListData: [],
  companyListHeader: {},
  filter: {},
  searchValue: '',
  userData: {},
  viewClientData: [],
};

// <-----------------------reducer for add/edit client-------------------->
const addClientStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    clientLoading: false,
    loading: true,
  };
};
const addClientSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload?.data;
  return {
    ...state,
    clientLoading: true,
    error: false,
    clientData: data,
  };
};
const addClientError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    clientLoading: true,
    error: true,
    errorMessage,
  };
};

const editClientStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    clientLoading: false,
  };
};
const editClientSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    clientLoading: true,
    clientData: {
      ...state.clientData,
    },
  };
};
const editClientError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    clientLoading: true,
    error: true,
    errorMessage,
  };
};
// <-----------------------reducer for viewing list of clients-------------------->
const viewClientsStart = (state = INITIAL_STATE, action) => {
  return {
    ClientsListloading: true,
    ...state,
  };
};
const viewClientsSuccess = (state = INITIAL_STATE, action) => {
  let clientList = [];
  const { data } = action.payload;
  if (data?.page_number === 1) {
    clientList = data?.data;
  } else {
    clientList = [...state.clientsListData, ...data?.data];
  }
  return {
    ...state,
    error: false,
    ClientsListloading: false,
    companyListHeader: data,
    clientsListData: clientList,
  };
};
const viewClientsError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    ClientsListloading: false,
    errorMessage,
  };
};
const clientFiltersReducer = (state = INITIAL_STATE, action) => {
  const { filter, searchValue } = action.payload;
  return {
    ...state,
    filter: filter,
    searchValue: searchValue,
  };
};

const saveModeReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    clientLoading: false,
  };
};
// <---------------reducer for delete client's from list----------------->
const deleteClientsStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const deleteClientsSuccessReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const deleteClientsErrorReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

// <-----------------------reducer for viewing single client data-------------------->
const viewClientStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const viewClientSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    ClientsListloading: false,
    viewClientData: data?.data,
  };
};
const viewClientError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: true,
    error: true,
    errorMessage,
  };
};
// <-----------------------reducer for reset the client data-------------------->
const resetClientData = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    viewClientData: [],
    clientLoading: false,
  };
};

const HANDLERS = {
  [ClientTypes.ADD_CLIENT_START]: addClientStart,
  [ClientTypes.ADD_CLIENT_SUCCESS]: addClientSuccess,
  [ClientTypes.ADD_CLIENT_ERROR]: addClientError,
  [ClientTypes.SAVE_MODE_REDUCER]: saveModeReducer,

  [ClientTypes.EDIT_CLIENT_START]: editClientStart,
  [ClientTypes.EDIT_CLIENT_SUCCESS]: editClientSuccess,
  [ClientTypes.EDIT_CLIENT_ERROR]: editClientError,

  [ClientTypes.VIEW_CLIENTS_START]: viewClientsStart,
  [ClientTypes.VIEW_CLIENTS_SUCCESS]: viewClientsSuccess,
  [ClientTypes.VIEW_CLIENTS_ERROR]: viewClientsError,
  [ClientTypes.CLIENT_FILTERS]: clientFiltersReducer,
  //
  [ClientTypes.DELETE_CLIENTS_START]: deleteClientsStartReducer,
  [ClientTypes.DELETE_CLIENTS_ERROR]: deleteClientsErrorReducer,
  [ClientTypes.DELETE_CLIENTS_SUCCESS]: deleteClientsSuccessReducer,

  [ClientTypes.VIEW_CLIENT_START]: viewClientStart,
  [ClientTypes.VIEW_CLIENT_SUCCESS]: viewClientSuccess,
  [ClientTypes.VIEW_CLIENT_ERROR]: viewClientError,
  [ClientTypes.RESET_CLIENT_DATA]: resetClientData,
};
export const clientsReducer = createReducer(INITIAL_STATE, HANDLERS);
