import { useEffect, useState } from 'react';
import {
  ProjectFolderCardsWrapper,
  ProjectFolderWrapper,
} from './Project.Styles';
import { STRINGS } from '../../constants/labels';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../constants/constant';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store/action';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';
import ProjectFolderCards from '../../components/Cards/ProjectsFolderCards.View';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import UniversalModal from '../../components/modals/UniversalModal';
import { ModalHeading, ModalSubHeading } from '../brands/Brand.styles';
import AssetCards from '../../components/Cards/AssetCards.View';
import Loading from '../../components/loader/Loader.View';

const ProjectFolder = () => {
  //
  // folder_id
  const strings = STRINGS['ENGLISH'];
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState({
    deleteModal: false,
    selectedAssetId: null,
    selectedFolderId: null,
    selectedVersionID: null,
  });
  const param = useParams();
  const navigate = useNavigate();
  const { project_id, project_path } = param;
  const projectType = searchParams.get('project_type');
  const folderId = searchParams.get('folder_id');
  const projectName = searchParams.get('project_name');

  const { projectsFileListData, uploadFileloading, projectDetails } =
    useSelector((state) => state.project);
  useEffect(() => {
    const payload = {
      project_id,
      search_item: '',
      page_size: DEFAULT_CLIENT_PAGE_SIZE,
      page: 1,
    };
    if (folderId) {
      payload['folder_id'] = folderId;
    }
    dispatch(Creators.fileList({ payload }));
  }, [folderId]);

  const onClickOption = (data, filesData) => {
    const { asset_id, folder_id } = filesData;
    switch (data?.key) {
      case 'delete':
        setIsModalOpen({
          ...isModalOpen,
          deleteModal: true,
          selectedAssetId: asset_id,
          selectedFolderId: folder_id,
        });
        // setIsCreateFolder((prev) => !prev);
        break;
      default:
        // setIsFileUpload((prev) => !prev);
        break;
    }
  };

  //

  const deletefolder = (data) => {
    dispatch(
      Creators.deleteFolder({
        project_id,
        projectType,
        SelectedFolderId: isModalOpen.selectedFolderId,
        folderId,
        assetId: isModalOpen.selectedAssetId,
        versionId: isModalOpen.selectedVersionID,
      })
    );
    ondeleteCancel();
  };
  const ondeleteCancel = () => {
    setIsModalOpen({
      ...isModalOpen,
      deleteModal: false,
      selectedAssetId: null,
      selectedFolderId: null,
      selectedVersionID: null,
    });
  };
  const onclickCard = (data) => {
    const { folder_id, asset_id, version_id, asset_type, name } = data;
    if (name?.type === 'folder') {
      navigate(
        `/${project_path}/${project_id}/files?folder_id=${folder_id}&project_name=${projectName}`
      );
    } else {
      navigate(
        `/${project_path}/${project_id}/asset_review?asset_id=${asset_id}&version_id=${version_id}`
      );
    }
  };
  const deltefile = (data) => {
    const { asset_id, folder_id, version_id } = data;
    setIsModalOpen({
      ...isModalOpen,
      deleteModal: true,
      selectedAssetId: asset_id,
      selectedFolderId: folder_id,
      selectedVersionID: version_id,
    });
  };
  const onClickAsset = (data) => {
    const { asset_id, version_id } = data;
    navigate(
      `/${project_path}/${project_id}/asset_review?asset_id=${asset_id}&version_id=${version_id}`
    );
  };
  return (
    <>
      <SubHeader
        buttonDisplay={'none'}
        title={projectDetails[project_id]?.project_name || projectName}
      />

      <ProjectFolderWrapper>
        {/* <-------------------table-------------------------> */}

        {!projectsFileListData[project_id]?.length && (
          <Empty
            height={'calc(100vh - 135px)'}
            heading={strings.NOT_FOUND_FOLDER}
            hubheading1={strings.WANT_TO_ADD_FOLDER}
            // subheading2={strings.CONTACT_AGENCY}
          />
        )}
        {uploadFileloading && <Loading open={uploadFileloading} />}

        <ProjectFolderCardsWrapper>
          {projectsFileListData[project_id]?.map((data, index) => {
            return (
              <>
                {data?.name?.type === 'folder' ? (
                  <ProjectFolderCards
                    data={data}
                    onClickOption={onClickOption}
                    onclickCard={onclickCard}
                  />
                ) : (
                  <AssetCards
                    data={data}
                    index={index}
                    deltefile={deltefile}
                    onclick={onClickAsset}
                  />
                )}
              </>
            );
          })}
        </ProjectFolderCardsWrapper>
        <UniversalModal
          isOpenModal={isModalOpen.deleteModal}
          leftText={'Cancel'}
          rightText={'Delete'}
          borderRadius={'14px'}
          btn={true}
          onleftbtnClick={ondeleteCancel}
          onrightbtnClick={deletefolder}
        >
          <div
            style={{
              width: '360px',
              height: '160px',
            }}
          >
            <ModalHeading>{strings.DELETE_FILE}</ModalHeading>
            <ModalSubHeading>
              {strings.DELETE_FILE_CONFIRMATION}
            </ModalSubHeading>
          </div>
        </UniversalModal>
      </ProjectFolderWrapper>
    </>
  );
};
export default ProjectFolder;
