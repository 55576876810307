import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const Header = styled.div`
  position: fixed;
  top: ${({ top }) => (top ? top : '55px')};
  right: ${({ right }) => (right ? right : '0px')};
  left: ${({ left }) => (left ? left : '55px')};
  font-weight: 600;
  text-transform: capitalize;
  width: 92%;
  height: 45px;
  padding-left: 10px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${COLORS.TEXT_MEDIUM_GREY};
  border-bottom: 1px solid ${COLORS.OVERLAY_GREY};
`;

export const TabButton = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  padding: 10px;
  font-size: 14px;
  background-color: ${COLORS.PRIMARY_BLACK};
  color: ${({ active }) => (active ? COLORS.PURPLE : COLORS.TEXT_MEDIUM_GREY)};
  border-bottom: ${({ active }) =>
    active ? `1px solid ${COLORS.PURPLE}` : 'none'};
  font-weight: ${({ active }) => (active ? '500' : '400')};

  box-shadow: ${({ active }) => !active && '0 2px 4px rgba(0, 0, 0, 0.1)'};
  user-select: none;
  transition: all 0.3s ease, color 0.3s ease;

  &:hover {
    font-weight: 500;
    border-bottom: 1px solid ${COLORS.PURPLE};
    color: ${COLORS.PURPLE};
  }
`;
export const BtnContainer = styled.div``;
export const Website = styled.a`
  color: ${COLORS.URL_COLOR};
  font-size: 13px;
  font-family: PoppinsRegular;
  text-decoration: underline;
`;

export const MyAgencyDetailsBaseContainer = styled.div`
  margin-top: 70px;
  margin-left: 100px;
`;
