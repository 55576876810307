import { Button } from '../../constants/GlobalStyles.Styles';
import { GoBackSpan } from '../../pages/BaseLayout/BaseLayout.Styles';
import { Row } from '../CalendarModal/CalendarModal.Styles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import Back from '../../assets/icons/Back.svg';
import WhiteBack from '../../assets/icons/WhiteBack.svg';
import WhitePlus from '../../assets/icons/Plus.svg';
import { SubHeaderTitle } from '../../pages/assetVersions/AssetVersion.Style';
import { STRINGS } from '../../constants/labels';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  PATH_NAME_INCLUDES_MAP,
  PATH_NAME_MAP,
} from '../../constants/constant';
import UniversalModal from '../modals/UniversalModal';
import { FlexContainer } from '../../constants/BaseStyles';

const SubHeader = ({
  display,
  buttonDisplay,
  marginTop,
  buttonMarginTop,
  isSubHeaderButtonClicked,
  setIsSubHeaderButtonClicked,
  title,
  isBack = true,
  titleColor,
  btnFalse = true,
  customBackFunction,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  //location
  const location = useLocation();
  const pathname = location.pathname;

  //navigation
  const navigate = useNavigate();

  //params
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { project_id, event_id } = params;

  const [isOpenModal, setIsopenModal] = useState(false);
  const [currentName, setCurrentName] = useState('');

  // Dependencies: pathname - to determine the current location in the application
  useEffect(() => {
    const title = setTitle();
    setCurrentName(title);
  }, [pathname]);

  //fn to set title based on url
  const setTitle = () => {
    const matchedKey = Object.keys(PATH_NAME_INCLUDES_MAP).find((key) =>
      pathname.includes(key)
    );
    return matchedKey
      ? PATH_NAME_INCLUDES_MAP[matchedKey]
      : PATH_NAME_MAP[pathname];
  };

  const handleGoBack = () => {
    if (
      pathname.includes('/asset-versions/') &&
      window?.history?.state?.idx === 0
    ) {
      navigate(`/projects/project/${project_id}/view-event/${event_id}`);
    } else {
      if (window?.history?.state?.idx === 0) {
        navigate('/');
      } else if (pathname.includes('/website_review')) {
        const project_id_1 = searchParams.get('project_id');
        navigate(
          `/projects/view-project/website_list/${project_id_1}?project_type=Tech`
        );
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <>
      <Row
        cards
        spaceBetween
        height={'40px'}
        width={'91.5vw'}
        marginLeft={'95px'}
        marginTop={'90px'}
      >
        <Row flexStart marginTop={marginTop}>
          <div>
            {isBack && (
              <GoBackSpan
                onClick={() =>
                  customBackFunction ? customBackFunction() : handleGoBack()
                }
              >
                <DynamicSVGIcon iconUrl={WhiteBack} />
              </GoBackSpan>
            )}
          </div>
          <SubHeaderTitle titleColor={titleColor}>{title}</SubHeaderTitle>
        </Row>
        {btnFalse && (
          <Button
            marginTop={'0px'}
            visibility={buttonDisplay}
            width={'150px'}
            height={'35px'}
            noBoxShadow={true}
            onClick={() => {
              setIsSubHeaderButtonClicked(true);
              setIsopenModal(!isOpenModal);
            }}
          >
            <FlexContainer className="ai_center jc_center">
              <span>
                <DynamicSVGIcon width="20px" iconUrl={WhitePlus} />
              </span>
              {strings.CREATE_NEW_TEXT}
            </FlexContainer>
          </Button>
        )}
      </Row>
    </>
  );
};

export default SubHeader;
