import { STRINGS } from '../../constants/labels';
import {
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { ModalHeading, ModalSubHeading } from '../../pages/brands/Brand.styles';
import { Col } from '../CalendarModal/CalendarModal.Styles';
import ProjectFolderCards from '../Cards/ProjectsFolderCards.View';
import { FileLabel, Link } from './AddBrandForm.Styles';
import {
  CaptionInput,
  Fileinput,
  InputContainer,
  InputLabel,
  ProjectInput,
} from './CreateFolder.Styles';
import {
  CaptionInputContainer,
  FileContainer,
  InputWrapper,
  UploadEventFileContainer,
  UploadFileInputContainer,
} from './UploadEventFile.Styles';

const UploadEventFile = ({ formValue, setFromValue }) => {
  const strings = STRINGS['ENGLISH'];
  //   const [AssetsTitle]
  return (
    <UploadEventFileContainer>
      <ModalHeading>Add asset</ModalHeading>
      <ModalSubHeading>Enter asset details</ModalSubHeading>
      {/* <-------------------- */}
      <InputWrapper>
        <InputContainer>
          <InputLabel>Asset Title</InputLabel>
          <ProjectInput
            placeholder="Enter Asset Title"
            value={formValue?.name}
            onChange={(e) => {
              setFromValue({
                ...formValue,
                name: e.target.value,
              });
            }}
          />
        </InputContainer>
        {/*  */}
        <CaptionInputContainer>
          <InputLabel>Asset Caption</InputLabel>
          <CaptionInput
            placeholder="Enter Asset caption"
            value={formValue?.caption}
            onChange={(e) => {
              setFromValue({
                ...formValue,
                caption: e.target.value,
              });
            }}
          />
        </CaptionInputContainer>
        {/*  */}
        <UploadFileInputContainer>
          <InputLabel>Asset Upload</InputLabel>
          {formValue?.file ? (
            <FileContainer>
              <ProjectFolderCards
                data={formValue?.file}
                file={true}
                index={0}
                onCrossClick={() => {
                  setFromValue({
                    ...formValue,
                    file: null,
                  });
                }}
              />
            </FileContainer>
          ) : (
            <FileLabel>
              <Col width={'100%'}>
                <NameWrapper>
                  {strings.DRAG_N_DROP_FILE} <Link>{strings.BROWSE_FILE}</Link>
                </NameWrapper>
                <RoleWrapper>{strings.FILE_UPLOAD_TEXT}</RoleWrapper>
              </Col>

              <Fileinput
                type="file"
                accept="image/*,.pdf,.mp4,.avi,.mov,.wmv"
                onChange={(event) => {
                  setFromValue({
                    ...formValue,
                    file: event.target.files[0],
                  });
                }}
              />
            </FileLabel>
          )}
        </UploadFileInputContainer>
      </InputWrapper>
    </UploadEventFileContainer>
  );
};
export default UploadEventFile;
