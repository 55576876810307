import styled from 'styled-components';

export const Radio = styled.div`
  height: 40px;
  width: 40px;
  border: 2px solid #d2d2d2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Active = styled.div`
  height: 24px;
  border-radius: 50%;
  background-color: 'black';
`;
