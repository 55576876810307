/**
 * Login Page function component
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import {
  BackLogin,
  InputField,
  LoginOptions,
  Container,
  InputFieldContainer,
  LeftTitleText,
} from './Login.Styles';
import {
  HeaderText,
  InputBox,
  Button,
  GradientText,
} from '../../../constants/GlobalStyles.Styles';
import { STRINGS } from '../../../constants/labels';
import { CHECK_VALIDITY } from '../../../constants/validation';
import { ErrorToast } from '../../../services/toast/toast';
import { COLORS, TOAST_POSITION } from '../../../constants/BaseStyles';
import { Creators } from '../store/action';
import { cookie } from '../../../services/cookies/cookieServices';
import { GET_WINDOW_SIZE } from '../../../constants/functions';
import { sizeValue } from '../../../constants/device';
import { setDataToLocal } from '../../../services/cookies/helper';
import {
  NameWrapper,
  RoleWrapper,
} from '../../../pages/BaseLayout/BaseLayout.Styles';
import {
  Col,
  Label,
} from '../../../components/CalendarModal/CalendarModal.Styles';
import logo from '../../../assets/images/Panorbit Logo.png';

const LoginLeftText = () => {
  //Text
  const strings = STRINGS['ENGLISH'];

  return (
    <Col width={'100%'} marginTop={'25%'}>
      <div>
        <img src={logo} width={'180px'} />
      </div>
      {/* <RoleWrapper fontSize={'18px'}>{strings.NICE_TO_SEE_YOU}</RoleWrapper> */}
      <LeftTitleText marginBottom={'20px'}>
        <GradientText>{strings.AGENCY}</GradientText>
      </LeftTitleText>
      <RoleWrapper color={COLORS.WHITE} fontSize={'15px'} textAlign={'center'}>
        {strings.LANDING_CONTENT}
      </RoleWrapper>
    </Col>
  );
};

export default LoginLeftText;
