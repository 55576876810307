import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { ClientCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { STRINGS } from '../../../constants/labels';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../../constants/constant';

//TEXT
const strings = STRINGS['ENGLISH'];

export function* addClientSaga(action) {
  try {
    yield put(ClientCreators.addClientStart());

    const { formData } = action.payload;
    const apiDataObj = {
      company_name: formData?.company_name,
      email: formData?.email,
      type: 'client',
      primary_contact_name: formData?.primary_contact_name,
      primary_contact_phone_number: formData?.primary_contact_phone_number,
    };
    //if website is blank
    if (formData?.website) {
      apiDataObj['website'] = formData?.website;
    }

    const response = yield call(
      ApiService.post,
      apiEndPoints.addClient,
      apiDataObj
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(ClientCreators.addClientSuccess({ data }));
      let validationMessage = strings.SUCCESSFULLY + ' ' + strings.SAVED;
      SuccessToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'success1');
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(ClientCreators.addClientError(payload));
  }
}

export function* editClientSaga(action) {
  try {
    yield put(ClientCreators.editClientStart());
    const { formData } = action.payload;
    const apiDataObj = {
      company_id: formData?.company_id,
      company_name: formData?.company_name,
      email: formData?.email,
      type: formData?.type,
      website: formData?.website,
      primary_contact_name: formData?.primary_contact_name,
      primary_contact_phone_number: formData?.primary_contact_phone_number,
    };
    const response = yield call(
      ApiService.post,
      apiEndPoints.editClient,
      apiDataObj
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(ClientCreators.editClientSuccess(data));

      let validationMessage = strings.SUCCESSFULLY + ' ' + strings.SAVED;
      SuccessToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'success1');
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(ClientCreators.editClientError(payload));
  }
}

export function* viewClientsSaga(action) {
  const { sortParams, pageNumber, input } = action.payload;
  try {
    yield put(ClientCreators.viewClientsStart());
    const response = yield call(ApiService.post, apiEndPoints.viewClients, {
      query_item: input,
      ...sortParams,
      page_size: DEFAULT_CLIENT_PAGE_SIZE,
      page: pageNumber,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(ClientCreators.viewClientsSuccess({ data: data?.meta }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(ClientCreators.viewClientsError(payload));
  }
}
// <---------------saga for delete client's from list----------------->
export function* deleteClientsSaga(action) {
  try {
    const { companyId, sortParams, input } = action.payload;
    yield put(ClientCreators.deleteClientsStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteClients, {
      params: {
        company_id: companyId,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].SUCCESSFULL_DELETION,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(ClientCreators.deleteClientsSuccess({}));
      yield put(
        ClientCreators.viewClients({
          pageNumber: 1,
          sortParams: sortParams,
          input: input,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(ClientCreators.deleteClientsError(payload));
  }
}

export function* viewClientSaga(action) {
  const { company_id } = action.payload;
  try {
    yield put(ClientCreators.viewClientStart());
    const response = yield call(ApiService.get, apiEndPoints.viewClient, {
      params: { company_id: company_id },
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(ClientCreators.viewClientSuccess({ data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(ClientCreators.viewClientError(payload));
  }
}
