import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const CardIconWrapper = styled.div`
  background: ${({ background }) =>
    background || COLORS.PRIMARY_BACKGROUND_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Subtle box shadow with low opacity */
  transition: all 400ms ease;
`;

export const CardIcon = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 8px;
  object-fit: fit;
`;

export const CardIconText = styled.div`
  font-size: 18px;
  transition: all 400ms ease;
  text-align: center;
  color: ${({ color }) => (color ? color : COLORS.PRIMARY_BACKGROUND_COLOR)};
  font-family: PoppinsSemiBold;
`;
export const CardText = styled.div`
  font-size: 1rem;
  color: ${COLORS.TEXT_MEDIUM_GREY};
`;

export const CardCantainer = styled.div`
  width: ${({ width }) => (width ? width : '340px')};
  height: ${({ height }) => (height ? height : '200px')};
  border-top: 6px solid ${(props) => props.bg || 'none'};
  background: ${(props) =>
    props.img
      ? `url(${props.img}) center/cover no-repeat`
      : 'none'}; // Fix background image property
  border-radius: ${({ borderRadiusAll }) =>
    borderRadiusAll ? '12px' : '12px 12px 0px 0px'};
  padding: ${({ padding }) => (padding ? padding : '10px 15px 10px 15px')};
  opacity: 1;
  margin: ${({ margin }) => (margin ? margin : '0px')};

  position: relative;
  display: flex;
  color: ${COLORS.TEXT_MEDIUM_GREY};
  background-color: ${COLORS.PRIMARY_GREY};
  cursor: pointer;
  transition: all 400ms ease;
`;

export const CardUsername = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '13px')};
  // letter-spacing: -0.06px;
  color: ${({ noPurpleText, usernameColor }) =>
    usernameColor
      ? usernameColor || COLORS.MEDIUM_PRUPLE
      : COLORS.TEXT_MEDIUM_GREY};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  font-family: 'PoppinsMedium';
  flex: 0 1 78%;
`;

export const UserCardUsername = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '13px')};
  // letter-spacing: -0.06px;
  color: ${({ noPurpleText, usernameColor }) =>
    usernameColor
      ? usernameColor || COLORS.MEDIUM_PRUPLE
      : COLORS.TEXT_MEDIUM_GREY};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  font-family: 'PoppinsMedium';
  flex: 0 1 78%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconContainer = styled.div`
  background: ${({ bg }) =>
    `transparent linear-gradient(-254deg, ${bg} 0%, ${bg}80 100%, #FFFFFF 100%) 0% 0% no-repeat padding-box`};

  width: 100%;
  height: 100px;
  border-radius: 14px;
  display: flex;
  align-items: flex-end;
  padding: 10px;
`;
export const IconAndText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ height }) => (height ? height : '100%')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  align-items: ${({ align }) => (align ? align : 'center')};
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  // text-transform: capitalize;
  font-size: 14px;
`;

export const CircleStackContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  padding: 5px;
  font-size: 13px;
  font-weight: 500;
  color: ${COLORS.TEXT_MEDIUM_GREY};
`;

export const Circle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid ${COLORS.PRIMARY_GREY};
  background-color: #333; /* or any color you prefer */
  margin-left: -15px; /* Adjust this value for slight overlap */
`;

export const Tab = styled.div`
  background: ${(props) => props.bg || 'inherit'};
  width: 150px;
  height: 34px;
  margin-top: -10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
  text-transform: capitalize;
  color: ${COLORS.PRIMARY_BLACK};
`;
export const UserCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${COLORS.OVERLAY_GREY};
  padding: 5px;
`;

export const FileCard = styled.div`
  height: 150px;
  width: 190px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 1px 1px 5px ${COLORS.BOX_SHADOW_COLOR};
  cursor: pointer;
  position: relative;
  color: ${COLORS.TEXT_MEDIUM_GREY};
  background: ${COLORS.PRIMARY_GREY};
`;

export const FolderWrapper = styled.div`
  height: 70px;
  width: 120px;
`;
export const KababIcons = styled.div`
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
`;

export const UserIconText = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${COLORS.PRIMARY_BACKGROUND_COLOR};
  font-family: PoppinsSemiBold;
`;
