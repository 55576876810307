import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
import { ToastContainer } from 'react-toastify';

export const ToastMessageContainer = styled(ToastContainer)`
  & .Toastify__toast {
    min-height: 0;
    max-height: 80px;
  }
  & .Toastify__toast-body {
    margin: 10px;
  }
  & .Toastify__progress-bar {
    background: ${COLORS.PRIMARY_COLOR};
  }
`;

export const ToastMessage = styled.div``;

export const ToastTitle = styled.div`
  font-size: 16px;
  color: ${COLORS.PRIMARY_ICON_TEXT_COLOR};
  font-family: PoppinsRegular;
  margin-bottom: 10px;
`;

export const ToastBody = styled.div`
  font-size: 12px;
  color: ${COLORS.PRIMARY_ICON_TEXT_COLOR};
  font-family: 'InterSemiBold';
`;
