import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
import { COLOR_POST_MAPPING } from '../../constants/constant';

export const Header = styled.div`
  height: 60px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // background: ${COLORS.PRIMARY_BACKGROUND_COLOR};
`;

export const Title = styled.div`
  text-align: ${({ textCenter }) => (textCenter ? 'center' : '')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  text-transform: capitalize;
  width: ${({ width }) => (width ? width : '180px')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${COLORS.COMMENTS_BACKGROUND_COLOR};
  height: ${({ height }) => height && height};
`;
export const SubHeaderTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ titleColor }) => titleColor || COLORS.COMMENTS_BACKGROUND_COLOR};
  font-family: PoppinsRegular;
  font-size: 18px;
  text-transform: capitalize;
  max-width: 400px;
`;

export const InfoDiv = styled.div`
  padding: 10px;
  border-radius: 3px;
  background: ${(props) => props.backgroundColor || COLORS.OVERLAY_GREY};
  // color: ${(props) => props.color || COLORS.GRAY};
  color: ${(props) =>
    props.backgroundColor ? 'white' : COLORS.COMMENTS_BACKGROUND_COLOR};
  cursor: ${(cursor) => (cursor ? cursor : '')};
  margin-left: 5px;
  font-weight: 600;
  // color: white;
  text-align: center;
  height: 35px;
  width: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isFirstChild, isFlexEnd }) =>
    isFlexEnd ? 'flex-end' : isFirstChild ? 'flex-start' : 'space-evenly'};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  // margin: 10px;
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
`;
export const ComponentsWrapper = styled.div`
  margin-left: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 75px;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  z-index: 0;
`;
export const RowWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const CommentsWrapper = styled.div`
  height: 100%;
  width: 27vw;
  margin-right: 80px;
  z-index: 0;
`;

export const DropDownWrapper = styled.div`
  min-width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`;

export const ChangeStatusDiv = styled.div`
  padding: 8px;
  margin-right: 15px;
  font-weight: 600;
  text-align: center;
  // background: ${COLORS.APPROVED_COLOR};
`;

export const SelectWrapper = styled.div`
  width: ${({ width }) => (width ? width : '130px')};
  margin: 10px;
  font-size: 14px;
  line-height: 15px;
`;

export const RefreshBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : '100px')};
`;
