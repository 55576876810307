import InfiniteScroll from 'react-infinite-scroll-component';

import { sizeValue } from '../../constants/device';

const PageInfiniteScroll = ({
  dataLength,
  children,
  scrollInfinite,
  scrollDetails,
  viewType,
  scrollHeight,
}) => {
  //   const { responsiveData } = useSelector((state) => state?.profile);
  //   const obj = {
  //     padding: '20px',
  //     overflowY: 'scroll',
  //     alignItems: 'center',
  //     display: 'flex',
  //     flexWrap: 'wrap',
  //     alignContent: 'flex-start',
  //   };
  //   if (responsiveData?.innerWidth < sizeValue.tablet) {
  //     obj['justifyContent'] = 'center';
  //   }
  return (
    <>
      {scrollInfinite ? (
        <InfiniteScroll
          dataLength={dataLength}
          next={scrollDetails.onFetchMoreData}
          hasMore={scrollDetails.hasMore}
          height={scrollHeight ? scrollHeight : 'calc(100vh - 145px)'}
          scrollThreshold="50px"
          //   style={viewType === 'Grid' && obj}
          className="grid"
          loader={dataLength >= 10 ? <h4>Loading...</h4> : null}
        >
          {children}
        </InfiniteScroll>
      ) : (
        children
      )}
    </>
  );
};

export default PageInfiniteScroll;
