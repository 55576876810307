import { useSelector } from 'react-redux';
import { Row } from '../dashboards/adminDashboard.Styles';
import { MyAgencyDetailsBaseContainer } from './MyAgencyBase.Styles';
import {
  MyAgencyCardContainer,
  MyAgencyIconWrapper,
  MyAgencyIcon,
  MyAgencyIconText,
  MyAgencyDetailsContainer,
  MyAgencyDetailsTitleContainer,
  MyAgencyDetailsLinkContainer,
  MyAgencyDetailsLink,
  MyAgencyDetailsTitle,
} from './MyAgency.Styles';
import {
  FIND_COLOR_RANGE,
  GET_INITIALS_FROM_NAME,
} from '../../constants/functions';

const Dashboard = () => {
  //store
  const myAgencyData = useSelector((state) => state.myAgency.myAgencyData);

  return (
    <>
      <MyAgencyDetailsBaseContainer>
        <MyAgencyCardContainer>
          <MyAgencyIconWrapper
            background={FIND_COLOR_RANGE(
              myAgencyData?.company_name,
              myAgencyData?.company_logo
            )}
          >
            {myAgencyData?.company_logo ? (
              <MyAgencyIcon src={myAgencyData?.company_logo} />
            ) : (
              <MyAgencyIconText>
                {GET_INITIALS_FROM_NAME(myAgencyData?.company_name)}
              </MyAgencyIconText>
            )}
          </MyAgencyIconWrapper>
          <MyAgencyDetailsContainer>
            <MyAgencyDetailsTitleContainer>
              <MyAgencyDetailsTitle>
                {myAgencyData.company_name}
              </MyAgencyDetailsTitle>
            </MyAgencyDetailsTitleContainer>
            <MyAgencyDetailsLinkContainer>
              <MyAgencyDetailsLink
                href={
                  !myAgencyData?.website?.startsWith('http://') &&
                  !myAgencyData?.website?.startsWith('https://')
                    ? `https://${myAgencyData?.website}`
                    : myAgencyData?.website
                }
                target="_blank"
              >
                {myAgencyData?.website}
              </MyAgencyDetailsLink>
            </MyAgencyDetailsLinkContainer>
          </MyAgencyDetailsContainer>
        </MyAgencyCardContainer>
      </MyAgencyDetailsBaseContainer>
    </>
  );
};

export default Dashboard;
