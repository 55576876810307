/**
 * saga js
 *
 * it will have all the dashboard related sagas
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import {
  ApiService,
  ApiServiceMultipart,
} from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { MyAgencyCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { STRINGS } from '../../../constants/labels';

//TEXT
const strings = STRINGS['ENGLISH'];

export function* viewMyAgencySaga(action) {
  try {
    yield put(MyAgencyCreators.viewMyAgencyStart());
    const response = yield call(ApiService.get, apiEndPoints.getMyAgency);
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(MyAgencyCreators.viewMyAgencySuccess({ data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(MyAgencyCreators.viewMyAgencyError(payload));
  }
}

export function* editMyAgencySaga(action) {
  try {
    yield put(MyAgencyCreators.editMyAgencyStart());
    const {
      payload: { data: apiDataObj },
    } = action;

    const response = yield call(
      ApiServiceMultipart.put,
      apiEndPoints.editMyAgency,
      apiDataObj
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(MyAgencyCreators.editMyAgencySuccess(data));
      yield put(MyAgencyCreators.viewMyAgency());
      let validationMessage = strings.SUCCESSFULLY + ' ' + strings.SAVED;
      SuccessToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'success1');
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(MyAgencyCreators.editMyAgencyError(payload));
  }
}
