import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
//
export const CardImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: contain;
  background-color: gray;
`;
export const WebCardImg = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.WHITE};
`;
//
export const DeleteAssetContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  top: 2px;
  right: 3px;
  width: 20px;
  height: 20px;
  background-color: ${COLORS.BACKGROUND_COLOR};
  border-radius: 5px;
`;
export const CardType = styled.div`
  color: white;
  font-size: 0.8em;
  font-family: PoppinsMedium;
  background: ${COLORS.SECONDARY_BLUE};
  border-radius: 4px;
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
`;
export const CardsContainer = styled.div``;

export const Cards = styled.div`
  height: 130px;
  width: 175px;
  border-radius: 8px;
  box-shadow: 1px 1px 5px ${COLORS.BOX_SHADOW_COLOR};
  cursor: pointer;
  position: relative;
  color: ${COLORS.TEXT_MEDIUM_GREY};
  transition: filter 0.3s ease;
  ${DeleteAssetContainer} {
    display: none;
  }

  &:hover {
    ${DeleteAssetContainer} {
      display: flex;
    }
    ${CardImg},
    ${CardType},${WebCardImg} {
      filter: brightness(0.6);
    }
  }
`;

export const CardAssetView = styled.div``;
export const ThumbnilIConsContainer = styled.div`
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const InfoWrapper = styled.div`
  padding-top: 10px;
`;

export const CardTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 99%;
  cursor: pointer;
  color: ${COLORS.WHITE};
  font-size: 13px;
`;

export const CardTypeContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  bottom: 2px;
  right: 3px;
`;
export const CommentCount = styled.div`
  color: ${COLORS.GRNOTIFICATION_TIME_COLOREEN};
  font-family: PoppinsRegular;
  font-size: 0.7rem;
`;
