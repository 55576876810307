/**
 * saga js
 *
 * it will have all the  asset ver related sagas
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../../constants/constant';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { WebpageCreators } from './action';

//TEXT
const strings = STRINGS['ENGLISH'];

// <---------------saga for scrape webpage----------------->
export function* scrapeWebpageSaga(action) {
  const data = action.payload;
  const { website_name, url, project_id } = action.payload;
  try {
    yield put(WebpageCreators.scrapeWebpageStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.scrapeWebpage,
      data
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(WebpageCreators.scrapeWebpageSuccess({ data: data?.data }));
      yield put(
        WebpageCreators.getAllWebpages({
          page_size: DEFAULT_CLIENT_PAGE_SIZE,
          page: 1,
          project_id,
        })
      );

      SuccessToast(strings.SUCCESS, TOAST_POSITION.TOP_CENTER, 'success1');
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.scrapeWebpageError(payload));
  }
}

// <---------------saga for getting html webpage from s3----------------->
export function* getHTMLPageSaga(action) {
  const data = action.payload;
  const { version_id, webpage_id } = data;
  // const { webpage_id, version_id } = action.payload;
  try {
    yield put(WebpageCreators.getHTMLPageStart());
    const response = yield call(
      ApiService.get,
      `${apiEndPoints.htmlWebpage}?version_id=${version_id}&webpage_id=${webpage_id}`
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(WebpageCreators.getHTMLPageSuccess({ data: data?.data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.getHTMLPageError(payload));
  }
}

// <---------------saga for get webpage by version----------------->
export function* getWebpageSaga(action) {
  const { webpage_id, version_id, project_id } = action.payload;
  try {
    yield put(WebpageCreators.getWebpageStart());

    const response = yield call(ApiService.get, apiEndPoints.getWebpage, {
      params: {
        webpage_id,
        version_id,
        project_id,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(WebpageCreators.getWebpageSuccess({ data: data?.data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.getWebpageError(payload));
  }
}

// <---------------saga for get all versions of webpage----------------->
export function* getWebpageVersionsSaga(action) {
  const { webpage_id, project_id } = action.payload;
  try {
    yield put(WebpageCreators.getWebpageVersionsStart());

    const response = yield call(
      ApiService.get,
      apiEndPoints.getWebpageVersions,
      {
        params: {
          webpage_id,
          project_id,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        WebpageCreators.getWebpageVersionsSuccess({ data: data?.data })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.getWebpageVersionsError(payload));
  }
}

// <---------------saga for get list of all webpages----------------->
export function* getAllWebpagesSaga(action) {
  try {
    const { page, page_size, project_id } = action.payload;
    yield put(WebpageCreators.getAllWebpagesStart());
    const response = yield call(ApiService.get, apiEndPoints.getAllWebpages, {
      params: {
        page_size,
        page,
        project_id,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        WebpageCreators.getAllWebpagesSuccess({
          data: data?.meta,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.getAllWebpagesError(payload));
  }
}

// <---------------saga for adding comment to website ----------------->
export function* addWebsiteCommentSaga(action) {
  const data = action.payload;
  const { webpage_id, webpage_version_id, view_type } = action.payload;

  try {
    yield put(WebpageCreators.addWebsiteCommentStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.addWebsiteComment,
      data
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(WebpageCreators.addWebsiteCommentSuccess({ data: data?.data }));
      yield put(
        WebpageCreators.getCommentsByWebsite({
          webpage_id,
          version_id: webpage_version_id,
          view_type,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.addWebsiteCommentError(payload));
  }
}

// <---------------saga for getting comments by website ----------------->
export function* getCommentsByWebsiteSaga(action) {
  const {
    webpage_id,
    version_id: webpage_version_id,
    filter_by_resolved,
    filter_by_me,
    view_type,
  } = action.payload;
  const params = {
    webpage_id,
    webpage_version_id,
    view_type,
  };
  if (filter_by_resolved !== undefined) {
    params['filter_by_resolved'] = filter_by_resolved;
  }
  if (filter_by_me !== undefined) {
    params['filter_by_me'] = filter_by_me;
  }
  try {
    //pass project id
    yield put(WebpageCreators.getCommentsByWebsiteStart());
    const response = yield call(
      ApiService.get,
      apiEndPoints.getCommentsByWebpage,
      {
        params: params,
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;

      yield put(
        WebpageCreators.getCommentsByWebsiteSuccess({
          data: data?.meta,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.getCommentsByWebsiteError(payload));
  }
}

// <---------------delete website saga----------------->
export function* deleteWebsiteSaga(action) {
  try {
    const { webpageId, project_id } = action?.payload;
    yield put(WebpageCreators.deleteWebsiteStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteWebpage, {
      params: { webpage_id: webpageId },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(WebpageCreators.deleteWebsiteSuccess({ data: data?.meta }));

      yield put(
        WebpageCreators.getAllWebpages({
          page_size: DEFAULT_CLIENT_PAGE_SIZE,
          page: 1,
          project_id,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.deleteWebsiteFailure(payload));
  }
}
// <---------------saga for update the status ----------------->
export function* updateWebStatusSaga(action) {
  const { status, webpage_id, version_id, project_id } = action.payload;

  try {
    yield put(WebpageCreators.updateWebStatusStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.updateWebpageVersion,
      {
        webpage_id: webpage_id,
        version_id: version_id,
        status: status,
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(WebpageCreators.updateWebStatusSuccess({ comment_id: data }));
      yield put(
        WebpageCreators.getWebpage({ webpage_id, version_id, project_id })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(WebpageCreators.updateWebStatusError(payload));
  }
}
