/**
 * action js
 *
 * it will have all the event related actions
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getEvent: ['payload'],
  getEventStart: ['payload'],
  getEventSuccess: ['payload'],
  getEventError: ['payload'],
  //
  editEvent: ['payload'],
  editEventStart: ['payload'],
  editEventSuccess: ['payload'],
  editEventError: ['payload'],
  //
  getAssets: ['payload'],
  getAssetsStart: ['payload'],
  getAssetsSuccess: ['payload'],
  getAssetsError: ['payload'],

  //   addPost: ['payload'],
  //   addEventtart: ['payload'],
  //   addPostSuccess: ['payload'],
  //   addPostError: ['payload'],
  resetState: ['payload'],
});

export { Types as EventTypes, Creators as EventCreators };
