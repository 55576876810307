import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
import Plus from '../../assets/icons/Plus.svg';
import { INTERNAL } from '../../constants/constant';

export const Wrapper = styled.div`
  margin-left: 100px;
  margin-top: 0px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
  background-color: ${COLORS.PRIMARY_BLACK};
  color: ${COLORS.TEXT_MEDIUM_GREY};
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
`;
export const CalendarWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ScrollableDiv = styled.div`
  height: 70vh;
  margin-bottom: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;
//
export const EventListcontainer = styled.div`
  margin-left: 100px;
  margin-top: 95px;
`;
export const EventListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;
export const BAckAndTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;
export const BackIcon = styled.div``;
export const TitleText = styled.div`
  color: ${COLORS.WHITE};
`;
export const UploadFileBtn = styled.div`
  padding-right: 15px;
`;
export const CalendarWrape = styled.div`
  display: flex;
  margin-top: 80px;
  column-gap: 20px;
`;
export const ChooseCalanderType = styled.div`
  color: red;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  width: 120px;
  height: 35px;
  background: #353535;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
export const CalanderDateSelect = styled.div`
  position: absolute;
  left: 0;
  z-index: 3;
  cursor: pointer;
  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: black;
  }
  // <---------------------------------------------------------->
  .react-datepicker {
    width: 220px;
    background-color: ${COLORS.BLACK};
    box-shadow: 0px 3px 15px ${COLORS.TERTIARY_SHADOW_COLOR};
    border-radius: 14px;
    border: none;
  }
  // <---------------------- position of calander------------------------------------>
  .react-datepicker-popper {
    transform: translate3d(0px, 14px, 0px) !important;
  }
  // <----------------------- month year----------------------------------->

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    color: ${COLORS.WHITE};
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text:hover {
    background-color: ${COLORS.BLACK};
  }
  // <-----------------------date picker header----------------------------------->

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    background-color: ${COLORS.BLACK};
    color: ${COLORS.WHITE};
    border: none;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
export const DateTitle = styled.div``;
export const CalanderFormate = styled.div`
  padding-right: 5px;
  padding-left: 5px;
`;
export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: ${COLORS.NOTIFICATION_BG};
  border-radius: 12px;
  padding: 15px;
  height: calc(100vh - 205px);
`;
export const InfoHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.BTN_PRIMARY};
  padding-bottom: 10px;
`;
export const CreatatedOnDate = styled.div`
  color: ${COLORS.TEXT_SECONDARY_GREY};
  font-family: PoppinsRegular;
  font-size: 13px;
`;
export const CreatatedOnHeading = styled.div`
  color: ${COLORS.TEXT_GREY};
  font-family: PoppinsRegular;
  font-size: 12px;
`;
export const CreatatedOnWrapper = styled.div`
  padding-top: 5px;
`;
export const Description = styled.div`
  color: ${COLORS.WHITE};
  font-size: 14px;
  font-family: PoppinsRegular;
`;
export const EventDescription = styled.div`
  color: ${COLORS.TERTIARY_TEXT_COLOR};
  font-size: 12px;
  font-family: PoppinsRegular;
  padding-top: 10px;
  white-space: pre-wrap;
`;
export const EventDate = styled.div`
  color: ${COLORS.WHITE};
`;
export const DescriptionTextArea = styled.textarea`
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  font-family: PoppinsRegular, sans-serif;
  color: ${COLORS.TEXT_GREY};
  border: ${({ disabled }) =>
    disabled ? 'none' : `1px solid ${COLORS.OVERLAY_GREY}`};
  background-color: ${({ disabled }) =>
    disabled ? COLORS.PRIMARY_BLACK : COLORS.PRIMARY_GREY};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;
export const EventLabel = styled.div`
  text-align: start;
  padding-bottom: 5px;
  font-size: 0.8rem;
  color: ${COLORS.TEXT_GREY};
`;
export const ReactDate = styled.div`
  background-color: red;
  .react-datepicker-popper {
    transform: translate3d(220px, 130.2px, 0px) !important;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input: {
    width: 100%;
    height: 35px;
    background-color: ${COLORS.PRIMARY_BLACK};
    outline: none;
    border: none;
    color: ${COLORS.TEXT_GREY};
    padding-left: 10px;
  }
`;
