import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  CompleteNotificationContainer,
  NotificationValueDiv,
  NotificationsDate,
  NotificationsTitle,
} from './Notification.Styles';
import Notifications from './Notifications';
import { STRINGS } from '../../constants/labels';
import { BaseCreators } from '../../pages/BaseLayout/store/action';
import { useEffect } from 'react';
import Empty from '../EmptyFileComponent/EmptyFile.View';

const CompleteNotification = () => {
  const strings = STRINGS['ENGLISH'];
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.loggedinUser);
  const { notificationList } = useSelector((state) => state.base);
  // Group notifications by date
  const groupedNotifications =
    notificationList?.length > 0
      ? notificationList.reduce((result, currentItem) => {
          const date = moment(currentItem.created_at).format(
            'dddd - MMMM D, YYYY'
          );
          if (!result[date]) {
            result[date] = [];
          }
          result[date].push(currentItem);
          return result;
        }, {})
      : [];
  //
  useEffect(() => {
    const { username } = userData;
    if (userData) {
      dispatch(BaseCreators.notificationList({ username }));
      dispatch(BaseCreators.notificationCount({ username }));
    }
  }, [dispatch, userData]);
  return (
    <CompleteNotificationContainer>
      <NotificationsTitle>{strings.NOTIFICATIONS}</NotificationsTitle>
      <div style={{ overflowY: 'auto', height: 'calc(100vh - 120px)' }}>
        {Object.entries(groupedNotifications).map(([key, value]) => {
          return (
            <div>
              <NotificationsDate>{key}</NotificationsDate>
              <NotificationValueDiv>
                {value?.map((data) => (
                  <Notifications data={data} allNotification={true} />
                ))}
              </NotificationValueDiv>
            </div>
          );
        })}
        {groupedNotifications?.length < 1 ? (
          <Empty heading={strings.NO_NOTIFICATIONS} height={'20vh'} />
        ) : null}
      </div>
    </CompleteNotificationContainer>
  );
};
export default CompleteNotification;
