import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const ProjectCardTopPartCantainer = styled.div`
  width: ${({ width }) => (width ? width : '340px')};
  height: 120px;
  border-top: 6px solid ${(props) => props.bg || 'none'};
  background: ${(props) =>
    props.img
      ? `url(${props.img}) center/cover no-repeat`
      : 'none'}; // Fix background image property
  border-radius: ${({ borderRadiusAll }) =>
    borderRadiusAll ? '12px' : '12px 12px 0px 0px'};
  padding: 10px 15px 10px 15px;
  opacity: 1;
  margin: 0px;
  position: relative;
  color: ${COLORS.TEXT_MEDIUM_GREY};
  background-color: ${COLORS.PRIMARY_GREY};
  cursor: pointer;
  transition: all 400ms ease;
  position: relative;
  display: flex;
  align-items: center;
`;

export const ProjectTypeText = styled.div`
  background: ${(props) => props.bg || 'inherit'};
  width: 150px;
  height: 34px;
  margin-top: -10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 14px;
  padding: 5px;
  text-transform: capitalize;
  color: ${COLORS.BLACK};
  font-family: PoppinsSemiBold;
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
`;
export const ProjectName = styled.div`
  color: ${COLORS.TEXT_SECONDARY_GREY};
  font-size: 17px;
  max-width: 95%;
  width: 310px;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: PoppinsSemiBold;
  display: -webkit-box;
  max-width: 310px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProjectCardMenuContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 10px;
`;

export const ProjectCardTitleContainer = styled.div``;
