/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IFrameContainer } from './WebsiteIFrame.Styles';
import CommentPins from './CommentPins.View';
import { AssetCreators } from '../assetsView/store/action';

const WebsiteIFrame = ({
  singleWebpageData,
  iframeWidth,
  iframeHeight,
  webpage_id,
  version_id,
  project_id,
  isFullScreen,
  commentsData,
  showComment = true,
}) => {
  const dispatch = useDispatch();
  const [buttonPositions, setButtonPositions] = useState([]);
  const [iframeSrc, setIframeSrc] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [currentBtnCordinate, setCurrentBtnCordinate] = useState({});
  const [isCommentBoxVisible, setCommentBoxVisible] = useState(false);
  const [connectionStablish, setConnectionStablish] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(1);
  const connectionStablishRef = useRef(false);
  const [activeCommentId, setActiveCommentID] = useState(null);
  const [pinCoordinates, setPinCoordinates] = useState([]);
  const sourceRef = useRef(null);
  const overlayRef = useRef(null);
  const iframeRef = useRef(null);
  const commentsDataRef = useRef([]);

  // Function to handle iframe load
  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const communicateChildToParentFunction = (event) => {
    //
    if (
      event.origin !== 'http://localhost:3000' &&
      !event.origin.includes('panorbitprojects.com') &&
      !event.origin.includes('localhost') &&
      event.origin !== 'https://s3.ap-south-1.amazonaws.com' &&
      !event.origin.includes('amazonaws.com')
    ) {
      return;
    }
    if (event.data && typeof event.data === 'string') {
      try {
        const dataObj = JSON.parse(event.data);
        switch (dataObj?.type) {
          case 'INIT':
            // if (!updatedIframeReload.current) {
            //   //
            //   // console.log(iframeRef.current.src);
            //   iframeRef.current.src = iframeRef.current.src + '';
            //   updatedIframeReload.current = true;
            //   return;
            // }
            setConnectionStablish(true);
            connectionStablishRef.current = true;
            sourceRef.current = event.source;
            break;
          case 'ADD_NEW_COMMENT':
            // sourceRef.current.postMessage(JSON.parse(event.data), '*');
            setCurrentBtnCordinate(dataObj);
            setActiveCommentID(null);
            break;
          case 'UPDATE_POSITION_OF_COMMENTS':
            const coord = dataObj.commentData.map((data) => {
              let commentNo = commentsDataRef.current.find(
                (item) => item.comment_id === data.comment_id
              )?.comment_no;
              return {
                ...data,
                comment_no: commentNo,
              };
            });
            setPinCoordinates(coord);
            break;
          case 'SCROLL_TRIGGERED':
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteComment = (id, isCommentExist) => {
    setButtonPositions((prevPositions) =>
      prevPositions.filter((btn) => btn.comment_id !== id)
    );
    setCommentBoxVisible(false);

    if (isCommentExist) {
      dispatch(
        AssetCreators.deleteAssetComment({
          comment_id: id,
          assetID: '',
          versionId: version_id,
          website: true,
          webpage_id,
          version_id,
        })
      );
    }
  };

  const triggerMapComment = () => {
    const commentDataObject = {
      commentsData: [...buttonPositions],
      type: 'COMMENT_DATA',
    };
    if (connectionStablish) {
      sourceRef.current?.postMessage(JSON.stringify(commentDataObject), '*');
    }
  };

  const fullScreenViewSizeReset = () => {
    if (connectionStablish) {
      if (isFullScreen) {
      }
      const container = document.getElementById('iFrameContainer');
      const iframe = document.getElementById('FrameID');
      // console.log('container.offsetHeight', container.offsetHeight);
      // console.log('container.offsetWidth', container.offsetWidth);
      // console.log('container.clientHeight', container.clientHeight);
      // console.log('container.clientWidth', container.clientWidth);
      const scaleFactorWidth = container.offsetWidth / iframe.offsetWidth;
      const scaleFactorHeight = container.offsetHeight / iframe.offsetHeight;
      if (scaleFactorWidth > 1 && scaleFactorHeight > 1) {
        setScaleFactor(1);
        iframe.style.transform = `scale(1)`;
      } else {
        const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);
        setScaleFactor(scaleFactor);
        iframe.style.transform = `scale(${scaleFactor})`;
      }
    }
  };

  useEffect(() => {
    const overlay = overlayRef?.current;
    window.addEventListener('message', communicateChildToParentFunction, false);
    overlay?.addEventListener('click', (event) => {
      setCommentBoxVisible(true);
    });
    return () => {
      window.removeEventListener('message', communicateChildToParentFunction);
      overlay?.removeEventListener('click', (event) => {
        setCommentBoxVisible(true);
      });
      //
    };
  }, []);

  useEffect(() => {
    triggerMapComment();
  }, [buttonPositions, connectionStablish, webpage_id, version_id, project_id]);

  useEffect(() => {
    if (currentBtnCordinate) {
      setButtonPositions((prevPositions) => {
        let replaceValue = false;
        for (let i = 0; i < prevPositions.length; i += 1) {
          if (prevPositions[i].comment_id === currentBtnCordinate.comment_id) {
            replaceValue = true;
            break;
          }
        }

        if (replaceValue) {
          //
          return prevPositions.map((pos) =>
            pos.comment_id === currentBtnCordinate.comment_id
              ? currentBtnCordinate
              : pos
          );
        }
        return [...prevPositions, currentBtnCordinate];
      });
    }
  }, [currentBtnCordinate]);

  useEffect(() => {
    fullScreenViewSizeReset();
  }, [iframeWidth, connectionStablish, isFullScreen, fullScreenViewSizeReset]);

  useEffect(() => {
    setButtonPositions(commentsData);
    commentsDataRef.current = commentsData;
  }, [commentsData, webpage_id, version_id, project_id]);

  useEffect(() => {
    if (singleWebpageData?.s3_url) {
      setIframeSrc(singleWebpageData.s3_url.split('?')[0]);
    }
  }, [singleWebpageData?.s3_url]);

  return (
    <IFrameContainer id={'iFrameContainer'}>
      {singleWebpageData?.s3_url && (
        <iframe
          id="FrameID"
          ref={iframeRef}
          title={singleWebpageData?.webpage_name || 'Marko Website Review'}
          height={iframeHeight}
          width={iframeWidth}
          style={{
            borderRadius: '12px',
            transformOrigin: 'top left',
          }}
          src={iframeSrc}
          sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-presentation"
          referrerpolicy="origin"
          onLoad={handleIframeLoad} // Listen to the iframe load event
        />
      )}
      <div
        ref={overlayRef}
        style={{
          position: 'absolute',
          left: '0',
          top: '0',
          height: '100%',
          width: '100%',
          pointerEvents: 'none', // Ensures that the overlay doesn't block events on elements below
          display: showComment ? 'block' : 'none',
        }}
      >
        <CommentPins
          coordinates={pinCoordinates.map(
            ({ comment_id, x, y, commentBoxX, commentBoxY, comment_no }) => ({
              x,
              y,
              commentBoxX,
              commentBoxY,
              comment_id: comment_id,
              comment_no: comment_no,
            })
          )}
          currentBtnCordinate={currentBtnCordinate}
          setCommentBoxVisible={setCommentBoxVisible}
          isCommentBoxVisible={isCommentBoxVisible}
          setActiveCommentID={setActiveCommentID}
          activeCommentID={activeCommentId}
          deleteComment={deleteComment}
          webpage_id={webpage_id}
          version_id={version_id}
          project_id={project_id}
          scaleFactor={scaleFactor}
        />
      </div>
    </IFrameContainer>
  );
};

export default WebsiteIFrame;
