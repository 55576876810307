import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { Col, Label, Row } from '../CalendarModal/CalendarModal.Styles';
import { TextArea } from '../../constants/GlobalStyles.Styles';
import { FormContainer } from './AddBrandForm.Styles';
import { STRINGS } from '../../constants/labels';

const AddPostform = ({ title, setTitle, desc, setDesc }) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  return (
    <FormContainer>
      <Col width={'540px'}>
        <NameWrapper fontSize={'17px'}>{strings.CREATE_NEW_POST}</NameWrapper>
        <RoleWrapper>{strings.ENTER_POST_DETAILS}</RoleWrapper>
      </Col>
      <Label>{strings.POST_NAME}</Label>
      <Input
        width={'515px'}
        type="text"
        placeholder={strings.ENTER_POST_NAME}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        maxLength={60}
      />

      <Label>{strings.DESCRIPTION}</Label>
      <TextArea
        placeholder={strings.ENTER_DESCRIPTION}
        width={'520px'}
        fontSize={'13px'}
        noBgColor
        height={'100px'}
        value={desc}
        onChange={(e) => setDesc(e.target.value)}
        maxLength={1000}
      />
    </FormContainer>
  );
};

export default AddPostform;
