/**
 * saga js
 *
 * it will have all the event related sagas
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { EventCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { STRINGS } from '../../../constants/labels';

//TEXT
const strings = STRINGS['ENGLISH'];
// <----------------------get event data--------------->
export function* getEventSaga(action) {
  const { event_id } = action.payload;
  try {
    yield put(EventCreators.getEventStart());
    const response = yield call(ApiService.get, apiEndPoints.getEvent, {
      params: { id: event_id },
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(EventCreators.getEventSuccess({ data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(EventCreators.getEventError(payload));
  }
}

// <----------------------edit project data--------------->
export function* editEventSaga(action) {
  const { formValues } = action.payload;
  try {
    yield put(EventCreators.editEventStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.updateEvent,
      formValues
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(EventCreators.editEventSuccess({ data }));
      SuccessToast(
        STRINGS['ENGLISH'].EVENT_UPDATED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(EventCreators.editEventError(payload));
  }
}

// <----------------------get assets data--------------->
export function* getAssetsSaga(action) {
  const { ref_id, ref } = action.payload;
  try {
    yield put(EventCreators.getAssetsStart());
    const response = yield call(ApiService.get, apiEndPoints.getAssets, {
      params: { ref: ref, ref_id: ref_id },
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        EventCreators.getAssetsSuccess({
          data,
          meta: data?.meta,
          event_id: ref_id,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(EventCreators.getAssetsError());
  }
}
