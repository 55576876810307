/**
 * reducer js
 *
 * it will have all the asset ver related reducers
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createReducer } from 'reduxsauce';
import { AssetVersionsTypes } from './action';

const INITIAL_STATE = {
  assetVersionsLoading: false,
  error: false,
  loading: true,
  assetVersionsListData: [],
  singleAssetVersionData: [],
  captionData: [],
  commentsData: [],
  addComment: [],
  deleteComment: [],
  commentData: [],
  updateAssetData: [],
  downloadData: [],
  commentAddedSuccess: false,
  commentDeletedSuccess: false,
  latestVersion: {},
  videoTimestamp: null,
};

// <---------------reducer for get all asset versions----------------->
const getAssetVersionsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    assetVersionsLoading: true,
    loading: true,
  };
};

const getAssetVersionsSuccess = (state = INITIAL_STATE, action) => {
  let assetVersionsList = [];
  const { data } = action.payload;
  assetVersionsList = data;

  return {
    ...state,
    loading: false,
    assetVersionsLoading: false,
    assetVersionsListData: assetVersionsList,
  };
};
const latestVersionfnc = (state = INITIAL_STATE, action) => {
  const { value, label } = action.payload;
  return {
    ...state,
    latestVersion: { value: value, label: label },
  };
};

const getAssetVersionsError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    assetVersionsLoading: false,
  };
};

// <---------------reducer for get single asset version----------------->
const getSingleAssetVersionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};

const getSingleAssetVersionSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    singleAssetVersionData: data,
  };
};

const getSingleAssetVersionError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------reducer for getting list of comments----------------->
const getCommentsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};

const getCommentsSuccess = (state = INITIAL_STATE, action) => {
  let commentsList = [];
  const { data } = action.payload;
  commentsList = data?.data;

  return {
    ...state,
    error: false,
    loading: false,
    commentsData: commentsList,
  };
};

const getCommentsError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};
// <---------------reducer for posting comment----------------->
const addCommentStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    commentAddedSuccess: false,
  };
};
const addCommentSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    addComment: data,
    commentAddedSuccess: data?.is_reply ? false : true,
  };
};
const addCommentError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    commentAddedSuccess: false,
  };
};
// <---------------reducer for deleting comment----------------->
const deleteCommentStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    commentDeletedSuccess: false,
  };
};
const deleteCommentSuccess = (state = INITIAL_STATE, action) => {
  const { comment_id } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    deleteComment: comment_id,
    commentDeletedSuccess: true,
  };
};
const deleteCommentError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    commentDeletedSuccess: false,
  };
};
// <---------------reducer for getting single comment----------------->
const getCommentStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};
const getCommentSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    commentData: data,
  };
};
const getCommentError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};
// <---------------reducer for updating version of asset----------------->
const updateAssetVersionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};
const updateAssetVersionSucces = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    updateAssetData: data,
  };
};
const updateAssetVersionError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------reducer for downloading latest approved version of asset----------------->
const downloadAssetStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};
const downloadAssetSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    downloadData: data,
  };
};
const downloadAssetError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};

// <---------------reducer for setting timestamp of video----------------->
const setVideoTimestamp = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    videoTimestamp: action.payload,
  };
};

// <---------------reducer for resetting timestamp of video----------------->
const resetVideoTimestamp = (state = INITIAL_STATE) => {
  return {
    ...state,
    videoTimestamp: null,
  };
};

const resetStateReduer = (state = INITIAL_STATE, action) => {
  return {
    singleAssetVersionData: [],
    assetVersionsListData: [],
    captionData: [],
    commentsData: [],
    loading: false,
    commentAddedSuccess: false,
  };
};

const HANDLERS = {
  [AssetVersionsTypes.GET_ASSET_VERSIONS_START]: getAssetVersionsStart,
  [AssetVersionsTypes.GET_ASSET_VERSIONS_SUCCESS]: getAssetVersionsSuccess,
  [AssetVersionsTypes.GET_ASSET_VERSIONS_ERROR]: getAssetVersionsError,
  //
  [AssetVersionsTypes.LATEST_VERSION]: latestVersionfnc,
  //
  [AssetVersionsTypes.GET_SINGLE_ASSET_VERSION_START]:
    getSingleAssetVersionStart,
  [AssetVersionsTypes.GET_SINGLE_ASSET_VERSION_SUCCESS]:
    getSingleAssetVersionSuccess,
  [AssetVersionsTypes.GET_SINGLE_ASSET_VERSION_ERROR]:
    getSingleAssetVersionError,
  //
  [AssetVersionsTypes.GET_COMMENTS_START]: getCommentsStart,
  [AssetVersionsTypes.GET_COMMENTS_SUCCESS]: getCommentsSuccess,
  [AssetVersionsTypes.GET_COMMENTS_ERROR]: getCommentsError,
  //
  [AssetVersionsTypes.ADD_COMMENT_START]: addCommentStart,
  [AssetVersionsTypes.ADD_COMMENT_SUCCESS]: addCommentSuccess,
  [AssetVersionsTypes.ADD_COMMENT_ERROR]: addCommentError,
  //
  [AssetVersionsTypes.DELETE_COMMENT_START]: deleteCommentStart,
  [AssetVersionsTypes.DELETE_COMMENT_SUCCESS]: deleteCommentSuccess,
  [AssetVersionsTypes.DELETE_COMMENT_ERROR]: deleteCommentError,
  //
  [AssetVersionsTypes.UPDATE_ASSET_VERSION_START]: updateAssetVersionStart,
  [AssetVersionsTypes.UPDATE_ASSET_VERSION_SUCCESS]: updateAssetVersionSucces,
  [AssetVersionsTypes.UPDATE_ASSET_VERSION_ERROR]: updateAssetVersionError,
  //
  [AssetVersionsTypes.DOWNLOAD_ASSET_START]: downloadAssetStart,
  [AssetVersionsTypes.DOWNLOAD_ASSET_SUCCESS]: downloadAssetSuccess,
  [AssetVersionsTypes.DOWNLOAD_ASSET_ERROR]: downloadAssetError,
  //
  [AssetVersionsTypes.SET_VIDEO_TIMESTAMP]: setVideoTimestamp,
  [AssetVersionsTypes.RESET_VIDEO_TIMESTAMP]: resetVideoTimestamp,

  //
  [AssetVersionsTypes.RESET_STATE]: resetStateReduer,
};
export const AssetVersionsReducer = createReducer(INITIAL_STATE, HANDLERS);
