/**
 * saga js
 *
 * it will have all the  asset ver related sagas
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { AssetVersionsCreators } from './action';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../../constants/constant';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';

//TEXT
const strings = STRINGS['ENGLISH'];

// <---------------saga for getting asset versions ----------------->
export function* getAssetVersionsSaga(action) {
  const { asset_id } = action.payload;
  try {
    //pass project id
    yield put(AssetVersionsCreators.getAssetVersionsStart());
    const response = yield call(ApiService.get, apiEndPoints.getAssetVersions, {
      params: {
        asset_id: asset_id,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AssetVersionsCreators.latestVersion({
          value: data?.data[0]?.version_id,
          label: data?.data[0]?.version_name,
        })
      );
      yield put(
        AssetVersionsCreators.getAssetVersionsSuccess({
          data: data?.data,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetVersionsCreators.getAssetVersionsError(payload));
  }
}

// <---------------saga for getting single asset version ----------------->
export function* getSingleAssetVersionSaga(action) {
  const { asset_id, version_id } = action.payload;
  try {
    yield put(AssetVersionsCreators.getSingleAssetVersionStart());

    const response = yield call(
      ApiService.get,
      apiEndPoints.getSingleAssetVersion,
      {
        params: {
          asset_id: asset_id,
          version_id: version_id,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AssetVersionsCreators.getSingleAssetVersionSuccess({ data: data?.data })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetVersionsCreators.getSingleAssetVersionError(payload));
  }
}

// <---------------saga for getting comments ----------------->
export function* getCommentsSaga(action) {
  const { asset_id } = action.payload;
  try {
    //pass project id
    yield put(AssetVersionsCreators.getCommentsStart());
    const response = yield call(ApiService.get, apiEndPoints.getComments, {
      params: {
        asset_id: asset_id,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;

      yield put(
        AssetVersionsCreators.getCommentsSuccess({
          data: data,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetVersionsCreators.getCommentsError(payload));
  }
}

// <---------------saga for adding comment ----------------->
export function* addCommentSaga(action) {
  const data = action.payload;
  const { asset_id, version_id } = action.payload;

  try {
    yield put(AssetVersionsCreators.addCommentStart());
    const response = yield call(ApiService.post, apiEndPoints.addComment, data);

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(AssetVersionsCreators.addCommentSuccess({ data: data?.data }));
      yield put(AssetVersionsCreators.getComments({ asset_id, version_id }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetVersionsCreators.addCommentError(payload));
  }
}

// <---------------saga for deleting comment ----------------->
export function* deleteCommentSaga(action) {
  const { comment_id, asset_id, version_id } = action.payload;

  try {
    yield put(AssetVersionsCreators.deleteCommentStart());
    const response = yield call(
      ApiService.delete,
      apiEndPoints.delete_comment,
      {
        params: {
          comment_id: comment_id,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AssetVersionsCreators.deleteCommentSuccess({ comment_id: data })
      );
      yield put(AssetVersionsCreators.getComments({ asset_id, version_id }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetVersionsCreators.deleteCommentError(payload));
  }
}

// <---------------saga for updating asset version ----------------->
export function* updateAssetVersionSaga(action) {
  const { data, asset_id } = action.payload;
  const { data: originalData } = action.payload;

  try {
    yield put(AssetVersionsCreators.updateAssetVersionStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.updateAssetVersion,
      data
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AssetVersionsCreators.updateAssetVersionSuccess({ data: data })
      );
      const asset_id = originalData?.asset_id;
      yield put(AssetVersionsCreators.getAssetVersions({ asset_id }));
      SuccessToast(strings.ASSET_UPDATED, TOAST_POSITION.TOP_CENTER);
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetVersionsCreators.updateAssetVersionError(payload));
  }
}

// <---------------saga for downloading latest approved asset version ----------------->
export function* downloadAssetSaga(action) {
  const { data } = action.payload;
  // const { asset_id, version_id } = action.payload;

  try {
    yield put(AssetVersionsCreators.downloadAssetStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.updateAssetVersion,
      data
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AssetVersionsCreators.downloadAssetSuccess({ data: data?.data })
      );
      SuccessToast(strings.ASSET_UPDATED, TOAST_POSITION.TOP_CENTER);
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetVersionsCreators.downloadAssetError(payload));
  }
}
