import { useEffect, useState } from 'react';
import { DEFAULT_OPTION } from '../../constants/constant';
import DynamicSVGIcon from '../DynamicSVGIcon';
import {
  OptionCardContainer,
  OptionIcons,
  OptionName,
  OptionNameContainer,
} from './OptionCard.styles';

const OptionCard = ({ onClickOption, additionalData, option, right, top }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (option) {
      setOptions(option);
    } else {
      setOptions(DEFAULT_OPTION);
    }
  }, [option]);
  return (
    <OptionCardContainer right={right} top={top}>
      {options?.map((data, index) => {
        return (
          <OptionNameContainer
            onClick={(e) => {
              e.stopPropagation();
              onClickOption(data, additionalData);
            }}
          >
            <OptionIcons>
              <DynamicSVGIcon iconUrl={data?.iconUrl} width="1.5rem" />
            </OptionIcons>
            <OptionName>{data?.label}</OptionName>
          </OptionNameContainer>
        );
      })}
    </OptionCardContainer>
  );
};
export default OptionCard;
