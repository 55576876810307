/**
 * action js
 *
 * it will have all the project related actions
 *
 * */
import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  addProject: ['payload'],
  addProjectStart: ['payload'],
  addProjectSuccess: ['payload'],
  addProjectError: ['payload'],
  resetState: ['payload'],
  //
  //
  userList: ['payload'],
  filteredUserList: ['payload'],
  userListStart: ['payload'],
  userListSuccess: ['payload'],
  userListFailure: ['payload'],
  filteredUserListSuccess: ['payload'],
  //

  editProject: ['payload'],
  editProjectStart: ['payload'],
  editProjectSuccess: ['payload'],
  editProjectFailure: ['payload'],
  editProjectReset: ['payload'],
});
export { Types as AddProjectsTypes, Creators as AddProjectsCreators };
