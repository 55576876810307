/**
 * reducer js
 *
 * it will have all the event related reducers
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createReducer } from 'reduxsauce';
import { EventTypes } from './action';

const INITIAL_STATE = {
  error: false,
  loading: true,
  eventLoading: false,
  assetsLoading: false,
  eventData: [],
  assetsData: [],
  editEvent: false,
  viewEventData: {},
  assetMeta: {},
};
// <---------------reducer for get event from ca;endar----------------->
const getEventStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
  };
};
const getEventSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    eventData: data?.data,
  };
};
const getEventError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
  };
};
// <---------------reducer to edit event from calendar----------------->
const editEventStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    editEvent: false,
  };
};
const editEventSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    error: false,
    loading: false,
    editEvent: true,
    viewEventData: data?.data,
  };
};
const editEventError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    editEvent: false,
    errorMessage,
  };
};

// <---------------reducer for getting assets under event----------------->
const getAssetsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    assetsLoading: true,
  };
};
const getAssetsSuccess = (state = INITIAL_STATE, action) => {
  let assets = [];
  const { data, meta, event_id } = action.payload;
  assets = data?.data;
  return {
    ...state,
    error: false,
    loading: false,
    assetsLoading: false,
    assetsData: {
      ...state.assetsData,
      [event_id]: assets,
    },
    assetMeta: {
      ...state.projectListHeader,
      [event_id]: meta,
    },
  };
};
const getAssetsError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    assetsLoading: false,
    errorMessage,
  };
};
const resetStateReduer = (state = INITIAL_STATE, action) => {
  return {
    postsLoading: true,
    assetsData: [],
    eventData: [],
  };
};
const HANDLERS = {
  [EventTypes.GET_EVENT_START]: getEventStart,
  [EventTypes.GET_EVENT_SUCCESS]: getEventSuccess,
  [EventTypes.GET_EVENT_ERROR]: getEventError,
  //
  [EventTypes.EDIT_EVENT_START]: editEventStart,
  [EventTypes.EDIT_EVENT_SUCCESS]: editEventSuccess,
  [EventTypes.EDIT_EVENT_ERROR]: editEventError,
  //
  [EventTypes.GET_ASSETS_START]: getAssetsStart,
  [EventTypes.GET_ASSETS_SUCCESS]: getAssetsSuccess,
  [EventTypes.GET_ASSETS_ERROR]: getAssetsError,
  //
  [EventTypes.RESET_STATE]: resetStateReduer,
};
export const EventReducers = createReducer(INITIAL_STATE, HANDLERS);
