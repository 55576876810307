import { call, put } from 'redux-saga/effects';
import {
  ApiService,
  ApiServiceMultipart,
} from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { loggedUserCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';

// <---------------saga for get user's details ----------------->
export function* getUsersSaga(action) {
  try {
    yield put(loggedUserCreators.getUserDetailsStart());
    const response = yield call(ApiService.get, apiEndPoints.userDetails, {});
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;

      yield put(loggedUserCreators.getUserDetailsSuccess({ userData: data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(loggedUserCreators.getUserDetailsFailure(payload));
  }
}
// <---------------saga for Edit users----------------->
export function* updateUserDetailsSaga(action) {
  try {
    const { formData } = action.payload;
    yield put(loggedUserCreators.updateUserDetailsStart());
    const response = yield call(
      ApiServiceMultipart.post,
      apiEndPoints.editUSer,
      formData
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;

      SuccessToast(
        STRINGS['ENGLISH'].USER_UPDATED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );

      yield put(loggedUserCreators.getUserDetails());
      // loggedUserCreators.updateUserDetailsSuccess({ userData: data, update: update })
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(loggedUserCreators.updateUserDetailsFailure(payload));
  }
}
