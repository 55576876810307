import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getUserDetails: ['payload'],
  getUserDetailsStart: ['payload'],
  getUserDetailsSuccess: ['payload'],
  getUserDetailsFailure: ['payload'],
  backAction: ['payload'],
  //
  updateUserDetails: ['payload'],
  updateUserDetailsStart: ['payload'],
  updateUserDetailsSuccess: ['payload'],
  updateUserDetailsFailure: ['payload'],
});

export { Types as loggedUserTypes, Creators as loggedUserCreators };
