import React, { useEffect } from 'react';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { Col, Label, Row } from '../CalendarModal/CalendarModal.Styles';
import { TextArea } from '../../constants/GlobalStyles.Styles';
import {
  CustomDatePicker,
  FormContainer,
  DateWrapper,
  Wrapper,
  AddWrapper,
} from './AddBrandForm.Styles';
import { STRINGS } from '../../constants/labels';
import { SOCIALMEDIA, SOCIAL_MEDIA } from '../../constants/constant';
import FormatLabel from '../Dropdown/FormatLabel.View';
import FormatSocialMediaLabel from '../Dropdown/FormatSocialMediaLabel.View';
import { multiSelectDropwdownStyles } from '../DropdownStyles/MultiselectDropdownStyles';
import { generateDropdownStyles } from '../DropdownStyles/ProfileIconDropdownStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AddProjectsCreators } from '../../pages/addProjects/store/action';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const AddProjectform = ({
  projectType,
  fontSize,
  formValues,
  setFormValues,
  projectTypes,
  base_company_id,
  edit = false,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  const params = useParams();
  const { company_id } = params;
  const { userList, filteredUserData } = useSelector(
    (state) => state.addProject
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AddProjectsCreators.filteredUserList({
        companyId: company_id || base_company_id,
      })
    );

    dispatch(AddProjectsCreators.userList({ companyId: '' }));
    if (projectTypes) {
      setFormValues({
        ...formValues,
        project_type: projectTypes,
        // ['status']: 'Active',
        // ['type']: 'client',
      });
    }
  }, [company_id]);

  const handleChangeInput = (fieldName, value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  //STYLES
  //SELECT DROPDOWN
  const dropdownStyles = generateDropdownStyles();
  const agencyTeamStyles = multiSelectDropwdownStyles({
    optionSelected: formValues?.agency_users?.length > 0,
  });
  const clientTeamStyles = multiSelectDropwdownStyles({
    optionSelected: formValues?.client_users?.length > 0,
  });

  const socialMediaStyles = multiSelectDropwdownStyles({
    optionSelected: formValues?.socialMediaLinks?.length > 0,
    socialMedia: true,
  });

  // Function to add a new social media link
  const addSMLink = () => {
    setFormValues({
      ...formValues,
      socialMediaLinks: [
        ...formValues.socialMediaLinks,
        { name: '', link: '' },
      ],
    });
  };

  // Function to update a social media link
  const updateSMLink = (index, data, key) => {
    const updatedLinks = [...formValues.socialMediaLinks];
    updatedLinks[index] = { ...updatedLinks[index], [key]: data };

    // Check if the name property is null, then set the link property to null
    if (key === 'name' && data === null) {
      updatedLinks[index]['link'] = null;
    }

    setFormValues({ ...formValues, socialMediaLinks: updatedLinks });
  };

  return (
    <FormContainer marginBottom={'20px'}>
      <Col width={'420px'}>
        <NameWrapper fontSize={'17px'} marginBottom={'10px'}>
          {edit ? strings.UPDATE_NEW_PROJECT : strings.CREATE_NEW_PROJECT}
        </NameWrapper>
        <RoleWrapper>{strings.ENTER_PROJECT_DETAILS}</RoleWrapper>
      </Col>
      <Wrapper>
        <Label fontSize={fontSize}>{strings.PROJECT_NAME}</Label>
        <Input
          width={'420px'}
          type="text"
          placeholder={strings.ENTER_PROJECT_NAME}
          onChange={(event) => {
            handleChangeInput('project_name', event.target.value);
          }}
          value={formValues?.project_name}
        />

        <Label fontSize={fontSize}>{strings.ENTER_WEBSITE_URL}</Label>
        <Input
          width={'420px'}
          type="text"
          placeholder={strings.ENTER_WEBSITE_URL}
          onChange={(event) => {
            handleChangeInput('website_url', event.target.value);
          }}
          value={formValues?.website_url}
        />

        <Label fontSize={fontSize}>{strings.AGENCY_SPOC}</Label>
        <Select
          value={
            userList?.filter(
              (data) => data?.value === formValues?.base_company_spoc_username
            )[0]
          }
          options={userList}
          onChange={(option) => {
            handleChangeInput('base_company_spoc_username', option?.value);
          }}
          formatOptionLabel={FormatLabel}
          placeholder={strings.SELECT_SPOC}
          styles={dropdownStyles}
        />

        <Label fontSize={fontSize}>{strings.AGENCY_PROJECT_LEAD}</Label>
        <Select
          value={
            userList?.filter(
              (data) =>
                data?.value === formValues?.base_company_project_lead_username
            )[0]
          }
          options={userList}
          onChange={(option) => {
            handleChangeInput(
              'base_company_project_lead_username',
              option?.value
            );
          }}
          formatOptionLabel={FormatLabel}
          placeholder={strings.SELECT_PROJECT_LEAD}
          styles={dropdownStyles}
        />

        <Label width={'100%'} fontSize={fontSize}>
          {strings.AGENCY_TEAM_MEMBERS}
        </Label>
        <Select
          options={userList}
          value={userList?.filter((data) =>
            formValues?.agency_users.includes(data?.value)
          )}
          onChange={(selectedOptions) => {
            const dateForUpdate = selectedOptions.map((item) => item.value);
            handleChangeInput('agency_users', dateForUpdate);
          }}
          getOptionValue={(option) => option.label}
          formatOptionLabel={FormatLabel}
          styles={agencyTeamStyles}
          placeholder={'+'}
          isSearchable={true}
          isMulti
        />

        <Label fontSize={fontSize}>{strings.CLENT_SPOC}</Label>
        <Select
          value={
            filteredUserData?.filter(
              (data) => data?.value === formValues?.client_spoc_username
            )[0]
          }
          onChange={(option) => {
            handleChangeInput('client_spoc_username', option.value);
          }}
          options={filteredUserData}
          formatOptionLabel={FormatLabel}
          styles={dropdownStyles}
          placeholder={strings.SELECT_SPOC}
        />

        <Label width={'100%'} fontSize={fontSize}>
          {strings.CLIENT_TEAM_MEMBERS}
        </Label>
        <Select
          value={filteredUserData?.filter((data) =>
            formValues?.client_users.includes(data?.value)
          )}
          onChange={(selectedOptions) => {
            const dateForUpdate = selectedOptions.map((item) => item.value);
            handleChangeInput('client_users', dateForUpdate);
          }}
          options={filteredUserData}
          getOptionValue={(option) => option.label}
          formatOptionLabel={FormatLabel}
          styles={clientTeamStyles}
          placeholder={'+'}
          isSearchable={false}
          isMulti
        />

        {projectTypes === SOCIALMEDIA && (
          <>
            <Label fontSize={fontSize}>{strings.SOCIAL_MEDIA_LINK}</Label>
            <AddWrapper onClick={addSMLink}>{'+'}</AddWrapper>
            <Col spaceBetween width={'400px'}>
              {formValues?.socialMediaLinks?.map((socialMedia, index) => (
                <Row spaceBetween key={index}>
                  <div style={{ width: '180px' }}>
                    <Select
                      value={SOCIAL_MEDIA.find(
                        (option) => option.value === socialMedia.name
                      )}
                      onChange={(option) => updateSMLink(index, option, 'name')}
                      options={SOCIAL_MEDIA}
                      placeholder={strings.SELECT_SOCIAL_MEDIA}
                      formatOptionLabel={FormatSocialMediaLabel}
                      styles={socialMediaStyles}
                      isClearable={true}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <Input
                    width={'80%'}
                    type="text"
                    placeholder={strings.ENTER_SOCIAL_MEDIA}
                    value={socialMedia.link || ''}
                    onChange={(event) =>
                      updateSMLink(index, event.target.value, 'link')
                    }
                  />
                </Row>
              ))}
            </Col>
          </>
        )}

        <Label fontSize={fontSize}>{strings.START_DATE}</Label>
        <DateWrapper>
          <CustomDatePicker
            wrapperClassName="DataPicker"
            placeholderText={'DD-MM-YYYY'}
            selected={
              formValues?.started_at && new Date(formValues?.started_at)
            }
            onChange={(option) => {
              handleChangeInput(
                'started_at',
                moment(option).format('YYYY-MM-DD HH:mm:ss')
              );
            }}
          />
        </DateWrapper>

        <Label fontSize={fontSize}>{strings.DESCRIPTION}</Label>
        <TextArea
          placeholder={strings.ENTER_DESCRIPTION}
          width={'420px'}
          fontSize={'13px'}
          noBgColor
          height={'100px'}
          onChange={(event) => {
            handleChangeInput('description', event.target.value);
          }}
          value={formValues?.description}
        />
      </Wrapper>
    </FormContainer>
  );
};

export default AddProjectform;
