import styled, { css, keyframes } from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const CommentsWrapper = styled.div`
  position: fixed;
  z-index: 100;
  width: 280px;
  height: 170px;
  border-radius: 8px;
  background-color: rgb(18 18 18);
  box-shadow: rgba(0, 0, 0, 0.027) 0px 1.2px 2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4.8px, rgba(0, 0, 0, 0.05) 0px 5.6px 9px,
    rgba(0, 0, 0, 0.06) 0px 10.1px 16.1px, rgba(0, 0, 0, 0.07) 0px 18.8px 30.1px,
    rgba(0, 0, 0, 0.1) 0px 45px 72px;
  pointer-events: auto;

  ${({ x, y }) => css`
    transform: translate(${x}px, ${y}px);
  `}
`;

export const CommentsInner = styled.div`
  position: relative;
  padding: 14px;
`;

export const TextDiv = styled.textarea`
  display: block;
  position: relative;
  z-index: 0;
  inset: 0px;
  margin: 0px;
  padding: 0px;
  height: 100px;
  border: 0px;
  outline: 0px;
  width: 100%;
  line-height: 1.5;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 15px;
  background: transparent;
  transition: all 0.2s ease 0s;
  color: ${COLORS.WHITE};
  font-family: PoppinsRegular;
  resize: none;
`;

export const ButtonRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const SaveButton = styled.button`
  // background-color: rgb(229, 228, 236);
  border: 0;
  color: rgb(255, 255, 255);
  background-color: rgb(101, 82, 255);
  width: 100px;
  height: 30px;
  border-radius: 8px;
  letter-spacing: 0.1px;
  line-height: 1.5;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:hover {
    background-color: rgb(
      90,
      82,
      220
    ); /* Adjust the color as per your preference */
  }
`;

export const DeleteButton = styled.button`
  border: 0;
  width: 100px;
  height: 30px;
  border-radius: 8px;
  letter-spacing: 0.1px;
  line-height: 1.5;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:hover {
    background-color: lightgrey; /* Adjust the color as per your preference */
  }
`;
