import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardsContainer } from '../dashboards/adminDashboard.Styles';
import { STRINGS } from '../../constants/labels';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from '../../components/CalendarModal/CalendarModal.Styles';
import ProjectCards from '../../components/Cards/ProjectCards';
import { Creators } from './store/action';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import { ModalHeading, ModalSubHeading } from '../brands/Brand.styles';
import UniversalModal from '../../components/modals/UniversalModal';

const ProjectsCards = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  //navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalOpen,setIsopenModal] = useState(false)
  const { projectsData } = useSelector((state) => state.project);
  // const { userData } = useSelector((state) => state.loggedinUser);
  /**
   * Navigates to the specified URL using the navigate function.
   *
   * @param {string} data.to - The URL to navigate to.
   */
  const onCardClick = (data) => {
    const { project_id, project_name } = data;
    navigate(`/projects/${project_id}/table?project_name=${project_name}`);
    // navigate(data.to);
  };

  const onClickOption = (data, additionalData) => {
    const { key } = data;
    const { project_id } = additionalData;
    switch (key) {
      case 'delete':
        setIsModalOpen({ ...isModalOpen, deleteModal: true, id: project_id });
        break;
      case 'edit':
        // setEditModal(true);
        // setIsSubHeaderButtonClicked(true);
        // setBrandValues({
        //   company_id: company_id,
        //   company_name: company_name,
        //   start_date: moment(created_at).format('YYYY-MM-DD HH:mm:ss'),
        //   account_manager_username: account_manager?.username,
        //   description: description,
        //   toggle: false,
        //   disabled: false,
        // });
        // if (company_logo) {
        //   setImageData(company_logo);
        // }
        break;
      default:
        break;
    }
  };

  const onDelete = () => {
    dispatch(
      Creators.deleteProject({
        pageNumber: 1,
        input: '',
        project_id: isModalOpen.id,
      })
    );
    setIsModalOpen({ ...isModalOpen, deleteModal: false, id: null });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    dispatch(Creators.projectsList({}));
  }, []);
  //
  return (
    <>
      <SubHeader isBack={false} title={strings.PROJECTS} btnFalse={false} />
      <Col
        width={'100%'}
        marginLeft={'0px'}
        justify={'flex-start'}
        align={'flex-start'}
      >
        <CardsContainer>
          {projectsData?.map((data, index) => {
            return (
              <div key={index}>
                <ProjectCards
                  data={data}
                  onCardClick={onCardClick}
                  onClickOption={onClickOption}
                />
              </div>
            );
          })}
        </CardsContainer>
      </Col>
      <UniversalModal
        isOpenModal={isModalOpen.deleteModal}
        leftText={'Cancel'}
        rightText={'Delete'}
        borderRadius={'14px'}
        btn={true}
        onleftbtnClick={() =>
          setIsModalOpen({ ...isModalOpen, deleteModal: false, id: null })
        }
        onrightbtnClick={onDelete}
      >
        <div
          style={{
            width: '360px',
            height: '160px',
          }}
        >
          <ModalHeading>Delete Project?</ModalHeading>
          <ModalSubHeading>
            You are about to delete this project
          </ModalSubHeading>
        </div>
      </UniversalModal>
    </>
  );
};

export default ProjectsCards;
