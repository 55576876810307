import React from 'react';
import { Col, Row } from '../CalendarModal/CalendarModal.Styles';
import {
  ProfileDiv,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { UserIconText, CardUsername, UserCardUsername } from './Cards.Styles';
import ProjectsBrandCard from '../../assets/icons/Projects_Brand_Card.svg';
import DynamicSVGIcon from '../DynamicSVGIcon';
import DropDownIcon from '../../assets/icons/DropDownIcon.svg';
import {
  FIND_COLOR_RANGE,
  GET_INITIALS_FROM_NAME,
} from '../../constants/functions';
import { USERS_TYPES_PROFILE_HEADER } from '../../constants/constant';

const UserCard = ({
  profilePic,
  username,
  rightDiv,
  marginBottom,
  usernameFontSize,
  Projects,
  account_manager,
  Project = false,
  height,
  width,
  showRole,
  showDropDown,
  usernameColor,
}) => {
  return (
    <Row
      spaceBetween
      alignItems={showDropDown ? 'center' : 'flex-end'}
      marginBottom={marginBottom}
      marginTop={'5px'}
    >
      <Row padding={'3px'} flexStart center>
        <div>
          <ProfileDiv
            img={account_manager?.profile_pic_url || profilePic}
            height={height}
            width={width}
            bg={FIND_COLOR_RANGE(
              account_manager?.name,
              account_manager?.profile_pic_url
            )}
          >
            {!profilePic && !account_manager?.profile_pic_url && (
              <UserIconText>
                {GET_INITIALS_FROM_NAME(account_manager?.name || username)}
              </UserIconText>
            )}
          </ProfileDiv>
        </div>

        <UserCardUsername
          fontSize={usernameFontSize}
          noPurpleText
          usernameColor={usernameColor}
        >
          {account_manager?.name || username || 'name'}
          {showRole && (
            <RoleWrapper>{USERS_TYPES_PROFILE_HEADER[showRole]}</RoleWrapper>
          )}
        </UserCardUsername>
      </Row>
      <Row padding={'3px'} flexEnd>
        {Project && <DynamicSVGIcon iconUrl={ProjectsBrandCard} />}
        {showDropDown && <DynamicSVGIcon rotate="-90" iconUrl={DropDownIcon} />}
        <RoleWrapper color="#FFFFFF">
          {Projects && (Projects || 12)} {}
          {Project ? 'Projects' : rightDiv}
        </RoleWrapper>
      </Row>
    </Row>
  );
};

export default UserCard;
