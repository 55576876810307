import React from 'react';
import styled, { css } from 'styled-components';

export const PinButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(101, 82, 255);
  color: white;
  border: 1px solid rgb(101, 82, 255);
  height: 30px;
  width: 30px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  transition: opacity 0.2s ease 0s, background-color 0.2s ease 0s;

  ${({ x, y }) => css`
    transform: translate(${x}px, ${y}px);
  `}
`;
