import {
  CardCantainer,
  CardIcon,
  CardIconText,
  CardIconWrapper,
  IconAndText,
  KababIcons,
} from './Cards.Styles';
import BlueKabab from '../../assets/icons/BlueKabab.svg';
import DynamicSVGIcon from '../DynamicSVGIcon';
import UserCard from './UserCard.View';
import { useState } from 'react';
import OptionCard from '../options/OptionCard';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import {
  BrandCardBackgroundBackContainer,
  BrandCardBackgroundContainer,
  BrandCardNameRowContainer,
  BrandNameWrapper,
} from './BrandCards.Styles';
import {
  FIND_COLOR_RANGE,
  GET_INITIALS_FROM_NAME,
} from '../../constants/functions';

const BrandCards = ({ data, onCardClick, onClickOption = () => {} }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  return (
    <CardCantainer
      borderRadiusAll
      onClick={() => {
        onCardClick(data);
      }}
    >
      <IconAndText>
        <BrandCardBackgroundBackContainer>
          <BrandCardBackgroundContainer bg={data?.bg_color?.color_hex}>
            <CardIconWrapper
              background={FIND_COLOR_RANGE(
                data?.company_name,
                data?.company_logo
              )}
            >
              {data?.company_logo ? (
                <CardIcon src={data?.company_logo} />
              ) : (
                <CardIconText>
                  {GET_INITIALS_FROM_NAME(data?.company_name)}
                </CardIconText>
              )}
            </CardIconWrapper>
          </BrandCardBackgroundContainer>
        </BrandCardBackgroundBackContainer>
        <BrandCardNameRowContainer>
          <BrandNameWrapper>{data?.company_name || '-'}</BrandNameWrapper>
          <KababIcons
            onClick={(e) => {
              e.stopPropagation();
              setIsOptionsOpen((prev) => !prev);
            }}
          >
            {isOptionsOpen && (
              <UseClickOutside
                onClickOutside={() => {
                  setIsOptionsOpen(false);
                }}
              >
                <OptionCard
                  top={'20px'}
                  right={'0px'}
                  onClickOption={onClickOption}
                  additionalData={data}
                />
              </UseClickOutside>
            )}
            <DynamicSVGIcon iconUrl={BlueKabab} width="0.18rem" />
          </KababIcons>
        </BrandCardNameRowContainer>
        <UserCard
          Projects={data?.projects_count}
          account_manager={data?.account_manager}
          Project={true}
        />
      </IconAndText>
    </CardCantainer>
  );
};
export default BrandCards;
