import { useEffect, useState } from 'react';
import { BrandCardsContainer } from '../dashboards/adminDashboard.Styles';
import { BrandContainer, ModalHeading, ModalSubHeading } from './Brand.styles';
import { useDispatch, useSelector } from 'react-redux';
import BrandCards from '../../components/Cards/BrandCards.View';
import { BrandCreators } from './store/action';
import UniversalModal from '../../components/modals/UniversalModal';
import { useNavigate } from 'react-router-dom';
// import { Row } from '../../components/CalendarModal/CalendarModal.Styles';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import AddBrandform from '../../components/form/AddBrandForm.View';
import { STRINGS } from '../../constants/labels';
import { COLORS, TOAST_POSITION } from '../../constants/BaseStyles';
import { ErrorToast } from '../../services/toast/toast';
import {
  ADD_BRAND_ERROR,
  CLIENT_TYPE_USER_LIST,
  INTERNAL_TEAM_MEMBER_LIST,
} from '../../constants/constant';
import moment from 'moment';
import Loading from '../../components/loader/Loader.View';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';

const Brand = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brandListData, loadingbrandLoading } = useSelector(
    (state) => state.brand
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isOpenModal, setIsopenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubHeaderButtonClicked, setIsSubHeaderButtonClicked] =
    useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const { userData } = useSelector((state) => state.loggedinUser);

  const [brandValues, setBrandValues] = useState({
    // company_id: '',
    company_name: '',
    start_date: '',
    account_manager_username: '',
    description: '',
    company_logo: '',
    toggle: false,
    disabled: false,
  });
  const [imageData, setImageData] = useState('');
  //
  useEffect(() => {
    dispatch(
      BrandCreators.brandList({
        pageNumber: 1,
        sortParams: {},
        input: '',
      })
    );
  }, []);

  useEffect(() => {
    if (userData?.type) {
      if (CLIENT_TYPE_USER_LIST.includes(userData?.type)) {
        navigate('/projects');
      }
    } else if (userData?.user_organisation_role) {
      if (
        CLIENT_TYPE_USER_LIST.includes(userData?.user_organisation_role) ||
        INTERNAL_TEAM_MEMBER_LIST.includes(userData?.user_organisation_role)
      ) {
        navigate('/projects');
      }
    }
  }, [navigate, userData]);
  //
  const onCardClick = (data) => {
    const { company_id, company_name } = data;
    navigate(`/brands/${company_id}?company_name=${company_name}`);
  };
  //
  const onClickOption = (data, additionalData) => {
    const { key } = data;
    const {
      company_id,
      company_name,
      created_at,
      description,
      company_logo,
      account_manager,
    } = additionalData;
    switch (key) {
      case 'delete':
        setIsModalOpen({ ...isModalOpen, deleteModal: true, id: company_id });
        break;
      case 'edit':
        setEditModal(true);
        setIsSubHeaderButtonClicked(true);
        setIsEditMode(true);
        setBrandValues({
          company_id: company_id,
          company_name: company_name,
          start_date: moment(created_at).format('YYYY-MM-DD HH:mm:ss'),
          account_manager_username: account_manager?.username,
          description: description,
          toggle: false,
          disabled: false,
        });
        if (company_logo) {
          setImageData(company_logo);
        }
        break;
      default:
        break;
    }
  };
  //
  const onDelete = () => {
    dispatch(BrandCreators.deleteBrandList({ companyId: isModalOpen.id }));
    setIsModalOpen({ ...isModalOpen, deleteModal: false, id: null });
  };

  const closeModal = () => {
    setIsopenModal(false);
    setIsSubHeaderButtonClicked(false);
    setBrandValues({
      company_name: '',
      start_date: '',
      account_manager_username: '',
      description: '',
      company_logo: '',
      toggle: false,
      disabled: false,
    });
    setImageData('');
    setEditModal(false);
    setIsEditMode(false);
  };

  const validateAddBrand = (event) => {
    // let validationMessage = '';
    let valid = false;
    //
    Object.keys(brandValues).forEach((field) => {
      if (brandValues[field]?.length === 0) {
        // Check if length is 0
        ErrorToast(
          ADD_BRAND_ERROR[field],
          TOAST_POSITION.TOP_CENTER,
          `error_${field}`
        );
        valid = true; // Set to true if any field is empty
      }
    });
    //
    const formData = new FormData();

    const isRequiredField = (key) =>
      ![!editModal && 'company_id', 'toggle', 'disabled'].includes(key);

    for (const [key, value] of Object.entries(brandValues)) {
      if (isRequiredField(key)) {
        formData.append(key, value);
      }
    }

    if (editModal && !valid) {
      dispatch(BrandCreators.editBrand({ formData }));
      closeModal();
    } else if (!valid) {
      dispatch(BrandCreators.addBrand({ formData }));
      closeModal();
    }
  };

  return (
    <>
      <SubHeader
        isBack={false}
        title={strings.BRANDS}
        titleColor={COLORS.WHITE}
        isSubHeaderButtonClicked={isSubHeaderButtonClicked}
        setIsSubHeaderButtonClicked={setIsSubHeaderButtonClicked}
      />
      {loadingbrandLoading && <Loading open={loadingbrandLoading} />}
      <BrandContainer>
        <BrandCardsContainer>
          {brandListData?.map((data, index) => {
            return (
              <div key={index}>
                <BrandCards
                  data={data}
                  onCardClick={onCardClick}
                  onClickOption={onClickOption}
                />
              </div>
            );
          })}
          {brandListData?.length === 0 ? (
            <>
              <Empty
                heading={strings.NOT_FOUND_BRAND}
                // hubheading1={strings.WANT_TO_ADD_PROJECT}
                height={'85vh'}
              />
            </>
          ) : null}
        </BrandCardsContainer>
        {/* <----------------------delete the brand---------------------> */}
        <UniversalModal
          isOpenModal={isModalOpen.deleteModal}
          leftText={'Cancel'}
          rightText={'Delete'}
          borderRadius={'14px'}
          btn={true}
          onleftbtnClick={() =>
            setIsModalOpen({ ...isModalOpen, deleteModal: false, id: null })
          }
          onrightbtnClick={onDelete}
        >
          <div
            style={{
              width: '360px',
              height: '160px',
            }}
          >
            <ModalHeading>Delete Brand?</ModalHeading>
            <ModalSubHeading style={{ fontSize: '14px' }}>
              You are about to delete this brand
            </ModalSubHeading>
          </div>
        </UniversalModal>
      </BrandContainer>
      {/* <----------------edit and ad the brand------------------> */}
      <UniversalModal
        height={'98vh'}
        isOpenModal={isSubHeaderButtonClicked}
        btn={true}
        leftText={strings.CANCEL}
        rightBtnWidth={isEditMode ? '90px' : '120px'}
        rightText={isEditMode ? strings.UPDATE : strings.CREATE_BRAND}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={validateAddBrand}
        onleftbtnClick={closeModal}
      >
        <AddBrandform
          fontSize={'13px'}
          brandValues={brandValues}
          setBrandValues={setBrandValues}
          fileTypeError={fileTypeError}
          setFileTypeError={setFileTypeError}
          imageData={imageData}
          setImageData={setImageData}
          editMode={isEditMode}
        />
      </UniversalModal>
    </>
  );
};
export default Brand;
