import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import { STRINGS } from '../../constants/labels';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store/action';
import { WebsitePagesContainer } from './Project.Styles';
import UniversalModal from '../../components/modals/UniversalModal';
import { ModalHeading, ModalSubHeading } from '../brands/Brand.styles';
import Loading from '../../components/loader/Loader.View';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';
import WebsiteCards from '../../components/Cards/WebsiteCards.View';

const WebsitePages = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const param = useParams();
  const { project_id, project_path } = param;
  //
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get('project_name');
  // const projectType = searchParams.get('project_type');
  // const folderId = searchParams.get('folder_id');
  //
  const { webPageUrlList, addWebsiteUrlLoading, projectDetails } = useSelector(
    (state) => state.project
  );
  const [isModalOpen, setIsModalOpen] = useState({
    deleteModal: false,
    projectId: null,
    webpageId: null,
    versionId: null,
  });
  //
  useEffect(() => {
    dispatch(Creators.getWebPageList({ page: 1, projectID: project_id }));
  }, []);

  const deltefile = (data) => {
    const { webpage_id, version_id } = data;
    setIsModalOpen({
      ...isModalOpen,
      deleteModal: true,
      projectId: project_id,
      webpageId: webpage_id,
      versionId: version_id,
    });
  };
  const onClickAsset = (data) => {
    const { version_id, webpage_id } = data;
    navigate(
      `/${project_path}/${project_id}/website_review?webpage_id=${webpage_id}&version_id=${version_id}`
    );
  };
  const ondeleteCancel = () => {
    setIsModalOpen({
      ...isModalOpen,
      deleteModal: false,
      projectId: null,
      webpageId: null,
      versionId: null,
    });
  };
  const deletefolder = () => {
    dispatch(
      Creators.deleteWebPage({
        webpageId: isModalOpen.webpageId,
        projectId: isModalOpen.projectId,
      })
    );
    ondeleteCancel();
  };
  return (
    <>
      <SubHeader
        buttonDisplay={'none'}
        title={projectDetails[project_id]?.project_name || projectName}
        customBackFunction={() => {
          const url = `/brands/${projectDetails[project_id]?.base_company_id}?company_name=${projectDetails[project_id]?.base_company_name}`;
          navigate(url);
        }}
      />
      {addWebsiteUrlLoading && <Loading open={addWebsiteUrlLoading} />}
      {webPageUrlList[project_id]?.length ? (
        <WebsitePagesContainer>
          {webPageUrlList[project_id]?.map((data, index) => {
            return (
              <>
                <WebsiteCards
                  data={data}
                  index={index}
                  deltefile={deltefile}
                  onclick={onClickAsset}
                />
              </>
            );
          })}
        </WebsitePagesContainer>
      ) : (
        <Empty
          heading={strings.NOT_FOUND_PAGES}
          // hubheading1={strings.WANT_TO_ADD_PROJECT}
          height={'85vh'}
        />
      )}

      <UniversalModal
        isOpenModal={isModalOpen.deleteModal}
        leftText={'Cancel'}
        rightText={'Delete'}
        borderRadius={'14px'}
        btn={true}
        onleftbtnClick={ondeleteCancel}
        onrightbtnClick={deletefolder}
      >
        <div
          style={{
            width: '360px',
            height: '160px',
          }}
        >
          <ModalHeading>Delete fiels?</ModalHeading>
          <ModalSubHeading>You are about to delete this file</ModalSubHeading>
        </div>
      </UniversalModal>
    </>
  );
};
export default WebsitePages;
