import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS, CONTAINER_BORDER_RADIUS } from '../../constants/BaseStyles';
import { device } from '../../constants/device';
import logo from '../../assets/images/logo.png';

export const LayOutContainer = styled.div``;
export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 0px 0px ${COLORS.PRIMARY_SHADOW_COLOR};
  border-bottom: 2px solid ${COLORS.SECONDARY_BORDER_COLOR};

  @media print {
    display: none;
  }

  @media ${device.tablet} {
    padding: 0px 0px 0px 10px;
  }
`;

export const HamburgerContainer = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;

export const HamburgerImage = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const MobileView = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 110%;
  max-height: calc(100vh + 30px);
  width: calc(100vw);
  backdrop-filter: brightness(0.5);
  z-index: 103;
  // overflow-y: auto;
`;

export const SideBarMobileView = styled.div`
  display: none;
  position: absolute;
  top: 0px;
  height: calc(100vh);
  width: 250px;
  background-color: ${COLORS.PRIMARY_BACKGROUND_COLOR_ALT};
  box-shadow: 0px 0px 0px ${COLORS.PRIMARY_SHADOW_COLOR};
  border-right: 2px solid ${COLORS.SECONDARY_BORDER_COLOR};
  padding: 12px 10px;
  border-radius: 0px 10px 10px 0px;
  backdrop-filter: blur(3px);

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

  padding-bottom: 50px;

  @media ${device.tablet} {
    display: flex;
    /* height: calc(100vh - 9%); */
    padding-bottom: 10px;
    height: 100%;
    max-height: 100vh;
  }
`;

export const SideBar = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  width: 70px;
  padding-top: 20px;
  gap: 8px;
  flex-direction: column;
  height: calc(100vh - 77px);
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 2px 10px ${COLORS.PRIMARY_SHADOW_COLOR};
  border-right: 2px solid ${COLORS.SECONDARY_BORDER_COLOR};
  z-index: 1;
  @media print {
    display: none;
  }

  @media ${device.tablet} {
    display: none;
  }
`;
export const SearchInput = styled.input`
  display: flex;
  position: relative;
  background-color: inherit;
  color: ${COLORS.TEXT_GREY};
  border: none;
  margin: 5px;
  outline: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;
export const Input = styled.input`
  height: 35px;
  width: ${({ width }) => (width ? width : '100%')};
  border-radius: 3px;
  margin: 5px;
  border: ${({ disabled }) =>
    disabled ? 'none' : `1px solid ${COLORS.OVERLAY_GREY}`};
  background-color: ${({ disabled }) =>
    disabled ? COLORS.PRIMARY_BLACK : COLORS.PRIMARY_GREY};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
  // background-color: transparent;
  padding: 5px;
  // background-color: ${COLORS.OVERLAY_GREY};
  color: ${COLORS.TEXT_MEDIUM_GREY};
`;
export const UniversalSearchIcon = styled.img`
  width: 16px;
  height: 17px;

  position: absolute;
  top: 16px;
  right: 10px;
`;
export const ProfileAndSettings = styled.div`
  display: flex;
  gap: 40px;
  position: relative;
  @media ${device.tablet} {
    gap: 12px;
    align-items: flex-end;
    z-index: 30;
  }
`;

export const NotificationPanel = styled.div`
  position: absolute;
  top: 40px;
  right: -140px;
  z-index: 102;
  /* height: 600px; */
  @media ${device.tablet} {
    top: 49px;
    right: -65px;
  }
`;

export const ProfileDrop = styled.div`
  position: absolute;
  top: 40px;
  right: 18px;
  z-index: 102;
`;

export const HelpPanel = styled.div`
  position: absolute;
  top: 40px;
  right: 18px;
  z-index: 102;
`;

export const SettingsPanel = styled.div`
  position: absolute;
  top: 40px;
  right: 18px;
  z-index: 102;
  @media ${device.tablet} {
    top: 57px;
    right: -1px;
  }
`;

export const ModuleNameContainer = styled.div`
  display: none;
  @media ${device.tablet} {
    flex: 0 1 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BackContainer = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
  }
`;

export const BackIcon = styled.img`
  width: 30px;
  transform: rotate(180deg);
`;

export const ModuleName = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    font-size: 16px;
    font-family: 'PoppinsRegular';
    color: ${COLORS.PRIMARY_COLOR};
  }
`;

export const ClintLogo = styled.div``;
export const Notification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const IconContainer = styled.div`
  cursor: pointer;
`;

export const SettingsIconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    display: none;
  }
`;

export const HelpIconContainer = styled.div`
  cursor: pointer;
  @media ${device.tablet} {
    display: none;
  }
`;

export const NotificationsImg = styled.img`
  height: 30px;
  // width: 25px;
`;
export const Settings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SettingsImg = styled.img`
  width: 30px;
`;
export const Help = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    display: none;
  }
`;
export const HelpImg = styled.img`
  height: 30px;
`;
export const Profile = styled.div`
  padding-right: 35px;
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    padding-right: 8px;
  }
`;

// <--------------------Sidebar---------------->
export const SidebarContainer = styled.div``;
export const SideBarLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
  cursor: pointer;
  width: 100%;
  padding: 6px;
  margin: 2px;
  border-radius: 5px;
  background-color: ${({ active }) => (active ? COLORS.ACTIVE_BG : 'initial')};
  color: ${({ active }) =>
    active ? COLORS.COMMENTS_BACKGROUND_COLOR : COLORS.TEXT_GREY};
`;
export const SidebarIconsContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-content: center;
  height: 100%;
  padding: 2px;
`;
export const SidebarIcons = styled.img`
  height: 25px;
  width: 25px;
`;
export const SidebarLinks = styled.div`
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  text-wrap: nowrap;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;
export const SidebarLogo = styled.img`
  width: ${({ width }) => (width ? width : '30px')};
  height: ${({ height }) => (height ? height : '30px')};
`;
export const LogoTextContainer = styled.div`
  font-family: 'PoppinsRegular';
  color: ${COLORS.PRIMARY_COLOR};
  font-size: 0.6rem;
  margin-top: 2px;
`;
export const Logo = styled(NavLink)`
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ExtraFeatures = styled.div``;

export const Menu = styled.div`
  max-height: calc(100vh - 300px);
  overflow: scroll;
`;

// export const NotificationCount = styled.div`
//   width: 20px;
//   height: 20px;
//   background-color: ${COLORS.PRIMARY_COLOR};
//   border-radius: 50%;
//   color: ${COLORS.PRIMARY_BACKGROUND_COLOR_ALT};
//   position: absolute;
//   bottom: 10px;
//   right: -10px;
//   font-size: 8px;
//   font-family: 'InterMedium';
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
export const Dashboard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  margin-right: 10px;
`;
export const GoBackSpan = styled.div`
  position: relative;
  padding-right: 10px;
  cursor: pointer;
  padding-top: 3px;
  z-index: 1;
`;
export const ThreeDot = styled.div`
  cursor: pointer;
  width: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};
`;

export const ProfileDiv = styled.div`
  height: ${({ height }) => (height ? height : '30px')};
  width: ${({ width }) => (width ? width : '30px')};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ bg }) => (bg ? bg : COLORS.BACKGROUND_OPTION_3)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ noMargin }) => (noMargin ? '0px' : '5px')};
  margin-left: ${({ stackedProfiles }) => (stackedProfiles ? '-15px' : '0px')};
  border: ${({ stackedProfiles }) =>
    stackedProfiles ? `1px solid ${COLORS.PRIMARY_BLACK}` : 'none'};
  color: ${COLORS.PRIMARY_BLACK};
  font-size: 0.8em;
  border-radius: 50%;
  cursor: pointer;
`;

export const NameWrapper = styled.div`
  color: ${COLORS.TEXT_SECONDARY_GREY};
  // text-align: center;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '13px')};
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;
export const RoleWrapper = styled.div`
  color: ${({ color }) => (color ? color : COLORS.TEXT_GREY)};
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'auto')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0')};
`;
export const Rolesd = styled.div`
  color: ${({ color }) => (color ? color : COLORS.TEXT_GREY)};
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'auto')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0')};
  margin-top: 10px;
`;

export const CaretUpDiv = styled.div`
  position: absolute;
  top: -9px;
  right: 7px;
  background-color: ${COLORS.PRIMARY_GREY};
`;
export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${COLORS.PRIMARY_BACKGROUND_COLOR};
  background-color: ${COLORS.PRIMARY_GREY};
`;

export const LogoDiv = styled.div`
  height: 32px;
  width: 32px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${logo});
  background-postion: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
export const NotificationContainer = styled.div`
  position: absolute;
  width: 400px;
  padding-bottom: 10px;
  top: 50px;
  right: -150px;
  background: ${COLORS.NOTIFICATION_BG} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px ${COLORS.TERTIARY_SHADOW_COLOR};
  border-radius: 14px;
  max-height: 80vh;
  overflow-y: auto;
`;
export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
`;
export const NotificationLabelAndCount = styled.div`
  display: flex;
  font-size: 0.8rem;
  font-family: PoppinsRegular;
  column-gap: 10px;
  align-items: center;
`;
export const NotificationLabel = styled.div`
  color: ${COLORS.WHITE};
`;
export const NotificationCount = styled.div`
  color: ${COLORS.NOTIFICATION_TIME_COLOR};
  background: ${COLORS.BACKGROUND_COLOR} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ClearAllDiv = styled.div`
  text-decoration: underline;
  letter-spacing: -0.06px;
  color: #00c281;
  cursor: pointer;
  font-family: PoppinsRegular;
  font-size: 0.7rem;
`;
export const NotificationBorderLine = styled.div`
  border: 1px solid #6c6c6c;
  opacity: 0.4;
  margin-left: 15px;
  margin-right: 15px;
`;

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const FileUploadingView = styled.div`
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  position: absolute;
  width: 400px;
  background-color: ${COLORS.BACKGROUND_COLOR_ALT};
  bottom: 0;
  right: 30px;
  z-index: 9999999;
`;
export const FileUploadingHeader = styled.div`
  background-color: ${COLORS.BACKGROUND_COLOR};
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`;
export const NoOfFile = styled.div`
  font-family: PoppinsMedium;
  font-size: 0.9rem;
  color: ${COLORS.WHITE};
`;
export const HideIcon = styled.div``;
export const UploadingFiles = styled.div`
  padding-left: 20px;
  row-gap: 15px;
  display: ${({ expanded }) => (expanded ? 'none' : 'flex')};
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 10px;
  max-height: 60vh;
  overflow-y: auto;
`;
export const FilesForUploadContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
export const UploadFileName = styled.div`
  font-family: PoppinsLight;
  font-size: 0.9rem;
  color: ${COLORS.WHITE};
`;
export const UploadStatusIcons = styled.div``;
export const FolderIcons = styled.div``;
export const ProgressBar = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({
    percentCompleted,
  }) => `radial-gradient(closest-side, #313131 79%, transparent 80% 100%),
  conic-gradient(#54c0ef ${percentCompleted}%, #645f5f 0)`};
`;
