/* eslint-disable max-len */
import React from 'react';
import { Active, Radio } from './RadioButton.Style';

const Radiobutton = ({ active = false, onClick, margin }) => (
  <Radio style={{ margin }} onClick={() => onClick()}>
    {active && <Active />}
  </Radio>
);

export default Radiobutton;
