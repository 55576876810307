import { call, put } from 'redux-saga/effects';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { ApiService } from '../../../services/axios/apiService';
import { ERROR_HANDLING } from '../../../constants/functions';
import { BaseCreators } from './action';
// <----------------------list of notification--------------------->
export function* notificationListSaga(action) {
  const { username } = action.payload;
  try {
    yield put(BaseCreators.notificationListStart());

    const response = yield call(ApiService.get, apiEndPoints.notificationList, {
      params: {
        username: username,
        page: 1,
        page_size: 100,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(BaseCreators.notificationListSuccess({ data: data?.response }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BaseCreators.notificationListError(payload));
  }
}
// <----------------------count on unread notification--------------------->
export function* countUnreadNotificationSaga(action) {
  const { username } = action.payload;
  try {
    yield put(BaseCreators.notificationCountStart());

    const response = yield call(
      ApiService.get,
      apiEndPoints.notificationCount,
      {
        params: {
          username: username,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(BaseCreators.notificationCountSuccess({ data: data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BaseCreators.notificationListError(payload));
  }
}

// <----------------------read notification--------------------->
export function* readNotificationSaga(action) {
  const { username, notificationId } = action.payload;
  try {
    yield put(BaseCreators.readNotificationStart());

    const response = yield call(
      ApiService.post,
      apiEndPoints.readNotification,
      {
        username: username,
        notification_id: notificationId,
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(BaseCreators.readNotificationSuccess({ data: data }));
      yield put(BaseCreators.notificationList({ username }));
      yield put(BaseCreators.notificationCount({ username }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(BaseCreators.readNotificationError(payload));
  }
}
