import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Input,
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { Col, Label } from '../CalendarModal/CalendarModal.Styles';
import { FormContainer, Wrapper } from './AddBrandForm.Styles';
import { STRINGS } from '../../constants/labels';
import { INTERNAL, PANORBIT } from '../../constants/constant';
import { multiSelectDropwdownStyles } from '../DropdownStyles/MultiselectDropdownStyles';
import { generateDropdownStyles } from '../DropdownStyles/ProfileIconDropdownStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallingCodes,
  CountryCode,
  CountryList,
  CountrySelectContainer,
  DropDownIcon,
  PhoneNumberContainer,
  UserInput,
} from '../modals/UserModals.Styles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import DropDown from '../../assets/icons/DropDownIcon.svg';
import { UserCreators } from '../../pages/users/store/action';
import FormatLabelV1 from '../Dropdown/FormatLabelV1.View';

const AddUsersClient = ({
  fontSize,
  formValues,
  setFormValues,
  edit = false,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  const [showDropDown, setShowDorpDown] = useState(false);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();

  const { clientSelectData } = useSelector((state) => state.userList);

  const filteredClients = clientSelectData?.filter((client) => {
    return !client?.label
      ?.trim()
      .toLowerCase()
      .includes(PANORBIT.toLowerCase());
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://restcountries.com/v2/all');
        const data = await response.json();
        // Update the state with the fetched country data
        setCountries(data);
      } catch (error) {
        // Handle errors if the fetch operation fails
        console.error('Error fetching country data:', error);
      }
    };
    fetchData();

    dispatch(UserCreators.selectClient({ search: '', type: INTERNAL }));
  }, []);

  const handleChangeInput = (fieldName, value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  //STYLES
  //SELECT DROPDOWN
  const dropdownStyles = generateDropdownStyles();
  const agencyTeamStyles = multiSelectDropwdownStyles({
    optionSelected: formValues?.agency_users?.length > 0,
  });
  const clientTeamStyles = multiSelectDropwdownStyles({
    optionSelected: formValues?.client_users?.length > 0,
  });

  const socialMediaStyles = multiSelectDropwdownStyles({
    optionSelected: formValues?.socialMediaLinks?.length > 0,
    socialMedia: true,
  });

  // Function to add a new social media link
  const addSMLink = () => {
    setFormValues({
      ...formValues,
      socialMediaLinks: [
        ...formValues.socialMediaLinks,
        { name: '', link: '' },
      ],
    });
  };

  // Function to update a social media link
  const updateSMLink = (index, data, key) => {
    const updatedLinks = [...formValues.socialMediaLinks];
    updatedLinks[index] = { ...updatedLinks[index], [key]: data };

    // Check if the name property is null, then set the link property to null
    if (key === 'name' && data === null) {
      updatedLinks[index]['link'] = null;
    }

    setFormValues({ ...formValues, socialMediaLinks: updatedLinks });
  };

  return (
    <FormContainer marginBottom={'20px'}>
      <Col width={'420px'}>
        <NameWrapper fontSize={'17px'} marginBottom={'10px'}>
          {edit ? strings.UPDATE_USER : strings.ADD_NEW_USER}
        </NameWrapper>
        <RoleWrapper>{strings.ENTER_USER_DETAILS}</RoleWrapper>
      </Col>
      <Wrapper>
        <Label fontSize={fontSize}>{strings.NAME}</Label>
        <Input
          width={'420px'}
          type="text"
          placeholder={strings.NAME}
          onChange={(event) => {
            handleChangeInput('first_name', event.target.value);
          }}
          value={formValues?.first_name}
          maxLength={60}
        />

        <Label fontSize={fontSize}>{strings.COMPANY}</Label>
        <Select
          value={
            filteredClients?.filter(
              (data) => data?.company_id === formValues?.base_company_id
            )[0]
          }
          options={filteredClients}
          onChange={(option) => {
            setFormValues({
              ...formValues,
              ['base_company_id']: option?.company_id || '',
              ['base_company_name']: option?.label || '',
            });
          }}
          formatOptionLabel={FormatLabelV1}
          placeholder={strings.COMPANY}
          styles={dropdownStyles}
        />
        <Label fontSize={fontSize}>{strings.PHONE_NUMBER}</Label>
        <PhoneNumberContainer>
          <CountrySelectContainer
            onClick={() => {
              setShowDorpDown((prev) => !prev);
            }}
          >
            <CountryCode>{formValues?.country_code || '+91'}</CountryCode>
            <DropDownIcon>
              <DynamicSVGIcon iconUrl={DropDown} rotate={'-90'} />
            </DropDownIcon>
            {showDropDown && (
              <CountryList>
                {countries?.map((data) => {
                  return (
                    <>
                      <CallingCodes
                        onClick={(event) => {
                          event.stopPropagation();
                          handleChangeInput('country_code', event.target.value);
                          setShowDorpDown(false);
                        }}
                      >{`+${data?.callingCodes[0]}`}</CallingCodes>
                    </>
                  );
                })}
              </CountryList>
            )}
          </CountrySelectContainer>
          <UserInput
            border={true}
            value={formValues?.phone_number}
            maxLength={10}
            onChange={(event) => {
              if (!isNaN(event.target.value)) {
                handleChangeInput('phone_number', event.target.value);
              }
            }}
          />
        </PhoneNumberContainer>
        <Label fontSize={fontSize}>{strings.EMAIL}</Label>
        <Input
          width={'420px'}
          type="text"
          placeholder={strings.EMAIL}
          onChange={(event) => {
            handleChangeInput('email', event.target.value);
          }}
          value={formValues?.email}
          maxLength={60}
          disabled={edit}
        />
      </Wrapper>
    </FormContainer>
  );
};

export default AddUsersClient;
