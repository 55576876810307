// dependency
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

// component
import TabsTable from '../../components/table/Table.View';
import UniversalModal from '../../components/modals/UniversalModal';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';
import { ModalHeading, ModalSubHeading } from '../brands/Brand.styles';

// Constent
import {
  DEFAULT_CLIENT_PAGE_SIZE,
  EMAIL_REGEX,
  ORDER_BY_MAP_FALSE,
  ORDER_BY_MAP_TRUE,
  ROW_OPTION_LABELS,
  SORT_KEY_MAP,
  USER_VALIDATIONS_MAPPING,
} from '../../constants/constant';
import { ErrorToast } from '../../services/toast/toast';
import { TOAST_POSITION } from '../../constants/BaseStyles';
// label
import { STRINGS } from '../../constants/labels';
// Creators
import { UserCreators } from './store/action';
import { AddUserCreators } from '../addUser/store/action';
import { loggedUserCreators } from '../homepage/store/action';
// Assets
import AddUsersClient from '../../components/form/AddUsersClient.View';
import UserRoleComponent from '../../components/UserRoleComponent/UserRoleComponent.View';

const BrandUsers = () => {
  //dispatch
  const dispatch = useDispatch();

  //nav
  const navigate = useNavigate();

  //store
  const {
    userListAgencyData: userListData,
    userListHeader,
    searchValue, //saved input value in reducer
    filter, //saved filter in reducer
    clientSelectData,
    selectedfilters, // saved filter in reducer
  } = useSelector((state) => state.userList);
  const { savemode, editmode, editUserLoading } = useSelector(
    (state) => state.addUser
  );
  const { userData } = useSelector((state) => state.loggedinUser);

  //state
  const [searchAndSort, setSearchAndSort] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [email, setEmail] = useState(null);
  const [shortListData, setShortListData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState({
    addMyAgencyUsers: false,
    deleteUser: false,
  });
  const [checkList, setCheckList] = useState([]);
  const [input, setInput] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const strings = STRINGS['ENGLISH'];

  const [formValues, setFormValues] = useState({
    first_name: '',
    base_company_id: '',
    base_company_name: '',
    is_admin: '',
    phone_number: '',
    is_active: true,
    type: 'CLIENT',
    user_organisation_role: '',
    country_code: '+91',
    role: '',
    username: '',
  });

  const handleSortBy = (columnDetails, order_by) => {
    const { key } = columnDetails;
    const sortParams = {
      [SORT_KEY_MAP[key]]: order_by
        ? ORDER_BY_MAP_TRUE[key]
        : ORDER_BY_MAP_FALSE[key],
    };
    setSearchAndSort(sortParams);
    dispatch(
      UserCreators.userFilters({
        filter: sortParams,
        searchValue: input,
        selectedOption,
      })
    );
  };

  const closeModal = () => {
    setIsOpenModal((prevState) => ({
      ...prevState,
      addMyAgencyUsers: false,
      deleteUser: false,
    }));
    setFormValues({
      first_name: '',
      base_company_id: '',
      base_company_name: '',
      is_admin: false,
      phone_number: '',
      is_active: true,
      type: 'CLIENT',
      user_organisation_role: '',
      country_code: '+91',
      role: '',
      email: '',
      username: '',
    });
    setEmail(null);
  };

  const handleSubmit = (e) => {
    let valid = true;
    let validationMessage = 'Input fields are required';
    for (const [key, value] of Object.entries(formValues)) {
      if (value === '' || value === undefined) {
        valid = false;
        validationMessage = `${USER_VALIDATIONS_MAPPING[key]} is required`;
        break; // No need to continue checking other fields if one is empty
      } else if (key === 'email' && !EMAIL_REGEX.test(value)) {
        valid = false;
        validationMessage = 'Email is invalid';
        break; // No need to continue checking other fields if email is invalid
      } else if (key === 'phone_number' && value?.toString().length !== 10) {
        valid = false;
        validationMessage = 'Phone number should be 10 digits';
        break; // No need to continue checking other fields if phone number length is invalid
      }
    }

    if (valid) {
      dispatch(
        AddUserCreators.editUser({
          data: formValues,
        })
      );
    } else {
      ErrorToast(validationMessage, TOAST_POSITION.TOP_CENTER, 'error1');
    }
  };

  /*------------Infinite scroll-----------------*/
  const pageNumber = Number(userListHeader.page_number);
  const hasMoreData = () => {
    return userListHeader.count <= pageNumber * DEFAULT_CLIENT_PAGE_SIZE
      ? true
      : false;
  };
  const onFetchMoreData = () => {
    setHasMore(false);
    return;
    // if (hasMoreData()) {
    //   setHasMore(false);
    // } else {
    //   dispatch(
    //     UserCreators.getUserList({
    //       pageNumber: pageNumber + 1,
    //       sortParams: filter,
    //       input: searchValue,
    //       companyId: selectedfilters?.company_id,
    //     })
    //   );
    // }
  };

  const editHandleClick = (data) => {
    setIsOpenModal({
      ...isOpenModal,
      addMyAgencyUsers: true,
      deleteUser: false,
    });
    setFormValues({
      first_name: data.name,
      base_company_id: data.base_company_id,
      base_company_name: data.base_company_name,
      is_admin: data.user_organisation_role === 'ADMIN',
      phone_number: data.phone_number,
      email: data.email,
      is_active: true,
      type: 'CLIENT',
      user_organisation_role: data.user_organisation_role,
      country_code: data.country_code,
      role: data.user_organisation_role,
      username: data.username,
    });
  };

  //modal fns
  const deleteuser = (data) => {
    const { email } = data;
    setEmail(email);
    setIsOpenModal({
      addMyAgencyUsers: false,
      deleteUser: true,
    });
  };

  const onCancel = () => {
    setIsOpenModal({
      addMyAgencyUsers: false,
      deleteUser: false,
    });
    setEmail(null);
  };

  const onItemClick = (data) => {
    const { username } = data;
  };

  const confirmDelete = () => {
    if (email) {
      dispatch(
        UserCreators.deleteUser({
          user_type: 'CLIENT',
          email,
        })
      );
      setIsOpenModal({
        addMyAgencyUsers: false,
        deleteUser: false,
      });
    }
  };

  useEffect(() => {
    if (userData.hasOwnProperty('is_admin')) {
      if (!userData.is_admin) {
        navigate('/');
      }
    }
  }, [navigate, userData]);

  useEffect(() => {
    dispatch(
      UserCreators.getUserListAgency({
        user_type: 'CLIENT',
      })
    );
    // deciding the path for previous page
    // dispatch(Creators.resetProjectFilter());
    dispatch(
      loggedUserCreators.backAction({
        goBackPath: '/',
      })
    );
    //
  }, [savemode, editmode]);

  useEffect(() => {
    if (!editUserLoading && editmode) {
      closeModal();
    }
  }, [editUserLoading, editmode]);

  return (
    <>
      <TabsTable
        metaDetails={userListHeader}
        dataDetails={userListData || []}
        shortListData={shortListData}
        handleShortList={setShortListData}
        checkList={checkList}
        setCheckList={setCheckList}
        scrollInfinite={true}
        checkBoxDisable={true}
        handleSortBy={handleSortBy}
        onItemSelect={onItemClick}
        scrollDetails={{
          hasMore: hasMore,
          onFetchMoreData: onFetchMoreData,
        }}
        ObjectComponent={{ user_organisation_role: UserRoleComponent }}
        rowOptions={ROW_OPTION_LABELS(editHandleClick, deleteuser)}
      />
      <Empty
        heading={strings.NOT_FOUND_USER_LIST}
        hubheading1={strings.WANT_TO_ADD_USER}
        subheading2={strings.ADD_USER}
        height={'85vh'}
      />
      <UniversalModal
        width={'500px'}
        height={'550px'}
        isOpenModal={isOpenModal?.addMyAgencyUsers}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPDATE}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={handleSubmit}
        onleftbtnClick={closeModal}
      >
        <AddUsersClient
          edit={true}
          formType={'CLIENT'}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </UniversalModal>

      <UniversalModal
        width={'420px'}
        height={'200px'}
        isOpenModal={isOpenModal?.deleteUser}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.DELETE}
        onrightbtnClick={confirmDelete}
        onleftbtnClick={onCancel}
      >
        <div
          style={{
            width: '360px',
            height: '160px',
          }}
        >
          <ModalHeading>Delete User?</ModalHeading>
          <ModalSubHeading>
            Are you sure you want to delete {email}?
          </ModalSubHeading>
        </div>
      </UniversalModal>
    </>
  );
};
export default BrandUsers;
