import { useEffect, useRef, useState } from 'react';
import { STRINGS } from '../../constants/labels';
import Camera from '../../assets/icons/Camera.svg';
import {
  CallingCodes,
  ChoosePic,
  CountryCode,
  CountryList,
  CountrySelectContainer,
  DropDownIcon,
  InputLabel,
  PhoneNumberContainer,
  UserAvatar,
  UserDetils,
  UserForm,
  UserInput,
  UserMoalContainer,
  UserProfile,
} from '../modals/UserModals.Styles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import DropDown from '../../assets/icons/DropDownIcon.svg';

const EditOrgDetails = ({ formValues, setFormValues }) => {
  const strings = STRINGS['ENGLISH'];
  const fileInput = useRef(null);
  //
  const [countries, setCountries] = useState([]);
  const [showDropDown, setShowDorpDown] = useState(false);

  // useEffect hook to fetch data from the 'https://restcountries.com/v2/all' endpoint
  useEffect(() => {
    // Define an asynchronous function to fetch country data
    const fetchData = async () => {
      try {
        const response = await fetch('https://restcountries.com/v2/all');
        const data = await response.json();
        // Update the state with the fetched country data
        setCountries(data);
      } catch (error) {
        // Handle errors if the fetch operation fails
        console.error('Error fetching country data:', error);
      }
    };

    // Invoke the fetchData function when the component mounts (empty dependency array)
    fetchData();
  }, []);
  //

  return (
    <UserMoalContainer>
      <UserProfile>{strings.EDIT}</UserProfile>
      <UserDetils>{strings.EDIT_DETAILS}</UserDetils>
      <UserAvatar
        onClick={(e) => {
          e.stopPropagation();
          fileInput.current && fileInput.current.click();
        }}
        img={formValues?.company_logo}
      >
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={(event) => {
            if (event.target.files && event.target.files[0]) {
              setFormValues({
                ...formValues,
                company_logo: URL.createObjectURL(event.target.files[0]),
                company_logo_file: event.target.files[0],
              });
            } else {
              setFormValues({
                ...formValues,
                company_logo: '',
                company_logo_file: null,
              });
            }
          }}
          ref={fileInput}
          style={{
            display: 'none',
          }}
        ></input>
        <ChoosePic>
          <DynamicSVGIcon iconUrl={Camera} />
        </ChoosePic>
      </UserAvatar>
      <UserForm>
        <InputLabel>Name</InputLabel>
        <UserInput
          value={formValues?.company_name}
          onChange={(e) => {
            setFormValues({ ...formValues, company_name: e.target.value });
          }}
          maxLength={60}
        />
        <InputLabel>Website</InputLabel>
        <UserInput
          value={formValues?.website}
          onChange={(e) => {
            setFormValues({ ...formValues, website: e.target.value });
          }}
          maxLength={120}
        />
        <InputLabel>Primary Contact Name</InputLabel>
        <UserInput
          value={formValues?.primary_contact_name}
          onChange={(e) => {
            setFormValues({
              ...formValues,
              primary_contact_name: e.target.value,
            });
          }}
          maxLength={120}
        />
        <InputLabel>Primary Contact Number</InputLabel>
        <PhoneNumberContainer>
          <CountrySelectContainer
            onClick={() => {
              setShowDorpDown((prevState) => !prevState);
            }}
          >
            <CountryCode>{formValues?.country_code}</CountryCode>
            <DropDownIcon>
              <DynamicSVGIcon iconUrl={DropDown} rotate={'-90'} />
            </DropDownIcon>
            {showDropDown && (
              <CountryList>
                {countries?.map((data) => {
                  return (
                    <>
                      <CallingCodes
                        onClick={(event) => {
                          event.stopPropagation();
                          setFormValues({
                            ...formValues,
                            country_code: `+${data?.callingCodes[0]}`,
                          });

                          setShowDorpDown(false);
                        }}
                      >{`+${data?.callingCodes[0]}`}</CallingCodes>
                    </>
                  );
                })}
              </CountryList>
            )}
          </CountrySelectContainer>
          <UserInput
            border={true}
            value={formValues?.primary_contact_phone_number}
            maxLength={10}
            onChange={(event) => {
              if (!isNaN(event.target.value)) {
                setFormValues({
                  ...formValues,
                  primary_contact_phone_number: event.target.value,
                });
              }
            }}
          />
        </PhoneNumberContainer>

        <InputLabel disable={true}>Primary Contact Email Address</InputLabel>
        <UserInput
          value={formValues?.email}
          maxLength={60}
          onChange={(event) => {
            setFormValues({
              ...formValues,
              email: event.target.value,
            });
          }}
        />
      </UserForm>
      {/* <------------------btn container-----------------> */}
    </UserMoalContainer>
  );
};
export default EditOrgDetails;
