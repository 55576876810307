import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
import { Link } from 'react-router-dom';

// export const Notification = styled.div``;
export const NotificationsContainer = styled(Link)`
  display: flex;
  gap: 10px;
  padding: 10px 15px 5px 15px;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isRead }) => !isRead && '#1e1e1e'};
  max-width: 50vw;
  border-radius: 10px;
`;
export const UnreadDot = styled.div`
  background-color: ${COLORS.PURPLE};
  width: 5.5px;
  height: 5px;
  border-radius: 50%;
  visibility: ${({ isRead }) => (isRead ? 'hidden' : 'visible')};
`;
export const UserProfile = styled.div`
  width: 33px;
  height: 30px;
  background-color: ${COLORS.PRIMARY_BACKGROUND_COLOR};
  border-radius: 50%;
`;
export const NotificationText = styled.div`
  color: #a0a0a0;
  font-family: PoppinsRegular;
  display: flex;
  flex-direction: ${({ allNotification }) =>
    allNotification ? 'row' : 'column'};
  justify-content: ${({ allNotification }) =>
    allNotification && 'space-between'};
  width: ${({ allNotification }) => allNotification && '100%'};
`;
export const NotificationAction = styled.div`
  font-size: 0.7rem;
  display: flex;
`;
export const NotificationTime = styled.div`
  color: ${COLORS.NOTIFICATION_TIME_COLOR};
  font-size: 0.8rem;
`;
export const UnReadSpan = styled.span`
  color: #e0e0e0;
`;
export const CompleteNotificationContainer = styled.div`
  margin-left: 95px;
  margin-top: 85px;
`;
export const NotificationsDate = styled.div`
  color: ${COLORS.NOTIFICATION_TIME_COLOR};
  font-family: PoppinsRegular;
  font-size: 0.8rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #232323;
  margin-top: 15px;
  padding-bottom: 5px;
`;
export const NotificationsTitle = styled.div`
  color: ${COLORS.NOTIFICATION_ACTIVE_COLOR};
`;
export const NotificationValueDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
