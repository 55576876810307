import React, { useState, useCallback } from 'react';
import { useIntersectionObserver } from '@wojtekmaj/react-hooks';
import { Page } from 'react-pdf';

const PdfIntersectionObserver = ({
  pageNumber,
  setPageVisibility,
  ...otherProps
}) => {
  const [page, setPage] = useState();

  const observerConfig = {
    // How much of the page needs to be visible to consider page visible
    threshold: 0,
  };

  //set if page is visible or not using intersection observer api
  const onIntersectionChange = useCallback(
    ([entry]) => {
      setPageVisibility(pageNumber, entry.isIntersecting);
    },
    [pageNumber, setPageVisibility]
  );

  useIntersectionObserver(page, observerConfig, onIntersectionChange);

  return (
    <Page
      canvasRef={setPage}
      pageNumber={pageNumber}
      {...otherProps}
      loading={<h1>Loading.....</h1>}
      // width={1000}
      // height={600}
    />
  );
};

export { PdfIntersectionObserver };
