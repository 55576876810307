import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : 'space-evenly')};
  padding: 20px;
  height: auto;
  width: ${({ width }) => (width ? width : '450px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
`;

export const Wrapper = styled.div`
  max-height: 80vh;
  width: 430px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const UploadInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const FileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.PRIMARY_GREY};
  border: 1px dashed ${COLORS.OVERLAY_GREY};
  width: ${({ width }) => (width ? width : '100%')};
  height: 90px;
  // margin: 5px;
  margin-bottom: 25px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
`;

export const Link = styled.label`
  color: ${COLORS.PURPLE};
  text-decoration: underline;
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 350px;
  margin: 5px;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 16px;
  z-index: 100;
  border: ${({ disabled }) =>
    disabled ? 'none' : `1px solid ${COLORS.OVERLAY_GREY}`};
  background-color: ${({ disabled }) =>
    disabled ? COLORS.PRIMARY_BLACK : COLORS.PRIMARY_GREY};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;

export const CustomSelect = styled(Select)`
  background-color: ${COLORS.PRIMARY_GREY};
  width: 350px;
  border-radius: 5px;
  border: 1px solid ${COLORS.OVERLAY_GREY};
  color: ${COLORS.TEXT_MEDIUM_GREY};
`;

export const DateWrapper = styled.div`
  .react-datepicker {
    box-shadow: 3px 2px 11px rgb(0 0 0 / 30%);
  }
  .react-datepicker__input-container input,
  .react-datepicker__input-container input::placeholder {
    width: 420px;
    font-size: 12px;
    color: ${COLORS.TEXT_MEDIUM_GREY};
    z-index: 100 !important;
  }

  .react-datepicker-popper {
    z-index: 1 !important;
  }
`;

export const DropdownImages = styled.div``;

export const AddWrapper = styled.div`
  height: 35px;
  width: 35px;
  margin: 5px;
  // padding: 3px;
  border: 1px solid ${COLORS.OVERLAY_GREY};
  color: ${COLORS.TEXT_MEDIUM_GREY};
  font-size: 22px;
`;

export const LogoDisplay = styled.div`
  height: 88px;
  width: 90px;
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const CalendarIconDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
`;
