import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const PdfWrapper = styled.div`
  position: relative;
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  }
`;

export const NavigationContainer = styled.div`
  position: absolute;
  visibility: hidden;
  top: auto;
  // bottom: 15vh;
  bottom: 0;
  left: 48%;
  height: 30px;
  z-index: 1;
  padding: 5px 10px 5px 10px;
  text-align: center;
  background-color: ${COLORS.COMMENTS_BACKGROUND_COLOR};
  box-shadow: 0 10px 20px 0 ${COLORS.BOX_SHADOW_COLOR};
  transform: translateX(-50%);
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

export const HoverWrapper = styled.div`
&:hover {
  ${NavigationContainer} {
    opacity: 1;
    visibility: visible;
  }
`;

export const PdfOverflowDiv = styled.div`
  position: relative;
  max-height: 70vh;
  height: auto;
  overflow-x: auto;
`;

export const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
`;

export const PageInfo = styled.p`
  margin: 0;
  font-size: 16px;
  // line-height: 2.8;
`;
