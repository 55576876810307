import { useDispatch } from 'react-redux';
import {
  CHECK_TIME_DIFFERENCE,
  GENRATE_URL_FOR_NOTIFICATION,
} from '../../constants/functions';
import {
  NotificationAction,
  NotificationText,
  NotificationTime,
  NotificationsContainer,
  UnreadDot,
  UserProfile,
} from './Notification.Styles';
import { BaseCreators } from '../../pages/BaseLayout/store/action';

const Notifications = ({ data, allNotification }) => {
  const dispatch = useDispatch();
  const readNotifications = (username, notificationId) => {
    dispatch(BaseCreators.readNotification({ username, notificationId }));
  };
  return (
    <>
      <NotificationsContainer
        isRead={data?.is_read}
        onClick={() => {
          readNotifications(data?.username, data?.notification_id);
        }}
        to={GENRATE_URL_FOR_NOTIFICATION(data)}
      >
        <UnreadDot isRead={data?.is_read}></UnreadDot>
        <UserProfile></UserProfile>
        <NotificationText allNotification={allNotification}>
          <NotificationAction>
            {data?.notification_meta?.message}
          </NotificationAction>
          <NotificationTime>
            {CHECK_TIME_DIFFERENCE(data?.created_at)}
          </NotificationTime>
        </NotificationText>
      </NotificationsContainer>
    </>
  );
};
export default Notifications;
