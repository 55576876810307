import { STATUS } from '../../constants/constant';
import { Status, StatusContainer } from './status.Styles';

const StatusComponent = (data) => {
  let value = '';
  if (typeof data?.value === 'string') {
    value = data.value;
  } else if (typeof data?.value === 'object') {
    value = data?.value?.value;
  }
  return (
    <StatusContainer>
      <Status bg={value}>{STATUS[value]}</Status>
    </StatusContainer>
  );
};
export default StatusComponent;
