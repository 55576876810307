import React from 'react';
import { Row } from '../CalendarModal/CalendarModal.Styles';
import {
  NameWrapper,
  ProfileDiv,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { GET_STATUS_COLOR } from '../../constants/functions';

const FormatStatusLabel = (option) => {
  return (
    <Row flexStart>
      <ProfileDiv
        img={option?.img}
        bg={GET_STATUS_COLOR(option?.value)}
        height={'15px'}
        width={'15px'}
      />
      <NameWrapper>{option?.label}</NameWrapper>
    </Row>
  );
};

export default FormatStatusLabel;
