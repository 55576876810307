import { createReducer } from 'reduxsauce';
import { BrandTypes } from './action';

const INITIAL_STATE = {
  error: false,
  clientLoading: false,
  BrnadListloading: false,
  clientData: {},
  brandListData: [],
  projectListData: {},
  companyListHeader: {},
  filter: {},
  searchValue: '',
  userData: {},
  viewClientData: [],
  brandData: {},
  brandLoading: false,
  addBrandSuccess: false,
  loadingbrandLoading: false,
};

// <-----------------------reducer for viewing list of brands-------------------->

const brandListStart = (state = INITIAL_STATE, action) => {
  return {
    BrnadListloading: true,
    ...state,
  };
};
const brandListSuccess = (state = INITIAL_STATE, action) => {
  let brandList = [];
  const { data } = action.payload;
  if (data?.page_number === 1) {
    brandList = data?.data;
  } else {
    brandList = [...state.brandListData, ...data?.data];
  }
  return {
    ...state,
    error: false,
    BrnadListloading: false,
    companyListHeader: data,
    brandListData: brandList,
  };
};
const brandListError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    error: true,
    BrnadListloading: false,
    errorMessage,
  };
};
// const brandFiltersReducer = (state = INITIAL_STATE, action) => {
//   const { filter, searchValue } = action.payload;
//   return {
//     ...state,
//     filter: filter,
//     searchValue: searchValue,
//   };
// };
//
// <---------------reducer for delete client's from list----------------->
const deleteBrandsStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const deleteBrandsSuccessReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const deleteBrandsErrorReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
// <---------------reducer for get project list based on company id----------------->
const getProjectsByCompanyIdStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};
const getProjectsByCompanyIdSuccessReducer = (
  state = INITIAL_STATE,
  action
) => {
  let projectList = state.brandListData;
  const { data, companyId } = action.payload;
  if (data?.page_number === 1) {
    projectList[companyId] = data?.data;
  } else {
    projectList[companyId] = [...state.brandListData[companyId], ...data?.data];
  }
  return {
    ...state,
    error: false,
    projectListData: projectList,
  };
};
const getProjectsByCompanyIdErrorReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

// <---------------reducer for adding client/brand----------------->
const addBrandStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    brandLoading: false,
    loading: true,
    addBrandSuccess: false,
    loadingbrandLoading: true,
  };
};
const addBrandSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload?.data;
  return {
    ...state,
    brandLoading: true,
    error: false,
    brandData: data,
    addBrandSuccess: true,
    loadingbrandLoading: false,
  };
};
const addBrandError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    loadingbrandLoading: false,
    error: true,
    errorMessage,
    addBrandSuccess: false,
  };
};
//
// <---------------reducer for edit brand from list----------------->
const editBrandStartReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingbrandLoading: true,
  };
};
const editBrandSuccessReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingbrandLoading: false,
  };
};
const editBrandErrorReducer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingbrandLoading: false,
  };
};

const HANDLERS = {
  [BrandTypes.BRAND_LIST_START]: brandListStart,
  [BrandTypes.BRAND_LIST_SUCCESS]: brandListSuccess,
  [BrandTypes.BRAND_LIST_ERROR]: brandListError,
  //   [BrandTypes.CLIENT_FILTERS]: brandFiltersReducer,
  //
  [BrandTypes.DELETE_BRAND_LIST_START]: deleteBrandsStartReducer,
  [BrandTypes.DELETE_BRAND_LIST_SUCCESS]: deleteBrandsSuccessReducer,
  [BrandTypes.DELETE_BRAND_LIST_ERROR]: deleteBrandsErrorReducer,
  //
  [BrandTypes.GET_PROJECTS_BY_COMPANY_ID_START]:
    getProjectsByCompanyIdStartReducer,
  [BrandTypes.GET_PROJECTS_BY_COMPANY_ID_SUCCESS]:
    getProjectsByCompanyIdSuccessReducer,
  [BrandTypes.GET_PROJECTS_BY_COMPANY_ID_ERROR]:
    getProjectsByCompanyIdErrorReducer,
  //
  [BrandTypes.ADD_BRAND_START]: addBrandStart,
  [BrandTypes.ADD_BRAND_SUCCESS]: addBrandSuccess,
  [BrandTypes.ADD_BRAND_ERROR]: addBrandError,
  //
  [BrandTypes.EDIT_BRAND_START]: editBrandStartReducer,
  [BrandTypes.EDIT_BRAND_SUCCESS]: editBrandSuccessReducer,
  [BrandTypes.EDIT_BRAND_ERROR]: editBrandErrorReducer,
};
export const brandReducer = createReducer(INITIAL_STATE, HANDLERS);
