/**
 * action js
 *
 * it will have all the user list related actions
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getUserList: ['payload'],
  getUserListStart: ['payload'],
  getUserListSuccess: ['payload'],
  getUserListFailure: ['payload'],
  userFilters: ['payload'],
  //
  deleteUser: ['payload'],
  deleteUserStart: ['payload'],
  deleteUserSuccess: ['payload'],
  deleteUserFailure: ['payload'],
  //
  selectClient: ['payload'],
  selectClientStart: ['payload'],
  selectClientSuccess: ['payload'],
  selectClientFailure: ['payload'],
  //
  resetUserFilters: ['payload'],

  getUserListAgency: ['payload'],
  getUserListAgencyStart: ['payload'],
  getUserListAgencySuccess: ['payload'],
  getUserListAgencyFailure: ['payload'],
});

export { Types as UserTypes, Creators as UserCreators };
