/**
 * saga js
 *
 * it will have all the assets related sagas
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import {
  ApiService,
  ApiServiceMultipart,
} from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { AssetCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { STRINGS } from '../../../constants/labels';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../../constants/constant';
import { WebpageCreators } from '../../WebsiteFeedback/store/action';
import { EventCreators } from '../../viewPosts/store/action';

// <----------------------Get assets----------------------------->
export function* assetCommentSaga(action) {
  const { assetID, versionId, latest, resolved, FilterByMe } = action.payload;
  try {
    yield put(AssetCreators.viewAssetCommentStart());

    const response = yield call(ApiService.get, apiEndPoints.assetsComment, {
      params: {
        asset_id: assetID,
        version_id: versionId,
        filter_by_me: FilterByMe,
        filter_by_resolved: resolved,
        sort_by_latest: latest,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AssetCreators.viewAssetCommentSuccess({
          data: data?.meta?.data,
          commentMeta: data?.meta,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.viewAssetCommentError(payload));
  }
}
// <----------------------add assets comments----------------------------->
export function* addssetCommentSaga(action) {
  const {
    assetID,
    versionId,
    refId,
    comments,
    ref,
    userName,
    is_reply,
    parent_comment_id,
  } = action.payload;
  const data = {
    asset_id: assetID,
    version_id: versionId,
    ref: ref,
    ref_id: refId,
    comments: comments,
    is_reply: is_reply,
    created_by_username: userName,
    last_updated_by_username: userName,
    tagged_usernames: [],
    is_resolved: false,
  };
  if (parent_comment_id) {
    data['parent_comment_id'] = parent_comment_id;
  }

  try {
    yield put(AssetCreators.addAssetCommentStart());
    const response = yield call(ApiService.post, apiEndPoints.addComment, data);
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(AssetCreators.addAssetCommentSuccess({ data: data?.response }));
      yield put(
        AssetCreators.viewAssetComment({
          assetID: assetID,
          versionId: versionId,
          latest: false,
          resolved: false,
          FilterByMe: false,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.addAssetCommentFailure(payload));
  }
}
export function* viewAssetsSaga(action) {
  const { assetID, versionId } = action.payload;
  try {
    yield put(AssetCreators.viewAssetsStart());

    const response = yield call(
      ApiService.get,
      apiEndPoints.getSingleAssetVersion,
      {
        params: {
          asset_id: assetID,
          version_id: versionId,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(AssetCreators.viewAssetsSuccess({ data: data?.data, assetID }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.viewAssetsFailure(payload));
  }
}
// <---------------saga for deleting comment ----------------->
export function* deleteAssetsCommentSaga(action) {
  const {
    comment_id,
    assetID,
    versionId,
    website,
    webpage_id,
    version_id,
    view_type,
  } = action.payload;
  try {
    yield put(AssetCreators.deleteAssetCommentStart());
    const response = yield call(
      ApiService.delete,
      apiEndPoints.delete_comment,
      {
        params: {
          comment_id: comment_id,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(AssetCreators.deleteAssetCommentSuccess({ comment_id: data }));
      if (!website) {
        yield put(
          AssetCreators.viewAssetComment({
            assetID: assetID,
            versionId: versionId,
            latest: false,
            resolved: false,
            FilterByMe: false,
          })
        );
      } else {
        yield put(
          WebpageCreators.getCommentsByWebsite({
            webpage_id: webpage_id,
            version_id: version_id,
            view_type,
          })
        );
      }
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.deleteAssetCommentFailure(payload));
  }
}
// <---------------saga for update the comment ----------------->
export function* updateAssetsCommentSaga(action) {
  const {
    data,
    assetID,
    versionId,
    website,
    webpage_id,
    version_id,
    view_type,
  } = action.payload;

  try {
    yield put(AssetCreators.updateAssetCommentStart());
    const response = yield call(ApiService.post, apiEndPoints.update_comment, {
      ...data,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(AssetCreators.updateAssetCommentSuccess({ comment_id: data }));
      if (website) {
        yield put(
          WebpageCreators.getCommentsByWebsite({
            webpage_id: webpage_id,
            version_id: version_id,
            view_type,
          })
        );
      } else {
        yield put(
          AssetCreators.viewAssetComment({
            assetID: assetID,
            versionId: versionId,
            latest: false,
            resolved: false,
            FilterByMe: false,
          })
        );
      }

      // yield put(AssetCreators.viewAssets({ assetID, versionId }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.updateAssetCommentFailure(payload));
  }
}
// <---------------saga for update the status ----------------->
export function* updateAssetsStatusSaga(action) {
  const { assetID, versionId, data } = action.payload;

  try {
    yield put(AssetCreators.updateAssetStatusStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.updateAssetVersion,
      data
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(AssetCreators.updateAssetStatusSuccess({ comment_id: data }));
      yield put(AssetCreators.viewAssets({ assetID, versionId }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.updateAssetStatusFailure(payload));
  }
}
// <---------------saga for update the status ----------------->
export function* addNewAssetSaga(action) {
  const { formData, assetID, event = '' } = action.payload;
  try {
    yield put(AssetCreators.uploadNewVersionFileStart());
    const response = yield call(
      ApiServiceMultipart.post,
      apiEndPoints.addAsset,
      formData
    );
    if (response.status >= 200 && response.status < 300) {
      yield put(AssetCreators.uploadNewVersionFileSuccess({}));

      SuccessToast(STRINGS['ENGLISH'].ASSET_ADDED, TOAST_POSITION.TOP_CENTER);
      if (!event) {
        yield put(AssetCreators.getAssetVersionList({ assetID }));
      } else {
        yield put(EventCreators.getAssets({ ref: 'event', ref_id: event }));
      }
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.uploadNewVersionFileFailure(payload));
  }
}
// <--------------saga to get list of version of asset--------------->
export function* getVersionListSaga(action) {
  const { assetID } = action.payload;
  try {
    //pass project id
    yield put(AssetCreators.getAssetVersionListStart());
    const response = yield call(ApiService.get, apiEndPoints.getAssetVersions, {
      params: {
        asset_id: assetID,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AssetCreators.getAssetVersionListSuccess({
          data: data?.data,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AssetCreators.getAssetVersionListFailure(payload));
  }
}
