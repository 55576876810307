import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './stores/store';
import { ModalProvider } from 'styled-react-modal';
// import { server } from '../src/mocks/server';

const root = ReactDOM.createRoot(document.getElementById('root'));

// // Start the mock server
// server.listen();

root.render(
  <Router>
    <ModalProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ModalProvider>
  </Router>
);
