/* eslint-disable react-hooks/exhaustive-deps */
/**
 * baseLayOut js
 *
 * Component That has define LayOut on organization level that component will be visible throw out Project.
 *
 * Project: MakroApp
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 *
 */
import { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  ClearAllDiv,
  Dashboard,
  FileUploadingHeader,
  FileUploadingView,
  FilesForUploadContainer,
  FolderIcons,
  HideIcon,
  IconContainer,
  LogoDiv,
  NameWrapper,
  NoOfFile,
  NotificationBorderLine,
  NotificationContainer,
  NotificationCount,
  NotificationHeader,
  NotificationLabel,
  NotificationLabelAndCount,
  NotificationList,
  ProfileDiv,
  ProgressBar,
  RoleWrapper,
  SearchInput,
  SideBarLinkContainer,
  SidebarIcons,
  SidebarIconsContainer,
  SidebarLinks,
  ThreeDot,
  UploadFileName,
  UploadStatusIcons,
  UploadingFiles,
} from './BaseLayout.Styles';

import {
  Header,
  SideNavContainer,
  Profile,
  ExpandedProfile,
  StyledLink,
  LogoPosition,
  SideBarContent,
  DropDownContainer,
  SearchBarContainer,
} from '../dashboards/Dashboard.Styles';
import { setAccessTokenToHeader } from '../../services/axios/helper';
import { setUserDataToLocal } from '../../services/cookies/helper';
import { LandingPage } from '../../constants/GlobalStyles.Styles';
import { STRINGS } from '../../constants/labels';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import folder from '../../assets/icons/folder.svg';
import NotificationIconWithoutAlert from '../../assets/icons/Notification_without_alert.svg';
import NotificationIconWithAlert from '../../assets/icons/Notification_with_alert.svg';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import DropDoenIcon from '../../assets/icons/DropDownIcon.svg';
import Search from '../../assets/icons/search.svg';
import FilterIcon from '../../assets/icons/Filter.svg';
import Fileuploaded from '../../assets/icons/Fileuploaded.svg';
import Logout from '../../assets/icons/Logout.svg';
import ProfileIcon from '../../assets/icons/Profile.svg';
import OrganisationIcon from '../../assets/icons/Organisation.svg';
import { useDispatch, useSelector } from 'react-redux';
import { loggedUserCreators } from '../homepage/store/action';

import {
  ADMIN_CARD_NAMES,
  CLIENT_CARD_NAMES,
  GET_INITIALS_FROM_NAME,
  USERS_TYPES_PROFILE_HEADER,
} from '../../constants/constant';
import { Col, Row } from '../../components/CalendarModal/CalendarModal.Styles';

import { FIND_COLOR_RANGE, getHeaderStyles } from '../../constants/functions';
import Notifications from '../../components/Notification/Notifications';
import { BaseCreators } from './store/action';
import UserModals from '../../components/modals/UserModals';
import { UserIconText } from '../../components/Cards/Cards.Styles';

const BaseLayout = () => {
  //dispatch
  const dispatch = useDispatch();

  //TEXT
  const strings = STRINGS['ENGLISH'];

  //navigation
  const navigate = useNavigate();

  //state management
  const [expandProfile, setExpandProfile] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [searchParams] = useSearchParams();

  //location
  const location = useLocation();
  const pathname = location.pathname;

  //params
  const params = useParams();
  const { project_id, event_id } = params;

  //redux
  const { userData } = useSelector((state) => state.loggedinUser);
  const { notificationList, notificationListCount } = useSelector(
    (state) => state.base
  );
  const { uploadFiles, uploadFolderloading, files } = useSelector(
    (state) => state.project
  );
  const [filesName, setFilesName] = useState([]);
  useEffect(() => {
    if (uploadFolderloading) {
      setFilesName(uploadFiles);
    } else {
      setFilesName([]);
    }
  }, [uploadFolderloading]);
  // console.log(notificationList, 'notificationList');

  // useEffect to dispatch an action to get user details when the component mounts
  // Dependencies: None
  useEffect(() => {
    dispatch(loggedUserCreators.getUserDetails());
  }, []);
  const onClickNotification = () => {
    const { username } = userData;
    if (userData) {
      setIsNotificationOpen((prev) => !prev);
      dispatch(BaseCreators.notificationList({ username }));
      dispatch(BaseCreators.notificationCount({ username }));
    }
  };

  // useEffect to update the card list based on the user's admin status
  // Dependencies: userData.is_admin - to determine the user's admin status
  useEffect(() => {
    if (
      userData?.is_admin ||
      userData?.user_organisation_role === 'BRAND_MANAGER'
    ) {
      setCardList(ADMIN_CARD_NAMES);
    } else {
      setCardList(CLIENT_CARD_NAMES);
    }
    const { username } = userData;
    if (username) {
      dispatch(BaseCreators.notificationCount({ username }));
    }
  }, [userData?.is_admin]);

  //delete session/cookies
  const deleteCookies = () => {
    setUserDataToLocal();
    setAccessTokenToHeader();
  };
  /**
   * Navigates to the specified URL using the navigate function.
   *
   * @param {string} url - The URL to navigate to.
   */
  const navigateTo = (url) => {
    navigate(url);
  };

  const handleGoBack = () => {
    if (
      pathname.includes('/asset-versions/') &&
      window?.history?.state?.idx === 0
    ) {
      navigate(`/projects/project/${project_id}/view-event/${event_id}`);
    } else {
      if (window?.history?.state?.idx === 0) {
        navigate('/');
      } else if (pathname.includes('/website_review')) {
        const project_id_1 = searchParams.get('project_id');
        navigate(
          `/projects/view-project/website_list/${project_id_1}?project_type=Tech`
        );
      } else {
        navigate(-1);
      }
    }
  };

  const [expanded, setExpanded] = useState(false);
  // set margin + visibility styles  dynamically based on path
  const { colHeight } = getHeaderStyles(pathname);
  return (
    <LandingPage>
      <SideNavContainer>
        <LogoPosition
          onClick={() => {
            navigateTo('/');
          }}
        >
          <LogoDiv />
        </LogoPosition>
        <SideBarContent>
          {cardList.map((data, index) => {
            return (
              <SideBarLinkContainer
                active={pathname.includes(data.to)}
                key={index}
                onClick={() => {
                  if (!data?.disable) {
                    navigateTo(data.to);
                  }
                }}
              >
                <SidebarIconsContainer>
                  <SidebarIcons src={data.icon} alt={data.text} />
                </SidebarIconsContainer>
                <SidebarLinks>{data.text}</SidebarLinks>
              </SideBarLinkContainer>
            );
          })}
        </SideBarContent>
        {/* <BotDiv>Pan Bot</BotDiv> */}
      </SideNavContainer>
      <Col
        width={'100%'}
        height={colHeight || '130px'}
        marginLeft={'80px'}
        justify={'flex-end'}
        align={'flex-start'}
      >
        <Header>
          <Row spaceBetween>
            <Dashboard>
              {/* {currentName} */}
              <SearchBarContainer>
                {/* <IconContainer></IconContainer> */}
                <IconContainer>
                  <DynamicSVGIcon iconUrl={Search} />
                </IconContainer>
                <SearchInput type="text" placeholder={strings.SEARCH} />
                <IconContainer>
                  <DynamicSVGIcon width="20px" iconUrl={FilterIcon} />
                </IconContainer>
              </SearchBarContainer>
            </Dashboard>
            <Row spaceBetween center marginRight={'40px'} width={'200px'}>
              {/* <-----------------------------Notification start---------------------> */}
              <Row spaceBetween center>
                <div
                  onClick={() => {
                    onClickNotification();
                  }}
                >
                  <DynamicSVGIcon
                    width="22px"
                    iconUrl={
                      notificationListCount
                        ? NotificationIconWithAlert
                        : NotificationIconWithoutAlert
                    }
                  />
                </div>
                <UseClickOutside
                  onClickOutside={() => {
                    setIsNotificationOpen(false);
                  }}
                >
                  {isNotificationOpen && (
                    <NotificationContainer>
                      <NotificationHeader>
                        <NotificationLabelAndCount>
                          <NotificationLabel>
                            {strings.NOTIFICATIONS}
                          </NotificationLabel>
                          <NotificationCount>
                            {notificationListCount || 0}
                          </NotificationCount>
                        </NotificationLabelAndCount>
                        {/* <------------------clear all-----------------> */}
                        <ClearAllDiv
                          onClick={() => {
                            navigate('/notification');
                            setIsNotificationOpen(false);
                          }}
                        >
                          {strings.VIEW_ALL}
                        </ClearAllDiv>
                      </NotificationHeader>
                      <NotificationBorderLine></NotificationBorderLine>
                      <NotificationList>
                        {notificationList?.map((data) => {
                          return (
                            <>
                              <Notifications data={data} />
                            </>
                          );
                        })}
                      </NotificationList>
                    </NotificationContainer>
                  )}
                </UseClickOutside>
              </Row>
              {/* <-----------------------------Notification end ---------------------> */}
              <Row flexStart center>
                <ProfileDiv
                  img={userData?.profile_pic_url}
                  bg={FIND_COLOR_RANGE(
                    userData?.first_name,
                    userData?.profile_pic_url
                  )}
                >
                  {!userData?.profile_pic_url ? (
                    <UserIconText>
                      {GET_INITIALS_FROM_NAME(userData?.first_name)}
                    </UserIconText>
                  ) : null}
                </ProfileDiv>
                <Profile>
                  <Col width={'100px'} align={'flex-start'}>
                    <NameWrapper>{userData?.first_name}</NameWrapper>
                    <RoleWrapper>
                      {userData?.user_organisation_role
                        ? USERS_TYPES_PROFILE_HEADER[
                            userData?.user_organisation_role
                          ]
                        : ''}
                    </RoleWrapper>
                  </Col>
                </Profile>
              </Row>

              <ThreeDot
                onClick={() => {
                  setExpandProfile(!expandProfile);
                }}
              >
                <DynamicSVGIcon
                  iconUrl={DropDoenIcon}
                  width="1rem"
                  rotate="-90"
                />
                {expandProfile && (
                  <UseClickOutside
                    onClickOutside={() => {
                      setExpandProfile(false);
                    }}
                  >
                    <ExpandedProfile>
                      <DropDownContainer>
                        {/* <StyledLink
                          border={false}
                          onClick={() => {
                            setIsProfileOpen((prev) => !prev);
                          }}
                        >
                          <div>{strings.PROFILE}</div>
                        </StyledLink> */}
                        <StyledLink
                          border={false}
                          onClick={() => {
                            setIsProfileOpen((prev) => !prev);
                          }}
                        >
                          <div>
                            <DynamicSVGIcon
                              iconUrl={ProfileIcon}
                              width="1rem"
                            />
                          </div>
                          <div>{strings.PROFILE}</div>
                        </StyledLink>
                        {userData?.user_organisation_role === 'ADMIN' && (
                          <StyledLink
                            border={false}
                            onClick={() => {
                              //
                              navigate('/organisation/details');
                            }}
                          >
                            <div style={{ marginLeft: '-3px' }}>
                              <DynamicSVGIcon
                                iconUrl={OrganisationIcon}
                                width="1.2rem"
                              />
                            </div>
                            <div>{strings.ORGANISATION}</div>
                          </StyledLink>
                        )}

                        <StyledLink
                          border={false}
                          onClick={() => {
                            deleteCookies();
                          }}
                        >
                          <div>
                            <DynamicSVGIcon iconUrl={Logout} width="1rem" />
                          </div>
                          <div>{strings.LOGOUT}</div>
                        </StyledLink>
                      </DropDownContainer>
                    </ExpandedProfile>
                  </UseClickOutside>
                )}
              </ThreeDot>
            </Row>
          </Row>
        </Header>
      </Col>
      <UserModals
        isProfileOpen={isProfileOpen}
        setIsProfileOpen={setIsProfileOpen}
        userData={userData}
      />
      {/* <----------------------------file uploading------------------> */}
      {uploadFolderloading && (
        <FileUploadingView>
          <FileUploadingHeader>
            <NoOfFile>Uploading {filesName?.length} files</NoOfFile>
            <HideIcon
              onClick={() => {
                setExpanded((prev) => !prev);
              }}
            >
              <DynamicSVGIcon iconUrl={DropDoenIcon} rotate={'-90'} />
            </HideIcon>
          </FileUploadingHeader>
          <UploadingFiles expanded={expanded}>
            {Object.entries(files).map(([key, value]) => {
              return (
                <FilesForUploadContainer>
                  <UploadStatusIcons>
                    {value?.percentCompleted === 100 ? (
                      <DynamicSVGIcon iconUrl={Fileuploaded} />
                    ) : (
                      <>
                        <ProgressBar
                          percentCompleted={value?.percentCompleted}
                          class="progress-bar"
                          role="progressbar"
                          aria-valuenow={value?.percentCompleted}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></ProgressBar>
                      </>
                    )}
                  </UploadStatusIcons>
                  <FolderIcons>
                    <DynamicSVGIcon iconUrl={folder} width="2rem" />
                  </FolderIcons>
                  <UploadFileName>{key}</UploadFileName>
                </FilesForUploadContainer>
              );
            })}
          </UploadingFiles>
        </FileUploadingView>
      )}
      <Outlet></Outlet>
    </LandingPage>
  );
};
export default BaseLayout;
