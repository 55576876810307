import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventCreators } from '../viewPosts/store/action';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BAckAndTitle,
  BackIcon,
  CreatatedOnDate,
  CreatatedOnHeading,
  CreatatedOnWrapper,
  Description,
  DescriptionTextArea,
  EventDescription,
  EventInfo,
  EventLabel,
  EventListHeader,
  EventListcontainer,
  InfoHeading,
  ReactDate,
  TitleText,
  UploadFileBtn,
} from './Calendar.Styles';
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import Back from '../../assets/icons/Back.svg';
import Button from '../../components/Buttons/Button.View';
import { COLORS, TOAST_POSITION } from '../../constants/BaseStyles';
import UniversalModal from '../../components/modals/UniversalModal';
import { STRINGS } from '../../constants/labels';
import UploadEventFile from '../../components/form/UploadEventFile.View';
import { ADD_EVENT_ERROR } from '../../constants/constant';
import { ErrorToast } from '../../services/toast/toast';
import { AssetCreators } from '../assetsView/store/action';
import { ProjectFolderCardsWrapper } from '../projects/Project.Styles';
import AssetCards from '../../components/Cards/AssetCards.View';
import ProjectFolderCards from '../../components/Cards/ProjectsFolderCards.View';
import { ModalHeading, ModalSubHeading } from '../brands/Brand.styles';
import { Creators } from '../projects/store/action';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';
import Loading from '../../components/loader/Loader.View';
import Edit from '../../assets/icons/Edit.svg';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { PostsCreators } from './store/action';
import { Input } from '../BaseLayout/BaseLayout.Styles';

const EventList = () => {
  const strings = STRINGS['ENGLISH'];
  const navigate = useNavigate();
  //
  const param = useParams();
  const { project_id, project_path } = param;
  const [isEventEdit, setIsEventEdit] = useState({
    isEditModal: false,
    createdDate: '',
    disc: '',
    title: '',
  });
  const [formValue, setFromValue] = useState({
    name: '',
    status: 'INPROGRESS',
    caption: '',
    ref: 'event',
    ref_id: '',
    file: null,
  });
  const [uploadFolder, setUploadFile] = useState(false);
  const [isModalOpen, setisModalOpen] = useState({
    isDeleteModal: false,
    assetId: '',
    versionId: '',
  });
  //
  const dispatch = useDispatch();
  const params = useParams();
  //
  const { event_id } = params;
  useEffect(() => {
    dispatch(EventCreators.getAssets({ ref: 'event', ref_id: event_id }));
  }, []);
  //
  const { assetsData, assetMeta, uploadNewVersionFileLoading } = useSelector(
    (state) => state.getEvent
  );
  const uploadfolder = () => {
    let valid = false;
    //
    Object.keys(formValue).forEach((field) => {
      if (formValue[field]?.length === 0) {
        // Check if length is 0
        ErrorToast(
          ADD_EVENT_ERROR[field],
          TOAST_POSITION.TOP_CENTER,
          `error_${field}`
        );
        valid = true; // Set to true if any field is empty
      }
    });
    const formData = new FormData();
    formData.append('file', formValue?.file);
    formData.append('name', formValue?.name);
    formData.append('status', formValue.status);
    formData.append('caption', formValue.caption);
    formData.append('ref', formValue.ref);
    formData.append('ref_id', formValue.ref_id);
    formData.append('project_id', project_id);
    if (!valid) {
      dispatch(
        AssetCreators.uploadNewVersionFile({ formData, event: event_id })
      );
      oncancelUpload();
    }
  };
  const oncancelUpload = () => {
    setFromValue({
      name: '',
      status: 'INPROGRESS',
      caption: '',
      ref: 'event',
      ref_id: '',
      file: null,
    });
    setUploadFile(false);
  };
  //

  const deltefile = (data) => {
    const { asset_id, version_id } = data;
    setisModalOpen({
      ...isModalOpen,
      isDeleteModal: true,
      assetId: asset_id,
      versionId: version_id,
    });
  };
  const deltefiles = () => {
    dispatch(
      Creators.deleteFolder({
        project_id,
        assetId: isModalOpen.assetId,
        versionId: isModalOpen.versionId,
        event: event_id,
      })
    );
    ondeleteCancel();
  };
  const ondeleteCancel = () => {
    setisModalOpen({
      ...isModalOpen,
      isDeleteModal: false,
      assetId: '',
      versionId: '',
    });
  };
  const onClickAsset = (data) => {
    const { asset_id, version_id } = data;
    navigate(
      `/${project_path}/${project_id}/asset_review?asset_id=${asset_id}&version_id=${version_id}&ref_type=event`
    );
  };
  const onEditCancel = () => {
    setIsEventEdit({
      ...isEventEdit,
      isEditModal: false,
    });
  };
  const onUpdate = () => {
    dispatch(
      PostsCreators.updateEventDetails({
        data: {
          id: event_id,
          title: isEventEdit.title,
          // allDay: true,
          // project_id: project_id,
          // status: 'ACTIVE',
          start: isEventEdit.createdDate,
          // ended_at: '2024-01-23T18:30:00.000Z',
          desc: isEventEdit.disc,
          event_id: event_id,
        },
      })
    );
    setIsEventEdit({
      ...isEventEdit,
      isEditModal: false,
    });
  };
  const CustomDateInput = forwardRef((props, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        style={{
          width: '100%',
          height: '35px',
          backgroundColor: '#121212',
          outline: 'none',
          border: 'none',
          color: '#909090',
          paddingLeft: '10px',
        }}
      />
    );
  });

  return (
    <EventListcontainer>
      <EventListHeader>
        <BAckAndTitle>
          <BackIcon
            onClick={() => {
              navigate(-1);
            }}
          >
            <DynamicSVGIcon iconUrl={Back} width="1rem" />
          </BackIcon>
          <TitleText>{assetMeta[event_id]?.event_name || ''}</TitleText>
        </BAckAndTitle>
        {/* <------------------------------------> */}
        <UploadFileBtn>
          <Button
            text={'Upload file'}
            width={100}
            height={35}
            bgColor={COLORS.BTN_GRADIENT}
            opacity={1}
            fontSize={'0.8rem'}
            // rightIcon={DropDownIcon}
            btnClick={() => {
              setUploadFile(true);
              setFromValue({
                ...formValue,
                ref_id: event_id,
              });
            }}
          />
        </UploadFileBtn>
      </EventListHeader>
      <div
        style={{
          display: 'flex',
        }}
      >
        {assetsData[event_id]?.length ? (
          <ProjectFolderCardsWrapper>
            {assetsData[event_id]?.map((data, index) => {
              return (
                <>
                  <AssetCards
                    data={data}
                    index={index}
                    deltefile={deltefile}
                    onclick={onClickAsset}
                  />
                </>
              );
            })}
          </ProjectFolderCardsWrapper>
        ) : (
          <>
            <Empty heading={strings.NOT_FOUND_ASSET} height={'85vh'} />
          </>
        )}
        <EventInfo>
          <InfoHeading>
            <Description>{strings.DETAIL}</Description>
            <div
              onClick={() => {
                setIsEventEdit({
                  ...isEventEdit,
                  isEditModal: true,
                  title: assetMeta[event_id]?.event_name,
                  disc: assetMeta[event_id]?.event_details?.description || '',
                  createdDate: new Date(
                    assetMeta[event_id]?.event_details?.started_at
                  ),
                });
              }}
            >
              <DynamicSVGIcon iconUrl={Edit} width="1.5rem" />
            </div>
          </InfoHeading>
          <CreatatedOnWrapper style={{ marginTop: '10px' }}>
            <CreatatedOnHeading>{strings.DATE}</CreatatedOnHeading>
            <CreatatedOnDate>
              {assetMeta[event_id]?.event_details?.started_at
                ? moment(assetMeta[event_id]?.event_details?.started_at).format(
                    'DD MMMM, YYYY'
                  )
                : '-'}
            </CreatatedOnDate>
          </CreatatedOnWrapper>
          <CreatatedOnWrapper style={{ marginTop: '10px' }}>
            <CreatatedOnHeading>{strings.EVENT_DESCRIPTION}</CreatatedOnHeading>
            <EventDescription>
              {assetMeta[event_id]
                ? assetMeta[event_id]?.event_details?.description || '-'
                : '-'}
            </EventDescription>
          </CreatatedOnWrapper>
        </EventInfo>
      </div>
      {/* <-------------------loading------------------> */}
      {uploadNewVersionFileLoading && (
        <Loading open={uploadNewVersionFileLoading} />
      )}

      {/*<-------------------Modal for upload folder-------------------->  */}
      <UniversalModal
        width={'600px'}
        height={'530px'}
        // rightBtnWidth={'120px'}
        isOpenModal={uploadFolder}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPLOAD}
        onrightbtnClick={uploadfolder}
        onleftbtnClick={
          // setUploadFile(false);
          oncancelUpload
        }
      >
        <UploadEventFile formValue={formValue} setFromValue={setFromValue} />
      </UniversalModal>
      {/* <--------------------------------delete modal---------------------> */}
      <UniversalModal
        isOpenModal={isModalOpen.isDeleteModal}
        leftText={'Cancel'}
        rightText={'Delete'}
        borderRadius={'14px'}
        btn={true}
        onleftbtnClick={ondeleteCancel}
        onrightbtnClick={deltefiles}
      >
        <div
          style={{
            width: '360px',
            height: '160px',
          }}
        >
          <ModalHeading>Delete fiels?</ModalHeading>
          <ModalSubHeading>You are about to delete this file</ModalSubHeading>
        </div>
      </UniversalModal>
      {/* <--------------------------------Edit modal---------------------> */}
      <UniversalModal
        width={'500px'}
        height={'460px'}
        isOpenModal={isEventEdit.isEditModal}
        leftText={'Cancel'}
        rightText={'Update'}
        borderRadius={'14px'}
        btn={true}
        onleftbtnClick={onEditCancel}
        onrightbtnClick={onUpdate}
      >
        <div>
          <TitleText style={{ textAlign: 'center', width: '100%' }}>
            {strings.UPDATE_DETAILS}
          </TitleText>
          <EventLabel>{strings.TITLE}</EventLabel>
          <Input
            width={'460px'}
            type="text"
            placeholder={strings.TITLE}
            onChange={(e) => {
              setIsEventEdit({
                ...isEventEdit,
                title: e.target.value,
              });
            }}
            value={isEventEdit?.title}
            maxLength={60}
          />
          <EventLabel>{strings.DATE}</EventLabel>
          <ReactDate>
            <ReactDatePicker
              selected={isEventEdit.createdDate}
              onChange={(date) => {
                // setStartDate(date);
                setIsEventEdit({
                  ...isEventEdit,
                  createdDate: date,
                });
              }}
              customInput={<CustomDateInput />}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat={'dd-MM-yyyy'}
            />
          </ReactDate>
          <EventLabel>{strings.EVENT_DESCRIPTION}</EventLabel>
          <DescriptionTextArea
            rows="8"
            cols="50"
            value={isEventEdit.disc}
            onChange={(e) => {
              setIsEventEdit({
                ...isEventEdit,
                disc: e.target.value,
              });
            }}
          />
        </div>
      </UniversalModal>
    </EventListcontainer>
  );
};
export default EventList;
