import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const BrandContainer = styled.div`
  width: calc(100vw - 100px);
  margin-top: 25px;
  margin-left: 95px;
`;
export const ModalHeading = styled.div`
  color: ${COLORS.WHITE};
  font-family: PoppinsRegular;
  font-size: 18px;
  padding-top: 15px;
`;
export const ModalSubHeading = styled.div`
  color: ${COLORS.NOTIFICATION_INACTIVE_COLOR};
  font-family: PoppinsRegular;
  font-size: 0.7rem;
  padding-top: 10px;
`;
