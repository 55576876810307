import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const OptionCardContainer = styled.div`
  // width: 85px;
  background-color: ${COLORS.BACKGROUND_COLOR};
  border-radius: 8px;
  position: absolute;
  right: ${({ right }) => right || '-10px'};
  top: ${({ top }) => top || '30px'};
  z-index: ${({ Index }) => (Index ? Index : 1)};
  padding: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;
export const OptionNameContainer = styled.div`
  display: flex;
  column-gap: 5px;
  padding-right: 5px;
  align-items: center;
  cursor: pointer;
`;
export const OptionIcons = styled.div``;
export const OptionName = styled.div`
  color: ${COLORS.TERTIARY_TEXT_COLOR};
  font-family: PoppinsRegular;
  font-size: 0.7rem;
  white-space: nowrap;
`;
