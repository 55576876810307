import { STRINGS } from '../../constants/labels';
import {
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { ModalHeading, ModalSubHeading } from '../../pages/brands/Brand.styles';
import { Col } from '../CalendarModal/CalendarModal.Styles';
import { FileLabel, Link, UploadInput } from './AddBrandForm.Styles';
import {
  CreateFolderContainer,
  Fileinput,
  FolderContainer,
  ModalHeadingContainer,
} from './CreateFolder.Styles';
import ProjectFolderCards from '../Cards/ProjectsFolderCards.View';
import { ErrorToast } from '../../services/toast/toast';
import { TOAST_POSITION } from '../../constants/BaseStyles';

const UploadFiles = ({ files, setFiles, file }) => {
  const strings = STRINGS['ENGLISH'];
  const handleChange = (event, type) => {
    if (event.target.files.length > 10) {
      ErrorToast(
        "Can't upload more then 10 files",
        TOAST_POSITION.TOP_CENTER,
        'error1'
      );
      return;
    }
    setFiles([...event.target.files]);
  };
  const onCrossClick = (index) => {
    // Create a copy of the files array
    const updatedFiles = [...files];
    // Remove the file at the specified index
    updatedFiles.splice(index, 1);
    // Update the state with the modified files array
    setFiles(updatedFiles);
  };
  return (
    <CreateFolderContainer>
      <ModalHeadingContainer>
        <ModalHeading>
          {file ? strings.UPLOAD_NEW_FILE : strings.UPLOAD_NEW_FOLDER}
        </ModalHeading>
        <ModalSubHeading>
          {file ? strings.UPLOAD_THE_FILE : strings.UPLOAD_THE_FOLDER}
        </ModalSubHeading>
      </ModalHeadingContainer>
      {/*  */}
      <FolderContainer>
        {files?.map((file, index) => {
          return (
            <>
              <ProjectFolderCards
                data={file}
                file={true}
                index={index}
                onCrossClick={onCrossClick}
                fileUploadType={true}
              />
            </>
          );
        })}
      </FolderContainer>

      {/*  */}
      <FileLabel
        style={{
          position: 'relative',
        }}
      >
        <Col width={'100%'}>
          <NameWrapper>
            {strings.DRAG_N_DROP_FILE} <Link>{strings.BROWSE_FILE}</Link>
          </NameWrapper>
          <RoleWrapper>{strings.FILE_UPLOAD_TEXT}</RoleWrapper>
        </Col>

        {file ? (
          <Fileinput
            type="file"
            multiple
            onChange={(event) => handleChange(event, 'file')}
          />
        ) : (
          <Fileinput
            type="file"
            multiple
            webkitdirectory="true"
            mozdirectory="true"
            directory="true"
            onChange={(event) => handleChange(event, 'folder')}
          />
        )}
      </FileLabel>
    </CreateFolderContainer>
  );
};
export default UploadFiles;
