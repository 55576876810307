import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const ProjectsFolderCardsContainer = styled.div``;
export const FolderCards = styled.div`
  background-color: ${({ file }) =>
    file ? COLORS.FOLDER_BG_COLOR : COLORS.NOTIFICATION_BG};
  width: ${({ file }) => (file ? '100px' : '175px')};
  height: ${({ file }) => (file ? '100px' : '130px')};
  border-radius: 8px;
  position: relative;
  padding: 5px 10px 10px 10px;
`;
export const FileText = styled.div`
  position: absolute;
  width: 90%;
  bottom: 10px;
`;
export const KababIcons = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  padding-right: 1.5px;
  padding-left: 1.5px;
`;
export const FolderIconContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
`;
export const CrossIcons = styled.div`
  position: absolute;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: ${COLORS.BTN_PRIMARY};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
