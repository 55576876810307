import 'react-tooltip/dist/react-tooltip.css';
import {
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import DynamicSVGIcon from '../DynamicSVGIcon';
import BlueKabab from '../../assets/icons/BlueKabab.svg';
import Close from '../../assets/icons/Close.svg';
import Folder from '../../assets/icons/folder.svg';
import { useState } from 'react';
import OptionCard from '../options/OptionCard';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import {
  CrossIcons,
  FileText,
  FolderCards,
  FolderIconContainer,
  KababIcons,
} from './ProjectsFolderCards.Styles';
import { PROJECT_OPTION } from '../../constants/constant';

const ProjectFolderCards = ({
  data,
  file = false,
  onCrossClick,
  index,
  onclickCard = () => {},
  onClickOption = () => {},
  fileUploadType = false,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  return (
    <FolderCards
      file={file}
      onClick={() => {
        onclickCard(data);
      }}
    >
      {file ? (
        <CrossIcons
          onClick={(e) => {
            e.stopPropagation();
            onCrossClick(data, index);
          }}
        >
          <DynamicSVGIcon iconUrl={Close} />
        </CrossIcons>
      ) : (
        <KababIcons
          onClick={(e) => {
            e.stopPropagation();
            setIsOptionsOpen(true);
          }}
        >
          <DynamicSVGIcon iconUrl={BlueKabab} width="0.18rem" />
          {isOptionsOpen && (
            <UseClickOutside
              onClickOutside={() => {
                setIsOptionsOpen(false);
              }}
            >
              <OptionCard
                onClickOption={onClickOption}
                additionalData={data}
                file={true}
                option={PROJECT_OPTION}
              />
            </UseClickOutside>
          )}
        </KababIcons>
      )}
      <FileText>
        <NameWrapper>
          {fileUploadType
            ? data.name
            : data?.name?.type === 'folder'
            ? data?.name?.name
            : data?.name?.file_name}
        </NameWrapper>
        {!file && <RoleWrapper>{data?.inside_file_count} files</RoleWrapper>}
      </FileText>
      <FolderIconContainer>
        <DynamicSVGIcon iconUrl={Folder} width={file ? '3rem' : '3.5rem'} />
      </FolderIconContainer>
    </FolderCards>
  );
};
export default ProjectFolderCards;
