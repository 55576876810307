/**
 * action js
 *
 * it will have all the add user related actions
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  addUser: ['payload'],
  addUserStart: ['payload'],
  addUserSuccess: ['payload'],
  addUserFailure: ['payload'],
  saveMode: ['payload'],
  //
  editUser: ['payload'],
  editUserStart: ['payload'],
  editUserSuccess: ['payload'],
  editUserFailure: ['payload'],
  editMode: ['payload'],
});
export { Types as AddUserTypes, Creators as AddUserCreators };
