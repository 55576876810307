/**
 * DynamicSVGIcon js
 *
 * component will return the DynamicSVGIcon just pass the url of svg as props
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 *
 */
import React from 'react';
import { ReactSVG } from 'react-svg';
const DynamicSVGIcon = ({
  iconUrl,
  width = '100%',
  rotate = 0,
  height = 'auto',
}) => (
  <ReactSVG
    src={iconUrl}
    beforeInjection={(svg) => {
      svg.classList.add('dynamicSVGIcon');
      svg.setAttribute(
        'style',
        `width: ${width};transform: rotate(${rotate}deg);height:${height}; cursor: pointer; display: flex; alignItems: center; justifyContent: center;  `
      );
    }}
  />
);
export default DynamicSVGIcon;
