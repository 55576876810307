/**
 * action js
 *
 * it will have all the login related actions
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  login: ['payload'],
  loginStart: null,
  loginSuccess: ['payload'],
  loginFailure: ['payload'],

  //store username
  storeUserDetails: ['payload'],

  logout: ['payload'],
  logoutStart: null,
  logoutSuccess: ['payload'],
  logoutFailure: ['payload'],

  checkMail: ['payload'],
  checkMailStart: null,
  checkMailSuccess: ['payload'],
  checkMailFailure: ['payload'],
});

export { Types, Creators };
