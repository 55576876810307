import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

const MyAgencyCardContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const MyAgencyIconWrapper = styled.div`
  background: ${({ background }) =>
    background || COLORS.PRIMARY_BACKGROUND_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Subtle box shadow with low opacity */
  transition: all 400ms ease;
`;

const MyAgencyIcon = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 8px;
  object-fit: fit;
`;

const MyAgencyIconText = styled.div`
  font-size: 18px;
  transition: all 400ms ease;
  text-align: center;
  color: ${COLORS.PRIMARY_BACKGROUND_COLOR};
  font-family: PoppinsSemiBold;
`;

const MyAgencyDetailsContainer = styled.div``;
const MyAgencyDetailsTitleContainer = styled.div``;
const MyAgencyDetailsTitle = styled.h2`
  font-family: PoppinsRegular;
  font-size: 18px;
  color: ${COLORS.WHITE};
  margin: 0;
  margin-top: 5px;
  padding: 0;
`;
const MyAgencyDetailsLinkContainer = styled.div``;
const MyAgencyDetailsLink = styled.a`
  font-family: PoppinsRegular;
  font-size: 14px;
  color: ${COLORS.GRAY};
`;

export {
  MyAgencyCardContainer,
  MyAgencyIconWrapper,
  MyAgencyIcon,
  MyAgencyIconText,
  MyAgencyDetailsContainer,
  MyAgencyDetailsTitleContainer,
  MyAgencyDetailsLinkContainer,
  MyAgencyDetailsTitle,
  MyAgencyDetailsLink,
};
