import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const CreateFolderContainer = styled.div`
  padding-left: 100px;
  padding-right: 100px;
`;
export const ModalHeadingContainer = styled.div``;
export const InputContainer = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;
export const ProjectInput = styled.input`
  background: ${COLORS.NOTIFICATION_BG};
  color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BACKGROUND_COLOR};
  border-radius: 8px;
  height: 35px;
  width: 100%;
  outline: none;
  padding-left: 10px;
  font-family: PoppinsRegular;
`;
export const CaptionInput = styled.textarea`
  background: ${COLORS.NOTIFICATION_BG};
  color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BACKGROUND_COLOR};
  border-radius: 8px;
  height: 80px;
  width: 100%;
  outline: none;
  padding-left: 10px;
  font-family: PoppinsRegular;
`;
export const InputLabel = styled.div`
  color: ${COLORS.NOTIFICATION_ACTIVE_COLOR};
  font-size: 0.7rem;
  font-family: PoppinsRegular;
`;
export const FolderContainer = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
  overflow-x: auto;
`;
export const Fileinput = styled.input`
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;
