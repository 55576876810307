/**
 * saga js
 *
 * it will have all the add user related sagas
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { AddUserCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';

// <---------------saga for add users----------------->
export function* addUserSaga(action) {
  try {
    const { data } = action.payload;
    yield put(AddUserCreators.addUserStart());
    const response = yield call(ApiService.post, apiEndPoints.addUser, {
      ...data,
    });
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].USER_ADDED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(AddUserCreators.addUserSuccess());
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AddUserCreators.addUserFailure(payload));
  }
}
// <---------------saga for Edit users----------------->
export function* editUserSaga(action) {
  try {
    const { data, update } = action.payload;
    yield put(AddUserCreators.editUserStart());
    const response = yield call(ApiService.post, apiEndPoints.editUSer, {
      ...data,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      SuccessToast(
        STRINGS['ENGLISH'].USER_UPDATED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(
        AddUserCreators.editUserSuccess({ userData: data, update: 'TRUE' })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AddUserCreators.editUserFailure(payload));
  }
}
