import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const EmptyProjectPage = styled.div`
  width: 100%;
  height: 100%;
`;
export const TableContainer = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '100vh')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PlaceHolderImage = styled.div``;
export const NoProjImage = styled.img`
  height: 250px;
`;
export const NoProjectText = styled.div`
  font-family: PoppinsRegular;
  font-size: 30px;
  color: ${COLORS.TEXT_GREY};
  margin-bottom: 10px;
`;
export const AddProjectContainer = styled.div``;
export const AddNewText = styled.div`
  margin-bottom: 10px;
  font-family: PoppinsRegular;
  font-size: 15px;
  color: ${COLORS.TEXT_GREY};
  text-align: center;
`;
