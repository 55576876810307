/**
 * saga js
 *
 * it will have all the  user list related sagas
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { UserCreators } from './action';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { DEFAULT_CLIENT_PAGE_SIZE, CLIENT } from '../../../constants/constant';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { STRINGS } from '../../../constants/labels';
import { TOAST_POSITION } from '../../../constants/BaseStyles';

// <---------------saga for featch user list ----------------->
export function* featchUserListSaga(action) {
  try {
    const { pageNumber, sortParams, input, companyId } = action.payload;
    const filter = {};
    if (companyId) {
      filter['filter_by_company_id'] = [companyId];
    }
    yield put(UserCreators.getUserListStart());
    const response = yield call(ApiService.post, apiEndPoints.users, {
      query_item: input,
      page_size: DEFAULT_CLIENT_PAGE_SIZE,
      page: pageNumber,
      ...filter,
      ...sortParams,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(UserCreators.getUserListSuccess({ data: data?.meta }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(UserCreators.getUserListFailure(payload));
  }
}
// <---------------saga for delete user  ----------------->
export function* deleteUserSaga(action) {
  try {
    const { user_type, email } = action.payload;
    yield put(UserCreators.deleteUserStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteUser, {
      params: {
        email: email,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].USER_DELETED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(UserCreators.deleteUserSuccess());
      yield put(
        UserCreators.getUserListAgency({
          user_type
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(UserCreators.deleteUserFailure(payload));
  }
}
// <---------------saga for select Clients----------------->
export function* selectClientSaga(action) {
  const { type } = action.payload;

  try {
    const { search } = action.payload;
    yield put(UserCreators.selectClientStart());
    const response = yield call(
      ApiService.get,
      type === CLIENT
        ? apiEndPoints.selectCompany
        : apiEndPoints.selectUserCompany,
      apiEndPoints.selectCompany,
      {
        params: {
          query_item: search,
          type: type,
        },
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(UserCreators.selectClientSuccess({ selectData: data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(UserCreators.selectClientFailure(payload));
  }
}

// <---------------saga for featch user list ----------------->
export function* getUserListAgencySaga(action) {
  try {
    const { user_type } = action.payload;
    yield put(UserCreators.getUserListAgencyStart());
    const response = yield call(ApiService.get, apiEndPoints.usersAgency, {
      params: {
        company_id: '',
        user_type,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(UserCreators.getUserListAgencySuccess({ data: data?.meta }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(UserCreators.getUserListAgencyFailure(payload));
  }
}
