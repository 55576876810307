/**
 * action js
 *
 * it will have all the asset related actions
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  //   getPosts: ['payload'],
  //   getPostsStart: ['payload'],
  //   getPostsSuccess: ['payload'],
  //   getPostsError: ['payload'],
  addAsset: ['payload'],
  addAssetStart: ['payload'],
  addAssetSuccess: ['payload'],
  addAssetError: ['payload'],
  resetState: ['payload'],
});

export { Types as AssetsTypes, Creators as AssetsCreators };
