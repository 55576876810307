import {
  DropDownIcons,
  DropDownListContainer,
  DropDownText,
  OptionDropDownContainer,
} from './TabsTable.Style';
import DynamicSVGIcon from '../DynamicSVGIcon';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';

const OptionDropDown = ({ entry, rowOptions, closeDropdownOptions }) => {
  return (
    <UseClickOutside
      onClickOutside={() => {
        closeDropdownOptions();
      }}
    >
      <OptionDropDownContainer>
        {rowOptions?.map((data, index) => {
          return (
            <DropDownListContainer
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                data?.handleClick(entry);
                closeDropdownOptions();
              }}
            >
              <DropDownIcons>
                <DynamicSVGIcon iconUrl={data?.iconUrl} />
              </DropDownIcons>
              <DropDownText>{data?.label}</DropDownText>
            </DropDownListContainer>
          );
        })}
      </OptionDropDownContainer>
    </UseClickOutside>
  );
};
export default OptionDropDown;
