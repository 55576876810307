/**
 * saga js
 *
 * it will have all the project related sagas
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { AddProjectsCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { STRINGS } from '../../../constants/labels';
import { BrandCreators } from '../../brands/store/action';

//TEXT
const strings = STRINGS['ENGLISH'];

export function* addProjectSaga(action) {
  const { addProjectData, companyId } = action.payload;
  try {
    yield put(AddProjectsCreators.addProjectStart());
    const response = yield call(ApiService.post, apiEndPoints.createProject, {
      ...addProjectData,
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(AddProjectsCreators.addProjectSuccess());
      SuccessToast(STRINGS['ENGLISH'].PROJECT_ADDED, TOAST_POSITION.TOP_CENTER);
      yield put(BrandCreators.getProjectsByCompanyId({ companyId: companyId }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AddProjectsCreators.addProjectError(payload));
  }
}
// <----------------------get uers list---------------->
export function* getUSerLsitSaga(action) {
  const { companyId } = action.payload;
  try {
    yield put(AddProjectsCreators.userListStart());
    const response = yield call(ApiService.get, apiEndPoints.userList, {
      params: {
        company_id: companyId,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(AddProjectsCreators.userListSuccess({ userList: data }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AddProjectsCreators.userListFailure(payload));
  }
}
// <----------------------get uers list---------------->
export function* getfilteredUserListSaga(action) {
  const { companyId } = action.payload;
  try {
    yield put(AddProjectsCreators.userListStart());
    const response = yield call(ApiService.get, apiEndPoints.userList, {
      params: {
        company_id: companyId,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        AddProjectsCreators.filteredUserListSuccess({ userList: data })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AddProjectsCreators.userListFailure(payload));
  }
}

// <----------------------get project data--------------->
export function* editProjectSaga(action) {
  const { projectData, update } = action.payload;
  try {
    yield put(AddProjectsCreators.editProjectStart());
    const response = yield call(ApiService.post, apiEndPoints.updateProject, {
      ...projectData,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;

      if (update) {
        SuccessToast(
          STRINGS['ENGLISH'].PROJECT_UPDATED,
          TOAST_POSITION.TOP_CENTER,
          'success1'
        );
      }
      yield put(
        AddProjectsCreators.editProjectSuccess({
          projectViewData: data,
          update,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AddProjectsCreators.editProjectFailure(payload));
  }
}
