import styled from 'styled-components';
import searchicon from '../../assets/images/searchicon.png';
import { COLORS } from '../../constants/BaseStyles';
export const ProjectsContainer = styled.div`
  margin-top: 55px;
  margin-left: 55px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const ClientHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  align-items: center;
`;
export const SearchInput = styled.div``;
export const InputSearch = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  width: 200px;
  border-bottom: 1px solid #a0a6b2;
  padding: 12px 20px 12px 40px;
  background-image: url(${searchicon});
  background-repeat: no-repeat;
  background-position: 10px 10px;
  outline: none;
  text-decoration: none;
  ::placeholder {
    color: green;
  }
`;

export const FilterBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;
export const FilterContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1.5px solid #000;
`;
export const FilterContainerExpanded = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 100px;
  margin: 15px;
`;
export const ProjectFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start
  align-items: center;
  padding-left: 20px;
  margin-left: 455px;
`;
export const ProjectNameLable = styled.div`
  color: #000;
  font-size: medium;
  font-weight: 600;
`;
export const CloseIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;
export const ReactSelectContainer = styled.div`
  width: 200px;
  margin-bottom: 10px;
`;
export const ProjectFolderWrapper = styled.div`
  // width: 95%;
  // overflow-y: auto;
  // overflow-x: hidden;
  margin-left: 100px;
  margin-top: 70px;
`;
export const FileOption = styled.div`
  color: ${COLORS.TEXT};
`;
export const InputdivContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: ${(props) => (props.rowGap ? props.rowGap : '25px')};

  align-items: center;
`;
export const DropDownDiv = styled.div`
  position: absolute;
  top: 40px;
  background-color: ${COLORS.PRIMARY_BACKGROUND_COLOR};
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 10px;
  padding: 10px;
  border-radius: 5px;
  min-width: 150px;
`;
export const ChooseOption = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
export const HeaderWraper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.bet ? 'space-between' : 'flex-end')};
  padding-top: 10px;
  padding-right: 10px;
`;
export const ProjectFolderHeaderWrapper = styled.div`
  width: 100%;
`;
export const DragBorder = styled.div`
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0;
  border: 1px dashed ${COLORS.GRAY};
  border-radius: 6px;
  overflow-y: auto;
  background-color: #e6e6e6;
`;
export const FolderInput = styled.input`
  width: 100%;
  height: 100%;
`;
export const UploadFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const UploadBtn = styled.button`
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  &:hover {
    text-decoration-line: underline;
  }
`;
export const InputFileUpload = styled.label`
  display: none;
`;

export const FilseContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
`;
export const FilesTypeAndName = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  background-color: ${COLORS.DISABLED_GRAY};
`;
export const FileName = styled.div``;
export const FileUploadDiv = styled.div`
  position: absolute;
  left: 29%;
  top: 40%;
`;

export const CreateProjectInput = styled.input`
  background: ${COLORS.PRIMARY_BACKGROUND_COLOR};
  color: #525865;
  border-radius: 4px;
  border: 1px solid ${COLORS.BORDER};
  box-shadow: inset 0px 1px 8px ${COLORS.BOX_SHADOW_COLOR};

  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  -webkit-transition: 0.18s ease-out;
  -moz-transition: 0.18s ease-out;
  -o-transition: 0.18s ease-out;
  transition: 0.18s ease-out;
  &:hover {
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.02);
  }
  &:focus {
    border: 1px solid ${COLORS.BORDER};
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01),
      0px 0px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const ProjectDateWrapper = styled.div`
  .react-datepicker {
    box-shadow: 3px 2px 11px rgb(0 0 0 / 30%);
  }
  .react-datepicker__input-container input,
  .react-datepicker__input-container input::placeholder {
    width: 220px;
    font-size: 12px;
    color: ${COLORS.TEXT_MEDIUM_GREY};
    z-index: 99999 !important;
  }

  .react-datepicker-popper {
    z-index: 99999 !important;
  }
`;

export const ProjectTypeWrapper = styled.div`
  width: 220px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;

export const P = styled.p`
  font-size: 13px;
  text-decoration: ${({ isLink }) => (isLink ? 'underline' : 'none')};
  color: ${({ isLink }) =>
    isLink ? `${COLORS.LINK_BLUE}` : ` ${COLORS.TEXT_NEAR_WHITE}`};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
`;
export const PathOrderContainer = styled.div``;
export const PathContainer = styled.div``;
export const PathName = styled.div``;
export const ArrowSymbol = styled.div``;
export const ProjectsCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-left: ${({ noMarginLeft }) => (noMarginLeft ? '0px' : '95px')};
  overflow: scroll;
  margin-top: 140px;
`;
export const ProjectFolderCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  max-height: calc(100vh - 205px);
  overflow-y: auto;
  width: 70%;
  height: 100%;
`;
export const WebsitePagesContainer = styled.div`
  margin-left: 100px;
  margin-top: 70px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;
