/**
 * reducer js
 *
 * it will have all the login related reducers
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';
import { act } from 'react-dom/test-utils';

const INITIAL_STATE = {
  loginLoading: false,
  validationSuccess: false,
  fullName: '',
  loggedin: false,
  accessToken: '',
  errorMessage: '',
  checkMailLoading: false,
  checked: false,
  mailExist: '',
};

// will trigger when called from login saga.
// it will updating the login loading indicator login action
const loginStart = (state = INITIAL_STATE) => ({
  ...state,
  loginLoading: true,
  validationSuccess: false,
});

// will trigger when called from login saga and on app initialzation(if token exists in cookies).
// it will set the auth state and then user can use the application
const loginSuccess = (state = INITIAL_STATE, action) => {
  const data = action.payload;
  const { email, first_name, last_name, username } = action.payload;

  return {
    ...state,
    loginLoading: false,
    errorMessage: '',
    loggedin: true,
    fullName: first_name + last_name,
    // accessToken,
    validationSuccess: true,
  };
};

// then login is failed.
const loginFailure = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loginLoading: false,
    validationSuccess: false,
    errorMessage,
  };
};

const checkMailStart = (state = INITIAL_STATE) => ({
  ...state,
  checkMailLoading: true,
  checked: false,
  mailExist: '',
});

const checkMailSuccess = (state = INITIAL_STATE) => ({
  ...state,
  checkMailLoading: false,
  checked: true,
  mailExist: 'SUCCESS',
});

const checkMailFailure = (state = INITIAL_STATE) => ({
  ...state,
  checkMailLoading: false,
  checked: true,
  mailExist: 'FAILURE',
});

// authReducers.js
const storeUserDetails = (state = INITIAL_STATE, action) => {
  const {
    payload: { first_name, last_name },
  } = action;

  return {
    ...state,
    user: {
      ...state.user,
      first_name,
      last_name,
    },
  };
};

const logoutStart = (state = INITIAL_STATE) => ({
  ...state,
  logoutLoading: true,
});

const logoutSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  logoutLoading: false,
  logoutUser: 'Success',
  accountHomeId: action.payload.accountHomeId,
});

const logoutFailure = (state = INITIAL_STATE) => ({
  ...state,
  logoutLoading: false,
  logoutUser: 'Failure',
});

//Maping the action types with their functions which will update the store
const HANDLERS = {
  [Types.LOGIN_START]: loginStart,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT_START]: logoutStart,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
  [Types.CHECK_MAIL_START]: checkMailStart,
  [Types.CHECK_MAIL_SUCCESS]: checkMailSuccess,
  [Types.CHECK_MAIL_FAILURE]: checkMailFailure,
  [Types.STORE_USER_DETAILS]: storeUserDetails,
};

export const authReducer = createReducer(INITIAL_STATE, HANDLERS);
