/**
 * reducer js
 *
 * it will have all the calendar related reducers
 *
 * Project: Marko
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { createReducer } from 'reduxsauce';
import { PostsTypes } from './action';

const INITIAL_STATE = {
  postsLoading: false,
  postsData: [],
  error: false,
  loading: true,
  addPostSuccessful: false,
  postsListData: [],
};
// <---------------reducer for add posts to calendar----------------->
const addPostStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: true,
    addPostSuccessful: false,
  };
};
const addPostSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: false,
    loading: false,
    addPostSuccessful: true,
  };
};
const addPostError = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loading: false,
    error: true,
    errorMessage,
    addPostSuccessful: false,
  };
};
// <---------------reducer for get posts for calendar----------------->
const getPostsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    postsLoading: true,
  };
};

const getPostsSuccess = (state = INITIAL_STATE, action) => {
  let postsList = [];
  const { data } = action.payload;
  postsList = data;

  return {
    ...state,
    postsLoading: false,
    // postsListHeader: data,
    postsListData: postsList,
  };
};

const getPostsError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    postsLoading: false,
  };
};
const resetStateReduer = (state = INITIAL_STATE, action) => {
  return {
    postsLoading: true,
  };
};
// <--------------------------------------------------------------------->
const updateEventDetailsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    error: false,
    // postsListHeader: data,
  };
};

const updateEventSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};
const updateEventDetailsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    error: true,
    loading: false,
  };
};
const HANDLERS = {
  [PostsTypes.GET_POSTS_START]: getPostsStart,
  [PostsTypes.GET_POSTS_SUCCESS]: getPostsSuccess,
  [PostsTypes.GET_POSTS_ERROR]: getPostsError,
  [PostsTypes.ADD_POST_START]: addPostStart,
  [PostsTypes.ADD_POST_SUCCESS]: addPostSuccess,
  [PostsTypes.ADD_POST_ERROR]: addPostError,
  [PostsTypes.UPDATE_EVENT_DETAILS_START]: updateEventDetailsStart,
  [PostsTypes.UPDATE_EVENT_DETAILS_SUCCESS]: updateEventSuccess,
  [PostsTypes.UPDATE_EVENT_DETAILS_ERROR]: updateEventDetailsFailure,
};
export const PostsReducer = createReducer(INITIAL_STATE, HANDLERS);
