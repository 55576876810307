import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props ? props.bgColor : COLORS.PRIMARY_COLOR)};
  border-radius: 8px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  cursor: pointer;
`;
export const ButtonText = styled.div`
  color: ${COLORS.PRIMARY_STROKE_COLOR};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-family: PoppinsRegular, sans-serif;
`;
export const RightIcon = styled.div`
  padding-left: ${({ paddingleft }) => paddingleft || '10px'};
`;
