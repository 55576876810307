import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';

export const WebsiteHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;
export const WebsiteNameAndStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const WebsiteFeebackContainer = styled.div`
  margin-top: 70px;
  margin-left: 95px;
  background-color: ${COLORS.PRIMARY_BLACK};
`;
export const BackIconAndName = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;
export const BackIconContainer = styled.div``;
export const WebSiteName = styled.div`
  color: ${COLORS.WHITE};
  font-family: PoppinsRegular;
  font-size: 0.9rem;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const PagesAndVersion = styled.div`
  display: flex;
  column-gap: 10px;
`;
export const AddVersionBtn = styled.div`
  padding-right: 15px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;
export const PageBtn = styled.div``;
export const VersionBtn = styled.div`
  position: relative;
`;
export const shareBtn = styled.div``;
//
export const WebsiteAndComment = styled.div`
  display: flex;
  display: flex;
  width: 100%;
`;
export const WebsiteContainer = styled.div`
  width: 60%;
  height: 80vh;
`;
export const CommentContainer = styled.div`
  width: 4%;
`;
