import React, { useEffect, useState } from 'react';
import TabsTable from '../../components/table/Table.View';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from './store/action';
import {
  DEFAULT_CLIENT_PAGE_SIZE,
  ROW_OPTION_LABELS,
} from '../../constants/constant';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SubHeader from '../../components/SubHeader/SubHeader.View';
import UniversalModal from '../../components/modals/UniversalModal';
import AddLinkForm from '../../components/form/AddLinkForm.View';
import { STRINGS } from '../../constants/labels';
import StatusComponent from '../../components/Status/StatusComponent.View';
import { ModalHeading, ModalSubHeading } from '../brands/Brand.styles';
import Empty from '../../components/EmptyFileComponent/EmptyFile.View';

const ProjectsTableList = () => {
  //TEXT
  const strings = STRINGS['ENGLISH'];
  //navigation
  const navigate = useNavigate();
  //
  const params = useParams();
  const { project_id } = params;
  const { userData } = useSelector((state) => state.loggedinUser);
  const { username } = userData;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const projectName = searchParams.get('project_name');

  const [shortListData, setShortListData] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [deleteModalOpen, setIsDeleteModalOpen] = useState({
    deleteModal: false,
    selectedId: '',
  });
  const [editModalOpen, setIsEditModalOpen] = useState({
    editModal: false,
    selectedId: '',
  });
  const [hasMore, setHasMore] = useState(true);
  const [addLinkData, setAddLinkData] = useState({
    title: '',
    link: '',
    status: {},
    link_id: '',
  });

  const { projectDetails } = useSelector((state) => state.project);
  const { projectTableListData, projectListHeader } = useSelector(
    (state) => state.project
  );

  //default: display list of data
  useEffect(() => {
    dispatch(
      Creators.viewProjectTableList({
        projectId: project_id,
        pageNumber: 1,
      })
    );
  }, [dispatch, project_id]);

  const editHandleClick = (data) => {
    const { link_id, title, link, status } = data;
    setIsEditModalOpen({
      ...editModalOpen,
      editModal: true,
      selectedId: link_id,
    });
    setAddLinkData({
      title: title,
      link: link,
      status: status,
      link_id: link_id,
      updated_by: username,
    });
  };
  // triggers the input when the button is clicked

  const deleteproject = (data) => {
    const { link_id } = data;
    setIsDeleteModalOpen({
      ...deleteModalOpen,
      deleteModal: true,
      selectedId: link_id,
    });
  };

  const editWebsite = () => {
    if (project_id) {
      dispatch(
        Creators.editLink({
          editData: { ...addLinkData, project_id },
          projectId: project_id,
        })
      );
    }

    onCancelEdit();
  };
  //
  const onCancelEdit = () => {
    setIsEditModalOpen({
      ...editModalOpen,
      editModal: false,
      selectedId: null,
    });
    setAddLinkData({});
  };
  //
  const onDelete = () => {
    dispatch(
      Creators.deleteLink({
        linkId: deleteModalOpen?.selectedId,
        projectId: project_id,
      })
    );
    onCancelDelete();
  };
  //
  const onCancelDelete = () => {
    setIsDeleteModalOpen({
      ...deleteModalOpen,
      deleteModal: false,
      selectedId: null,
    });
  };
  //
  /*------------Infinite scroll-----------------*/
  const pageNumber = Number(projectListHeader[project_id]?.pageNumber || 1);

  const hasMoreData = () => {
    return projectListHeader[project_id]?.count <=
      pageNumber * DEFAULT_CLIENT_PAGE_SIZE
      ? true
      : false;
  };
  const onFetchMoreData = () => {
    if (hasMoreData()) {
      setHasMore(false);
      return;
    } else {
      dispatch(
        Creators.viewProjectTableList({
          projectId: project_id,
          pageNumber: pageNumber + 1,
        })
      );
    }
  };
  return (
    <>
      <SubHeader
        buttonDisplay={'none'}
        title={projectDetails[project_id]?.project_name || projectName}
        customBackFunction={() => {
          const url = `/brands/${projectDetails[project_id]?.base_company_id}?company_name=${projectDetails[project_id]?.base_company_name}`;
          navigate(url);
        }}
      />
      <TabsTable
        scrollHeight={'calc(100vh - 200px)'}
        metaDetails={projectListHeader[project_id] || {}}
        dataDetails={projectTableListData[project_id] || []}
        shortListData={shortListData}
        handleShortList={setShortListData}
        checkList={checkList}
        setCheckList={setCheckList}
        scrollInfinite={true}
        checkBoxDisable={true}
        ObjectComponent={{ status: StatusComponent }}
        // onItemSelect={onItemClick}
        // loading={projectsListLoading}
        // handleSortBy={handleSortBy}
        loader={<h4>Loading...</h4>}
        scrollDetails={{
          hasMore: hasMore,
          onFetchMoreData: onFetchMoreData,
        }}
        rowOptions={ROW_OPTION_LABELS(editHandleClick, deleteproject)}
      />
      <Empty
        heading={strings.NOT_RECORD_FOUND}
        hubheading1={strings.ADD_NEW_ENTRY}
        height={'85vh'}
      />
      {/* <-------------------Modals for add website-------------------> */}
      <UniversalModal
        width={'600px'}
        height={'420px'}
        isOpenModal={editModalOpen?.editModal}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPDATE}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={editWebsite}
        onleftbtnClick={onCancelEdit}
      >
        <AddLinkForm
          edit={true}
          addLinkData={addLinkData}
          setAddLinkData={setAddLinkData}
        />
      </UniversalModal>
      {/* <-------------> */}
      {/* <----------------------delete the brand---------------------> */}
      <UniversalModal
        isOpenModal={deleteModalOpen.deleteModal}
        leftText={'Cancel'}
        rightText={'Delete'}
        borderRadius={'14px'}
        btn={true}
        onleftbtnClick={onCancelDelete}
        onrightbtnClick={onDelete}
      >
        <div
          style={{
            width: '360px',
            height: '160px',
          }}
        >
          <ModalHeading>Delete Website?</ModalHeading>
          <ModalSubHeading>
            You are about to delete this website
          </ModalSubHeading>
        </div>
      </UniversalModal>
    </>
  );
};

export default ProjectsTableList;
