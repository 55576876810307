/**
 * saga js
 *
 * it will have all the project related sagas
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import {
  ApiService,
  ApiServiceMultipart,
} from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { Creators as DashboardCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { STRINGS } from '../../../constants/labels';
import { DEFAULT_CLIENT_PAGE_SIZE } from '../../../constants/constant';
import { BrandCreators } from '../../brands/store/action';
import { store } from '../../../stores/store';
import { WebpageCreators } from '../../WebsiteFeedback/store/action';
import { EventCreators } from '../../viewPosts/store/action';

//TEXT
const strings = STRINGS['ENGLISH'];

// <---------------projects list by brand ID saga----------------->
export function* viewProjectTableListSaga(action) {
  try {
    const { projectId, pageNumber = 1 } = action.payload;
    yield put(DashboardCreators.viewProjectTableListStart());
    const response = yield call(ApiService.get, apiEndPoints.listLinks, {
      params: {
        project_id: projectId,
        page: pageNumber,
        page_size: DEFAULT_CLIENT_PAGE_SIZE,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response?.data;
      yield put(
        DashboardCreators.viewProjectTableListSuccess({
          data: data?.meta,
          projectId,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.viewProjectTableListError(payload));
  }
}
// <---------------projects list saga----------------->
export function* projectsListSaga(action) {
  try {
    const { pageNumber = 1, input = '' } = action.payload;
    yield put(DashboardCreators.projectsListStart());
    const response = yield call(ApiService.post, apiEndPoints.viewProjects, {
      page_size: DEFAULT_CLIENT_PAGE_SIZE,
      page: pageNumber,
      query_item: input,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(DashboardCreators.projectsListSuccess({ data: data?.meta }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.projectsListError(payload));
  }
}
// <---------------delete project saga----------------->
export function* deleteProjectSaga(action) {
  try {
    const { project_id, company_id } = action.payload;
    yield put(DashboardCreators.deleteProjectStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteProject, {
      params: {
        project_id: project_id,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].PROJECT_DELETED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.deleteProjectSuccess());
      if (company_id) {
        yield put(
          BrandCreators.getProjectsByCompanyId({ companyId: company_id })
        );
      } else {
        yield put(DashboardCreators.projectsList({}));
      }
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.deleteProjectFailure(payload));
  }
}

// <---------------file an folder list saga----------------->
export function* getFilesSaga(action) {
  try {
    const { payload } = action?.payload;
    yield put(DashboardCreators.fileListStart());
    const response = yield call(ApiService.get, apiEndPoints.fileList, {
      params: payload,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        DashboardCreators.fileListSuccess({
          data: data?.meta,
          projectId: payload?.project_id,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.fileListFailure(payload));
  }
}
// <---------------create folder saga----------------->
export function* createFolderSaga(action) {
  try {
    const { payload } = action?.payload;
    yield put(DashboardCreators.CreateFolderStart());
    const response = yield call(ApiService.post, apiEndPoints.createFolder, {
      ...payload,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(DashboardCreators.CreateFolderSuccess({ data: data?.meta }));
      const payloadData = {
        project_id: payload?.project_id,
        search_item: '',
        page_size: DEFAULT_CLIENT_PAGE_SIZE,
        page: 1,
      };
      if (payload?.base_folder_id) {
        payloadData['folder_id'] = payload?.base_folder_id;
      }
      SuccessToast(
        STRINGS['ENGLISH'].FOLDER_CREATED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.fileList({ payload: payloadData }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.CreateFolderFailure(payload));
  }
}

// <---------------upload file saga----------------->
export function* uploadFolderSaga(action) {
  try {
    const { files, project_id, projectType, folderId } = action?.payload;
    yield put(DashboardCreators.uploadFolderStart({ files }));
    const responses = yield Promise.all(
      files.map(async (file, index) => {
        const split = file?.webkitRelativePath.split('/');
        if (split) {
          split.pop();
        }
        const result = split.join('/');
        const formData = new FormData();
        formData.append('files', file);
        formData.append('name', file?.name || 'file');
        formData.append('project_id', project_id);
        formData.append('module', projectType);
        formData.append('dir', `/${result}/`);
        if (folderId) {
          formData.append('folder_id', folderId);
        }
        return await ApiServiceMultipart.post(
          apiEndPoints.uploadFolder,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / total);
              // store.dispatch(
              //   DashboardCreators.updateUploadProgress({ percentCompleted })
              // );
              store.dispatch(
                DashboardCreators.updateUploadProgress({
                  percentCompleted,
                  fileName: file.name,
                })
              );
            },
          }
        );
      })
    );
    // if (responses) {
    //   setIsFileUploading(false);
    // }
    if (responses[0].status >= 200 && responses[0].status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].FOLDER_UPLOADED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      const payload = {
        project_id,
        module: projectType,
        search_item: '',
        page_size: DEFAULT_CLIENT_PAGE_SIZE,
        page: 1,
      };
      yield put(DashboardCreators.uploadFolderSuccess());
      if (folderId) {
        payload['folder_id'] = folderId;
      }
      yield put(DashboardCreators.fileList({ payload }));
    } else {
      throw responses;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.uploadFolderFailure(payload));
  }
}
// <---------------upload  saga----------------->
export function* uploadFileSaga(action) {
  try {
    const { formData, project_id, projectType, folderId } = action?.payload;
    yield put(DashboardCreators.uploadFileStart());
    const response = yield call(
      ApiServiceMultipart.post,
      apiEndPoints.uploadFile,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          // const { loaded, total } = progressEvent;
          // const percentCompleted = Math.round((loaded * 100) / total);
          // // console.log(percentCompleted, 'percentCompleted');
          // // store.dispatch(
          // //   DashboardCreators.updateUploadProgress({
          // //     percentCompleted,
          // //     fileName: file.name,
          // //   })
          // // );
        },
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      SuccessToast(
        STRINGS['ENGLISH'].FILE_UPLOADED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.uploadFileSuccess({ data: data?.meta }));
      const payload = {
        project_id,
        module: projectType,
        search_item: '',
        page_size: DEFAULT_CLIENT_PAGE_SIZE,
        page: 1,
      };
      yield put(DashboardCreators.uploadFolderSuccess());
      if (folderId) {
        payload['folder_id'] = folderId;
      }
      yield put(DashboardCreators.fileList({ payload }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.uploadFileFailure(payload));
  }
}
// <---------------delete folder saga----------------->
export function* deleteFolderSaga(action) {
  try {
    const {
      project_id,
      projectType,
      folderId,
      SelectedFolderId,
      assetId,
      versionId,
      event = false,
    } = action?.payload;
    const params = {};
    if (versionId) {
      params['version_id'] = versionId;
    }
    if (assetId) {
      params['asset_id'] = assetId;
    } else {
      params['folder_id'] = SelectedFolderId;
    }
    yield put(DashboardCreators.deleteFolderStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteFolder, {
      params: params,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      SuccessToast(
        STRINGS['ENGLISH'].FILE_DELETED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.deleteFolderSuccess({ data: data?.meta }));
      const payload = {
        project_id,
        module: projectType,
        search_item: '',
        page_size: DEFAULT_CLIENT_PAGE_SIZE,
        page: 1,
      };
      if (folderId) {
        payload['folder_id'] = folderId;
      }
      if (event) {
        yield put(EventCreators.getAssets({ ref: 'event', ref_id: event }));
      } else {
        yield put(DashboardCreators.fileList({ payload }));
      }
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.deleteFolderFailure(payload));
  }
}
//
// <----------------------get project data--------------->
export function* projectDetailsSaga(action) {
  const { projectId } = action.payload;
  try {
    yield put(DashboardCreators.projectDetailsStart());
    const response = yield call(ApiService.get, apiEndPoints.projectDetails, {
      params: {
        project_id: projectId,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response?.data;
      yield put(DashboardCreators.projectDetailsSuccess({ data, projectId }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.editProjectFailure(payload));
  }
}
// <----------------------edit project data--------------->
export function* updateProjectSaga(action) {
  const { updateProjectData } = action.payload;
  try {
    yield put(DashboardCreators.updateProjectStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.updateProject,
      updateProjectData
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response?.data;
      yield put(DashboardCreators.updateProjectSuccess({ data }));
      yield put(
        DashboardCreators.projectDetails({
          projectId: updateProjectData?.project_id,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.updateProjectFailure(payload));
  }
}
// <---------------add website saga----------------->
export function* addLinkSaga(action) {
  try {
    const { data } = action?.payload;
    yield put(DashboardCreators.addLinkStart());
    const response = yield call(ApiService.post, apiEndPoints.addLink, data);
    if (response.status >= 200 && response.status < 300) {
      // const { data } = response;
      SuccessToast(
        STRINGS['ENGLISH'].LINK_ADDED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.addLinkSuccess({}));

      yield put(
        DashboardCreators.viewProjectTableList({ projectId: data?.project_id })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.deleteFolderFailure(payload));
  }
}
// <---------------delete website saga----------------->
export function* deleteLinkSaga(action) {
  try {
    const { linkId, projectId } = action?.payload;
    yield put(DashboardCreators.deleteLinkStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteLink, {
      params: {
        link_id: linkId,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].LINK_DELETED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.deleteLinkSuccess({}));

      yield put(DashboardCreators.viewProjectTableList({ projectId }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.deleteLinkError(payload));
  }
}
// <---------------delete website saga----------------->
export function* editLinkSaga(action) {
  try {
    const { editData, projectId } = action?.payload;
    yield put(DashboardCreators.editLinkStart());
    const response = yield call(
      ApiService.post,
      apiEndPoints.editLink,
      editData
    );
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].LINK_DELETED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.editLinkSuccess({}));

      yield put(DashboardCreators.viewProjectTableList({ projectId }));
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.editLinkError(payload));
  }
}
// <-------------- website list for scraping saga-------------->

export function* websiteListSaga(action) {
  const { page, projectID } = action?.payload;
  try {
    yield put(DashboardCreators.getWebPageListStart());
    const response = yield call(ApiService.get, apiEndPoints.getAllWebpages, {
      // payload,
      params: {
        page: page,
        page_size: DEFAULT_CLIENT_PAGE_SIZE,
        project_id: projectID,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(
        DashboardCreators.getWebPageListSuccess({
          data: data?.meta,
          projectId: projectID,
        })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.getWebPageListFailure(payload));
  }
}
// <--------------add website url for scraping saga-------------->

export function* addWebpagesSaga(action) {
  const { url, projectId, username, webpage_id } = action.payload;
  try {
    yield put(DashboardCreators.addWebpagesStart());
    const response = yield call(ApiService.post, apiEndPoints.scrapeWebpage, {
      project_id: projectId,
      webpage_name: 'website_name',
      url: url,
      added_by_user_name: username,
      webpage_id,
    });

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      yield put(DashboardCreators.addWebpagesSuccess({ data: data?.data }));
      if (webpage_id) {
        yield put(
          WebpageCreators.getWebpageVersions({
            webpage_id,
            project_id: projectId,
          })
        );
      } else {
        yield put(
          DashboardCreators.getWebPageList({ page: 1, projectID: projectId })
        );
      }

      SuccessToast(strings.SUCCESS, TOAST_POSITION.TOP_CENTER, 'success1');
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.addWebpagesFailure(payload));
  }
}
// <---------------delete web page saga----------------->
export function* deleteWebPageSaga(action) {
  try {
    const { webpageId, projectId } = action?.payload;
    yield put(DashboardCreators.deleteWebPageStart());
    const response = yield call(ApiService.delete, apiEndPoints.deleteWebpage, {
      params: {
        webpage_id: webpageId,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        STRINGS['ENGLISH'].WEBPAGE_DELETED,
        TOAST_POSITION.TOP_CENTER,
        'success1'
      );
      yield put(DashboardCreators.deleteWebPageSuccess({}));

      yield put(
        DashboardCreators.getWebPageList({ page: 1, projectID: projectId })
      );
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(DashboardCreators.deleteWebPageFailure(payload));
  }
}
