/**
 * saga js
 *
 * it will have all the login related sagas
 *
 * Project: BluePrint
 *
 * Devlopers:
 * 1. Panorbit Services LLP
 */

import { call, put } from 'redux-saga/effects';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { setAccessTokenToHeader } from '../../../services/axios/helper';
import { setUserDataToLocal } from '../../../services/cookies/helper';
import { Creators as AuthCreators } from './action';
import { ERROR_HANDLING } from '../../../constants/functions';
import { SuccessToast } from '../../../services/toast/toast';
import { TOAST_POSITION } from '../../../constants/BaseStyles';
import { STRINGS } from '../../../constants/labels';

//sends otp to mail id
export function* checkMailSaga(action) {
  try {
    const { mail } = action.payload;
    // let params = {
    //   email: mail,
    // };
    yield put(AuthCreators.checkMailStart());
    const response = yield call(ApiService.post, apiEndPoints.login, {
      email: mail,
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(AuthCreators.checkMailSuccess());
      // yield put(AuthCreators.checkOtpSaga());
    } else {
      throw response;
    }
    // yield put(AuthCreators.checkMailSuccess());
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AuthCreators.checkMailFailure(payload));
  }
}

// will trigger when user clicked login button.
//validates the otp sent
export function* loginSaga(action) {
  //need email and otp
  //for validation message key from response -> success or failure
  try {
    yield put(AuthCreators.loginStart());
    // const data = action.payload;

    const {
      payload: { mail, otp },
    } = action;
    //
    const apiDataObj = {
      email: mail,
      otp: otp,
    };

    const response = yield call(
      ApiService.post,
      apiEndPoints.validate,
      apiDataObj
    );
    if (response.status >= 200 && response.status < 300) {
      const { data } = response.data;

      //write code to set AccessToken to localStorage / cookies
      yield setUserDataToLocal({
        accessToken: data?.tokens?.access,
        full_name: data?.first_name + ' ' + data?.last_name,
        // organisationId: data?.organisation_id,
      });
      yield setAccessTokenToHeader(data?.tokens?.access);
      yield put(AuthCreators.loginSuccess(data));
      window.location.reload();
    } else {
      throw response;
    }
  } catch (err) {
    //
    const payload = ERROR_HANDLING(err);
    yield put(AuthCreators.loginFailure(payload));
  }
}

// will trigger when user clicked login button.
export function* createUserSaga(action) {
  try {
    yield put(AuthCreators.createUserStart());
    const {
      payload: { name, email, phoneNumber, companyName, password },
    } = action;
    //

    const apiDataObj = {
      email: email,
      full_name: name,
      password: password,
      confirm_password: password,
      phonenumber: phoneNumber,
      organisation: companyName,
    };

    const response = yield call(
      ApiService.post,
      apiEndPoints.createUser,
      apiDataObj
    );

    if (response.status >= 200 && response.status < 300) {
      yield put(AuthCreators.createUserSuccess());
    } else {
      throw response;
    }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AuthCreators.createUserFailure(payload));
  }
}

//LOGOUT SAGA NOT NEEDED -> DELETED FROM FRONT END ITSELF
export function* logoutSaga(action) {
  try {
    const { accessToken } = action.payload;

    yield put(AuthCreators.logoutStart());

    // const response = yield axios.put(apiEndPoints.editUserDetails, formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + accessToken,
    //   },
    // });

    // if (response.status >= 200 && response.status < 300) {
    //   const {
    //     data: { data },
    //   } = response;

    yield put(AuthCreators.logoutSuccess({ accessToken }));
    setUserDataToLocal();
    setAccessTokenToHeader('');
    // } else {
    //   throw response;
    // }
  } catch (err) {
    const payload = ERROR_HANDLING(err);
    yield put(AuthCreators.logoutFailure(payload));
  }
}
