import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Input,
  NameWrapper,
  RoleWrapper,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { Col, Label } from '../CalendarModal/CalendarModal.Styles';
import { TextArea } from '../../constants/GlobalStyles.Styles';
import {
  CustomDatePicker,
  FileLabel,
  FormContainer,
  Link,
  UploadInput,
  DateWrapper,
  LogoDisplay,
  CalendarIconDiv,
} from './AddBrandForm.Styles';
import { STRINGS } from '../../constants/labels';
import { LOGO_ALLOWED_EXTENSIONS } from '../../constants/constant';
import FormatLabel from '../Dropdown/FormatLabel.View';
import { generateDropdownStyles } from '../DropdownStyles/ProfileIconDropdownStyles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { UserCreators } from '../../pages/users/store/action';
import DynamicSVGIcon from '../DynamicSVGIcon';
import CalendarIcon from '../../assets/icons/Calendar.svg';

const AddBrandform = ({
  fontSize,
  brandValues,
  setBrandValues,
  fileTypeError,
  setFileTypeError,
  imageData,
  setImageData,
  editMode = false,
}) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  //REDUX
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const { brandData } = useSelector((state) => state.brand);
  const [option, setSelectedOption] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [usersDropdown, setUsersDropdown] = useState([]);

  //store
  const { userListData } = useSelector((state) => state.userList);

  useEffect(() => {
    dispatch(
      UserCreators.getUserList({
        pageNumber: 1,
      })
    );
  }, []);

  useEffect(() => {
    const users = userListData?.map((user) => ({
      label: user.name,
      value: user.username,
      img: user.profile_pic_url,
    }));

    setUsersDropdown(users);
  }, [userListData]);
  const handleChangeInput = (fieldName, value) => {
    if (fieldName === 'account_manager_username') {
      setBrandValues({
        ...brandValues,
        [fieldName]: value?.value,
      });
      setSelectedOption(value);
    } else if (fieldName === 'start_date') {
      setStartDate(value);
      setBrandValues({
        ...brandValues,
        [fieldName]: moment(value).format('YYYY-MM-DD HH:mm:ss'),
      });
    } else {
      setBrandValues({
        ...brandValues,
        [fieldName]: value,
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Handle file upload logic here
    if (!LOGO_ALLOWED_EXTENSIONS.includes(file?.type)) {
      setFileTypeError(true);
    } else {
      setFileTypeError(false);
    }
    setFile(file);
    handleChangeInput('company_logo', file);

    if (e.target.files && e.target.files[0]) {
      setImageData(URL.createObjectURL(e.target.files[0]));
      handleChangeInput('company_logo', e.target.files[0]);
    }
  };

  //STYLES
  //SELECT DROPDOWN
  const dropdownStyles = generateDropdownStyles();
  return (
    <FormContainer>
      <Col width={'420px'}>
        <NameWrapper marginBottom="10px" fontSize={'18px'}>
          {editMode ? strings.EDIT_BRAND : strings.CREATE_NEW_BRAND}
        </NameWrapper>
        <RoleWrapper>{strings.ENTER_BRAND_DETAILS}</RoleWrapper>
      </Col>
      <Label fontSize={fontSize}>{strings.BRAND_NAME}</Label>
      <Input
        width={'420px'}
        maxLength={60}
        type="text"
        placeholder={strings.ENTER_BRAND_NANE}
        value={brandValues?.company_name}
        onChange={(event) => {
          handleChangeInput('company_name', event.target.value);
        }}
      />

      <Label fontSize={fontSize}>{strings.START_DATE}</Label>
      <DateWrapper style={{ position: 'relative' }}>
        <CustomDatePicker
          wrapperClassName="DataPicker"
          selected={
            brandValues?.start_date && new Date(brandValues?.start_date)
          }
          placeholderText="DD-MM-YYYY"
          onChange={(date) => handleChangeInput('start_date', date)}
          dateFormat="dd-MM-yyyy"
        />
        <CalendarIconDiv>
          <DynamicSVGIcon width="20px" iconUrl={CalendarIcon} />
        </CalendarIconDiv>
      </DateWrapper>
      <Label fontSize={fontSize}>{strings.ACCOUNT_MANAGER}</Label>
      <Select
        value={
          usersDropdown?.filter(
            (data) => data?.value === brandValues?.account_manager_username
          )[0]
        }
        options={usersDropdown}
        formatOptionLabel={FormatLabel}
        placeholder={strings.SELECT_ACC_MAN}
        styles={dropdownStyles}
        isClearable={true}
        onChange={(option) => {
          handleChangeInput('account_manager_username', option);
        }}
      />
      <Label fontSize={fontSize}>{strings.DESCRIPTION}</Label>
      <TextArea
        placeholder={strings.ENTER_DESCRIPTION}
        width={'420px'}
        maxLength={250}
        fontSize={'13px'}
        noBgColor
        height={'90px'}
        value={brandValues?.description}
        onChange={(event) => {
          handleChangeInput('description', event.target.value);
        }}
      />

      <Label fontSize={fontSize}>{strings.BRAND_LOGO}</Label>
      <FileLabel width={'420px'}>
        <Col width={'100%'}>
          {imageData ? (
            <LogoDisplay img={imageData}></LogoDisplay>
          ) : (
            <>
              <NameWrapper>
                {strings.DRAG_N_DROP_FILE} <Link>{strings.BROWSE_FILE}</Link>
              </NameWrapper>
              <RoleWrapper>{strings.FILE_SUPPORTED_FORMATS}</RoleWrapper>
              <UploadInput type="file" onChange={handleFileChange} />
            </>
          )}
        </Col>
      </FileLabel>
    </FormContainer>
  );
};

export default AddBrandform;
