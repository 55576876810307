import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  notificationList: ['payload'],
  notificationListStart: ['payload'],
  notificationListSuccess: ['payload'],
  notificationListError: ['payload'],
  //
  notificationCount: ['payload'],
  notificationCountStart: ['payload'],
  notificationCountSuccess: ['payload'],
  notificationCountError: ['payload'],
  //
  readNotification: ['payload'],
  readNotificationStart: ['payload'],
  readNotificationSuccess: ['payload'],
  readNotificationError: ['payload'],
});
export { Types as BaseTypes, Creators as BaseCreators };
