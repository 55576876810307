// component
import DynamicSVGIcon from '../../components/DynamicSVGIcon';
import {
  AddVersionBtn,
  BackIconAndName,
  BackIconContainer,
  PagesAndVersion,
  VersionBtn,
  WebSiteName,
  WebsiteHeader,
  WebsiteNameAndStatusContainer,
} from '../WebsiteFeedback/WebsiteFeeback.Styles';
import {
  AddVersionHeading,
  AddVersionSubHeading,
  AddVersionWrapper,
  AssetCaption,
  AssetCommentContainer,
  AssetReviewAndComment,
  AssetReviewContainer,
  AssetStatus,
  AssetStatusContainer,
  AssetsContainer,
  BlankAsset,
  CanvasWrapper,
  CaptionCloseModal,
  CaptionContainer,
  CaptionHistoryHeading,
  CaptionLabel,
  CaptionText,
  DownloadAsset,
  EditCaption,
  FullScreen,
  ImageAsset,
  ImageAssetContainer,
  ListOfCaption,
  PdfContainer,
  SelectedStatus,
  StatusLabel,
  StatusList,
  StatusLogo,
  StatusWrpper,
  UploadVersionFiles,
  UploadVersionFilesLabel,
  UploadVersionFolderContainer,
  VersionCaptionContainer,
  VersionCaptionInput,
  VersionCaptionLabel,
  VersionList,
  VersionName,
  Video,
  VideoAssetContainer,
  ZoomContainer,
  ZoomInContainer,
  ZoomOutContainer,
  ZoomValue,
} from './Assets.Styles';
import Button from '../../components/Buttons/Button.View';
import CommentItem from '../../components/Comments/Comment.View';

// assets
import Back from '../../assets/icons/WhiteBack.svg';
import Minus from '../../assets/icons/Minus.svg';
import Plus from '../../assets/icons/Plus.svg';
import GoFullscreen from '../../assets/icons/Go_fullscreen.svg';
import Close from '../../assets/icons/Close.svg';
import Share from '../../assets/icons/Share.svg';
import DropDownIcon from '../../assets/icons/DropDownIcon.svg';
import StatusSelection from '../../assets/icons/Status_selection.svg';
import Edit from '../../assets/icons/Edit.svg';
import Upload from '../../assets/icons/Upload.svg';
import Caption from '../../assets/icons/Caption_history.svg';
// consent
import { COLORS, TOAST_POSITION } from '../../constants/BaseStyles';
import { STRINGS } from '../../constants/labels';
// dependency
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssetCreators } from './store/action';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from '../../services/toast/toast';
import {
  ASSET_STATUS,
  CLIENT_TYPE_USER_LIST,
  INTERNAL_TEAM_MEMBER_LIST,
  STATUS,
} from '../../constants/constant';
import UseClickOutside from '../../utils/useClickOutside/UseClickOutSide';
import UniversalModal from '../../components/modals/UniversalModal';
import { FileLabel, Link } from '../../components/form/AddBrandForm.Styles';
import { Col } from '../../components/CalendarModal/CalendarModal.Styles';
import { NameWrapper, RoleWrapper } from '../BaseLayout/BaseLayout.Styles';
import { Fileinput } from '../../components/form/CreateFolder.Styles';
import ProjectFolderCards from '../../components/Cards/ProjectsFolderCards.View';
import CreateFolder from '../../components/form/CreateFolder.View';
import PdfViewer from '../../components/Pdf/PdfViewer';
import {
  downloadImage,
  downloadPdf,
  downloadVideo,
  isElementFullScreen,
} from '../../constants/functions';
import CommentIndividual from '../../components/Comments/CommentIndividual';
import {
  TransformComponent,
  TransformWrapper,
  useTransformContext,
} from 'react-zoom-pan-pinch';
import { isEmpty } from '../../utils/utility';

const Assets = () => {
  const string = STRINGS['ENGLISH'];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const [searchParams] = useSearchParams();
  const assetID = searchParams.get('asset_id');
  const versionId = searchParams.get('version_id');
  const ref_type = searchParams.get('ref_type');
  //
  const param = useParams();
  const { project_id, project_path } = param;
  const strings = STRINGS['ENGLISH'];
  const { userData } = useSelector((state) => state.loggedinUser);
  const { AssetVersionData, assetVersionsListData } = useSelector(
    (state) => state.asset
  );
  const { comments, commentMetaData } = useSelector((state) => state.asset);
  //
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  //
  const [comment, setComment] = useState('');
  const [isUpdateReview, setIsUpdateReview] = useState(false);
  const [captionOpen, setCaptionOpen] = useState(false);
  const [editCaption, setEditCaption] = useState(false);
  const [isUpdateVersion, setIsUpdateVersion] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [caption, setCaption] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [canvasSize, setCanvasSize] = useState({ width: 350, height: 400 });
  const [scale, setScale] = useState(1);
  const [image, setImage] = useState(null);
  const [editCaptionText, setEditCaptionText] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  //
  useEffect(() => {
    if (assetID && versionId) {
      dispatch(
        AssetCreators.viewAssetComment({
          assetID,
          versionId,
          latest: false,
          resolved: false,
          FilterByMe: false,
        })
      );
      //
      dispatch(
        AssetCreators.viewAssets({
          assetID,
          versionId,
        })
      );
      //
      dispatch(AssetCreators.getAssetVersionList({ assetID }));
      // viewAssets
    }
  }, [assetID, versionId]);
  const value = {
    value: 'INPROGRESS',
  };
  //
  const onComment = (parent_comment_id) => {
    if (!comment) {
      ErrorToast('Write something to comment');
      return;
    }
    dispatch(
      AssetCreators.addAssetComment({
        assetID,
        versionId,
        refId: project_id,
        comments: comment,
        ref: 'project',
        userName: userData?.username,
        parent_comment_id,
        is_reply: parent_comment_id ? true : false,
      })
    );
    oncancelComment();
  };
  const oncancelComment = () => {
    setComment('');
  };

  const copyToClip = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    SuccessToast(string.COPY_URL, TOAST_POSITION.TOP_CENTER);
  };
  const updateStatus = (status) => {
    if (
      AssetVersionData[assetID]?.status === 'APPROVED' &&
      (CLIENT_TYPE_USER_LIST.includes(userData?.type) ||
        CLIENT_TYPE_USER_LIST.includes(userData?.user_organisation_role))
    ) {
      WarningToast(
        'Assets are approved for production connect with Panorbit !',
        TOAST_POSITION.TOP_CENTER
      );
      return;
    } else if (
      AssetVersionData[assetID]?.status === 'APPROVED' &&
      INTERNAL_TEAM_MEMBER_LIST.includes(userData?.user_organisation_role)
    ) {
      WarningToast(
        'Assets are approved for production connect with Admin/Brand Manager',
        TOAST_POSITION.TOP_CENTER
      );
      return;
    }
    const data = {
      asset_id: assetID,
      status: status,
      version_id: versionId,
    };
    dispatch(AssetCreators.updateAssetStatus({ data, assetID, versionId }));
  };
  const uploadfiles = () => {
    const formData = new FormData();
    if (!files.length) {
      ErrorToast('Select a file', TOAST_POSITION.TOP_CENTER, 'error1');
      return;
    }
    //Commenting this to make caption optional
    // if (!caption) {
    //   ErrorToast('Enter file Caption', TOAST_POSITION.TOP_CENTER, 'error1');
    //   return;
    // }
    formData.append('file', files[0]);
    formData.append('name', 'name');
    formData.append('status', 'INPROGRESS');
    formData.append('caption', caption);
    // formData.append('ref', ref_type ? ref_type : 'project');
    // formData.append('ref_id', project_id);
    formData.append('project_id', project_id);
    formData.append('asset_id', assetID);
    dispatch(AssetCreators.uploadNewVersionFile({ formData, assetID }));
    onCloseUpload();
  };
  //
  const onCloseUpload = () => {
    setUploadFile(false);
    setFiles([]);
    setCaption('');
  };
  //
  const handleChange = (event) => {
    setFiles([...event.target.files]);
  };
  //
  const onCrossClick = (index) => {
    // Create a copy of the files array
    const updatedFiles = [...files];
    // Remove the file at the specified index
    updatedFiles.splice(index, 1);
    // Update the state with the modified files array
    setFiles(updatedFiles);
  };
  const handleChangeVersion = (data) => {
    const { version_id, asset_id } = data;
    navigate(
      `/${project_path}/${project_id}/asset_review?asset_id=${asset_id}&version_id=${version_id}`
    );
    setIsUpdateVersion(false);
  };
  const goBack = () => {
    // if (ref_type) {
    navigate(-1);
    // } else {
    //   navigate(
    //     `/${project_path}/${project_id}/files?project_name=${AssetVersionData[assetID]?.project_name}`
    //   );
    // }
  };
  //
  let initialX;
  const stepSize = 0.1;
  const maxZoomLevel = 1.5;
  const minZoomLevel = 0.15;
  //

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(isElementFullScreen('fullscreenDiv'));
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    // Clean up the event listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'MSFullscreenChange',
        handleFullScreenChange
      );
    };
  }, []);
  // useEffect(() => {
  //   if (
  //     AssetVersionData[assetID]?.watermarked_s3_image_link?.length &&
  //     canvasRef?.current
  //   ) {
  //     // const container = containerRef.current;
  //     // const canvas = canvasRef.current;
  //     // const ctx = canvas?.getContext('2d');
  //     // const containerRect = container?.getBoundingClientRect();
  //     // const canvasRect = canvas?.getBoundingClientRect();
  //     // initialX = (containerRect?.width - canvasRect?.width) / 2;
  //     // const initialY = (containerRect?.height - canvasRect?.height) / 2;
  //     // setPosition({ x: initialX, y: initialY });
  //     // const img = new Image();
  //     // const imageWidth = canvasSize?.width * scale;
  //     // const imageHeight = canvasSize?.height * scale;
  //     // img.src = AssetVersionData?.watermarked_s3_image_link[0];
  //     // img.onload = () => {
  //     //   setImage(img);
  //     //   ctx?.drawImage(img, 0, 0, imageWidth, imageHeight);
  //     // };
  //   }
  // }, [AssetVersionData[assetID]?.watermarked_s3_image_link]);

  //
  // const redrawImage = () => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas?.getContext('2d');
  //   ctx?.clearRect(0, 0, canvas.width, canvas.height);

  //   const imageWidth = canvasSize?.width * scale;
  //   const imageHeight = canvasSize?.height * scale;

  //   const container = containerRef.current;
  //   const containerRect = container?.getBoundingClientRect();
  //   const canvasRect = canvas?.getBoundingClientRect();
  //   initialX = (containerRect?.width - canvasRect?.width) / 2;
  //   const initialY = (containerRect?.height - canvasRect?.height) / 2;
  //   setPosition({ x: initialX, y: initialY });

  //   // Draw the image
  //   if (image) {
  //     ctx?.drawImage(image, 0, 0, imageWidth, imageHeight);
  //   } else {
  //     // setImageUrl(null);
  //   }
  // };

  // const handleZoom = (factor) => {
  //   if (factor === stepSize) {
  //     if (scale < maxZoomLevel) {
  //       // const newScale = Math.max(stepSize, scale + factor);
  //       setScale((prev) => prev + 0.1);
  //     }
  //   }
  //   if (factor === -stepSize) {
  //     if (scale > minZoomLevel) {
  //       // const newScale = Math.max(stepSize, scale + factor);
  //       // setScale(newScale);
  //       setScale((prev) => prev - 0.1);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   redrawImage();
  // }, [scale]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setInitialPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      e.preventDefault();
      setPosition({
        x: e.clientX - initialPosition.x,
        y: e.clientY - initialPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const updateCaption = () => {
    const data = {
      asset_id: assetID,
      caption: editCaptionText,
      version_id: versionId,
    };
    dispatch(AssetCreators.updateAssetStatus({ data, assetID, versionId }));
    onEditCaptionTextCancel();
  };
  const onEditCaptionTextCancel = () => {
    setEditCaptionText('');
    setEditCaption(false);
  };

  const viewFullScreenClick = () => {
    const fullscreenDiv = document.getElementById('fullscreenDiv');
    try {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // current working methods
        if (fullscreenDiv.requestFullscreen) {
          // setIsFullScreen(true);
          fullscreenDiv.requestFullscreen();
        } else if (fullscreenDiv.mozRequestFullScreen) {
          /* Firefox */
          // setIsFullScreen(true);
          fullscreenDiv.mozRequestFullScreen();
        } else if (fullscreenDiv.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          // setIsFullScreen(true);
          fullscreenDiv.webkitRequestFullscreen();
        } else if (fullscreenDiv.msRequestFullscreen) {
          /* IE/Edge */
          // setIsFullScreen(true);
          fullscreenDiv.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          setIsFullScreen(false);
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          // setIsFullScreen(false);
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          // setIsFullScreen(false);
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          // setIsFullScreen(false);
          document.msExitFullscreen();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  //

  const handleScaleChange = (e) => {
    setScale(e.state.scale);
  };

  return (
    <AssetsContainer>
      <WebsiteHeader>
        <WebsiteNameAndStatusContainer>
          {/* <---------------page name and status-----------------> */}
          <BackIconAndName>
            <BackIconContainer
              onClick={() => {
                goBack();
              }}
            >
              <DynamicSVGIcon iconUrl={Back} width="1rem" />
            </BackIconContainer>
            <WebSiteName>{AssetVersionData[assetID]?.name}</WebSiteName>
            <AssetStatusContainer>
              <AssetStatus
                bg={AssetVersionData[assetID]?.status}
                onClick={() => {
                  setIsUpdateReview(true);
                }}
              >
                {STATUS[AssetVersionData[assetID]?.status]}
              </AssetStatus>
              <>
                {isUpdateReview && (
                  <UseClickOutside
                    onClickOutside={() => {
                      setIsUpdateReview(false);
                    }}
                  >
                    <StatusList>
                      {ASSET_STATUS.map((data) => {
                        return (
                          <StatusWrpper
                            onClick={() => {
                              // setReviewState(data.value);
                              updateStatus(data.value);
                              setIsUpdateReview(false);
                            }}
                          >
                            <StatusLogo>
                              <DynamicSVGIcon
                                iconUrl={data?.img}
                                width="0.6rem"
                              />
                            </StatusLogo>
                            <StatusLabel>{data.label}</StatusLabel>
                            {AssetVersionData[assetID]?.status ===
                              data.value && (
                              <SelectedStatus>
                                <DynamicSVGIcon
                                  iconUrl={StatusSelection}
                                  width="0.6rem"
                                />
                              </SelectedStatus>
                            )}
                          </StatusWrpper>
                        );
                      })}
                    </StatusList>
                  </UseClickOutside>
                )}
              </>
            </AssetStatusContainer>
          </BackIconAndName>
          {/* <-----------------pages version and share-----------------> */}
          <PagesAndVersion>
            <VersionBtn>
              <Button
                text={AssetVersionData[assetID]?.version_name}
                width={100}
                height={35}
                bgColor={COLORS.BTN_PRIMARY}
                opacity={1}
                fontSize={'0.8rem'}
                rightIcon={DropDownIcon}
                btnClick={() => {
                  setIsUpdateVersion(true);
                }}
              />
              <>
                <UseClickOutside
                  onClickOutside={() => {
                    setIsUpdateVersion(false);
                  }}
                >
                  {isUpdateVersion && (
                    <VersionList>
                      {assetVersionsListData?.map((data) => {
                        return (
                          <VersionName
                            onClick={() => {
                              handleChangeVersion(data);
                            }}
                          >
                            {data?.version_name}
                          </VersionName>
                        );
                      })}
                    </VersionList>
                  )}
                </UseClickOutside>
              </>
            </VersionBtn>
            <shareBtn>
              <Button
                btnClick={() => {
                  copyToClip();
                }}
                text={''}
                width={35}
                height={35}
                bgColor={COLORS.BTN_PRIMARY}
                opacity={1}
                fontSize={'0.8rem'}
                paddingleft={'0px'}
                rightIcon={Share}
                rightIconrotation={0}
                iconSize={'1rem'}
              />
            </shareBtn>
          </PagesAndVersion>
          {/*<------------------Add version-----------------------------> */}
          <AddVersionBtn
            show={
              !CLIENT_TYPE_USER_LIST.includes(userData?.type) &&
              AssetVersionData[assetID]?.status !== 'APPROVED'
            }
          >
            <Button
              text={string.ADD_VERSION}
              width={120}
              height={35}
              bgColor={COLORS.BTN_GRADIENT}
              opacity={1}
              fontSize={'0.8rem'}
              btnClick={() => {
                setUploadFile(true);
              }}
            />
          </AddVersionBtn>
        </WebsiteNameAndStatusContainer>
      </WebsiteHeader>
      {/* <-----------------------asset review and versioning------------------> */}
      <AssetReviewAndComment>
        <AssetReviewContainer id="fullscreenDiv">
          {AssetVersionData[assetID]?.asset_type === 'IMAGE' ? (
            <ImageAssetContainer
              fullScreen={isFullScreen}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              ref={containerRef}
            >
              <TransformWrapper
                initialScale={1}
                minScale={0.5}
                maxScale={3}
                wheel={{ step: 0.1 }} // Adjust the zooming sensitivity
                pinch={{ step: 5 }} // Adjust the pinch sensitivity
                doubleClick={{ step: 0.5 }} // Adjust the double-click zoom sensitivity
                onTransformed={handleScaleChange}
              >
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <>
                    <TransformComponent>
                      {AssetVersionData[assetID]?.watermarked_s3_image_link
                        ?.length ||
                      AssetVersionData[assetID]?.original_s3_image_link
                        ?.length ? (
                        <ImageAsset
                          src={
                            AssetVersionData[assetID]?.status === 'APPROVED'
                              ? AssetVersionData[assetID]
                                  ?.original_s3_image_link[0]
                              : AssetVersionData[assetID]
                                  ?.watermarked_s3_image_link[0] ||
                                AssetVersionData[assetID]
                                  ?.original_s3_image_link[0]
                          }
                        />
                      ) : null}
                    </TransformComponent>
                    <ZoomContainer>
                      <ZoomInContainer onClick={() => zoomOut(0.1)}>
                        <DynamicSVGIcon iconUrl={Minus} />
                      </ZoomInContainer>
                      <ZoomValue>{`${
                        (scale * 100).toFixed(0) || 0
                      }%`}</ZoomValue>
                      <ZoomOutContainer onClick={() => zoomIn(0.1)}>
                        <DynamicSVGIcon iconUrl={Plus} />
                      </ZoomOutContainer>
                      <FullScreen onClick={() => viewFullScreenClick()}>
                        <DynamicSVGIcon iconUrl={GoFullscreen} />
                      </FullScreen>
                    </ZoomContainer>
                    {AssetVersionData[assetID]?.status === 'APPROVED' && (
                      <DownloadAsset
                        onClick={() =>
                          downloadImage(
                            AssetVersionData[assetID]?.name,
                            AssetVersionData[assetID]?.original_s3_image_link[0]
                          )
                        }
                      >
                        <DynamicSVGIcon iconUrl={Upload} rotate={180} />
                      </DownloadAsset>
                    )}
                  </>
                )}
              </TransformWrapper>
            </ImageAssetContainer>
          ) : null}

          {AssetVersionData[assetID]?.asset_type === 'VIDEO' ? (
            <VideoAssetContainer>
              {(AssetVersionData[assetID]?.watermarked_s3_video_link ||
                AssetVersionData[assetID]?.original_s3_video_link) && (
                <Video
                  controls
                  disablepictureinpicture
                  controlslist="nodownload"
                  disablePictureInPicture
                  disableRemotePlayback
                >
                  <source
                    src={
                      AssetVersionData[assetID]?.status === 'APPROVED'
                        ? AssetVersionData[assetID]?.original_s3_video_link[0]
                        : AssetVersionData[assetID]
                            ?.watermarked_s3_video_link[0] ||
                          AssetVersionData[assetID]?.original_s3_video_link[0]
                    }
                    type="video/mp4"
                  ></source>
                </Video>
              )}
              {AssetVersionData[assetID]?.status === 'APPROVED' && (
                <DownloadAsset
                  onClick={() => {
                    downloadVideo(
                      AssetVersionData[assetID]?.name,
                      AssetVersionData[assetID]?.original_s3_video_link[0]
                    );
                  }}
                >
                  <DynamicSVGIcon iconUrl={Upload} rotate={180} />
                </DownloadAsset>
              )}
            </VideoAssetContainer>
          ) : null}
          {AssetVersionData[assetID]?.asset_type === 'PDF' &&
          (AssetVersionData[assetID]?.watermarked_pdf_s3_link ||
            AssetVersionData[assetID]?.pdf_s3_link) ? (
            <PdfContainer>
              <PdfViewer
                pdfProps={
                  AssetVersionData[assetID]?.status === 'APPROVED'
                    ? AssetVersionData[assetID]?.pdf_s3_link
                    : AssetVersionData[assetID]?.watermarked_pdf_s3_link &&
                      !isEmpty(
                        AssetVersionData[assetID]?.watermarked_pdf_s3_link
                      )
                    ? AssetVersionData[assetID]?.watermarked_pdf_s3_link
                    : AssetVersionData[assetID]?.pdf_s3_link
                }
              />
              {AssetVersionData[assetID]?.status === 'APPROVED' && (
                <DownloadAsset
                  onClick={() => {
                    downloadPdf(
                      AssetVersionData[assetID]?.name,
                      AssetVersionData[assetID]?.pdf_s3_link
                    );
                  }}
                >
                  <DynamicSVGIcon iconUrl={Upload} rotate={180} />
                </DownloadAsset>
              )}
            </PdfContainer>
          ) : null}
          {AssetVersionData[assetID]?.asset_type === 'FILE' ? (
            <BlankAsset>Preview is not available</BlankAsset>
          ) : null}
          <CaptionContainer>
            <CaptionLabel>
              <CaptionText>Caption</CaptionText>
              <EditCaption
                onClick={() => {
                  setEditCaption(true);
                  if (AssetVersionData[assetID]?.caption?.length > 0) {
                    let cap = AssetVersionData[assetID]?.caption[0].caption;
                    setEditCaptionText(cap);
                  } else {
                    setEditCaptionText('');
                  }
                }}
              >
                <DynamicSVGIcon iconUrl={Edit} width="1.2rem" />
              </EditCaption>
              {/*  */}
              <EditCaption
                onClick={() => {
                  setCaptionOpen((prev) => !prev);
                  // console.log('f');
                }}
              >
                <DynamicSVGIcon iconUrl={Caption} width="1.2rem" />
              </EditCaption>
            </CaptionLabel>
            <AssetCaption>
              {AssetVersionData[assetID]?.caption?.length > 0
                ? AssetVersionData[assetID]?.caption[0].caption
                : null}
            </AssetCaption>
          </CaptionContainer>
        </AssetReviewContainer>
        {/* <-----------------------------comment section-----------------------> */}
        <AssetCommentContainer>
          <CommentItem
            comments={comments}
            commentMetaData={commentMetaData}
            comment={comment}
            setComment={setComment}
            onComment={onComment}
            oncancelComment={oncancelComment}
          />
        </AssetCommentContainer>
      </AssetReviewAndComment>
      {/* captionOpen */}
      {/* <-----------------------modal for upload new asset version----------------------> */}
      <UniversalModal
        width={'600px'}
        height={'450px'}
        isOpenModal={uploadFile}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.ADD}
        onrightbtnClick={uploadfiles}
        onleftbtnClick={() => {
          onCloseUpload();
        }}
      >
        <AddVersionWrapper>
          <AddVersionHeading>Add version</AddVersionHeading>
          <AddVersionSubHeading>Enter version details</AddVersionSubHeading>
          <VersionCaptionContainer>
            <VersionCaptionLabel>Version Caption</VersionCaptionLabel>
            <VersionCaptionInput
              placeholder="Enter Version Caption"
              value={caption}
              onChange={(e) => {
                setCaption(e.target.value);
              }}
            />
          </VersionCaptionContainer>
          {/* <---------------upload files-----------> */}
          <UploadVersionFiles>
            <UploadVersionFilesLabel>Upload</UploadVersionFilesLabel>
            {files.length ? (
              <UploadVersionFolderContainer>
                {files?.map((file, index) => {
                  return (
                    <>
                      <ProjectFolderCards
                        data={file}
                        file={true}
                        index={index}
                        onCrossClick={onCrossClick}
                      />
                    </>
                  );
                })}
              </UploadVersionFolderContainer>
            ) : (
              <FileLabel>
                <Col width={'100%'}>
                  <NameWrapper>
                    {strings.DRAG_N_DROP_FILE}{' '}
                    <Link>{strings.BROWSE_FILE}</Link>
                  </NameWrapper>
                  <RoleWrapper>{strings.FILE_UPLOAD_TEXT}</RoleWrapper>
                </Col>

                <Fileinput
                  type="file"
                  // multiple
                  onChange={(event) => handleChange(event)}
                />
              </FileLabel>
            )}
          </UploadVersionFiles>
        </AddVersionWrapper>
      </UniversalModal>
      {/* editCaption */}
      <UniversalModal
        width={'600px'}
        height={'300px'}
        rightBtnWidth={'120px'}
        isOpenModal={editCaption}
        btn={true}
        leftText={strings.CANCEL}
        rightText={strings.UPDATE}
        // rightBtnDisabled={isScraping}
        onrightbtnClick={updateCaption}
        onleftbtnClick={() => {
          onEditCaptionTextCancel();
        }}
      >
        <CreateFolder
          caption={true}
          folderName={editCaptionText}
          setFolderame={setEditCaptionText}
        />
      </UniversalModal>
      {/* <-----------------------------List of caption----------------------------------------> */}
      <UniversalModal
        width={'600px'}
        height={'300px'}
        rightBtnWidth={'120px'}
        isOpenModal={captionOpen}
      >
        <CaptionCloseModal
          onClick={() => {
            setCaptionOpen(false);
          }}
        >
          <DynamicSVGIcon iconUrl={Close} width="1rem" />
        </CaptionCloseModal>
        {/* <--------------------liast of caption-----------------> */}
        <ListOfCaption>
          <CaptionHistoryHeading>Caption history</CaptionHistoryHeading>
          {AssetVersionData[assetID]?.caption?.map((data, index) => {
            return (
              <div key={index}>
                <CommentIndividual
                  userData={{ data: data }}
                  caption={false}
                  reply={true}
                />
              </div>
            );
          })}
        </ListOfCaption>
      </UniversalModal>
    </AssetsContainer>
  );
};
export default Assets;
