import React from 'react';
import { Row } from '../CalendarModal/CalendarModal.Styles';
import {
  NameWrapper,
  ProfileDiv,
} from '../../pages/BaseLayout/BaseLayout.Styles';
import { CardUsername } from '../Cards/Cards.Styles';
import { STRINGS } from '../../constants/labels';

const FormatLabel = (option, { context }) => {
  //TEXT
  const strings = STRINGS['ENGLISH'];

  return context === 'value' ? (
    <Row flexStart>
      <ProfileDiv img={option?.img} />
      <NameWrapper>{option?.label}</NameWrapper>
    </Row>
  ) : (
    <Row spaceBetween>
      <Row flexStart>
        <ProfileDiv img={option?.img} />
        <NameWrapper>{option?.label}</NameWrapper>
      </Row>
      <CardUsername marginRight={'10px'} fontSize={'12px'}>
        {strings.ADD}
      </CardUsername>
    </Row>
  );
};

export default FormatLabel;
