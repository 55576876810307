/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import {
  CellTemplate,
  RowTemplate,
  TableContainerTemplate,
  device,
  fontFamily,
  fontSize,
  Colors,
  COLORS,
} from '../../constants/BaseStyles';

export const Container = styled.div`
  width: 92%;
  height: 100%;
  display: flex;
  flex: 1;
  font-size: 62.5%;
  border-radius: 1rem;
  margin-top: 60px;
  margin-left: ${({ left }) => (left ? left : '64px')};
  background-color: ${COLORS.PRIMARY_GREY};

  table {
    margin: 0;
    padding: 0;
  }
`;

export const HeaderContainer = styled.div`
  min-width: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  padding-bottom: 10px;
  background-color: ${COLORS.PRIMARY_GREY};
`;

export const MachineHeaderText = styled.div`
  position: relative;
  flex: 1;
  font-size: ${fontSize.titleBig};
  color: ${COLORS.TEXT_MEDIUM_GREY};
  background-color: ${COLORS.PRIMARY_GREY};

  box-shadow: inset 0px 5px 10px ${Colors.light.shadow};
  max-width: 45rem;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0rem 1rem;
  cursor: pointer;

  & * {
    fill: ${Colors.light.contrast.primary} !important;
  }

  &:first-child {
    border-top-left-radius: 1rem;
  }
  &:last-child {
    box-shadow: inset -5px 5px 10px ${Colors.light.shadow};
    border-top-right-radius: 1rem;
  }
  &.active {
    color: ${COLORS.TEXT_MEDIUM_GREY};
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: ${Colors.light.pureSecondary};
    box-shadow: 5px -5px 10px ${({ theme }) => theme.shadow};
    z-index: 1;

    & * {
      fill: ${Colors.light.contrast.lightQuaternary} !important;
    }
  }
`;

export const SearchIcon = styled.div`
  margin: 0px 5px;
  align-self: center;
  cursor: pointer;
  & * {
    fill: ${Colors.light.contrast.primary} !important;
  }
`;

export const SearchInput = styled.input`
  height: 20px;
  width: 120px;
  border: 0px;
  font-size: ${fontSize.title};
  background-color: transparent;
  ::placeholder {
    font-size: ${fontSize.title};
  }
  :focus {
    outline: none;
  }
  @media ${device.tablet} {
    font-size: 14px;
    ::placeholder {
      font-size: 14px;
    }
  }

  /* remove animation  */
  /* &.show {
    animation: leftAnimate ease-in 300ms;
    @keyframes leftAnimate {
      0% {
        width: 20px;
      }
      100% {
        width: 120px;
        /* transform: scaleX(1); */
      }
    }
  } */
`;

export const CancelIcon = styled.div`
  position: absolute;
  width: 1.5rem;
  right: 10px;

  /* & * {
    fill: ${Colors.light.contrast.primary} !important;
  } */
`;

export const ContentContainer = styled.div`
  /* height: calc(100% - 5rem); */
  height: 100%;
  width: 100%;
  background-color: ${COLORS.PRIMARY_BLACK};
  overflow-y: auto;
  box-sizing: border-box;
  /* margin-top: -10px; */
`;

export const TableContainer = styled(TableContainerTemplate)`
  width: 98%;
  height: calc(100% - 6rem);
  margin: 1rem auto 0px;
  background-color: ${COLORS.PRIMARY_GREY};

  border-radius: 1.2rem;
  overflow: auto;

  .infinite-scroll-component grid {
    background: red;
  }
`;
export const Row = styled(RowTemplate)`
  position: relative;
  z-index: 2;
  margin-bottom: 1.5rem;
  background-color: ${COLORS.PRIMARY_GREY};
  box-shadow: -5px -5px 11px #fffffff5;
  border-radius: 10px;
  &.header {
    z-index: 9;
  }
`;

export const Cell = styled(CellTemplate)`
  cursor: ${({ pointer }) => pointer && 'pointer'};
  flex: ${({ flex }) => flex || 1};
  background-color: ${COLORS.PRIMARY_GREY};

  padding: 15px 10px;

  &:first-child {
    flex: 2.5;
    justify-content: flex-start;
    /* padding-left: 1rem; */
  }

  &.KPV {
    overflow: unset;
  }
  &.groupHeading {
    justify-content: flex-start;
    padding: 15px 10px;
  }

  &.active {
    background-color: ${Colors.light.table.hightlight};
    & > .active {
      color: red;
      border: 1px solid red;
    }
  }
  &.align {
    justify-content: flex-start;
  }

  /* overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
  /* display: none; */

  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  /* scrollbar-width: none; */
`;

export const ToogleGroupICon = styled.span`
  position: absolute;
  left: 2.5rem;
  padding: 3px 5px;
  font-size: 2rem;
  cursor: pointer;

  & path:last-child {
    fill: ${Colors.light.contrast.quaternary} !important;
  }

  & path:first-child {
    fill: ${Colors.light.core.pureSecondary} !important;
  }
`;

export const CollapsableContainer = styled.div`
  position: relative;
  z-index: 1;
  animation: collapsableAnim 300ms 1;
  @keyframes collapsableAnim {
    0% {
      opacity: 0;
      /* transform: translateY(-30px); */
    }
    100% {
      opacity: 1;
      /* transform: translateY(0px); */
    }
  }
`;

export const ActionIcon = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.disable {
    cursor: not-allowed;
    opacity: 0.2;
  }
`;
export const Table = styled.table`
  width: 100%;
  background-color: ${COLORS.PRIMARY_GREY};

  &.nested {
    width: 90%;
    position: absolute;
    border-spacing: 0rem 2rem;
  }
  border-collapse: separate;
  & > td,
  th {
    vertical-align: middle;
    font-weight: 200 !important;
    padding: 200px;
    height: 60px;
    border-bottom: 1px solid ${COLORS.OVERLAY_GREY};
  }

  &.attached {
    border-spacing: 0px;

    & p {
      font-size: 1.8rem;
    }

    & .clip {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    & tr {
      background-color: ${COLORS.PRIMARY_GREY};
      font-weight: 300;
      box-shadow: none;

      &.header {
        background-color: ${COLORS.PRIMARY_GREY};

        position: sticky;
        top: -1rem;
        z-index: 1;
      }

      &.fillColor td {
        background-color: ${COLORS.PRIMARY_GREY};

        // background-color: ${Colors.light.table.normal};
      }

      &.active td {
        background-color: ${COLORS.PRIMARY_GREY};

        // background-color: ${Colors.light.table.hightlight};
      }
    }

    & th,
    td {
      // color: ${Colors.light.contrast.lightQuaternary};
      color: ${COLORS.TEXT_MEDIUM_GREY};
      font-weight: 300;
  padding: 20px;
  width: 90%;

      text-transform: capitalize;
      font-size: 1.8rem;
      border-radius: 0px !important;
      // background-color: ${Colors.light.core.secondary};
      background-color: ${COLORS.PRIMARY_GREY};
    }

    td {
      background-color: ${COLORS.PRIMARY_GREY};
  color: ${COLORS.TEXT_MEDIUM_GREY};
  width: 90%;

    }

    td.groupHeading {
      border-right: none;
      background-color: ${COLORS.PRIMARY_GREY};
    }

    & th {
      color: ${Colors.light.contrast.darkSecondary};
  background-color: ${COLORS.PRIMARY_GREY};
  font-weight: 300;

    padding: 10px;
    & td.ai_left {
      text-align: left;
      padding: 2rem;
    }
  }

  &.noBorder {
    & th,
    td {
      border-right: 0px solid;
      text-align: left;
  background-color: ${COLORS.PRIMARY_GREY};
      font-weight: 300;
  padding: 20px;
  width: 90%;

    }
  }
`;

export const Th = styled.th`
  font-weight: 300;
  padding: 20px;
  color: ${COLORS.TEXT_MEDIUM_GREY};
  // padding-bottom: 1rem;
  background-color: ${COLORS.PRIMARY_BLACK};
`;

export const Tr = styled.tr`
  min-height: 100px;
  background-color: ${COLORS.PRIMARY_BLACK};
  color: ${COLORS.TEXT_MEDIUM_GREY};

  &.header {
    box-shadow: none;
    border-radius: 0;
  }

  &.child {
    padding-left: 3rem;
  }

  & > th:last-child {
    border-right: 0;
  }

  & > td:last-child {
    border-right: 0;

    ::after {
      border: 0;
    }
    ::before {
      border: 0;
    }
  }
  position: relative;
  &.nested {
    display: none;
  }
`;

export const Td = styled.td`
  font-size: 12px;
  font-weight: 500;
  color: ${({ color }) => color || COLORS.NOTIFICATION_INACTIVE_COLOR};
  background-color: ${COLORS.PRIMARY_BLACK};
  padding: 8px;
  position: relative;
  text-align: center;
  border-bottom: 1px solid ${COLORS.PRIMARY_GREY};
  font-family: PoppinsRegular;

  & p {
    font-size: ${fontSize.text};
  }

  ${(props) =>
    props.color &&
    css`
      // background-color: ${props.color} !important;
    `};

  /* &::before {
      content: '';
      height: 2rem;
      width: 1px;
    
      position: absolute;
      right: -1px;
      top: -14px;
    } */

  /* &::after {
      content: '';
      height: 2rem;
      width: 1px;
     
      position: absolute;
      right: -1px;
      bottom: -14px;
    } */
`;
export const RowOptionsKabab = styled.div`
  position: relative;
  padding: 0px 2px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
`;
export const OptionDropDownContainer = styled.div`
  position: absolute;
  min-width: max-content;
  // min-height: 20px;
  background-color: ${COLORS.BACKGROUND_COLOR};
  color: ${COLORS.TEXT_MEDIUM_GREY};

  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px;
  top: 20px;
  right: 20px;
  border-radius: 6px;
  z-index: 20;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
`;
export const DropDownListContainer = styled.div`
  // padding: 5px;
  column-gap: 10px;
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
`;
export const DropDownIcons = styled.div``;

export const DropDownText = styled.div`
  cursor: pointer;
`;
export const LinkUrl = styled.a`
  color: ${COLORS.NOTIFICATION_ACTIVE_COLOR};
  font-family: PoppinsRegular;
  font-size: 12px;
`;
