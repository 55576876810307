import { StyledModal } from '../../constants/BaseStyles';
import { LoaderMainConatiner, LoaderContainer } from './Loader.Styles';

const Loader = ({ open }) => {
  return (
    <StyledModal isOpen={open} width={'auto'} height={'auto'}>
      <LoaderMainConatiner>
        <LoaderContainer />
      </LoaderMainConatiner>
    </StyledModal>
  );
};

export default Loader;
