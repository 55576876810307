import {
  AddNewText,
  AddProjectContainer,
  EmptyProjectPage,
  NoProjectText,
  PlaceHolderImage,
  TableContainer,
} from './EmptyFile.Styles';

const Empty = ({ heading, hubheading1, subheading2, height }) => {
  return (
    <EmptyProjectPage>
      <TableContainer height={height}>
        <PlaceHolderImage>
          {/* <NoProjImage src={EmptySheet} /> */}
        </PlaceHolderImage>
        <NoProjectText>{heading}</NoProjectText>
        <AddProjectContainer>
          <AddNewText>{hubheading1}</AddNewText>
          <AddNewText>{subheading2}</AddNewText>
        </AddProjectContainer>
      </TableContainer>
    </EmptyProjectPage>
  );
};
export default Empty;
